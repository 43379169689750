import {Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BreadCrumbElement } from '../../../../shared/entities/breadcrumb.element.entity';
import { EventsService } from '../../../../services/events/events.service';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-bl-keyword-info',
  templateUrl: './bl-keyword-info.component.html'
})
export class BlKeywordInfoComponent {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public blKeywordId: string;
  public blKeyword: any;

  /* */
  public housing;

  /* */
  constructor(private activatedRoute: ActivatedRoute,
              private eventsService: EventsService,
              private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient) {
    this.blKeywordId = this.activatedRoute.snapshot.params['blKeywordId'];
    this.requestData();

    this.eventsService.on('blKeywordInfoReloadBlKeywordData', () => {
      this.requestData();
    });
  }

  public requestData() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/pages/bl-keyword/' + this.blKeywordId)
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
          console.log(response);
          this.blKeyword = response.pageBlKeyword;
          this.updateBreadcrumb();
        },
        err => {
          this.loadingIndicatorService.hideLoadingBar();
        });
  }

  updateBreadcrumb() {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Pages', 'javascript:void(0)'),
      new BreadCrumbElement('BL Keywords', 'javascript:void(0)'),
      new BreadCrumbElement(this.blKeywordId, 'javascript:void(0)')
    ];
  }

}
