import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../../../../environments/environment';
import { LoadingIndicatorService } from '../../../../../services/loading-indicator/loading-indicator.service';
import { HousingInfoService } from '../../../../../services/housing-info/housing-info.service';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '../../../../../services/events/events.service';

@Component({
  selector: 'app-housing-info-details-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.css']
})
export class HousingInfoDetailsEmailSettingsComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  form: FormGroup;
  isLoading = false;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'contractReminderAfterBooking': new FormControl({ value: false, disabled: true }),
      'housingRequestReminder': new FormControl({ value: false, disabled: true }),
      'sendDepartureBillingNotification': new FormControl({ value: false, disabled: true }),
      'recommendedActions': new FormControl({ value: false, disabled: true }),
      'bookingConfirmationReminder': new FormControl({ value: false, disabled: true })
    });
  }

  onSubmitForm() {
    if (this.form.status !== 'VALID') {
      return;
    }
    this.showLoading();

    this.postData().subscribe(
      (response: any) => {
        this.hideLoading();
        this.housingInfoService.setSelectedHousing(response);
      },
      err => {

        if (err.error.message === 'E_VALIDATION_FAILED') {
          try {
            for (const error of err.error.errors) {
              this.form.get(error.property).setErrors(error.constraints);
              this.form.get(error.property).markAsDirty();
            }
          } catch (error) {
            console.log('Catched E', error);
          }
          this.hideLoading();
          return;
        }

        this.hideLoading();
        this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
      }
    );
  }

  private postData() {
    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/preferences/emails', {
      contractReminderAfterBooking: this.form.get('contractReminderAfterBooking').value,
      housingRequestReminder: this.form.get('housingRequestReminder').value,
      sendDepartureBillingNotification: this.form.get('sendDepartureBillingNotification').value,
      recommendedActions: this.form.get('recommendedActions').value,
      bookingConfirmationReminder: this.form.get('bookingConfirmationReminder').value,
    });
  }

  ngOnInit() {
    if (this.housing != null) {
      this.mapHousingToForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  private mapHousingToForm() {
    const vals = {
      contractReminderAfterBooking: this.housing.preferences == null || this.housing.preferences.emails == null || this.housing.preferences.emails.contractReminderAfterBooking == null ? false : this.housing.preferences.emails.contractReminderAfterBooking,
      housingRequestReminder: this.housing.preferences == null || this.housing.preferences.emails == null || this.housing.preferences.emails.housingRequestReminder == null ? false : this.housing.preferences.emails.housingRequestReminder,
      sendDepartureBillingNotification: this.housing.preferences == null || this.housing.preferences.emails == null || this.housing.preferences.emails.sendDepartureBillingNotification == null ? false : this.housing.preferences.emails.sendDepartureBillingNotification,
      recommendedActions: this.housing.preferences == null || this.housing.preferences.emails == null || this.housing.preferences.emails.recommendedActions == null ? false : this.housing.preferences.emails.recommendedActions,
      bookingConfirmationReminder: this.housing.preferences == null || this.housing.preferences.emails == null || this.housing.preferences.emails.bookingConfirmationReminder == null ? false : this.housing.preferences.emails.bookingConfirmationReminder,
    };

    this.form.setValue(
      vals
    );

    this.enableDisableFormCtrl();
  }

  private enableDisableFormCtrl() {
    const enable = (this.housing != null);
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (enable) {
          this.form.controls[key].enable();
        } else {
          this.form.controls[key].disable();
        }
      }
    }
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

}
