import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {EventsService} from "../../../../services/events/events.service";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-validation-error-details-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class ValidationErrorDetailsGeneralComponent implements OnInit {
  @Input('validationError') public validationError;

  constructor(private eventsService: EventsService,
              private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient) {
  }

  ngOnInit() {
  }

  public stringify(o: any) {
    return JSON.stringify(o, null, "\t")
  }

  public changeState(s: string) {
    this.loadingIndicatorService.showLoadingBar();

    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/validation-error/' + this.validationError._id, {state: s})
    // this.http.get(environment.urls.service + '/api/v1/authenticated/admin/validation-error')
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
        },
        err => {
          this.loadingIndicatorService.hideLoadingBar();
        });
  }

  public delete() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.delete(environment.urls.service + '/api/v1/authenticated/admin/validation-error/' + this.validationError._id)
    // this.http.get(environment.urls.service + '/api/v1/authenticated/admin/validation-error')
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
        },
        err => {
          this.loadingIndicatorService.hideLoadingBar();
        });
  }

}
