<div class="block block-rounded block-themed" *ngIf="validationError != null">
  <div class="block-header bg-gd-primary">
    <h3 class="block-title">General Information</h3>
    <div class="block-options">
    </div>
  </div>
  <div class="block-content block-content-full">
    <div class="form-group row">
      <label class="col-4" for="details-dateStart">Path</label>
      <div class="col-8 text-left">
        <span id="details-dateStart"
              name="details-dateStart">{{validationError.path}}</span>
      </div>

      <label class="col-4" for="details-dateTo">Request Type</label>
      <div class="col-8 text-left">
        <span id="details-dateTo" name="details-dateTo">{{validationError.requestType}}</span>
      </div>

      <label class="col-4" for="details-nights">Created At</label>
      <div class="col-8 text-left">
        <span id="details-nights" name="details-nights">{{validationError.createdAt}}</span>
      </div>

      <label class="col-4" for="details-constraints">Error</label>
      <div class="col-8 text-left">
        <code id="details-constraints" name="details-constraints">{{stringify(validationError.detectedErrors)}}</code>
      </div>
    </div>
    <div class="form-group row">
      <button type="button" class="btn btn-default" (click)="changeState('NEW')">New</button> &nbsp;
      <button type="button" class="btn btn-primary" (click)="changeState('IN_PROGRESS')">In Progress</button> &nbsp;
      <button type="button" class="btn btn-success" (click)="changeState('FINISHED')">Finished</button> &nbsp;
      <button type="button" class="btn btn-warning" (click)="changeState('CANCELLED')">Cancelled</button> &nbsp;
      <button type="button" class="btn btn-danger" (dblclick)="delete()">Delete</button>
    </div>
  </div>
</div>
