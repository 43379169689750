<app-content-header i18n-title title="Instant Booking Filter" i18n-subTitle
                    subTitle="Check housings and prepare them for Instant Booking"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">
  <div class="row">
    <div class="col-sm-4 col-lg-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Navigation</h3>
        </div>

        <div class="block-content">
          <ng-select
            placeholder="Choose one.."
            id="example-select20"
            [clearable]="false"
            [items]="list_cities"
            bindLabel="text"
            bindValue="googlePlaceId"
            (change)="changedItem($event)"
            [(ngModel)]="selected_city"
            groupBy="gid">

            <ng-template ng-label-tmp let-item="item">
              <span style="margin-left:10px">{{item.text}}</span>
            </ng-template>
            <ng-template ng-optgroup-tmp let-item="item" let-index="index">
              {{item.text}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <span style="margin-left:10px">{{item.text}}</span>
            </ng-template>
          </ng-select>
        </div>
        <div class="block-content">
          <div class="list-group push">
            <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
               [ngClass]="{'active': section === 1}"
               (click)="switchSection(1)" href="javascript:void(0)">
              Housings to Check
              <span class="badge badge-pill badge-secondary">{{amountToCheck}}</span>
            </a>
            <a
              class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
              [ngClass]="{'active': section === 2}"
              (click)="switchSection(2)" href="javascript:void(0)">
              Declined Housings
              <span class="badge badge-pill badge-secondary">{{amountDeclined}}</span>
            </a>
            <a
              class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
              [ngClass]="{'active': section === 3}"
              (click)="switchSection(3)" href="javascript:void(0)">
              Accepted Housings
              <span class="badge badge-pill badge-secondary">{{amountAccepted}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Housings To Check</h3>
          <div class="block-options" *ngIf="section === 2">
            <button type="button" class="btn btn-sm btn-secondary" (click)="downloadDeclinedHousings()">Download
              Declined Housings
            </button>
          </div>
          <div class="block-options" *ngIf="section === 3">
            <button type="button" class="btn btn-sm btn-secondary" (click)="downloadCheckedHousings()">Download Accepted
              Housings
            </button>
          </div>
        </div>
        <div class="block-content block-content-full">
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th class="text-center">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let housing of list_housings; let i = index">
                <td>#{{((page*pageSize)+i+1)-pageSize}}</td>
                <td>
                  {{housing.name}}
                </td>
                <td class="text-center"><a type="button" class="btn btn-sm btn-success mb-10"
                                           [routerLink]="['/housings',housing._id,'details']"
                                           [queryParams]="{ibList:true, googlePlaceId: selected_city, page: page}">Edit</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="block-content block-content-sm bg-body-light font-size-sm">
          <nav aria-label="Page navigation" *ngIf="pagination != null">
            <ul class="pagination">

              <li class="page-item" [ngClass]="{'disabled': page-1 <= 0 }">
                <a class="page-link" (click)="switchPage(page-1)" tabindex="-1" aria-label="Previous">
                                                    <span aria-hidden="true">
                                                        <i class="fa fa-angle-double-left"></i>
                                                    </span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>

              <li class="page-item" *ngFor="let localPage of paginationNums"
                  [ngClass]="{'active': page === localPage }">
                <a class="page-link" (click)="switchPage(localPage)">{{localPage}}</a>
              </li>

              <li class="page-item"
                  [ngClass]="{'disabled': page+1 >= pagination.pageMax || localPage+1 >= pagination.pageMax }">
                <a class="page-link" (click)="switchPage(page+1)" aria-label="Next">
                                                    <span aria-hidden="true">
                                                        <i class="fa fa-angle-double-right"></i>
                                                    </span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
