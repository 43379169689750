<app-content-header title="Housing Request Filter Check"
                    subTitle="See why those housings don't show up"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">
  <div class="row">
    <div class="col-6 offset-3">
      <form [formGroup]="form" (submit)="onSubmitForm()">
        <div class="block">
          <div class="block-header block-header-default">
            <h3 class="block-title">Check for blocking Housing Request Filters</h3>
            <div class="block-options">
            </div>
          </div>
          <div class="block-content block-content-full">

            <div class="form-group row">
              <label class="col-4 col-form-label" for="housing-id">Housing Id</label>
              <div class="col-8"
                   [ngClass]="{'is-invalid': (form.get('housingId').touched || form.get('housingId').dirty) && form.get('housingId').invalid}">
                <input type="text" class="form-control" id="housing-id" name="housing-id"
                       placeholder="Please enter the Housing Id" formControlName="housingId">
                <div class="invalid-feedback"
                     *ngFor="let error of extractValidationErrorsAsArray(form.get('housingId').errors)">
                  {{error}}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label" for="request-id">Request Id</label>
              <div class="col-8"
                   [ngClass]="{'is-invalid': (form.get('requestId').touched || form.get('requestId').dirty) && form.get('requestId').invalid}">
                <input type="text" class="form-control" id="request-id" name="request-id"
                       placeholder="Please enter the Request Id" formControlName="requestId">
                <div class="invalid-feedback"
                     *ngFor="let error of extractValidationErrorsAsArray(form.get('requestId').errors)">
                  {{error}}
                </div>
              </div>
            </div>

            <div class="form-group">
              <button type="submit" class="btn btn-md btn-alt-primary">
                Check
              </button>
            </div>

          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- END Sauce -->


  <div class="row" *ngIf="blockingFilters != null">
    <div class="col-12">
      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Result Set</h3>
          <div class="block-options">
          </div>
        </div>
        <div class="block-content block-content-full">

          <div class="row">
            <div class="col-12">
              <table class="table table-sm">
                <thead>
                <th>Filter</th>
                <th>Filter Id</th>
                <th>Link</th>
                </thead>
                <tbody>
                <tr *ngFor="let blockingFilter of blockingFilters">
                  <td>{{housingRequestFilterTranslationService.translateHousingRequestFilterToReadableString(blockingFilter.filter)}}</td>
                  <td>{{blockingFilter.filterId}}</td>
                  <td><a class="badge badge-success" href="{{environment.urls.partner}}/de/h/{{housingId}}/request-filter/{{housingRequestFilterTranslationService.translateHousingRequestFilterToReadablePartnerURL(blockingFilter.filter)}}/{{blockingFilter.filterId}}"
                         target="_blank">Filter</a></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
