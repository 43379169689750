import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-content-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent {
    @Input() public title: string;
    @Input() public subTitle: string;
    @Input() public backgroundImage: string = '/assets/img/photos/photo21@2x.jpg';
    @Input() public containerBackgroundImage: any = { 'background-image': 'url(' + this.backgroundImage + ')'};
    @Input() public containerClass: any = {'bg-image': true};
    @Input() public subContainerClass: any = {'bg-primary-dark-op': true};
    @Input() public subContainerBackgroundImage: any = {};
    @Input() public contentClass: any = {'content': true, 'content-full': true, 'content-top': true};
    @Input() public pyClass: any = {'py-20': true};
    @Input() public h1class: any = {'h2': true, 'font-w700': true, 'text-white': true, 'mb-10': true};
    @Input() public h2class: any = {'h4': true, 'font-w400': true, 'text-white-op': true, 'mb-0': true};
}
