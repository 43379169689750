import {Routes} from '@angular/router';
import {EditComponent} from "./boundaries-activity/edit/edit.component";
import {BoundariesComponent} from "./boundaries/boundaries.component";
import {CrawlerJobsComponent} from "./crawler-jobs/crawler-jobs.component";
import {BoundariesEditComponent} from "./boundaries-edit/boundaries-edit.component";
import {HousingFilterIbComponent} from "./housing-filter-ib/housing-filter-ib.component";
import {ValidationErrorComponent} from "./validation-error/validation-error.component";
import {ValidationErrorDetailsComponent} from "./validation-error-details/validation-error-details.component";
import {BoundariesV2Routes} from "./boundaries-v2/boundaries-v2.routes";
import {EmailExistenceComponent} from "./email-existence/email-existence.component";
import {EmailServerBlacklistComponent} from "./email-blacklist/email-server-blacklist.component";
import {EmailBouncesComponent} from "./email-bounces/email-bounces.component";
import {EmailBouncesDetailsComponent} from "./email-bounces-details/email-bounces-details.component";
import { ImportOnboardingsComponent } from './import-onboardings/import-onboardings.component';

export const DeveloperRoutes: Routes = [
  {
    path: 'developer/boundaries-activity', component: EditComponent
  },
  {
    path: 'developer/boundaries', component: BoundariesComponent
  },
  {
    path: 'developer/crawler-jobs', component: CrawlerJobsComponent
  },
  {
    path: 'developer/boundaries/:boundaryId', component: BoundariesEditComponent
  },
  {
    path: 'developer/housing-filter-ib', component: HousingFilterIbComponent
  },
  {
    path: 'developer/validation-error', component: ValidationErrorComponent
  },
  {
    path: 'developer/validation-error/:validationErrorId', component: ValidationErrorDetailsComponent
  },
  {
    path: 'developer/emails/bounces', component: EmailBouncesComponent
  },
  {
    path: 'developer/emails/bounces/:id', component: EmailBouncesDetailsComponent
  },
  {
    path: 'developer/emails/email-existence', component: EmailExistenceComponent
  },
  {
    path: 'developer/emails/email-server-blacklist', component: EmailServerBlacklistComponent
  },
  {
    path: 'developer/import/onboardings', component: ImportOnboardingsComponent
  },
  ...BoundariesV2Routes
];
