import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HousingInfoService } from '../../../../../services/housing-info/housing-info.service';
import { LoadingIndicatorService } from '../../../../../services/loading-indicator/loading-indicator.service';
import { EventsService } from '../../../../../services/events/events.service';

@Component({
  selector: 'app-housing-info-details-whitelabel-configuration',
  templateUrl: './whitelabel-configuration.component.html',
  styleUrls: ['./whitelabel-configuration.component.css']
})
export class HousingInfoDetailsWhitelabelConfigurationComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  form: FormGroup;
  isLoading: boolean = false;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'enabled': new FormControl({value: false, disabled: false}),
      'logoImageUrlBig': new FormControl({value: '', disabled: false}),
      'logoImageUrlSmall': new FormControl({value: '', disabled: false}),
      'logoImageUrlEmail': new FormControl({value: '', disabled: false}),
      'widthImageBig': new FormControl({value: '', disabled: false}),
      'heightImageBig': new FormControl({value: '', disabled: false}),
      'widthImageSmall': new FormControl({value: '', disabled: false}),
      'heightImageSmall': new FormControl({value: '', disabled: false}),
      'widthImageEmail': new FormControl({value: '', disabled: false}),
      'heightImageEmail': new FormControl({value: '', disabled: false}),
      'primaryColor': new FormControl({value: '', disabled: false}),
      'textPrimary': new FormControl({value: '', disabled: false}),
      'bgPrimary': new FormControl({value: '', disabled: false}),
      'gtc': new FormControl({value: '', disabled: false}),
    });
  }

  onSubmitForm() {
    if (this.form.status !== 'VALID') {
      this.validateAllFormFields(this.form);
      return;
    }
    this.showLoading();

    this.postData().subscribe(
      (response: any) => {
        this.hideLoading();
        this.housingInfoService.setSelectedHousing(response.housing);
      },
      err => {

        if (err.error.message === 'E_VALIDATION_FAILED') {
          this.handleErrors(err.error.errors);

          this.hideLoading();
          return;
        }

        this.hideLoading();
        this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
      }
    );
  }

  private handleErrors(errors: []) {
    this.handleErrorChildren(errors);
  }

  private handleErrorChildren(errors: []) {
    for (const error of errors) {
      if (Array.isArray((error as any).children) && (error as any).children.length > 0) {
        this.handleErrorChildren((error as any).children);
      } else {
        console.log((error as any).property);
        try {
          this.form.get((error as any).property).setErrors((error as any).constraints);
          this.form.get((error as any).property).markAsDirty();
        } catch (error) {
          console.log('Catched Error while setting validation errors', error);
        }
      }
    }
  }

  private postData() {
    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/hbt', {
      enabled: this.createNullIfEmptyString(this.form.get('enabled').value),
      whitelabelConfiguration: {
        assets: {
          images: {
            logoImageUrlBig: this.createNullIfEmptyString(this.form.get('logoImageUrlBig').value),
            logoImageUrlSmall: this.createNullIfEmptyString(this.form.get('logoImageUrlSmall').value),
            logoImageUrlEmail: this.createNullIfEmptyString(this.form.get('logoImageUrlEmail').value),
            widthImageBig: this.createNullIfEmptyStringOrReturnFloat(this.form.get('widthImageBig').value),
            heightImageBig: this.createNullIfEmptyStringOrReturnFloat(this.form.get('heightImageBig').value),
            widthImageSmall: this.createNullIfEmptyStringOrReturnFloat(this.form.get('widthImageSmall').value),
            heightImageSmall: this.createNullIfEmptyStringOrReturnFloat(this.form.get('heightImageSmall').value),
            widthImageEmail: this.createNullIfEmptyStringOrReturnFloat(this.form.get('widthImageEmail').value),
            heightImageEmail: this.createNullIfEmptyStringOrReturnFloat(this.form.get('heightImageEmail').value),
          }
        },
        theme: {
          colors: {
            primaryColor: this.createNullIfEmptyString(this.form.get('primaryColor').value),
            textPrimary: this.createNullIfEmptyString(this.form.get('textPrimary').value),
            bgPrimary: this.createNullIfEmptyString(this.form.get('bgPrimary').value),
          }
        },
        documents: {
          gtc: this.createNullIfEmptyString(this.form.get('gtc').value),
        },
      },
    });
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    return s;
  }

  ngOnInit() {
    if (this.housing != null) {
      this.mapHousingToForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private mapHousingToForm() {
    const vals = {
      enabled: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.enabled == null) ? false : this.housing.housingBookingToolConfiguration.enabled,
      logoImageUrlBig: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.logoImageUrlBig == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.logoImageUrlBig,
      logoImageUrlSmall: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.logoImageUrlSmall == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.logoImageUrlSmall,
      logoImageUrlEmail: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.logoImageUrlEmail == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.logoImageUrlEmail,
      widthImageBig: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.widthImageBig == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.widthImageBig,
      heightImageBig: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.heightImageBig == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.heightImageBig,
      widthImageSmall: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.widthImageSmall == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.widthImageSmall,
      heightImageSmall: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.heightImageSmall == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.heightImageSmall,
      widthImageEmail: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.widthImageEmail == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.widthImageEmail,
      heightImageEmail: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.heightImageEmail == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.assets.images.heightImageEmail,
      primaryColor: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.theme.colors.primaryColor == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.theme.colors.primaryColor,
      textPrimary: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.theme.colors.textPrimary == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.theme.colors.textPrimary,
      bgPrimary: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.theme.colors.bgPrimary == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.theme.colors.bgPrimary,
      gtc: (this.housing.housingBookingToolConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration == null || this.housing.housingBookingToolConfiguration.whitelabelConfiguration.documents.gtc == null) ? '' : this.housing.housingBookingToolConfiguration.whitelabelConfiguration.documents.gtc,
    };
    this.form.setValue(
      vals
    );

  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

  private createNullIfEmptyStringOrReturnFloat(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    const d = parseFloat(s);

    if (isNaN(d)) {
      return s;
    }

    return d;
  }
}
