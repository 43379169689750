<form [formGroup]="form">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Hyperguest Configuration</h3>
    </div>
    <div class="block-content block-content-full">
      <div class="form-group row">
        <label class="col-4 col-form-label" for="subscriptionId">Subscription Id</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('subscriptionId').touched || form.get('subscriptionId').dirty) && form.get('subscriptionId').invalid}">
          <input type="text" class="form-control" id="subscriptionId" name="subscriptionId"
                 placeholder="Subscription Id of Housing" formControlName="subscriptionId">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-12 text-left">
          <button type="submit" class="btn btn-sm btn-alt-primary" (click)="handleRequestSubscribe()"
                  [disabled]="housing == null || isLoading === true"
                  *ngIf="housing.channelManager.cmSubscriptionId == null">
            Request Subscription Id
          </button>
          &nbsp;
          <button type="submit" class="btn btn-sm btn-alt-primary" (click)="handleRequestSubscriptionDetails()"
                  [disabled]="housing == null || isLoading === true"
                  *ngIf="housing.channelManager.cmSubscriptionId != null">
            Subscription Details
          </button>
          &nbsp;
          <button type="submit" class="btn btn-sm btn-alt-success" (click)="handleRequestFullAriSync()"
                  [disabled]="housing == null || isLoading === true"
                  *ngIf="housing.channelManager.cmSubscriptionId != null">
            Request Full ARI Sync
          </button>
          &nbsp;
          <button type="submit" class="btn btn-sm btn-warning" (click)="handleRequestDisableSubscription()"
                  [disabled]="housing == null || isLoading === true"
                  *ngIf="housing.channelManager.cmSubscriptionId != null">
            Disable Subscription
          </button>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12 text-left">
          <button type="submit" class="btn btn-sm btn-success" (click)="handleRequestEnableSubscription()"
                  [disabled]="housing == null || isLoading === true"
                  *ngIf="housing.channelManager.cmSubscriptionId != null">
            Enable Subscription
          </button>
          &nbsp;
          <button type="submit" class="btn btn-sm btn-danger" (click)="handleRequestUnsubscribeSubscription()"
                  [disabled]="housing == null || isLoading === true"
                  *ngIf="housing.channelManager.cmSubscriptionId != null">
            Unsubscribe
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<!-- END Sauce -->

<tripmakery-request-cm-settings-hyperguest-response-modal></tripmakery-request-cm-settings-hyperguest-response-modal>
