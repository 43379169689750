import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private jwt: string;

  constructor() {

  }

  public setJWT(s: string) {
    if (s == null) {
      localStorage.removeItem("app.login.jwt");
      return;
    }
    localStorage.setItem("app.login.jwt", s);
  }

  public setRoles(s: Array<string>) {
    localStorage.setItem("app.user.roles", JSON.stringify(s));
  }

  public getJWT() {
    return localStorage.getItem("app.login.jwt");

  }

  public getRoles() {
    const r = localStorage.getItem("app.user.roles");
  }


}
