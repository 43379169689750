import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { BreadCrumbElement } from '../../../shared/entities/breadcrumb.element.entity';
import { LoadingIndicatorService } from '../../../services/loading-indicator/loading-indicator.service';
import { EventsService } from '../../../services/events/events.service';
import { environment } from '../../../../environments/environment';
import {
  HousingRequestFilterTranslationService
} from '../../../services/housing-request-filter/housing-request-filter-translation.service';

@Component({
  selector: 'app-housing-request-filter-check',
  templateUrl: './housing-request-filter-check.component.html',
  styleUrls: ['./housing-request-filter-check.component.css']
})
export class HousingRequestFilterCheckComponent implements OnInit {
  /* */
  public blockingFilters;
  public environment = environment;


  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [
    new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
    new BreadCrumbElement('Housing Requests', 'javascript:void(0)'),
    new BreadCrumbElement('Housing Request Filter Check', 'javascript:void(0)')
  ];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public form: FormGroup;

  public housingId;

  /* */
  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
              private http: HttpClient,
              public housingRequestFilterTranslationService: HousingRequestFilterTranslationService,
              private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      'housingId': new FormControl({value: '', disabled: false}),
      'requestId': new FormControl({value: '', disabled: false})
    });
  }

  public async onSubmitForm() {
    /* */
    this.blockingFilters = null;

    try {
      /* */
      this.housingId = this.form.get('housingId').value;

      /* */
      const response = await this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing-filter-statistic/check-filters', {
        housingId: this.form.get('housingId').value,
        requestId: this.form.get('requestId').value
      }).toPromise();

      /* */
      this.blockingFilters = (response as any).blockingFilters;

    } catch (err) {
      if (err.error.message === 'E_VALIDATION_FAILED') {
        try {
          for (const error of err.error.errors) {
            this.form.get(error.property).setErrors(error.constraints);
            this.form.get(error.property).markAsDirty();
          }
        } catch (error) {
          console.log('Catched E', error);
        }
      } else {
        this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
      }
    }

    /* */
    this.changeDetectorRef.detectChanges();
  }

  /**
   * @TODO Static Method in Error.Utils
   * @param errors
   */
  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

}

