<div class="block block-rounded block-themed" *ngIf="details != null">
  <div class="block-header bg-gd-primary">
    <h3 class="block-title">Booking Details</h3>
    <div class="block-options">
    </div>
  </div>
  <div class="block-content block-content-full">
    <h3>General</h3>
    <div class="form-group row">
      <label class="col-4" for="details-dateStart">From</label>
      <div class="col-8 text-left">
        <span id="details-dateStart"
              name="details-dateStart">{{moment.utc(details.dateStart).format("YYYY-MM-DD")}}</span>
      </div>

      <label class="col-4" for="details-dateTo">To</label>
      <div class="col-8 text-left">
        <span id="details-dateTo" name="details-dateTo">{{moment.utc(details.dateEnd).format("YYYY-MM-DD")}}</span>
      </div>

      <label class="col-4" for="details-nights">Nights</label>
      <div class="col-8 text-left">
        <span id="details-nights" name="details-nights">{{details.nights}}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-4" for="details-adults">Adults</label>
      <div class="col-8 text-left">
        <span id="details-adults" name="details-adults">{{details.adults}}</span>
      </div>

      <label class="col-4" for="details-dateTo">Children</label>
      <div class="col-8 text-left">
        <span id="details-children" name="details-children">{{details.children}}</span>
      </div>

    </div>
    <div class="form-group row">
      <label class="col-4" for="details-groupType">Group Type</label>
      <div class="col-8">
        <span id="details-groupType" name="details-groupType">{{details.groupType}}</span>
      </div>

      <label class="col-4" for="details-organization">Organization</label>
      <div class="col-8">
        <span id="details-organization" name="details-organization">{{details.organization}}</span>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-4">Reservation</label>
      <div class="col-8">
        <span class="badge badge-success" *ngIf="details.isInstantBooking === true">Yes</span>
        <span class="badge badge-warning" *ngIf="details.isInstantBooking === false">No</span>
      </div>
    </div>

    <h3>Price</h3>
    <div class="form-group row">
      <label class="col-4" for="details-price-sleeping">Price Total (Sleeping)</label>
      <div class="col-8">
        <span id="details-price-sleeping" name="details-price-sleeping">{{details.priceSleepingTotal | delimitednumber:2}}</span>
      </div>

      <label class="col-4" for="details-price-with-breakfast">Price Total (with extra Breakfast)</label>
      <div class="col-8">
        <span id="details-price-with-breakfast" name="details-price-with-breakfast">{{details.priceTotal | delimitednumber:2}}</span>
      </div>

    </div>

    <h3>Requested Rooms</h3>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th class="text-left">Room Type</th>
          <th class="text-right">Persons</th>
          <th class="text-right">Amount</th>
          <th class="text-center">Breakfast Included</th>
          <th class="text-right">Price Total (Sleeping)</th>
          <th class="text-right">Price Total (With Breakfast)</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let room of details.rooms">
          <td class="text-left">{{room.roomType}}</td>
          <td class="text-right">{{room.persons}}</td>
          <td class="text-right">{{room.amount}}</td>
          <td class="text-center">
            <span class="badge badge-success" *ngIf="room.breakfastIncluded === true">Yes</span>
            <span class="badge badge-warning" *ngIf="room.breakfastIncluded === false">No</span>
          </td>
          <td class="text-right">{{room.price.sleepingTotal | delimitednumber:2}}</td>
          <td class="text-right">{{room.price.total | delimitednumber:2}}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <ng-container *ngIf="details.messageForHotel != null && details.messageForHotel.trim() !== ''">
      <h3>Message for Hotel</h3>
      <span>{{details.messageForHotel}}</span>

    </ng-container>
  </div>
</div>
