<div bsModal [config]="bsConfig" #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="modal-popin"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" bsModal>
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">Add a new Generated Text Destination</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" data-dismiss="modal" aria-label="Close"
                    (click)="hideChildModal()">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content block-content-full">

          <form [formGroup]="form">
            <div class="row">

              <div class="col-md-12">

                <!-- -->
                <div class="form-group row"
                     [ngClass]="{'is-invalid': form.get('identifier').dirty === true && form.get('identifier').status === 'INVALID'}">
                  <label class="col-12" for="identifier">Identifier</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="identifier"
                           name="keyword"
                           placeholder="Identifier for implementation" formControlName="identifier">
                    <div class="invalid-feedback"
                         *ngFor="let error of extractValidationErrorsAsArray(form.get('identifier').errors)">{{error}}
                    </div>
                  </div>
                </div>

                <!-- -->
                <div class="form-group row"
                     [ngClass]="{'is-invalid': form.get('description').dirty === true && form.get('description').status === 'INVALID'}">
                  <label class="col-12" for="identifier">Description</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="description"
                           name="keyword"
                           placeholder="Description of this text" formControlName="description">
                    <div class="invalid-feedback"
                         *ngFor="let error of extractValidationErrorsAsArray(form.get('description').errors)">{{error}}
                    </div>
                  </div>
                </div>

                <!-- -->
                <div class="form-group row"
                     [ngClass]="{'is-invalid': form.get('patternId').dirty === true && form.get('patternId').status === 'INVALID'}">
                  <label class="col-12" for="identifier">Pattern</label>
                  <div class="col-12">
                    <select class="form-control" formControlName="patternId" *ngIf="patterns != null">
                      <option selected>Please select..</option>
                      <option *ngFor="let element of patterns"
                              value="{{element._id}}">{{element.patternName}}</option>
                    </select>
                  </div>
                  <div class="invalid-feedback"
                       *ngFor="let error of extractValidationErrorsAsArray(form.get('patternId').errors)">{{error}}
                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-alt-secondary" data-dismiss="modal" (click)="hideChildModal()">Abort
        </button>
        <button type="button" class="btn btn-alt-success" (click)="handleClick()">
          <i class="fa fa-check"></i> Add Entry
        </button>
      </div>
    </div>
  </div>
</div>

