<div id="page-container" class="sidebar-o side-scroll page-header-fixed page-header-glass side-trans-enabled">
  <app-sidebar-alt></app-sidebar-alt>
  <app-sidebar></app-sidebar>
  <app-header></app-header>

  <main id="main-container">
    <router-outlet></router-outlet>
  </main>

  <app-footer></app-footer>
</div>
