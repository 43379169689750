<form [formGroup]="form" (submit)="onSubmitForm()">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Checked by Team</h3>
      <div class="block-options">
        <button type="submit" class="btn btn-sm btn-alt-primary" (click)="onSubmitForm()" [disabled]="housing == null || isLoading === true">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
        </button>
      </div>
    </div>
    <div class="block-content block-content-full">

      <div class="form-group row">
        <div class="col-4 offset-5">
          <label class="css-control css-control-success css-switch">
            <input type="checkbox" class="css-control-input" id="checkedByTeam" name="checkedByTeam"
                   formControlName="checkedByTeam">
            <span class="css-control-indicator"></span>
          </label>
        </div>
      </div>

    </div>
  </div>
</form>
