import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { EventsService } from '../../../../services/events/events.service';
import { environment } from '../../../../../environments/environment';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';

@Component({
  selector: 'tripmakery-offer-messages-edit-modal',
  templateUrl: './modal-offer-messages-edit.component.html',
  styleUrls: ['./modal-offer-messages-edit.component.css']
})
export class ModalOfferMessagesEditComponent implements OnInit {
  public offerId: string;
  public messageId: string;
  public offerMessageData: any;
  public form: FormGroup;
  public environment_;

  public bsConfig = {
    ignoreBackdropClick: true,
    backdrop: true,
  };

  @ViewChild('childModal') public childModal: ModalDirective;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'state': new FormControl({value: '', disabled: false}),
      'adminText': new FormControl({value: '', disabled: false}),
    });
    this.environment_ = environment;
  }

  ngOnInit() {
    this.eventsService.on('requestShowModalOfferMessageEdit', (property) => {
      this.offerId = property.offerId;
      this.messageId = property.messageId;
      this.loadChangeRequestDetails();
      this.showChildModal();
    });
  }

  private async loadChangeRequestDetails() {
    this.loadingIndicatorService.showLoadingBar();
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/offer/' + this.offerId + '/messages/' + this.messageId)
      .subscribe(
        async (response: any) => {

          /* */
          response.frontend = {
            urlUserRequest: await this.createUrlToUserAreaForRequest(response.requestId, response.userId, response.isB2bRequest),
            urlUserOffer: await this.createUrlToUserAreaForOffer(response.offerId, response.userId, response.isB2bRequest)
          };

          /* */
          this.offerMessageData = response;

          /* */
          this.form.get('state').setValue(this.offerMessageData.message.state);
          this.form.get('adminText').setValue(this.offerMessageData.message.adminText);
        },
        (error) => {
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public showChildModal(): void {
    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/offer/' + this.offerId + '/messages/' + this.messageId, {
      adminText: this.createNullIfEmptyString(this.form.get('adminText').value),
      state: this.createNullIfEmptyString(this.form.get('state').value),
    })
      .subscribe(
        (response: any) => {
          this.eventsService.broadcast('refreshOfferMessageList');
        },
        (error) => {
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.hideChildModal();
        }
      );
  }

  public handleFastStateSwitchWithoutEmail(state: string) {
    // this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/offer/change-requests/' + this.offerChangeRequestId + '/fast-state-switch', {
    //   state: state,
    // })
    //   .subscribe(
    //     (response: any) => {
    //       this.eventsService.broadcast('refreshOfferMessageList');
    //     },
    //     (error) => {
    //       this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
    //     },
    //     () => {
    //       this.hideChildModal();
    //     }
    //   );
  }

  public handleResendPartnerMail() {
    // this.http.post(environment.urls.service + '/api/v1/authenticated/admin/offer/change-requests/' + this.offerChangeRequestId + '/emails/partner/creation-notification', {})
    //   .subscribe(
    //     (response: any) => {
    //       this.eventsService.broadcast('refreshOfferMessageList');
    //     },
    //     (error) => {
    //       this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
    //     },
    //     () => {
    //       this.offerChangeRequestData.emailStatus.housing.creationNotification = {
    //         sent: false,
    //         sentAt: null,
    //       };
    //       // this.hideChildModal();
    //     }
    //   );
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    return s;
  }

  private createFalseIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return false;
    }
    return s;
  }

  public async createUrlToUserAreaForRequest(requestId: string,
                                             userId: string,
                                             b2bRequest: boolean) {
    if (b2bRequest === true) {
      const token = await this.handleGetLoginToken(userId);
      return environment.urls.pro + '/dashboard/requests/' + requestId + '?token=' + token;
    }
    return environment.urls.user + '/de/request/' + requestId + '/dashboard';
  }

  public async createUrlToUserAreaForOffer(offerId: string,
                                           userId: string,
                                           b2bRequest: boolean) {
    if (b2bRequest === true) {
      const token = await this.handleGetLoginToken(userId);
      return environment.urls.pro + '/offer/' + offerId + '?token=' + token;
    }
    return environment.urls.user + '/de/offer/' + offerId;
  }

  private async handleGetLoginToken(userId: string) {
    const data = await this.http.post(environment.urls.service + '/api/v1/authenticated/admin/user/' + userId + '/login-token', {}).toPromise();
    return (data as any).loginToken.token;
  }
}
