import {Component, OnInit} from '@angular/core';
import {BreadCrumbElement} from "../../../shared/entities/breadcrumb.element.entity";
import {ActivatedRoute} from "@angular/router";
import {EventsService} from "../../../services/events/events.service";
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {LoadingIndicatorService} from "../../../services/loading-indicator/loading-indicator.service";
import {HousingInfoService} from "../../../services/housing-info/housing-info.service";
import * as moment from "moment";
import {FormControl, FormGroup} from "@angular/forms";
import {ConfigService} from '../../../services/configservice/config.service';

@Component({
  selector: 'app-request-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.css']
})
export class FollowUpComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [
    new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
    new BreadCrumbElement('Requests', 'javascript:void(0)'),
    new BreadCrumbElement('Follow Up', 'javascript:void(0)')
  ];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public requests;
  public paginationData;
  public currentPage = 1;

  public form: FormGroup;

  /* */
  public groupManagerList = [];
  public selectedGroupManager: any;


  /* */
  constructor(private activatedRoute: ActivatedRoute,
              public housingInfoService: HousingInfoService,
              private eventsService: EventsService,
              private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient) {
    this.form = new FormGroup({
      'requestId': new FormControl({value: '', disabled: false}),
      'groupManager': new FormControl({value: '', disabled: false}),
    })
  }

  public ngOnInit() {
    /* */
    this.eventsService.on('refreshRequestFollowUpList', () => {
      const params = this.createParams();
      this.requestBookingDataFromBackend(params);
    });

    /* */
    this.requestGroupManager();

    /* */
    const params = this.createParams();
    this.requestBookingDataFromBackend(params);
  }

  public changeGroupManager(e: any) {
    if (e == null) {
      this.selectedGroupManager = null;
      return;
    }

    this.selectedGroupManager = e._id;
  }

  public requestBookingDataFromBackend(httpParams) {
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/request/follow-up', {params: httpParams})
      .subscribe(
        (response: any) => {

          /* */
          for (const pipeline of response.elements) {
            pipeline.adminInternals.followUp.followUpAt = moment(pipeline.adminInternals.followUp.followUpAt).toDate();
            pipeline.adminInternals.followUp.followUpAtString = moment(pipeline.adminInternals.followUp.followUpAt).format('ll')
          }

          /* */
          this.requests = response.elements;
          this.paginationData = response.pagination;

          /* */
          console.log(this.paginationData);
        }
      );
  }

  public requestAutoDisqualifyDelete(requestId: string) {
    this.http.delete(environment.urls.service + '/api/v1/authenticated/admin/request/' + requestId + '/follow-up')
      .subscribe(
        (response: any) => {
          /* */
          const params = this.createParams();
          this.requestBookingDataFromBackend(params);
        }
      );
  }

  public requestGroupManager() {
    const headers = new HttpHeaders({'x-auth': environment.xauth});

    this.http.get(environment.urls.service + '/api/v1/admin/group-manager/all', {headers})
      .subscribe(
        (response: any) => {
          console.log(response);
          this.groupManagerList = response;
        }
      );
  }

  private filterRequestId: string;
  private filterGroupManager: string;

  public applyFilter() {
    this.filterRequestId = this.createNullIfEmptyString(this.form.get('requestId').value);
    this.filterGroupManager = this.createNullIfEmptyString(this.selectedGroupManager);
    this.currentPage = 1;

    const params = this.createParams();
    this.requestBookingDataFromBackend(params);
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return null;
    }
    return s;
  }


  public createParams() {
    let params = new HttpParams();

    /* */
    if (this.filterGroupManager != null) {
      params = params.append('groupManagerId', this.filterGroupManager);
    }

    if (this.filterRequestId != null) {
      params = params.append('requestId', this.filterRequestId);
    }

    /* */
    params = params.append('page', this.currentPage.toString());
    params = params.append('limit', '15');
    return params;
  }

  public sendPage(page: number) {
    this.currentPage = page;
    const changePageParams = this.createParams();
    this.requestBookingDataFromBackend(changePageParams);
  }

  public showAddModal(requestId: string, date: Date) {
    this.eventsService.broadcast('requestShowModalRequestFollowUpAdd', requestId, date);
  }

  public createKitchenUrl(requestId: string) {
    return environment.urls.kitchen + '/request/' + requestId + '/offers';
  }

  public openInAdminBoard(requestId: string) {
    window.open(environment.urls.kitchen + '/pipeline/?source=search&requestId=' + requestId, "_blank");
  }

}
