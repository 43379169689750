<div class="block block-rounded block-themed" *ngIf="housing != null">
  <div class="block-header bg-gd-primary">
    <h3 class="block-title">Housing Details</h3>
    <div class="block-options">
      <a [routerLink]="['/housings', housing._id, 'details']" class="btn btn-sm btn-secondary">
        Details
      </a>
    </div>
  </div>
  <div class="block-content">
    <div class="form-group row">
      <label class="col-12" for="housing-name">Name</label>
      <div class="col-12">
        <span id="housing-name" name="housing-name">{{housing.name}}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12" for="housing-email">Email</label>
      <div class="col-12">
        <span id="housing-email" name="housing-email">{{housing.email}}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12" for="housing-website">Website</label>
      <div class="col-12">
        <a id="housing-website" name="housing-website" href="{{housing.homepage}}" target="_blank">{{housing.homepage}}</a>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12" for="housing-address">Address</label>
      <div class="col-12">
        <span id="housing-address" name="housing-address">{{housing.location.street}}, {{housing.location.postalCode}} {{housing.location.city}}, {{housing.location.countryCode}}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12" for="housing-phone">Phone</label>
      <div class="col-12">
        <span id="housing-phone" name="housing-phone">{{housing.phone}}</span>
      </div>
    </div>
  </div>
</div>
