import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BreadCrumbElement} from '../../../../shared/entities/breadcrumb.element.entity';
import {PagingService} from '../../../../services/pagingservice/paging.service';
import {FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {LoadingIndicatorService} from '../../../../services/loading-indicator/loading-indicator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {EventsService} from '../../../../services/events/events.service';

@Component({
  selector: 'app-pages-site-creation',
  templateUrl: './site-creation.component.html',
  styleUrls: ['./site-creation.component.css']
})
export class SiteCreationComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};
  public destinations: Array<any> = [];
  public paginationData;
  public form: FormGroup;

  public isAlCreationRunning = false;
  public isBlCreationRunning = false;
  public isGlCreationRunning = false;
  public isHPCreationRunning = false;

  public isAlFillDbRunning = false;
  public isBlFillDbRunning = false;
  public isHPFillDbRunning = false;
  public isGlFillDbRunning = false;
  public isDestFillDbRunning = false;

  public isHousingListCreationRunning = false;

  constructor(private http: HttpClient,
              private eventsService: EventsService,
              private changeDetectorRef: ChangeDetectorRef,
  ) {

  }

  ngOnInit() {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Pages', 'javascript:void(0)'),
      new BreadCrumbElement('Site Creation', 'javascript:void(0)'),
    ];

    this.getStatusOfAll();

    setInterval(() => {
      this.getStatusOfAll();
    }, 60000);
  }

  public async handleCreatePagesCall(page: string) {
    await this.http.get(environment.urls.service + '/api/v1/authenticated/admin/pages/' + page + '/create-pages').toPromise();
    this.handleGetStatus(page);
  }

  public async handleCreateHousingListCall() {
    await this.http.get(environment.urls.service + '/api/v1/authenticated/admin/pages/fill-db-elements-with-housings').toPromise();
    this.handleGetStatusFillDbElementsGlobal();
  }

  public async handleCreateHousingListPerPageCall(page: string) {
    await this.http.get(environment.urls.service + '/api/v1/authenticated/admin/pages/' + page + '/fill-db-elements-with-housings').toPromise();
    this.handleGetFillDbStatus(page);
  }

  private getStatusOfAll() {
    this.handleGetStatus('bl');
    this.handleGetStatus('gl');
    this.handleGetFillDbStatus('bl');
    this.handleGetFillDbStatus('destinations');
    this.handleGetStatusFillDbElementsGlobal();
  }

  public handleGetStatus(element: string) {
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/pages/' + element + '/is-creation-running').subscribe(
      (response: any) => {
        if (element === 'bl') {
          this.isBlCreationRunning = response.isActive;
        } else if (element === 'hp') {
          this.isHPCreationRunning = response.isActive;
        } else if (element === 'gl') {
          this.isGlCreationRunning = response.isActive;
        }
      },
      (error) => {
        this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
      },
      () => {
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  public handleGetFillDbStatus(element: string) {
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/pages/' + element + '/is-fill-db-elements-with-housings-running').subscribe(
      (response: any) => {
        if (element === 'bl') {
          this.isBlFillDbRunning = response.isActive;
        } else if (element === 'hp') {
          this.isHPFillDbRunning = response.isActive;
        } else if (element === 'gl') {
          this.isGlFillDbRunning = response.isActive;
        } else if (element === 'destinations') {
          this.isDestFillDbRunning = response.isActive;
        }
      },
      (error) => {
        this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
      },
      () => {
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  public handleGetStatusFillDbElementsGlobal() {
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/pages/is-creation-running').subscribe(
      (response: any) => {
        this.isHousingListCreationRunning = response.isActive;
      },
      (error) => {
        this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
      },
      () => {
        this.changeDetectorRef.detectChanges();
      }
    );
  }


}



