import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {LoadingIndicatorService} from "../../../services/loading-indicator/loading-indicator.service";
import {ConfigService} from "../../../services/configservice/config.service";
import {EventsService} from "../../../services/events/events.service";
import {BreadCrumbElement} from "../../../shared/entities/breadcrumb.element.entity";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-crawler-jobs',
  templateUrl: './crawler-jobs.component.html'
})
export class CrawlerJobsComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public page = 1;
  public limit = 25;
  public section = 1;

  /* */
  public pagination: any;
  public elements;
  public formFilter: FormGroup;
  public formAddition: FormGroup;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private http: HttpClient) {

    /* */
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Developer', 'javascript:void(0)'),
      new BreadCrumbElement('Crawler Jobs', 'javascript:void(0)')
    ];

    /* */
    this.formFilter = new FormGroup({
      'jobType_priceIndexer': new FormControl({value: '', disabled: false}),
      'jobType_housingMatcher': new FormControl({value: '', disabled: false}),
      'source': new FormControl({value: 'booking', disabled: false}),
      'state': new FormControl({value: '', disabled: false})
    });

    /* */
    this.formAddition = new FormGroup({
      'orderBy': new FormControl({value: '', disabled: false}),
      'sortMode': new FormControl({value: false, disabled: false}),
      'limit': new FormControl({value: false, disabled: false}),
    });

  }

  ngOnInit() {
    this.loadBoundariesToCheck();
  }

  public loadBoundariesToCheck() {
    this.loadingIndicatorService.showLoadingBar();

    // /* Build params */
    let params = new HttpParams();
    params = params.append('page', this.page.toString());
    params = params.append('limit', this.limit.toString());
    params = params.append('orderBy', 'createdAt');
    params = params.append('sortMode', '-1');

    if (this.formFilter.get('jobType_housingMatcher').value === true) {
      params = params.append('jobType', 'HOUSINGMATCHER');
    }
    if (this.formFilter.get('jobType_priceIndexer').value === true) {
      params = params.append('jobType', 'PRICEINDEXER');
    }


    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/crawler-jobs/' + this.formFilter.get('source').value, {params: params})
      .subscribe((response: any) => {
          this.elements = response.elements;
          this.pagination = response.pagination;

          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }

  public requestCheckForNewEntries() {
    this.loadingIndicatorService.showLoadingBar();

    // this.http.get(environment.urls.service + '/api/v1/authenticated/admin/boundary/check-for-new')
    //   .subscribe((response: any) => {
    //       this.loadingIndicatorService.hideLoadingBar();
    //     },
    //     error => {
    //       if (error.error === "Unauthorized") {
    //       }
    //       this.loadingIndicatorService.hideLoadingBar();
    //       this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
    //     }
    //   )
  }

  public sendPage(e: number) {
    this.page = e;
    this.loadBoundariesToCheck();
  }
}
