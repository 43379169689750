<form [formGroup]="form" (submit)="onSubmitForm()">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Rooms</h3>
      <div class="block-options">
        <button type="submit" class="btn btn-sm btn-alt-primary" [disabled]="housing == null || isLoading === true">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
        </button>
      </div>
    </div>
    <div class="block-content block-content-full">
      <div class="form-group row" [ngClass]="{'is-invalid': form.get('numberOfRooms').untouched === false && form.get('numberOfRooms').status === 'INVALID'}">
        <label class="col-lg-5 col-form-label" for="general-numberOfRooms">Number Of Rooms</label>
        <div class="col-lg-7">
          <input type="text" formControlName="numberOfRooms" class="form-control" id="general-numberOfRooms" name="general-numberOfRooms" placeholder="Enter Number of Rooms..">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('numberOfRooms').errors)">{{error}}</div>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{'is-invalid': form.get('amount_singleBed').untouched === false && form.get('amount_singleBed').status === 'INVALID'}">
        <label class="col-lg-5 col-form-label" for="general-amount-singleBed">Amount Singlebed Rooms</label>
        <div class="col-lg-7">
          <input type="text" formControlName="amount_singleBed" class="form-control" id="general-amount-singleBed" name="general-amount-singleBed" placeholder="Enter Amount Single Bed Rooms..">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('amount_singleBed').errors)">{{error}}</div>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{'is-invalid': form.get('amount_doubleBed').untouched === false && form.get('amount_doubleBed').status === 'INVALID'}">
        <label class="col-lg-5 col-form-label" for="general-amount-doubleBed">Amount Doublebed Rooms</label>
        <div class="col-lg-7">
          <input type="text" formControlName="amount_doubleBed" class="form-control" id="general-amount-doubleBed" name="general-amount-doubleBed" placeholder="Enter Amount Double Bed Room..">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('amount_doubleBed').errors)">{{error}}</div>
        </div>
      </div>
    </div>
  </div>
</form>
