<div class="alert alert-primary alert-dismissable" role="alert" *ngIf="housingInDb != null && hasHousingError === true">
  <h3 class="alert-heading font-size-h4 font-w400">Housing already exists</h3>
  <p class="mb-0">We got an housing with the same address in our database already: <br>
    {{housingInDb.name}} <small>[{{housingInDb._id}}]</small><br><br>
    Click <a class="alert-link" [routerLink]="['/housings', housingInDb._id, 'details']">here</a> to view its profile!
  </p>
</div>

<div class="row">
  <div class="col-8">
    <form [formGroup]="form" (submit)="onSubmitForm()">
      <div class="block block-rounded block-themed">
        <div class="block-header bg-gd-primary">
          <h3 class="block-title">Basic Info</h3>
          <div class="block-options">
          </div>
        </div>
        <div class="block-content block-content-full">
          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('name').dirty === true && form.get('name').status === 'INVALID'}">
            <label class="col-12" for="housing-name">Name</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-name" name="housing-name" placeholder="Housing Name"
                     formControlName="name" autocomplete="off">
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('name').errors)">
                {{error}}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12" for="housing-address">City</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-city" name="housing-city"
                     placeholder="Housing City"
                     autocomplete="off"
                     formControlName="city"
                     ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                     (onAddressChange)="handleAddressChangeOfCity($event)"
              >
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12" for="housing-address">Address</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-address" name="housing-address"
                     placeholder="Housing Address"
                     autocomplete="off"
                     formControlName="address"
                     ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                     (onAddressChange)="handleAddressChangeOfAddress($event)">
            </div>
          </div>

          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('distanceToCityCenter').dirty === true && form.get('distanceToCityCenter').status === 'INVALID'}">
            <label class="col-12" for="housing-distanceToCityCenter">Distance to City Center</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-distanceToCityCenter"
                     name="housing-distanceToCityCenter"
                     placeholder="Distance to City Center (km)" formControlName="distanceToCityCenter">
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('distanceToCityCenter').errors)">{{error}}
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('email').dirty === true && form.get('email').status === 'INVALID'}">
            <label class="col-12" for="housing-email">Email</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-email" name="housing-email"
                     placeholder="Housing Email" autocomplete="false" formControlName="email">
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('email').errors)">
                {{error}}
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('phone').dirty === true && form.get('phone').status === 'INVALID'}">
            <label class="col-12" for="housing-email">Phone</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-tel" name="housing-tel" placeholder="Housing Tel"
                     formControlName="phone">
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('phone').errors)">
                {{error}}
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('homepage').dirty === true && form.get('homepage').status === 'INVALID'}">
            <label class="col-12" for="housing-website">Website</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-website" name="housing-website"
                     placeholder="Housing Website" formControlName="homepage">
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('homepage').errors)">{{error}}
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('stars').dirty === true && form.get('stars').status === 'INVALID'}">
            <label class="col-12" for="housing-stars">Stars</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-stars" name="housing-stars"
                     placeholder="Housing Stars" formControlName="stars">
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('stars').errors)">{{error}}
              </div>
            </div>
          </div>
<!--          <div class="form-group row"-->
<!--               [ngClass]="{'is-invalid': form.get('displayStarsAsPoints').dirty === true && form.get('displayStarsAsPoints').status === 'INVALID'}">-->
<!--            <label class="col-12" for="housing-displayStarsAsPoints">Use Points instead of Stars</label>-->
<!--            <div class="col-12">-->
<!--              <input type="checkbox" class="custom-control-input" id="housing-displayStarsAsPoints" name="housing-displayStarsAsPoints"-->
<!--                     placeholder="Use Points" formControlName="displayStarsAsPoints">-->
<!--              <div class="invalid-feedback"-->
<!--                   *ngFor="let error of extractValidationErrorsAsArray(form.get('displayStarsAsPoints').errors)">{{error}}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('displayStarsAsPoints').dirty === true && form.get('displayStarsAsPoints').status === 'INVALID'}">
            <label class="col-12" for="housing-stars">Stars Settings</label>
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="housing-displayStarsAsPoints"
                       id="housing-displayStarsAsPoints"
                       formControlName="displayStarsAsPoints">
                <label class="custom-control-label" for="housing-displayStarsAsPoints">Use Points instead of Stars</label>
                <div class="invalid-feedback"
                     *ngFor="let error of extractValidationErrorsAsArray(form.get('displayStarsAsPoints').errors)">{{error}}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('numberOfRooms').dirty === true && form.get('numberOfRooms').status === 'INVALID'}">
            <label class="col-12" for="housing-numberOfRooms">Number of rooms</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-numberOfRooms" name="housing-numberOfRooms"
                     placeholder="Number of Rooms" formControlName="numberOfRooms">
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('numberOfRooms').errors)">{{error}}
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('price').dirty === true && form.get('price').status === 'INVALID'}">
            <label class="col-12" for="housing-stars">Price Per Person in Double Room</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-base-price" name="housing-base"
                     placeholder="Price Per Person in Double Room" formControlName="price">
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('price').errors)">{{error}}
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('checkIn').dirty === true && form.get('checkIn').status === 'INVALID'}">
            <label class="col-12" for="housing-checkIn">CheckIn</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-checkIn" name="housing-checkIn"
                     placeholder="CheckIn (HH:MM)" formControlName="checkIn">
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('checkIn').errors)">{{error}}
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{'is-invalid': form.get('checkOut').dirty === true && form.get('checkOut').status === 'INVALID'}">
            <label class="col-12" for="housing-checkOut">CheckOut</label>
            <div class="col-12">
              <input type="text" class="form-control" id="housing-checkOut" name="housing-checkOut"
                     placeholder="checkOut (HH:MM)" formControlName="checkOut">
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('checkOut').errors)">{{error}}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12" for="housing-stars">Hotel Type</label>
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5"
                   *ngFor="let housingType of housingTypes">
                <input class="custom-control-input" type="checkbox" name="housingType_{{housingType}}"
                       id="housingType_{{housingType}}"
                       formControlName="housingType_{{housingType}}">
                <label class="custom-control-label" for="housingType_{{housingType}}">{{housingType}}</label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12" for="housing-stars">Partner</label>
            <div class="col-12">

              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="housing-isPartner" id="housing-isPartner"
                       formControlName="isPartner">
                <label class="custom-control-label" for="housing-isPartner">Standard Partner</label>
              </div>

              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="housing-isTopPartner"
                       id="housing-isTopPartner"
                       formControlName="isTopPartner">
                <label class="custom-control-label" for="housing-isTopPartner">Top Partner</label>
              </div>

              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="housing-isExtranetPartner"
                       id="housing-isExtranetPartner"
                       formControlName="isExtranetPartner">
                <label class="custom-control-label" for="housing-isExtranetPartner">Extranet Partner</label>
              </div>

              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="housing-isExtranetPartner"
                       id="housing-marketplace"
                       formControlName="marketplace">
                <label class="custom-control-label" for="housing-marketplace">Marketplace Access</label>
              </div>

            </div>

          </div>

          <div class="form-group row">
            <label class="col-12" for="housing-stars">Price Calculation</label>
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="housing-housingPriceAsPriceAdjusted"
                       id="housing-price-as-priceAdjusted"
                       formControlName="priceCalculationLockedByAdmin">
                <label class="custom-control-label" for="housing-price-as-priceAdjusted">Lock Price Calculation</label>
              </div>
            </div>
          </div>
        </div>
        <div class="block-content block-content-full block-content-sm bg-body-light font-size-sm text-right">
          <button type="submit" class="btn btn-sm btn-alt-primary" (click)="onSubmitForm()"
                  [disabled]="isLoading === true">
            <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
            <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="col-4">
    <div class="block block-rounded block-themed">
      <div class="block-header bg-gd-primary">
        <h3 class="block-title">Additional Data</h3>
        <div class="block-options">
          <button type="button" class="btn-block-option" (click)="showHelpModal()">
            <i class="fa fa-question"></i>
          </button>
        </div>
        <div class="block-options">
        </div>
      </div>
      <div class="block-content block-content-full">

        <div class="row">
          <div class="col-12">
            <app-housing-crawl-housing [title]="'Booking'"
                                       [baseUrl]="Environment.urls.service + '/api/v1/authenticated/admin/housing-crawler/booking/crawl-housing'"
                                       (housingData)='saveBookingData($event)'></app-housing-crawl-housing>
          </div>
        </div>

        <div class="row mt-15">
          <div class="col-12">
            <app-housing-crawl-housing [title]="'Trivago'"
                                       [baseUrl]="Environment.urls.service + '/api/v1/authenticated/admin/housing-crawler/trivago/crawl-housing'"
                                       (housingData)='saveTrivagoData($event)'></app-housing-crawl-housing>
          </div>
        </div>

        <div class="row mt-15">
          <div class="col-12">
            <app-housing-crawl-housing [title]="'Tripadvisor'"
                                       [baseUrl]="Environment.urls.service + '/api/v1/authenticated/admin/housing-crawler/tripadvisor/crawl-housing'"
                                       (housingData)='saveTripadvisorData($event)'></app-housing-crawl-housing>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<div bsModal #childModal="bs-modal" class="modal" id="modal-large" tabindex="-1" role="dialog"
     aria-labelledby="modal-large" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">How to find the proper URL</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" aria-label="Close" (click)="childModal.hide()">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content">
          <div>
            <tabset>
              <tab heading="Booking.com" id="tab1">
                <div class="row">
                  <div class="col-12">
                    <h5 class="mt-15">1. Open Booking.com in your Browser</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/booking-1.png"/>
                  </div>
                </div>

                <div class="row mt-30">
                  <div class="col-12">
                    <h5 class="mt-15">2. Enter the housing name you are looking for into the search bar and click on
                      it</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/booking-2.png"/>
                  </div>
                </div>


                <div class="row mt-30">
                  <div class="col-12">
                    <h5 class="mt-15">3. Click on search</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/booking-3.png"/>
                  </div>
                </div>

                <div class="row mt-30">
                  <div class="col-12">
                    <h5 class="mt-15">4. Click on the specific housing, a new tab opens</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/booking-4.png"/>
                  </div>
                </div>

                <div class="row mt-30">
                  <div class="col-12">
                    <h5 class="mt-15">5. Copy the URL from the search bar</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/booking-5.png"/>
                  </div>
                </div>
              </tab>
              <tab heading="Trivago.at">
                <div class="row">
                  <div class="col-12">
                    <h5 class="mt-15">1. Open trivago.at in your Browser</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/trivago-1.png"/>
                  </div>
                </div>

                <div class="row mt-30">
                  <div class="col-12">
                    <h5 class="mt-15">2. Enter the housing name you are looking for into the search bar and click on
                      it</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/trivago-2.png"/>
                  </div>
                </div>

                <div class="row mt-30">
                  <div class="col-12">
                    <h5 class="mt-15">3. Click on search
                      it</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/trivago-3.png"/>
                  </div>
                </div>

                <div class="row mt-30">
                  <div class="col-12">
                    <h5 class="mt-15">4. Click on the specific housing
                      it</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/trivago-4.png"/>
                  </div>
                </div>

                <div class="row mt-30">
                  <div class="col-12">
                    <h5 class="mt-15">5. Click on the "Get shareable Link" Button
                      it</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/trivago-5.png"/>
                  </div>
                </div>

              </tab>
              <tab heading="Tripadvisor.at">
                <div class="row">
                  <div class="col-12">
                    <h5 class="mt-15">1. Open tripadvisor.at in your Browser</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/tripadvisor-1.png"/>
                  </div>
                </div>

                <div class="row mt-30">
                  <div class="col-12">
                    <h5 class="mt-15">2. Enter the housing name you are looking for into the search bar and click on
                      it</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/tripadvisor-2.png"/>
                  </div>
                </div>

                <div class="row mt-30">
                  <div class="col-12">
                    <h5 class="mt-15">3. Copy the url from the address bar
                      it</h5>
                    <img class="img-fluid" src="assets/img/tutorial/find-platform-urls/tripadvisor-3.png"/>
                  </div>
                </div>

              </tab>
            </tabset>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-alt-success" data-dismiss="modal" (click)="childModal.hide()">Close
        </button>
      </div>
    </div>
  </div>
</div>
