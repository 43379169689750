<app-content-header i18n-title title="Destination Override" i18n-subTitle
                    subTitle="Replace specific destinations with a new one during request creation"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>
<div class="content">
  <div class="row">
    <div class="col-md-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>

        <!-- -->
        <div class="block-content block-content-full">
          <form [formGroup]="form" (ngSubmit)="loadChangeRequestsToCheck()">
            <div class="row">
              <label class="col-12" for="destination">Destination</label>
              <div class="col-12">
                <input type="text" class="form-control" id="destination" name="destination"
                       placeholder="Destination"
                       autocomplete="off"
                       ngx-google-places-autocomplete
                       [options]='options'
                       #placesRef="ngx-places"
                       formControlName="googleAddressText"
                       (onAddressChange)="handleGooglePlaceIdChangeOriginal($event)"
                >
              </div>
            </div>
          </form>
        </div>
        <div class="block-content block-content-full bg-body-light">
          <button class="button btn btn-success" (click)="loadChangeRequestsToCheck()">Filter</button>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Destination Overrides</h3>
        </div>
        <div class="block-content block-content-full">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>Id</th>
                <th>Display Name</th>
                <th>Original Destination</th>
                <th>New Destination</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody *ngIf="destinations != null && pagination != null">
              <tr *ngFor="let destination of destinations | paginate: {
              itemsPerPage: pagination.limit,
              currentPage: pagination.page,
              totalItems: pagination.total}"
              >
                <td>{{destination._id}}</td>
                <td>{{destination.autocompletionName}}</td>
                <td>{{destination.selectedDestination.formatted_address}}</td>
                <td>{{destination.replacedDestination.formatted_address}}</td>
                <td>
                  <a [routerLink]="['/destination-override', destination._id]">
                    <span class="btn btn-sm btn-primary">Edit</span>
                  </a>&nbsp;
                  <button class="btn btn-sm btn-danger" (click)="deleteDestination(destination._id)">
                    Delete
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
          <pagination-controls [maxSize]="10"
                               (pageChange)="sendPage($event)"
                               *ngIf="pagination != null">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
