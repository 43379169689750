import {Injectable} from "@angular/core";
import {AddressObj} from "../../shared/entities/address-object.entity";
import * as _ from 'lodash';

export class GoogleLocationMapper  {

  mapAll(places: Array<any>): Array<AddressObj> {

    if (!places) {
      return null;
    }

    return places.filter(place => place).map(place => this.map(place));

  }

  map(place: any): AddressObj {

    if (!place) {
      return null;
    }
    const result = new AddressObj();
    let streetNumber = '';

    const addressComponents = place.address_components;
    if (addressComponents) {
      _.each(addressComponents, function (component) {
        const types = component.types;
        if (types) {

          // set area
          if (_.includes(types, 'administrative_area_level_1')) {
            result.adminLevel1 = component['long_name'];
            result.adminLevel1Code = component['short_name'];
          }

          if (_.includes(types, 'administrative_area_level_2')) {
            result.adminLevel2 = component['long_name'];
            result.adminLevel2Code = component['short_name'];
          }

          if (_.includes(types, 'administrative_area_level_3')) {
            result.adminLevel3 = component['long_name'];
            result.adminLevel3Code = component['short_name'];
          }

          // set country
          if (_.includes(types, 'country')) {
            result.country = component['long_name'];
            result.countryCode = component['short_name'];
          }

          // set city
          // if (_.includes(types, 'locality') && !_.includes(place.types, 'sublocality')) {
          if (_.includes(types, 'locality') && !_.includes(types, 'sublocality')) {
            result.city = component['long_name'];
          }

          // set street
          if (_.includes(types, 'route')) {
            result.street = component['long_name'];
          }

          // set street
          if (_.includes(types, 'street_number')) {
            streetNumber = component['long_name'];
          }

          // set postalCode
          if (_.includes(types, 'postal_code')) {
            result.postalCode = component['short_name'] || component['long_name'];
          }

          if (_.includes(place.types, 'sublocality') && _.includes(types, 'sublocality')) {
            result.city = component['long_name'];
          }

          if (!result.city
            && _.includes(place.types, 'administrative_area_level_2')
            && _.includes(types, 'administrative_area_level_2')) {
            result.city = component['long_name'];
          }
        }
      });
    }

    const geometry = place.geometry;
    if (geometry) {
      const location = geometry.location;
      if (location) {
        result.longitude = location.lng();
        result.latitude = location.lat();
      }
    }

    if (result.street && streetNumber) {
      result.street = result.street + ' ' + streetNumber;
    }

    if (!result.street && streetNumber) {
      result.street = 'Nr. ' + streetNumber;
    }

    result.googlePlaceId = place.place_id;
    result.name = place.formatted_address;

    result.raw = place;

    return result;

  }

}
