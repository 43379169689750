import { Component, OnInit } from '@angular/core';
import {BreadCrumbElement} from "../../../shared/entities/breadcrumb.element.entity";

@Component({
  selector: 'app-housing-add',
  templateUrl: './housing-add.component.html',
  styleUrls: ['./housing-add.component.css']
})
export class HousingAddComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  constructor() {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Housings', 'javascript:void(0)'),
      new BreadCrumbElement('Add new housing', 'javascript:void(0)')
    ];

  }

  ngOnInit() {
  }

}
