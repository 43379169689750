import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ConfigService} from "../../../services/configservice/config.service";
import {FormControl, FormGroup} from "@angular/forms";
import {LoadingIndicatorService} from "../../../services/loading-indicator/loading-indicator.service";
import {EventsService} from "../../../services/events/events.service";

@Component({
  selector: 'app-housing-edit',
  templateUrl: './housing-edit.component.html',
  styleUrls: ['./housing-edit.component.css']
})
export class HousingEditComponent implements OnInit {
  @ViewChild('fileInput') fileInput;

  public environment = environment;

  public pageId = 0;
  public housing: any;
  public housingId: string;

  public housingImagePreview: string;
  public housingImageOffer: string;
  public housingImageList: Array<string>;

  public revert_googlePlaceId;
  public revert_page;

  public popoverQualifiesForIBHtml = "<ul><li>Amount of Singlebed Rooms?</li><li>Amount of Doublebed Rooms?</li><li>High Quality Preview Image?</li><li>High Quality Offer Image?</li><li>At least 5 Images?</li></ul>"

  public formGeneral: FormGroup;

  constructor(private route: ActivatedRoute,
              private http: HttpClient,
              private configService: ConfigService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.housingId = this.route.snapshot.params.housingId;

  }

  ngOnInit() {

    this.route.queryParams.subscribe((params: Params) => {
      if (params["googlePlaceId"] && params["page"]) {
        this.revert_googlePlaceId = params["googlePlaceId"];
        this.revert_page = params["page"];
      }
    });

    this.formGeneral = new FormGroup({
      'name': new FormControl(''),
      'email': new FormControl(''),
      'stars': new FormControl(''),
      'homepage': new FormControl(''),
      'phone': new FormControl(''),
      'numberOfRooms': new FormControl(''),
      'rating': new FormControl(''),
      'amount_singleBed': new FormControl(''),
      'amount_doubleBed': new FormControl('')
    });

    this.requestData();
  }

  changePage(n: number) {
    this.pageId = n;
  }

  public requestData() {
    this.loadingIndicatorService.showLoadingBar();

    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId, {headers: headers})
      .subscribe((response: any) => {
          this.mapHousingResponse(response);
          this.loadingIndicatorService.hideLoadingBar();
        },
        err => {
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));
        });
  }

  private mapHousingResponse(response: any) {
    this.housing = response;

    /* Mapping */
    let doubleBedMapping = this.extractDoubleRooms(this.housing.roomDetailsNew);
    if (doubleBedMapping === 0 && response.roomDetails.doubleBed != null) {
      doubleBedMapping = response.roomDetails.doubleBed;
    }


    /* Form General */
    this.formGeneral.setValue({
      name: this.housing.name,
      email: this.housing.email,
      stars: this.housing.stars,
      homepage: (this.housing.homepage != null) ? this.housing.homepage : '',
      phone: this.housing.phone,
      numberOfRooms: this.housing.numberOfRooms,
      rating: this.housing.rating,
      amount_singleBed: this.extractSingleRooms(this.housing.roomDetailsNew),
      amount_doubleBed: doubleBedMapping
    });

    /* */
    this.housingImageList = [];
    if (this.housing.imagesOptimized.preview) {
      this.housingImagePreview = environment.urls.blob + '/' + this.housingId + '/raw/' + this.housing.imagesOptimized.preview;
    }

    if (this.housing.imagesOptimized.offer) {
      this.housingImageOffer = environment.urls.blob + '/' + this.housingId + '/raw/' + this.housing.imagesOptimized.offer;
    }

    for (const imageCategory in this.housing.imagesOptimized.list) {
      if (this.housing.imagesOptimized.list.hasOwnProperty(imageCategory)) {
        for (const image of this.housing.imagesOptimized.list[imageCategory]) {
          this.housingImageList.push(image);
        }
      }
    }
  }

  private extractSingleRooms(roomDetailsNew: any) {
    if (roomDetailsNew ==  null) {
      return 0;
    }

    for (const room of roomDetailsNew.rooms) {
      if (room.type === "SINGLE_BED") {
        return room.count;
      }
    }

    return 0;
  }

  private extractDoubleRooms(roomDetailsNew: any) {
    if (roomDetailsNew == null) {
      return 0;
    }

    for (const room of roomDetailsNew.rooms) {
      if (room.type === "DOUBLE_BED") {
        return room.count;
      }
    }

    return 0;
  }

  public setPreviewImage(image: string) {
    this.loadingIndicatorService.showLoadingBar();
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});

    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/images/' + image + '/set-preview', {}, {headers: headers})
      .subscribe((response: any) => {
          this.mapHousingResponse(response);
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          // if (error.error === "Unauthorized") {
          // }
          console.log("Error", error);
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public setOfferImage(image: string) {
    this.loadingIndicatorService.showLoadingBar();

    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});

    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/images/' + image + '/set-offer', {}, {headers: headers})
      .subscribe((response: any) => {
          this.mapHousingResponse(response);
          this.loadingIndicatorService.hideLoadingBar();

        },
        error => {
          if (error.error === "Unauthorized") {
          }
          console.log("Error", error);
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));

          this.loadingIndicatorService.hideLoadingBar();

        }
      );
  }

  public deleteImage(image: string) {
    this.loadingIndicatorService.showLoadingBar();

    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});

    this.http.delete(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/images/' + image, {headers: headers})
      .subscribe((response: any) => {
          this.mapHousingResponse(response);
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          console.log("Error", error);
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
          this.loadingIndicatorService.hideLoadingBar();

        }
      );
  }

  onSubmitFormGeneral() {
    if (this.formGeneral.status !== 'VALID') {
      return;
    }
    this.loadingIndicatorService.showLoadingBar();

    this.postGeneralData().subscribe(
      (response: any) => {
        console.log("ogge");
        this.loadingIndicatorService.hideLoadingBar();
        this.mapHousingResponse(response);
      },
      err => {

        if (err.error.message === "E_VALIDATION_FAILED") {
          for (const error of err.error.errors) {
            this.formGeneral.get(error.property).setErrors(error.constraints);
          }
          this.loadingIndicatorService.hideLoadingBar();
          return;
        }

        this.loadingIndicatorService.hideLoadingBar();
        this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));

        console.log(err);
      }
    )
  }

  private postGeneralData() {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});

    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/general', {
      name: this.formGeneral.get('name').value,
      email: this.formGeneral.get('email').value,
      stars: parseFloat(this.formGeneral.get('stars').value),
      homepage: this.formGeneral.get('homepage').value,
      phone: this.formGeneral.get('phone').value,
      numberOfRooms: parseFloat(this.formGeneral.get('numberOfRooms').value),
      rating: parseFloat(this.formGeneral.get('rating').value),
      amount_singleBed: parseFloat(this.formGeneral.get('amount_singleBed').value),
      amount_doubleBed: parseFloat(this.formGeneral.get('amount_doubleBed').value)
    }, {headers: headers});
  }

  public onSubmitSetHousingToFinished(b: boolean) {
    this.loadingIndicatorService.showLoadingBar();

    this.patchSetHousingToFinished(b).subscribe(
      (response: any) => {
        console.log("ogge");
        this.loadingIndicatorService.hideLoadingBar();

      },
      err => {
        console.log(err);
        this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));
        this.loadingIndicatorService.hideLoadingBar();

      }
    )
  }

  private patchSetHousingToFinished(b: boolean) {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});

    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/ib-check',
      {value: b}, {headers: headers});
  }

  public onSubmitSetHousingToQualifiedForIB(b: boolean) {
    this.loadingIndicatorService.showLoadingBar();

    this.patchSetHousingToQualifiedForIB(b).subscribe(
      (response: any) => {
        console.log("ogge");
        this.loadingIndicatorService.hideLoadingBar();

      },
      err => {
        console.log(err);
        this.loadingIndicatorService.hideLoadingBar();
        this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));

      }
    )
  }

  private patchSetHousingToQualifiedForIB(b: boolean) {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});

    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/ib-qualified',
      {value: b}, {headers: headers});
  }

  upload() {
    let fileBrowser = this.fileInput.nativeElement;
    const formData = new FormData();
    formData.append("category", "general");

    if (fileBrowser.files && fileBrowser.files[0]) {
      for (const file of fileBrowser.files) {
        formData.append("files", file);
      }
    }

    this._upload(formData);
  }

  _upload(formData) {
    this.loadingIndicatorService.showLoadingBar();

    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});
    headers.delete('Content-Type');

    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/images', formData, {headers: headers})
      .subscribe((response: any) => {
          this.mapHousingResponse(response);
          this.loadingIndicatorService.hideLoadingBar();

        },
        error => {
          if (error.error === "Unauthorized") {
          }
          console.log("Error", error);
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));

        }
      );
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }
}
