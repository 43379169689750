<form [formGroup]="form">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Rating</h3>
      <div class="block-options">
        <button type="submit" class="btn btn-sm btn-alt-primary" (click)="onSubmitForm()" [disabled]="housing == null || isLoading === true">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
        </button>
      </div>
    </div>
    <div class="block-content">
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-rating-total">Rating (Tot.)</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('rating').touched || form.get('rating').dirty) && form.get('rating').invalid}">
          <input type="text" class="form-control" id="housing-rating-total" name="housing-rating-total"
                 placeholder="Rating (Total)" formControlName="rating">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('rating').errors)">
            {{error}}
          </div>
        </div>
        <label class="col-2 col-form-label" for="housing-rating-numberOfVotes">No. of Votes</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('numberOfRatings').touched || form.get('numberOfRatings').dirty) && form.get('numberOfRatings').invalid}">
          <input type="text" class="form-control" id="housing-rating-numberOfVotes"
                 name="housing-rating-numberOfVotes" placeholder="Number of Votes" formControlName="numberOfRatings">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('numberOfRatings').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-rating-breakfast">Breakfast</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('breakfast').touched || form.get('breakfast').dirty) && form.get('breakfast').invalid}">
          <input type="text" class="form-control" id="housing-rating-breakfast" name="housing-rating-breakfast"
                 placeholder="Breakfast" formControlName="breakfast">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('breakfast').errors)">
            {{error}}
          </div>
        </div>
        <label class="col-2 col-form-label" for="housing-rating-food">Food</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('food').touched || form.get('food').dirty) && form.get('food').invalid}">
          <input type="text" class="form-control" id="housing-rating-food" name="housing-rating-food"
                 placeholder="Food" formControlName="food">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('food').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-rating-rooms">Rooms</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('rooms').touched || form.get('rooms').dirty) && form.get('rooms').invalid}">
          <input type="text" class="form-control" id="housing-rating-rooms" name="housing-rating-rooms"
                 placeholder="Rooms" formControlName="rooms">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('rooms').errors)">
            {{error}}
          </div>
        </div>
        <label class="col-2 col-form-label" for="housing-rating-wifi">Wifi</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('wifi').touched || form.get('wifi').dirty) && form.get('wifi').invalid}">
          <input type="text" class="form-control" id="housing-rating-wifi" name="housing-rating-wifi"
                 placeholder="Wifi" formControlName="wifi">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('wifi').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-rating-price">Price</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('price').touched || form.get('price').dirty) && form.get('price').invalid}">
          <input type="text" class="form-control" id="housing-rating-price" name="housing-rating-price"
                 placeholder="Price" formControlName="price">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('price').errors)">
            {{error}}
          </div>
        </div>
        <label class="col-2 col-form-label" for="housing-rating-service">Service</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('service').touched || form.get('service').dirty) && form.get('service').invalid}">
          <input type="text" class="form-control" id="housing-rating-service" name="housing-rating-service"
                 placeholder="Service" formControlName="service">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('service').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-rating-equipment">Equipment</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('equipment').touched || form.get('equipment').dirty) && form.get('equipment').invalid}">
          <input type="text" class="form-control" id="housing-rating-equipment" name="housing-rating-equipment"
                 placeholder="Equipment" formControlName="equipment">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('equipment').errors)">
            {{error}}
          </div>
        </div>
        <label class="col-2 col-form-label" for="housing-rating-location">Location</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('location').touched || form.get('location').dirty) && form.get('location').invalid}">
          <input type="text" class="form-control" id="housing-rating-location" name="housing-rating-location"
                 placeholder="Location" formControlName="location">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('location').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-rating-comfort">Comfort</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('comfort').touched || form.get('comfort').dirty) && form.get('comfort').invalid}">
          <input type="text" class="form-control" id="housing-rating-comfort" name="housing-rating-comfort"
                 placeholder="Comfort" formControlName="comfort">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('comfort').errors)">
            {{error}}
          </div>
        </div>
        <label class="col-2 col-form-label" for="housing-rating-tidiness">Tidiness</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('tidiness').touched || form.get('tidiness').dirty) && form.get('tidiness').invalid}">
          <input type="text" class="form-control" id="housing-rating-tidiness" name="housing-rating-tidiness"
                 placeholder="Tidiness" formControlName="tidiness">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('tidiness').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-rating-building">Building</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('building').touched || form.get('building').dirty) && form.get('building').invalid}">
          <input type="text" class="form-control" id="housing-rating-building" name="housing-rating-building"
                 placeholder="Building" formControlName="building">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('building').errors)">
            {{error}}
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
