import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as moment from "moment";
import {BsModalService, ModalDirective} from "ngx-bootstrap";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EventsService} from "../../../../services/events/events.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'tripmakery-request-auto-disqualification-add-modal',
  templateUrl: './modal-request-auto-disqualification-add.component.html',
  styleUrls: ['./modal-request-auto-disqualification-add.component.css']
})
export class ModalRequestAutoDisqualificationAddComponent implements OnInit {
  @Input() housingId: string;
  public form: FormGroup;

  @ViewChild('childModal') public childModal: ModalDirective;


  constructor(private http: HttpClient,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'requestId': new FormControl({value: '', disabled: false}),
      'autoDisqualifyAt': new FormControl({value: '', disabled: false}),
    })
  }


  ngOnInit() {
    this.eventsService.on('requestShowModalRequestAutoDisqualificationAdd', (requestId: string, date: Date) => {
      if (requestId != null) {
        this.form.patchValue({'requestId': requestId})
      } else {
        this.form.patchValue({'requestId': ''})
      }

      if (date != null) {
        this.form.patchValue({'autoDisqualifyAt': date})
      } else {
        this.form.patchValue({'autoDisqualifyAt': ''})
      }

      this.showChildModal()
    });
  }

  public showChildModal(): void {
    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/request/' + this.form.get('requestId').value + '/auto-disqualification', {
      autoDisqualifyAt: this.createNullIfEmptyString(this.form.get('autoDisqualifyAt').value),
    })
      .subscribe(
        (response: any) => {
          console.log(response);

          // if (this.createFalseIfEmptyString(this.form.get('service-booking').value) === true) {
          //   this.eventsService.broadcast("HOUSING_OVERVIEW_TRIGGER_LATEST_BOOKING_CRAWLER_JOBS")
          // }
          //
          // if (this.createFalseIfEmptyString(this.form.get('service-trivago').value) === true) {
          //   this.eventsService.broadcast("HOUSING_OVERVIEW_TRIGGER_LATEST_TRIVAGO_CRAWLER_JOBS")
          // }

          this.eventsService.broadcast('refreshAutoDisqualificationList');
        },
        (error) => {

        },
        () => {
          this.hideChildModal();
        }
      );
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return null;
    }
    return s;
  }

  private createFalseIfEmptyString(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return false;
    }
    return s;
  }

}
