import {Component, Input, OnInit} from '@angular/core';
import * as moment from "moment";

@Component({
  selector: 'app-instant-booking-details-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class InstantBookingDetailsDetailsComponent implements OnInit {
  @Input('details') details;

  public moment;

  constructor() {
    this.moment = moment();
  }

  ngOnInit() {
  }

}
