<div class="col-xl-12" *ngIf="paginationData != null">
  <h2 class="content-heading">Latest Housing Requests</h2>

  <!-- -->
  <div class="block block-rounded" *ngIf="housingRequests != null && housingRequests.length > 0">
    <div class="block-content">
      <div class="table-responsive">
        <table class="table table-borderless table-striped">
          <thead>
          <tr>
            <th>Id</th>
            <th>Request</th>
            <th class="text-center">State</th>
            <th>Meta</th>
            <th>Value</th>
            <th>Created</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let housingRequest of housingRequests | paginate: {
              id: 'ctrls_dp_' + section,
              itemsPerPage: paginationData.limit,
              currentPage: paginationData.page,
              totalItems: paginationData.total}">
            <td>
              {{housingRequest._id}}
            </td>
            <td>
              {{housingRequest.request}}
            </td>
            <td class="text-center">
              <span class="badge badge-primary" *ngIf="housingRequest.state === 'NEW'">New</span>
              <span class="badge badge-info" *ngIf="housingRequest.state === 'IN_PROGRESS'">In Progress</span>
              <span class="badge badge-danger" *ngIf="housingRequest.state === 'DECLINED' && housingRequest.status.expirationCheck === true">Declined (System)</span>
              <span class="badge badge-danger" *ngIf="housingRequest.state === 'DECLINED' && housingRequest.status.expirationCheck === false">Declined (Housing)</span>
              <span class="badge badge-success" *ngIf="housingRequest.state === 'OFFER_CREATED'">Offer Created</span>
            </td>
            <td>
              @TODO Meta
            </td>
            <td>
              @TODO €
            </td>
            <td class="d-none d-sm-table-cell">
              {{housingRequest.created}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
      <pagination-controls
        id="ctrls_dp_{{section}}"
        [maxSize]="paginationData.limit"
        (pageChange)="sendPage($event)"
      ></pagination-controls>
    </div>
  </div>

  <!-- -->
  <div class="block block-rounded" *ngIf="housingRequests == null || housingRequests.length === 0">
    <div class="block-content block-content-full text-center">
      No results to display
    </div>
  </div>

</div>
