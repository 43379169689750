import {Component} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {LoadingIndicatorService} from "../../../services/loading-indicator/loading-indicator.service";
import {ConfigService} from "../../../services/configservice/config.service";
import {BreadCrumbElement} from "../../../shared/entities/breadcrumb.element.entity";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'email-server-blacklist',
  templateUrl: './email-server-blacklist.component.html',
  styleUrls: ['./email-server-blacklist.component.css']
})
export class EmailServerBlacklistComponent {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public form: FormGroup;
  public data: any;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private http: HttpClient) {

    /* */
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Developer', 'javascript:void(0)'),
      new BreadCrumbElement('Emails', 'javascript:void(0)'),
      new BreadCrumbElement('Email Server Blacklist', 'javascript:void(0)'),
    ];

    /* */
    this.form = new FormGroup({
      'server': new FormControl({value: '', disabled: false}),
    });

  }

  public checkIfServerIsBlacklisted() {
    this.loadingIndicatorService.showLoadingBar();

    /* Build params */
    let params = new HttpParams();
    params = params.append('server', this.form.get('server').value.toString());
    this.data = null;

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/email/checks/mail-server-marked-as-spam', {params: params})
      .subscribe((response: any) => {
          this.data = response;
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
        }
      )
  }

}
