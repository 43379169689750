<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-popin"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" bsModal>
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">Add / Edit the date of next followup</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" data-dismiss="modal" aria-label="Close">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="form">
                <div class="form-group row">
                  <label class="col-12">Request Id</label>
                  <div class="col-12">
                    <input type="text" class="form-control"
                           placeholder="Please enter your Request Id"
                           autocomplete="off"
                           formControlName="requestId">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12">Date</label>
                  <div class="col-12">
                    <input type="text" class="form-control mb-3"
                    placeholder="Click here to open the menu" bsDatepicker formControlName="followUpAt"/>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-alt-secondary" data-dismiss="modal" (click)="hideChildModal()">Abort</button>
        <button type="button" class="btn btn-alt-success" (click)="handleClick()">
          <i class="fa fa-check"></i> Add FollowUp
        </button>
      </div>
    </div>
  </div>
</div>

