<div class="block" *ngIf="paginationData != null && rights != null">
  <div class="block-header block-header-default">
    <h3 class="block-title">Housing Access Rights</h3>
    <div class="btn-group">
      <label>Add new Hotel Access</label>&nbsp;
      <button type="button"
              class="btn btn-sm btn-success"
              tooltip="Add new Hotel Access"
              (click)="showAddHousingRightModal()">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
  <div class="block-content block-content-full">
    <div class="table-responsive">
      <table class="table table-striped table-vcenter">
        <thead>
        <tr>
          <th class="text-left">HousingId</th>
          <th class="text-left">Name</th>
          <th class="text-left">Rights</th>
          <th class="text-center">Actions</th>
        </tr>
        </thead>
        <tr
          *ngFor="let right of rights | paginate: {
              itemsPerPage: paginationData.limit,
              currentPage: paginationData.page,
              totalItems: paginationData.total}"
        >
          <td class="text-left">
            {{right.housingId}}
          </td>
          <td class="text-left">
            {{right.housing?.name}}
          </td>
          <td class="text-left">{{right.accessRights}}</td>
          <td class="text-center">
            <button class="btn btn-sm btn-danger" (click)="requestDelete(right._id)"><i class="fa fa-minus"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="block-content block-content-full bg-body-light">
    <div class="block-content block-content-full block-content-sm text-center">
      <pagination-controls [maxSize]="10"
                           (pageChange)="sendPage($event)"
      ></pagination-controls>
    </div>
  </div>
</div>

<!-- -->
<tripmakery-user-info-housing-access-add-modal [user]="user"
                                               #userInfoHousingAccessAddModalComponent></tripmakery-user-info-housing-access-add-modal>
