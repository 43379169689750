<agm-map (mapDblClick)="addPolygon($event)"
         class="mb-15"
         [latitude]="googleLocation != null ? googleLocation.geometry.location.lat : 0" [longitude]="googleLocation != null ? googleLocation.geometry.location.lon : 0"
>
</agm-map>

<div class="block">
  <div class="block-content">
    <h5>Settings</h5>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label">Edit Mode</label>
      <div class="col-lg-7">
        <button class="btn btn-primary btn-sm" (click)="toggleEditMode()">Toggle</button>
      </div>
    </div>
    <div class="form-group row" *ngIf="mode === 1">
      <label class="col-lg-3 col-form-label">Base Boundary</label>
      <div class="col-lg-7">
        <button class="btn btn-primary btn-sm" (click)="requestBaseBoundary()">Reimport</button>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label">Reset</label>
      <div class="col-lg-7">
        <button class="btn btn-primary btn-sm" (click)="resetPolygon()">Reset Polygon</button>
      </div>
    </div>
  </div>
</div>


<div class="block" *ngIf="mode === 1">
  <div class="block-content">
    <h5>Optimization</h5>
    <form [formGroup]="formOptimization" (ngSubmit)="optimizeBoundary()">
      <div class="form-group row">
        <label class="col-lg-3 col-form-label" for="boundaries-edit-rounds">Minification Rounds</label>
        <div class="col-lg-7">
          <div class="input-group">
            <input type="text" formControlName="rounds" class="form-control" id="boundaries-edit-rounds"
                   name="boundaries-edit-rounds"
                   placeholder="Please enter the amount of minification rounds. Every round halfs the amount of nodes!">
            <div class="input-group-append">
              <button type="submit" class="btn btn-secondary">Submit</button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-lg-3 col-form-label" for="boundaries-edit-margin">Margin</label>
        <div class="col-lg-7">
          <div class="input-group">
            <input type="text" formControlName="margin" class="form-control" id="boundaries-edit-margin"
                   name="boundaries-edit-margin"
                   placeholder="Please enter your margin value. Attention: Choose values around 0.035, otherwise bad stuff is going to happen!">
            <div class="input-group-append">
              <button type="submit" class="btn btn-secondary">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="block">
  <div class="block-content">
    <h5>Test the boundary</h5>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label">Perform Database Query</label>
      <div class="col-lg-7">
        <button class="btn btn-primary btn-sm" (click)="testBoundaryDetailsNew()">Run the test (may takes some
          seconds)
        </button>
      </div>
    </div>
    <div class="form-group row" *ngIf="errorDuringDbQuery === true">
      <div class="col-lg-12">
        <div class="alert alert-danger" role="alert">
          <h6 class="alert-heading font-size-h4 font-w400">Oh shit oh shit oh shit</h6>
          <p class="mb-0">There was an error performing a database query with the boundary. It looks like there's an
            issue with your boundary which our system can't fix automatically :(</p>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label">Found housings</label>
      <div class="col-lg-7">
        {{outputFoundHousings}}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label">Performance</label>
      <div class="col-lg-7">
        {{outputPerformance}}
      </div>
    </div>
  </div>
</div>
