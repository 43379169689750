<app-content-header i18n-title title="Validation Error Details" i18n-subTitle
                    subTitle="Details of logged validation errors"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content" *ngIf="validationErrorDetails != null">
  <div class="row">
    <div class="col-md-5">
      <div class="row">
        <div class="col-md-12">
          <app-validation-error-details-general
            [validationError]="validationErrorDetails"></app-validation-error-details-general>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <app-validation-error-details-code
        [validationError]="validationErrorDetails"></app-validation-error-details-code>
    </div>
  </div>
</div>
