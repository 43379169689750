import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {LoadingIndicatorService} from "../../../services/loading-indicator/loading-indicator.service";
import {ConfigService} from "../../../services/configservice/config.service";
import {EventsService} from "../../../services/events/events.service";
import {BreadCrumbElement} from "../../../shared/entities/breadcrumb.element.entity";
import {FormControl, FormGroup} from "@angular/forms";
import {FormUtils} from "../../../shared/utils/form-utils";

@Component({
  selector: 'app-boundaries',
  templateUrl: './boundaries.component.html',
  styleUrls: ['./boundaries.component.css']
})
export class BoundariesComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public page = 1;
  public limit = 25;
  public section = 1;

  /* @TODO */
  // public amountToCheck = 0;
  // public amountInactive = 0;
  // public amountActive = 0;

  /* */
  public pagination: any;
  public boundaries;
  public form: FormGroup;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private http: HttpClient) {

    /* */
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Boundaries', 'javascript:void(0)')
    ];

    /* */
    this.form = new FormGroup({
      'googlePlaceId': new FormControl({value: '', disabled: false}),
      'checked': new FormControl({value: false, disabled: false}),
      'notChecked': new FormControl({value: false, disabled: false}),
      'active': new FormControl({value: false, disabled: false}),
      'inactive': new FormControl({value: false, disabled: false}),
      'minNumberOfRequests': new FormControl({value: "0", disabled: false}),
      'sortmode': new FormControl({value: "CONVERSIONRATE_DESC", disabled: false})
    });

  }

  ngOnInit() {
    this.loadBoundariesToCheck();
  }

  public loadBoundariesToCheck() {
    this.loadingIndicatorService.showLoadingBar();

    /* Build params */
    let params = new HttpParams();
    params = params.append('page', this.page.toString());
    params = params.append('limit', this.limit.toString());
    if (this.form.get('googlePlaceId').value) {
      params = params.append('googlePlaceId', this.form.get('googlePlaceId').value);
    }
    params = params.append('active', FormUtils.returnAsNullIfEmpty(this.form.get('active').value));
    params = params.append('inactive', FormUtils.returnAsNullIfEmpty(this.form.get('inactive').value));
    params = params.append('checked', FormUtils.returnAsNullIfEmpty(this.form.get('checked').value));
    params = params.append('notChecked', FormUtils.returnAsNullIfEmpty(this.form.get('notChecked').value));
    params = params.append('minNumberOfRequests', FormUtils.returnAsNullIfEmpty(this.form.get('minNumberOfRequests').value));
    params = params.append('sortmode', this.form.get('sortmode').value);

    this.http.get(environment.urls.service + '/api/v2/authenticated/admin/boundary', {params: params})
      .subscribe((response: any) => {
          /* @TODO */
          // this.amountToCheck = response.counts.totalToCheck;
          // this.amountInactive = response.counts.totalInactive;
          // this.amountActive = response.counts.totalActive;
          this.boundaries = response.elements;
          this.pagination = response.pagination;

          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }

  public requestCheckForNewEntries() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/boundary/check-for-new')
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }

  public sendPage(e: number) {
    this.page = e;
    this.loadBoundariesToCheck();
  }
}
