<app-content-header i18n-title title="Email Bounces" i18n-subTitle
                    subTitle="Check for bounced emails and update housing details"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>
<div class="content">
  <div class="row">
    <div class="col-md-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>

        <!-- -->
        <div class="block-content block-content-full">
          <form [formGroup]="formFilter" (ngSubmit)="loadEmailBouncesToCheck()">
            <div class="form-group row">
              <label class="col-12" for="state">State</label>
              <div class="col-12">
                <select type="text" class="form-control"
                        id="state"
                        formControlName="state">
                  <option value="NEW" selected="selected">New</option>
                  <option value="DONE">Done</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12" for="state">Issue Type</label>
              <div class="col-12">
                <select type="text" class="form-control"
                        id="issueType"
                        formControlName="issueType">
                  <option value="ALL" selected="selected">All</option>
                  <option value="AUTO_ANSWER">Automatic Answer</option>
                  <option value="BLOCK_SES">Blocked SES</option>
                  <option value="BLOCK_ADMIN">Blocked by Admin</option>
                  <option value="BLOCK_BLACKLIST">Blocked by Blacklist</option>
                  <option value="BLOCK_SPAMFILTER">Blocked by Spamfilter</option>
                  <option value="ADDRESS_DOESNT_EXIST">Address doesn't exist</option>
                  <option value="MAIL_SERVER_NOT_FOUND">Mail Server not found</option>
                  <option value="DNS_SERVER_NOT_FOUND">DNS Server not found</option>
                  <option value="MAILBOX_FULL">Mailbox full</option>
                  <option value="TECHNICAL_PROBLEMS">Technical Problems</option>
                  <option value="COMPLAINT">User Complaint</option>
                  <option value="UNKNOWN">Unkown</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12" for="bounceSource">Source</label>
              <div class="col-12">
                <select type="text" class="form-control"
                        id="bounceSource"
                        formControlName="bounceSource">
                  <option value="GOOGLE" selected="selected">Google</option>
                  <option value="SES">Amazon</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12" for="destination">Destination</label>
              <div class="col-md-12">
                <input type="text" class="form-control" id="destination" name="destination"
                       placeholder="Destination"
                       autocomplete="off"
                       formControlName="destination"
                       ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                       (onAddressChange)="handleAddressChangeOfCity($event)"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12" for="housingId">Housing Id</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="Housing Id"
                       autocomplete="off"
                       id="housingId"
                       formControlName="housingId">
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12" for="requestId">Request Id</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="Request Id"
                       autocomplete="off"
                       id="requestId"
                       formControlName="requestId">
              </div>
            </div>

            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>

          </form>
        </div>

        <div class="block-content block-content-full block-content-sm bg-body-light font-size-sm text-right">
          <button type="submit" class="btn btn-sm btn-alt-primary" (click)="loadEmailBouncesToCheck()">
            Search
          </button>
        </div>
      </div>

    </div>
    <div class="col-md-9">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Email Bounces</h3>
        </div>
        <div class="block-content block-content-full">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>Email</th>
                <th>Issue</th>
                <th>Tasks</th>
                <th>Created At</th>
                <th class="text-center">Links</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody *ngIf="elements != null && pagination != null">
              <tr *ngFor="let element of elements | paginate: {
              itemsPerPage: pagination.limit,
              currentPage: pagination.page,
              totalItems: pagination.total}"
              >
                <td>{{element.email}}</td>
                <td>{{element.bounceDetails?.detectedIssue}}</td>
                <td>{{element.actions?.suggested}}</td>
                <td>{{element.createdAt}}</td>
                <td class="text-center">

                  <a
                    [routerLink]="['/housings/search']"
                    [queryParams]="{ page:1,limit:25,sorting:1,sort:'name','hotelMail': element.email }"
                    target="_blank"><i class="fa fa-address-card-o text-info"></i></a>

                  &nbsp;

                  <a
                    [routerLink]="['/developer/emails/bounces', element._id]"><i
                    class="fa fa-info text-success"></i></a>

                </td>
                <td>
                  <button class="btn btn-sm btn-success" (click)="markAsDone(element._id)">Done</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
          <pagination-controls [maxSize]="10"
                               (pageChange)="sendPage($event)"
                               *ngIf="pagination != null">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
