import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { LoadingIndicatorService } from '../../../../../services/loading-indicator/loading-indicator.service';
import { HousingInfoService } from '../../../../../services/housing-info/housing-info.service';
import { EventsService } from '../../../../../services/events/events.service';

@Component({
  selector: 'app-housing-info-accountsystem',
  templateUrl: './info-accountsystem.component.html',
  styleUrls: ['./info-accountsystem.component.css']
})
export class HousingInfoAccountSystem implements OnInit, OnChanges {
  @Input('housing') housing;
  public accessRights = [];
  public environment_;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.environment_ = environment;
  }

  ngOnInit() {
    if (this.housing != null) {
      this.handleGetAccessRights();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.handleGetAccessRights();
    }
  }

  public handleGetAccessRights() {
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/housing-accounts')
      .subscribe((response: any) => {
          this.accessRights = response.accessRights;
        },
        err => {
        });
  }

}
