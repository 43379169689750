<app-content-header i18n-title title="Email Existence" i18n-subTitle
                    subTitle="Check if an email address really exists"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>
<div class="content">
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Settings</h3>
        </div>

        <!-- -->
        <div class="block-content block-content-full">
          <form [formGroup]="form" (ngSubmit)="checkIfAddressExists()">
            <div class="row">
              <label class="col-12" for="email">Email</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       id="email"
                       formControlName="email">
              </div>
            </div>


            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>

            <div class="row">
              <div class="col-12">
                <button class="btn btn-info btn-sm" type="submit">Check for existence</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="existenceData != null">
    <div class="col-md-6 offset-md-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Result</h3>
        </div>

        <!-- -->
        <div class="block-content block-content-full">
          <div class="row">
            <div class="col-12">
              <span class="alert-danger" *ngIf="existenceData.result === 0">Email address might not exist</span>
              <span class="alert-danger" *ngIf="existenceData.result === 1">Invalid email address</span>
              <span class="alert-danger" *ngIf="existenceData.result === 2">Blocked by provider</span>
              <span class="alert-warning" *ngIf="existenceData.result === 3">Timeout while trying to reach mailserver</span>
              <span class="alert-success" *ngIf="existenceData.result === 4">Email address might exist</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
