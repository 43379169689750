<div class="alert alert-danger" *ngIf="isLocationInUseAlready === true">
  <h5>Unable to save housing</h5>
  There is already an housing registered with this Address / GooglePlaceId: <a [routerLink]="['/housings', locationInUseHousingId, 'details']">{{locationInUseHousingName}}</a>
</div>
<form [formGroup]="form" (submit)="onSubmitForm()">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Basic Info</h3>
      <div class="block-options">
        <button type="submit" class="btn btn-sm btn-alt-primary" (click)="onSubmitForm()" [disabled]="housing == null || isLoading === true">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
        </button>
      </div>
    </div>
    <div class="block-content block-content-full">
      <div class="form-group row"
           [ngClass]="{'is-invalid': form.get('name').dirty === true && form.get('name').status === 'INVALID'}">
        <label class="col-12" for="housing-name">Name</label>
        <div class="col-12">
          <input type="text" class="form-control" id="housing-name" name="housing-name" placeholder="Housing Name"
                 formControlName="name" autocomplete="off">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('name').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-12" for="housing-address">City</label>
        <div class="col-12">
          <input type="text" class="form-control" id="housing-city" name="housing-city"
                 placeholder="Housing City"
                 autocomplete="off"
                 formControlName="city"
                 ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChangeOfCity($event)"
          >
        </div>
      </div>
      <div class="form-group row">
        <label class="col-12" for="housing-address">Address</label>
        <div class="col-12">
          <input type="text" class="form-control" id="housing-address" name="housing-address"
                 placeholder="Housing Address"
                 autocomplete="off"
                 formControlName="address"
                 ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChangeOfAddress($event)">
        </div>
      </div>
      <div class="form-group row"
           [ngClass]="{'is-invalid': form.get('email').dirty === true && form.get('email').status === 'INVALID'}">
        <label class="col-12" for="housing-email">Email</label>
        <div class="col-12">
          <input type="text" class="form-control" id="housing-email" name="housing-email"
                 placeholder="Housing Email" autocomplete="false" formControlName="email">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('email').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row"
           [ngClass]="{'is-invalid': form.get('phone').dirty === true && form.get('phone').status === 'INVALID'}">
        <label class="col-12" for="housing-email">Phone</label>
        <div class="col-12">
          <input type="text" class="form-control" id="housing-tel" name="housing-tel" placeholder="Housing Tel"
                 formControlName="phone">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('phone').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row"
           [ngClass]="{'is-invalid': form.get('homepage').dirty === true && form.get('homepage').status === 'INVALID'}">
        <label class="col-12" for="housing-website">Website</label>
        <div class="col-12">
          <input type="text" class="form-control" id="housing-website" name="housing-website"
                 placeholder="Housing Website" formControlName="homepage">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('homepage').errors)">{{error}}
          </div>
        </div>
      </div>
      <div class="form-group row"
           [ngClass]="{'is-invalid': form.get('stars').dirty === true && form.get('stars').status === 'INVALID'}">
        <label class="col-12" for="housing-stars">Stars</label>
        <div class="col-12">
          <input type="text" class="form-control" id="housing-stars" name="housing-stars"
                 placeholder="Housing Stars" formControlName="stars">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('stars').errors)">{{error}}
          </div>
        </div>
      </div>
      <div class="form-group row"
           [ngClass]="{'is-invalid': form.get('displayStarsAsPoints').dirty === true && form.get('displayStarsAsPoints').status === 'INVALID'}">
        <label class="col-12" for="housing-stars">Stars Settings</label>
        <div class="col-12">
          <div class="custom-control custom-checkbox custom-control-inline mb-5">
            <input class="custom-control-input" type="checkbox" name="housing-displayStarsAsPoints"
                   id="housing-displayStarsAsPoints"
                   formControlName="displayStarsAsPoints">
            <label class="custom-control-label" for="housing-displayStarsAsPoints">Use Points instead of Stars</label>
            <div class="invalid-feedback"
                 *ngFor="let error of extractValidationErrorsAsArray(form.get('displayStarsAsPoints').errors)">{{error}}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row"
           [ngClass]="{'is-invalid': form.get('price').dirty === true && form.get('price').status === 'INVALID'}">
        <label class="col-12" for="housing-base-price">Price Per Person in Double Room</label>
        <div class="col-12">
          <input type="text" class="form-control" id="housing-base-price" name="housing-base"
                 placeholder="Price Per Person in Double Room" formControlName="price">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('price').errors)">{{error}}
          </div>
        </div>
      </div>
      <div class="form-group row"
           [ngClass]="{'is-invalid': form.get('priceAdjusted').dirty === true && form.get('priceAdjusted').status === 'INVALID'}">
        <label class="col-12" for="housing-price-adjusted">Price Adjusted (Created Automatically)</label>
        <div class="col-12">
          <input type="text" class="form-control disabled" id="housing-price-adjusted" name="housing-price-adjusted"
                 placeholder="Automatically calculated price" formControlName="priceAdjusted" readonly>
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('priceAdjusted').errors)">{{error}}
          </div>
        </div>
      </div>
<!--      <div class="form-group row"-->
<!--           [ngClass]="{'is-invalid': form.get('commission').dirty === true && form.get('commission').status === 'INVALID'}">-->
<!--        <label class="col-12" for="housing-commission">Commission</label>-->
<!--        <div class="col-12">-->
<!--          <input type="text" class="form-control" id="housing-commission" name="housing-commission"-->
<!--                 placeholder="Housing Commission" formControlName="commission">-->
<!--          <div class="invalid-feedback"-->
<!--               *ngFor="let error of extractValidationErrorsAsArray(form.get('commission').errors)">{{error}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="form-group row"-->
<!--           [ngClass]="{'is-invalid': form.get('language').dirty === true && form.get('language').status === 'INVALID'}">-->
<!--        <label class="col-12" for="housing-commission">Language</label>-->
<!--        <div class="col-12">-->
<!--          <select class="form-control" id="housing-language" name="housing-language" placeholder="Housing Language"-->
<!--                  formControlName="language">-->
<!--            <option value="de">German</option>-->
<!--            <option value="en">English</option>-->
<!--          </select>-->
<!--          <div class="invalid-feedback"-->
<!--               *ngFor="let error of extractValidationErrorsAsArray(form.get('language').errors)">{{error}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="form-group row"
           [ngClass]="{'is-invalid': form.get('checkIn').dirty === true && form.get('checkIn').status === 'INVALID'}">
        <label class="col-12" for="housing-checkIn">CheckIn</label>
        <div class="col-12">
          <input type="text" class="form-control" id="housing-checkIn" name="housing-checkIn"
                 placeholder="CheckIn (HH:MM)" formControlName="checkIn">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('checkIn').errors)">{{error}}
          </div>
        </div>
      </div>
      <div class="form-group row"
           [ngClass]="{'is-invalid': form.get('checkOut').dirty === true && form.get('checkOut').status === 'INVALID'}">
        <label class="col-12" for="housing-checkOut">CheckOut</label>
        <div class="col-12">
          <input type="text" class="form-control" id="housing-checkOut" name="housing-checkOut"
                 placeholder="checkOut (HH:MM)" formControlName="checkOut">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('checkOut').errors)">{{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-12" for="housing-stars">Hotel Type</label>
        <div class="col-12">
          <div class="custom-control custom-checkbox custom-control-inline mb-5"
               *ngFor="let housingType of housingTypes">
            <input class="custom-control-input" type="checkbox" name="housingType_{{housingType}}"
                   id="housingType_{{housingType}}"
                   formControlName="housingType_{{housingType}}">
            <label class="custom-control-label" for="housingType_{{housingType}}">{{housingType}}</label>
          </div>
        </div>

      </div>
      <div class="form-group row">
        <label class="col-12" for="housing-stars">Partner</label>
        <div class="col-12">

          <div class="custom-control custom-checkbox custom-control-inline mb-5">
            <input class="custom-control-input" type="checkbox" name="housing-isPartner" id="housing-isPartner"
                   formControlName="isPartner">
            <label class="custom-control-label" for="housing-isPartner">Standard Partner</label>
          </div>

          <div class="custom-control custom-checkbox custom-control-inline mb-5">
            <input class="custom-control-input" type="checkbox" name="housing-isTopPartner" id="housing-isTopPartner"
                   formControlName="isTopPartner">
            <label class="custom-control-label" for="housing-isTopPartner">Top Partner</label>
          </div>

          <div class="custom-control custom-checkbox custom-control-inline mb-5">
            <input class="custom-control-input" type="checkbox" name="housing-isExtranetPartner"
                   id="housing-isExtranetPartner"
                   formControlName="isExtranetPartner">
            <label class="custom-control-label" for="housing-isExtranetPartner">Extranet Partner</label>
          </div>

          <div class="custom-control custom-checkbox custom-control-inline mb-5">
            <input class="custom-control-input" type="checkbox" name="housing-isExtranetPartner"
                   id="housing-marketplace"
                   formControlName="marketplace">
            <label class="custom-control-label" for="housing-marketplace">Marketplace Access</label>
          </div>
        </div>

      </div>
      <div class="form-group row">
        <label class="col-12" for="housing-stars">Price Calculation</label>
        <div class="col-12">
          <div class="custom-control custom-checkbox custom-control-inline mb-5">
            <input class="custom-control-input" type="checkbox" name="housing-housingPriceAsPriceAdjusted"
                   id="housing-price-as-priceAdjusted"
                   formControlName="priceCalculationLockedByAdmin">
            <label class="custom-control-label" for="housing-price-as-priceAdjusted">Lock Price Calculation</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
