<div class="block">
  <div class="block-content block-content-full">
    <div class="row">
      <div class="col-md-3">
        <img
          *ngIf="housing.imagesOptimized != null && housing.imagesOptimized.preview != null"
          src="{{environment.urls.blob}}/{{housing._id}}/w400/{{housing.imagesOptimized.preview}}"
          style="width:100%;height:100%; object-fit: cover;">
        <img
          *ngIf="housing.imagesOptimized != null && housing.imagesOptimized.offer != null"
          src="{{environment.urls.blob}}/{{housing._id}}/w400/{{housing.imagesOptimized.offer}}"
          style="width:33%;height:33%; object-fit: cover; margin-top:-95px">
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-9">
            <h5>
              {{housing.name}}
              <span *ngIf="housing.stars != null && housing.stars != ''">
                <i class="fa fa-fw fa-star text-warning" *ngFor="let key of housing.stars | arrayFromNumber"></i>
              </span>
              <br>
              <small>{{housing.email}}</small>
            </h5>
          </div>
          <div class="col-md-3 text-right pull-right">
            <a class="btn btn-sm btn-primary" [routerLink]="['/housings', housing._id, 'overview']">Info</a>&nbsp;
            <a class="btn btn-sm btn-success" [routerLink]="['/housings', housing._id, 'details']">Edit</a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">{{housing.location.street}}, {{housing.location.postalCode}} {{housing.location.city}},
            {{housing.location.adminLevel1}}, {{housing.location.countryCode}} - {{housing.distance}}km from city center
            <small>(Show on Map)</small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            {{housing.numberOfRooms}} rooms
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngFor="let housingType of housing.type">
              <a class="badge badge-primary" href="javascript:void(0)">{{housingType}}</a>&nbsp;
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="housing.internalInfo.isPartner === true">
              <a class="badge badge-success" href="javascript:void(0)">Partner</a>&nbsp;
            </ng-container>
            <ng-container *ngIf="housing.internalInfo.isTopPartner === true">
              <a class="badge badge-success" href="javascript:void(0)">
                Top
                Partner</a>&nbsp;
            </ng-container>
            <ng-container *ngIf="housing.internalInfo.isExtranetPartner === true">
              <a class="badge badge-success" href="{{environment.urls.partner}}/h/{{housing._id}}" target="_blank">Extranet</a>&nbsp;
            </ng-container>
            <ng-container *ngIf="housing.internalInfo.qualifiedForNewIB === true">
              <a class="badge badge-success" href="javascript:void(0)">Direct Search</a>&nbsp;
            </ng-container>
            <ng-container *ngIf="housing.internalInfo.closed === true">
              <a class="badge badge-danger" href="javascript:void(0)">Closed</a>&nbsp;
            </ng-container>
            <ng-container *ngIf="housing.internalInfo.deleted === true">
              <a class="badge badge-danger" href="javascript:void(0)">Deleted</a>&nbsp;
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="col-2">&nbsp;</div>
        </div>

        <div class="d-none d-lg-block">
          <div class="row align-items-center">

            <ng-container *ngIf="housing.rating != null">
              <div class="col-auto pr-0">
                <div class="rounded py-1 px-2 font-weight-bold text-white"
                     [ngClass]="{
                   'bg-black': housing.rating <= 20,
                   'bg-red': housing.rating <= 40 && housing.rating > 20,
                   'bg-danger': housing.rating <= 60 && housing.rating > 40,
                   'bg-warning': housing.rating < 80 && housing.rating > 60,
                   'bg-primary': housing.rating <= 89 && housing.rating >= 80,
                   'bg-success': housing.rating >= 90
                   }"
                >
                  {{housing.rating}}
                </div>
              </div>
              <div class="col line-height-small">
                <div class="text-success">
                  <small class="font-weight-bold" *ngIf="housing.rating >= 90">Very good</small>
                  <small class="font-weight-bold" *ngIf="housing.rating <= 89 && housing.rating >= 80">Good</small>
                  <small class="font-weight-bold" *ngIf="housing.rating < 80 && housing.rating > 60">Okay</small>
                  <small class="font-weight-bold" *ngIf="housing.rating <= 60 && housing.rating > 40">Meh</small>
                  <small class="font-weight-bold" *ngIf="housing.rating <= 40 && housing.rating > 20">Bad</small>
                  <small class="font-weight-bold" *ngIf="housing.rating <= 20">No!</small>
                </div>
                <div>
                  <small>{{housing.numberOfRatings}} valuations</small>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="housing.internalInfo.matcherPoints != null">
              <div class="col-auto">
                <div class="rounded bg-gray-light py-1 px-2 font-weight-bold text-white">
                  {{housing.internalInfo.matcherPoints.total | delimitednumber:2}}
                </div>
              </div>
            </ng-container>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
