import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap";
import {environment} from "../../../../../../../environments/environment";

@Component({
  selector: 'app-housing-info-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.css']
})
export class ImageModalComponent implements OnInit {
  @Input('images') images: Array<string>;
  @Input('housingId') housingId: string;
  @Input('image') image: string;
  @ViewChild('childModal') public childModal: ModalDirective;
  @ViewChild('myImage') myImage: ElementRef;

  public imageWidth: number = 0;
  public imageHeight: number = 0;

  public environment = environment;

  constructor() {
  }

  ngOnInit() {
  }

  dosomething() {
   this.imageWidth = this.myImage.nativeElement.width;
   this.imageHeight = this.myImage.nativeElement.height;
  }

  hideModal() {
    this.childModal.hide();
  }

  showModal() {
    this.childModal.show();
  }

  public showNext() {
    const pos = this.images.indexOf(this.image);
    const posNext = pos + 1;

    if (posNext >= this.images.length) {
      this.image = this.images[0];
    } else {
      this.image = this.images[posNext];
    }
  }

  public showPrevious() {
    const pos = this.images.indexOf(this.image);
    const posNext = pos - 1;

    if (posNext < 0) {
      this.image = this.images[this.images.length - 1];
    } else {
      this.image = this.images[posNext];
    }
  }
}
