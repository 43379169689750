import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap';
import {EventsService} from '../../../../../../services/events/events.service';

@Component({
  selector: 'tripmakery-request-cm-settings-hyperguest-response-modal',
  templateUrl: './modal-channel-manager-settings-hyperguest-response.component.html',
  styleUrls: ['./modal-channel-manager-settings-hyperguest-response.component.css']
})
export class ModalChannelManagerSettingsHyperguestResponseComponent implements OnInit {
  @Input() housingId: string;

  @ViewChild('childModal') public childModal: ModalDirective;
public response: string;

  constructor(private eventsService: EventsService) {

  }

  ngOnInit() {
    this.eventsService.on('requestShowModalCMSettingsHyperguestResponse', (responseAsJson: any) => {
      this.response = JSON.stringify(responseAsJson, null, 2);
      this.showChildModal();
    });
  }

  public showChildModal(): void {
    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }
}
