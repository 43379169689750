<app-content-header i18n-title title="Boundaries" i18n-subTitle
                    subTitle="Boundaries Stuff"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>
<div class="content">
  <div class="row">
    <div class="col-md-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>

        <!-- -->
        <div class="block-content block-content-full">
          <form [formGroup]="form" (ngSubmit)="loadBoundariesToCheck()">
            <div class="row">
              <label class="col-12" for="googlePlaceId">Google Place Id</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="GooglePlaceId"
                       autocomplete="off"
                       id="googlePlaceId"
                       formControlName="googlePlaceId">
              </div>
            </div>


            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>


            <div class="row">
              <label class="col-12">Checked</label>
              <div class="col-12">
                <div class="custom-control custom-checkbox custom-control-inline mb-5"
                >
                  <input class="custom-control-input" type="checkbox" name="checked-already"
                         id="checked"
                         formControlName="checked">
                  <label class="custom-control-label" for="checked">Checked Already</label>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-control custom-checkbox custom-control-inline mb-5"
                >
                  <input class="custom-control-input" type="checkbox" name="checked-already"
                         id="notChecked"
                         formControlName="notChecked">
                  <label class="custom-control-label" for="notChecked">Not Checked Already</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>


            <div class="row">
              <label class="col-12">Active</label>
              <div class="col-12">
                <div class="custom-control custom-checkbox custom-control-inline mb-5"
                >
                  <input class="custom-control-input" type="checkbox" name="active"
                         id="active"
                         formControlName="active">
                  <label class="custom-control-label" for="active">Active</label>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-control custom-checkbox custom-control-inline mb-5"
                >
                  <input class="custom-control-input" type="checkbox" name="inactive"
                         id="inactive"
                         formControlName="inactive">
                  <label class="custom-control-label" for="inactive">Inactive</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>

            <div class="row">
              <label class="col-12" for="minNumberOfRequests">Minimum Number of Requests</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       id="minNumberOfRequests"
                       placeholder="Minimum Number of Requests"
                       formControlName="minNumberOfRequests">
              </div>
            </div>

            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>

            <div class="row">
              <label class="col-12">Sortmode</label>
              <div class="col-12">
                <select class="form-control" formControlName="sortmode">
                  <option value="CONVERSIONRATE_DESC" selected>Conversion Rate</option>
                  <option value="REQUESTS_DESC">Requests</option>
                  <option value="BOOKINGS_DESC">Bookings</option>
                  <option value="HOUSINGS_TOTAL_DESC">Housings (Total)</option>
                  <option value="HOUSINGS_ACTIVE_DESC">Housings (Active)</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>

            <div class="row">
              <div class="col-12">
                <button class="btn btn-info btn-sm" type="submit">Apply Filter</button>
              </div>
            </div>

          </form>
        </div>
      </div>

      <div class="block block-bordered mb-50">
        <div class="block-header block-header-default">
          <h3 class="block-title">Misc</h3>
        </div>
        <div class="block-content block-content-full">
          <div class="row">
            <div class="col-12 text-center">
              <button class="btn btn-info" (click)="requestCheckForNewEntries()">Check for new Entries</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Boundaries</h3>
        </div>
        <div class="block-content block-content-full">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>Id</th>
                <th>Formatted Address</th>
                <th>Requests</th>
                <th>Bookings</th>
                <th>Conversion Rate</th>
                <th>Housings (Total)</th>
                <th>Housings (Active)</th>
                <th>Checked</th>
                <th>Approved</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody *ngIf="boundaries != null && pagination != null">
              <tr *ngFor="let boundary of boundaries | paginate: {
              itemsPerPage: pagination.limit,
              currentPage: pagination.page,
              totalItems: pagination.total}"
              >
                <td>{{boundary._id}}</td>
                <td>{{boundary.googleResponse.formatted_address}}</td>
                <td>{{boundary.statistics.requests.total}}</td>
                <td>{{boundary.statistics.requests.booked}}</td>
                <td>{{boundary.statistics.requests.conversionRate | delimitednumber:2}}%</td>
                <td>{{boundary.statistics.housings.total}}</td>
                <td>{{boundary.statistics.housings.active}}</td>
                <td>
                <span class="badge badge-danger" *ngIf="boundary.validated === false || boundary.validated == null"><i
                  class="fa fa-times"></i></span>
                  <span class="badge badge-success" *ngIf="boundary.validated === true"><i
                    class="fa fa-check"></i></span>
                </td>
                <td>
                <span class="badge badge-danger" *ngIf="boundary.accepted === false || boundary.accepted == null"><i
                  class="fa fa-times"></i></span>
                  <span class="badge badge-success" *ngIf="boundary.accepted === true"><i
                    class="fa fa-check"></i></span>
                </td>
                <td><a class="btn btn-success btn-sm"
                       [routerLink]="['/developer', 'boundaries-v2', boundary._id, 'edit']">Edit</a></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
          <pagination-controls [maxSize]="10"
                               (pageChange)="sendPage($event)"
                               *ngIf="pagination != null">
            >
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
