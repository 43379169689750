import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LoadingIndicatorService } from '../../../services/loading-indicator/loading-indicator.service';
import { ConfigService } from '../../../services/configservice/config.service';
import { EventsService } from '../../../services/events/events.service';
import { BreadCrumbElement } from '../../../shared/entities/breadcrumb.element.entity';
import { FormControl, FormGroup } from '@angular/forms';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FieldsBasicPlaceDetails } from '../../../shared/consts/fields-basic-place-details';

@Component({
  selector: 'app-email-bounces',
  templateUrl: './email-bounces.component.html'
})
export class EmailBouncesComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};
  public googleLocation: any;
  public options = {
    fields: FieldsBasicPlaceDetails,
  };

  /* */
  public page = 1;
  public limit = 25;
  public section = 1;

  /* */
  public pagination: any;
  public elements;
  public formFilter: FormGroup;
  public formAddition: FormGroup;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private http: HttpClient) {

    /* */
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Developer', 'javascript:void(0)'),
      new BreadCrumbElement('Email Bounces', 'javascript:void(0)')
    ];

    /* */
    this.formFilter = new FormGroup({
      'state': new FormControl({value: 'NEW', disabled: false}),
      'bounceSource': new FormControl({value: 'GOOGLE', disabled: false}),
      'issueType': new FormControl({value: 'ALL', disabled: false}),
      'destination': new FormControl({value: '', disabled: false}),
      'housingId': new FormControl({value: '', disabled: false}),
      'requestId': new FormControl({value: '', disabled: false}),
    });

  }

  ngOnInit() {
    this.loadEmailBouncesToCheck();
  }

  public handleAddressChangeOfCity(address: Address) {
    this.googleLocation = JSON.parse(JSON.stringify(address));
    console.log('GOOGLELOCATION ', this.googleLocation.place_id);
    // this.loadEmailBouncesToCheck();
  }

  public loadEmailBouncesToCheck() {
    this.loadingIndicatorService.showLoadingBar();

    // /* Build params */
    let params = new HttpParams();
    params = params.append('page', this.page.toString());
    params = params.append('limit', this.limit.toString());
    params = params.append('state', this.formFilter.get('state').value);
    params = params.append('bounceSource', this.formFilter.get('bounceSource').value);
    params = params.append('requestId', this.formFilter.get('requestId').value);
    params = params.append('housingId', this.formFilter.get('housingId').value);

    if (this.formFilter.get('issueType').value !== 'ALL') {
      params = params.append('issueType', this.formFilter.get('issueType').value);
    }

    if (this.googleLocation != null && this.googleLocation.place_id != null && this.formFilter.get('destination').value.trim() !== '') {
      params = params.append('destinationGooglePlaceId', this.googleLocation.place_id);
    }

    this.elements = [];

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/email-bounces', {params: params})
      .subscribe((response: any) => {
          this.elements = response.elements;
          this.pagination = response.pagination;

          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        }
      );
  }

  public markAsDone(id: string) {
    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/email-bounces/' + id + '/simple-edit', {
      state: 'DONE',
      action: 'SIMPLE'
    })
      .subscribe((response: any) => {
          this.loadEmailBouncesToCheck();
        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public sendPage(e: number) {
    this.page = e;
    this.loadEmailBouncesToCheck();
  }
}
