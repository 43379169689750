<app-content-header i18n-title title="Request Details" i18n-subTitle
                    subTitle="View and manage all request related information"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">


  <div class="row">

    <div class="col-md-12">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Move History</h3>
        </div>

        <ng-container *ngIf="pipeline != null">
          <div class="block-content">
            <div class="table-responsive">
              <table class="table table-sm">
                <thead>
                <tr>
                  <th>Date</th>
                  <th>State From</th>
                  <th>State To</th>
                  <th>Handler</th>
                  <th class="text-left">Cause</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let pipelineHistoryElement of pipeline.history">
                  <td>{{pipelineHistoryElement.createdAt}}</td>
                  <td>{{pipelineHistoryElement.from}}</td>
                  <td>{{pipelineHistoryElement.to}}</td>
                  <td>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.handler === 0">Backend</span>
                    <span class="badge badge-success" *ngIf="pipelineHistoryElement.handler === 1">Service</span>
                  </td>
                  <td>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'KITCHEN'">Kitchen</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'AUTO_REQUEST'">Auto Request</span>
                    <span class="badge badge-danger" *ngIf="pipelineHistoryElement.cause === 'AUTO_MOVE'">Auto Move</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'USER_DISQUALIFICATION'">User Disqualification</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'USER_BOOKED'">User Booked</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'SPECIAL_REQUEST_IMPORTANT_REQUEST'">Important Special Request</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'SPECIAL_REQUEST_IMPORTANT_REQUEST_NO_USER_INTERACTION'">Important Special Request (No User Interaction)</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'REQUEST_VALIDATION_QUESTIONS_NOT_ANSWERED'">Request Questions not answered</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'CALLBACK_REQUEST_CREATION'">Callback Request (Creation)</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'CALLBACK_REQUEST'">Callback Request</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'AUTO_REQUEST_PS_NO_HOUSINGS_FOUND'">Auto Request (Process Shift) (No housings found)</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'AUTO_REQUEST_SR_NO_HOUSINGS_FOUND'">Auto Request (Special Request) (No housings found)</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'AUTO_REQUEST_SR'">Auto Request (Special Request)</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'AUTO_REQUEST_SR_NOT_ENOUGH_HOUSINGS_FOUND'">Auto Request (Special Request) (Not enough housings found)</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'AUTO_REQUEST_PS'">Auto Request (Process Shift)</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'AUTO_REQUEST_PS_NOT_ENOUGH_HOUSINGS_FOUND'">Auto Request (Process Shift) (Not enough housings found)</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'AUTO_REQUEST_STD'">Auto Request (Standard)</span>
                    <span class="badge badge-info" *ngIf="pipelineHistoryElement.cause === 'AUTO_REQUEST_STD_NOT_ENOUGH_HOUSINGS_FOUND'">Auto Request (Standard) (Not enough housings found)</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>


        </ng-container>

      </div>
    </div>

  </div>
</div>

