import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as moment from "moment";
import {BsModalService, ModalDirective} from "ngx-bootstrap";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EventsService} from "../../../../../services/events/events.service";

@Component({
  selector: 'tripmakery-housing-info-overview-modal-price-crawler-creation',
  templateUrl: './modal-price-crawler-creation.component.html',
  styleUrls: ['./modal-price-crawler-creation.component.css']
})
export class ModalPriceCrawlerCreationComponent implements OnInit {
  @Input() housingId: string;
  public form: FormGroup;

  @ViewChild('childModal') public childModal: ModalDirective;


  constructor(private http: HttpClient,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'service-booking': new FormControl({value: '', disabled: false}),
      'service-trivago': new FormControl({value: '', disabled: false}),
      'range': new FormControl({value: '', disabled: false}),
      'crawling-mode': new FormControl({value: '', disabled: false}),
    })
  }


  ngOnInit() {
    this.eventsService.on('requestShowHousingOverviewPriceCrawlerCreation', () => {
      this.showChildModal()
    });
  }

  public showChildModal(): void {
    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/price-crawler', {
      crawlBooking: this.createFalseIfEmptyString(this.form.get('service-booking').value),
      crawlTrivago: this.createFalseIfEmptyString(this.form.get('service-trivago').value),
      dateStart: moment.utc(this.form.get('range').value[0]).format("YYYY-MM-DD"),
      dateEnd:  moment.utc(this.form.get('range').value[1]).format("YYYY-MM-DD"),
      crawlingMode: this.createNullIfEmptyString(this.form.get('crawling-mode').value)
    })
      .subscribe(
        (response: any) => {
          this.hideChildModal();

          if (this.createFalseIfEmptyString(this.form.get('service-booking').value) === true) {
            this.eventsService.broadcast("HOUSING_OVERVIEW_TRIGGER_LATEST_BOOKING_CRAWLER_JOBS")
          }

          if (this.createFalseIfEmptyString(this.form.get('service-trivago').value) === true) {
            this.eventsService.broadcast("HOUSING_OVERVIEW_TRIGGER_LATEST_TRIVAGO_CRAWLER_JOBS")
          }
        }
      );
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return null;
    }
    return s;
  }

  private createFalseIfEmptyString(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return false;
    }
    return s;
  }

}
