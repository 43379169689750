<app-content-header i18n-title title="Request Search" i18n-subTitle
                    subTitle="Search for requests"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">


  <div class="row">

    <div class="col-md-6 offset-3">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Request Search</h3>
        </div>

        <div class="block-content">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="form">
                <div class="form-group row">
                  <label class="col-12">Request Id</label>
                  <div class="col-12">
                    <input type="text" class="form-control"
                           placeholder="Please enter your Request Id"
                           autocomplete="off"
                           formControlName="requestId">
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-12 text-right">
                    <button type="button" class="btn btn-success" (click)="performSearch()">Search</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
</div>

