<div bsModal #childModal="bs-modal" class="modal" id="modal-normal" tabindex="-1" role="dialog"
     aria-labelledby="modal-normal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">An Error occured</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" (click)="hideModal()" aria-label="Close">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content">
          <p>{{message}}</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-alt-secondary" (click)="hideModal()">Close</button>
      </div>
    </div>
  </div>
</div>
