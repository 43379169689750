<app-content-header title="Housing Request creation"
                    subTitle="Create a housing request manually"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">
  <div class="row">
    <div class="col-4 offset-4">
      <form [formGroup]="form" (submit)="onSubmitForm()">
        <div class="block">
          <div class="block-header block-header-default">
            <h3 class="block-title">Add a Housing Request</h3>
            <div class="block-options">
            </div>
          </div>
          <div class="block-content block-content-full">

            <div class="form-group row">
              <label class="col-4 col-form-label" for="housing-id">Housing Id</label>
              <div class="col-8"
                   [ngClass]="{'is-invalid': (form.get('housingId').touched || form.get('housingId').dirty) && form.get('housingId').invalid}">
                <input type="text" class="form-control" id="housing-id" name="housing-id"
                       placeholder="Please enter the Housing Id" formControlName="housingId">
                <div class="invalid-feedback"
                     *ngFor="let error of extractValidationErrorsAsArray(form.get('housingId').errors)">
                  {{error}}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label" for="request-id">Request Id</label>
              <div class="col-8"
                   [ngClass]="{'is-invalid': (form.get('requestId').touched || form.get('requestId').dirty) && form.get('requestId').invalid}">
                <input type="text" class="form-control" id="request-id" name="request-id"
                       placeholder="Please enter the Request Id" formControlName="requestId">
                <div class="invalid-feedback"
                     *ngFor="let error of extractValidationErrorsAsArray(form.get('housingId').errors)">
                  {{error}}
                </div>
              </div>
            </div>

            <div class="form-group">
              <button type="submit" class="btn btn-md btn-alt-primary" [disabled]="isLoading">
                <i class="fa fa-check mr-5" *ngIf="isSuccessful === true"></i>
                <i class="fa fa-save mr-5" *ngIf="isLoading === false && isSuccessful === false"></i>
                <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true && isSuccessful === false"></i>
                <ng-container *ngIf="isSuccessful === false">Save</ng-container>
              </button>
            </div>

          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- END Sauce -->
</div>
