import { Component, OnInit } from '@angular/core';
import { BreadCrumbElement } from '../../../../shared/entities/breadcrumb.element.entity';
import { PagingService } from '../../../../services/pagingservice/paging.service';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BlKeywordAngularParamsInterface } from './interfaces/bl-keyword-angular.params.interface';

declare const Codebase;

@Component({
  selector: 'app-pages-bl-search',
  templateUrl: './bl-search.component.html',
  styleUrls: ['./bl-search.component.css']
})
export class BlSearchComponent implements OnInit {
  /* */
  public page = 1;
  public limit = 25;

  /* */
  public sort = 'stars';

  /* */
  public userTotal = 0;

  /* */
  public sorting = -1;

  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};
  public blKeywords: Array<any> = [];
  public paginationData;
  public form: FormGroup;

  constructor(private pagingService: PagingService,
              private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private route: ActivatedRoute,
              private router: Router,
              private activeRoute: ActivatedRoute
  ) {
    const formGroupData = {
      'keyword': new FormControl({value: '', disabled: false}),
      'destination': new FormControl({value: '', disabled: false}),
      'isEnabled': new FormControl({value: true, disabled: false}),
    };
    this.form = new FormGroup(formGroupData);
  }

  ngOnInit() {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Pages', 'javascript:void(0)'),
      new BreadCrumbElement('BL', 'javascript:void(0)'),
    ];

    this.activeRoute.queryParams.subscribe((params: BlKeywordAngularParamsInterface) => {
      console.log('Angular URL Params: ' + JSON.stringify(params));
      this.mapAngularQueryParamsToForm(params);

      const httpParams: HttpParams = this.setHttpParamsFromFilledForm();
      console.log('Created HTTP Params: ' + httpParams);
      this.sendRequest(httpParams);
    });

    Codebase.helper('core-tab');
  }

  public submitForm() {
    const queryParams = {
      page: this.page,
      limit: this.limit,
      keyword: this.form.get('keyword').value,
      destination: this.form.get('destination').value,
      isEnabled: this.form.get('isEnabled').value,
    };

    this.router.navigate(['./'], {
      relativeTo: this.activeRoute,
      queryParams: queryParams,
      replaceUrl: true
    });
  }

  public mapAngularQueryParamsToForm(params: BlKeywordAngularParamsInterface) {
    for (const field in params) {
      if (params.hasOwnProperty(field)) {

        /* */
        if (field === 'keyword') {
          this.form.patchValue({'keyword': params[field]});
        }

        /* */
        if (field === 'destination') {
          this.form.patchValue({'destination': params[field]});
        }

        /* */
        if (field === 'isEnabled') {
          this.form.patchValue({'isEnabled': params[field] === 'true'});
        }

        /* */
        if (field === 'page') {
          const page = params[field];
          if (page !== '' && !isNaN(parseInt(page, 10))) {
            this.page = parseInt(page, 10);
          }
        }

        /* */
        if (field === 'limit') {
          const limit = params[field];
          if (limit !== '' && !isNaN(parseInt(limit, 10))) {
            this.limit = parseInt(limit, 10);
          }
        }
      }
    }
  }

  public setHttpParamsFromFilledForm() {
    let params: HttpParams = new HttpParams();

    /* */
    const keyword = this.form.get('keyword').value.trim();
    if (keyword !== '') {
      params = params.append('keyword', keyword);
    }

    /* */
    const destination = this.form.get('destination').value.trim();
    if (destination !== '') {
      params = params.append('destination', destination);
    }

    /* */
    const isEnabled = this.form.get('isEnabled').value;
    if (isEnabled !== null) {
      params = params.append('isEnabled', isEnabled.toString());
    }

    /* */
    if (this.page != null) {
      params = params.append('page', this.page.toString(10));
    }

    /* */
    if (this.limit != null) {
      params = params.append('limit', this.limit.toString(10));
    }

    return params;
  }

  public sendRequest(params: HttpParams) {
    this.loadingIndicatorService.showLoadingBar();

    /* Server Anfrage */
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/pages/bl', {
      params: params
    })
      .subscribe(
        (response: any) => {

          for (const element of response.elements) {
            element.urls = {
              'de': environment.urls.landingpage + '/de/bl/' + element.keywordData.publicId + '-' + element.destinationData.publicId + '/' + element.keywordData.slugsKeyword.de + '-' + element.destinationData.slugsDestination.de + '/?forceCacheRefresh=true',
              'en': environment.urls.landingpage + '/en/bl/' + element.keywordData.publicId + '-' + element.destinationData.publicId + '/' + element.keywordData.slugsKeyword.en + '-' + element.destinationData.slugsDestination.en + '/?forceCacheRefresh=true',
              'en-gb': environment.urls.landingpage + '/en-gb/bl/' + element.keywordData.publicId + '-' + element.destinationData.publicId + '/' + element.keywordData.slugsKeyword['en-gb'] + '-' + element.destinationData.slugsDestination['en-gb'] + '/?forceCacheRefresh=true',
              'es': environment.urls.landingpage + '/es/bl/' + element.keywordData.publicId + '-' + element.destinationData.publicId + '/' + element.keywordData.slugsKeyword.es + '-' + element.destinationData.slugsDestination.es + '/?forceCacheRefresh=true',
              'it': environment.urls.landingpage + '/it/bl/' + element.keywordData.publicId + '-' + element.destinationData.publicId + '/' + element.keywordData.slugsKeyword.it + '-' + element.destinationData.slugsDestination.it + '/?forceCacheRefresh=true',
            };
          }

          this.blKeywords = response.elements;
          this.paginationData = response.pagination;
          this.userTotal = response.pagination.total;
        },
        (error) => {

        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  /* */
  public sendPage(page: number) {
    this.page = page;
    this.submitForm();
  }

}



