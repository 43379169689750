export class FormUtils {


  public static returnAsStringBoolOrFalseIfEmpty(field: boolean) {
    return (field == null) ? 'false' : field.toString();
  }

  public static returnAsNullIfEmpty(field: string) {
    return (field == null) ? null : field.toString();
  }

  public static createNullIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    return s;
  }

  public static createFalseIfNull(element) {
    if (element == null) {
      return false;
    }
    return element;
  }


  public static returnAsNumberOrNullIfEmptyOrNoNumber(field: string) {
    const numberParsed = parseFloat(field);
    if (Number.isNaN(numberParsed)) {
      return null;
    }
    return numberParsed;
  }

  public static extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

}
