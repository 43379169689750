import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Lightbox} from "ngx-lightbox";
import {environment} from "../../../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {LoadingIndicatorService} from "../../../../../../services/loading-indicator/loading-indicator.service";
import {EventsService} from "../../../../../../services/events/events.service";
import {HousingInfoService} from "../../../../../../services/housing-info/housing-info.service";

@Component({
  selector: 'app-housing-info-image-element',
  templateUrl: './image-element.component.html',
  styleUrls: ['./image-element.component.css']
})
export class ImageElementComponent implements OnInit, OnChanges {
  @Input('image') image;
  @Input('housing') housing;
  @Input('enableControls') enableControls: boolean = true;
  @Input('imageId') imageId;
  @ViewChild('myImage') myImage: ElementRef;
  public visible = false;
  public imageUrl: string;
  public bgStyle: any;
  public environment = environment;
  public imageWidth: number = 0;
  public imageHeight: number = 0;

  constructor(private lightbox: Lightbox,
              private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient,
              private eventsService: EventsService,
              private housingInfoService: HousingInfoService) {
  }

  ngOnInit() {
    this.imageUrl = environment.urls.blob + '/' + this.housing._id + '/raw/' + this.image;
    if (this.image != null) {
      this.bgStyle = {
        'background-image': 'url(' + this.imageUrl + ')', 'height': '100%', 'width': '100%',
        'background-position': 'center center',
        'background-repeat': 'no-repeat',
        'background-size': 'cover'
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes.image != null && changes.image.currentValue != null) {
        this.imageUrl = environment.urls.blob + '/' + this.housing._id + '/raw/' + this.image;
        this.bgStyle = {
          'background-image': 'url(' + this.imageUrl + ')', 'height': '100%', 'width': '100%',
          'background-position': 'center center',
          'background-repeat': 'no-repeat',
          'background-size': 'cover'
        };
    }
  }

  mouseEnter(event) {
    if (this.enableControls === true) {
      this.visible = true;
    }
  }

  mouseLeave(event) {
    if (this.enableControls === true) {
      this.visible = false;
    }
  }

  public previewImageInModal(id: string) {
    this.eventsService.broadcast('housingInfoShowImageModal', id);
  }

  dosomething() {
    this.imageWidth = this.myImage.nativeElement.width;
    this.imageHeight = this.myImage.nativeElement.height;
  }

  public setPreviewImage() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/images/' + this.image + '/set-preview', {})
      .subscribe((response: any) => {
          this.housingInfoService.setSelectedHousing(response);
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          console.log("Error", error);
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public setOfferImage() {
    this.loadingIndicatorService.showLoadingBar();


    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/images/' + this.image + '/set-offer', {})
      .subscribe((response: any) => {
          this.housingInfoService.setSelectedHousing(response);
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          console.log("Error", error);
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
          this.loadingIndicatorService.hideLoadingBar();

        }
      );
  }

  public deleteImage() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.delete(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/images/' + this.image)
      .subscribe((response: any) => {
          this.housingInfoService.setSelectedHousing(response);
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          console.log("Error", error);
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
          this.loadingIndicatorService.hideLoadingBar();

        }
      );
  }
}
