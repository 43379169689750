<app-content-header i18n-title title="Crawler Jobs" i18n-subTitle
                    subTitle="Get an overview of our current crawler jobs"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>
<div class="content">
  <div class="row">
    <div class="col-md-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>

        <!-- -->
        <div class="block-content block-content-full">
          <form [formGroup]="formFilter" (ngSubmit)="loadBoundariesToCheck()" (change)="loadBoundariesToCheck()">
            <div class="row">
              <label class="col-12" for="source">Source</label>
              <div class="col-12">
                <select type="text" class="form-control"
                        id="source"
                        formControlName="source">
                  <option value="booking">Booking.com</option>
                  <option value="trivago">Trivago.de</option>
                </select>
              </div>
            </div>


            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>


            <div class="row">
              <label class="col-12">Job Type</label>
              <div class="col-12">
                <div class="custom-control custom-checkbox custom-control-inline mb-5"
                >
                  <input class="custom-control-input" type="checkbox" name="jobType_priceIndexer"
                         id="jobType_priceIndexer"
                         formControlName="jobType_priceIndexer">
                  <label class="custom-control-label" for="jobType_priceIndexer">Price Indexer</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline mb-5"
                >
                  <input class="custom-control-input" type="checkbox" name="jobType_housingMatcher"
                         id="jobType_housingMatcher"
                         formControlName="jobType_housingMatcher">
                  <label class="custom-control-label" for="jobType_housingMatcher">Housing Matcher</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>

          </form>
        </div>
      </div>

    </div>
    <div class="col-md-9">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Crawler Jobs</h3>
        </div>
        <div class="block-content block-content-full">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>Id</th>
                <th>HousingId</th>
                <th>Job Type</th>
                <th>Run Current</th>
                <th>Run Failed</th>
                <th>Run Max</th>
                <th>State</th>
                <th>CreatedAt</th>
              </tr>
              </thead>
              <tbody *ngIf="elements != null && pagination != null">
              <tr *ngFor="let element of elements | paginate: {
              itemsPerPage: pagination.limit,
              currentPage: pagination.page,
              totalItems: pagination.total}"
              >
                <td>{{element._id}}</td>
                <td>{{element.housingId}}</td>
                <td>
                  <span class="badge badge-info" *ngIf="element.jobType === 'PRICEINDEXER'">Price Indexer</span>
                  <span class="badge badge-info" *ngIf="element.jobType === 'HOUSINGMATCHER'">Housing Matcher</span>
                </td>
                <td>{{element.runCurrent}}</td>
                <td>{{element.runFailed}}</td>
                <td>{{element.runMax}}</td>
                <td>
                  <span class="badge badge-secondary" *ngIf="element.state === 'WAITING'">Waiting</span>
                  <span class="badge badge-primary" *ngIf="element.state === 'ACTIVE'">Active</span>
                  <span class="badge badge-success" *ngIf="element.state === 'FINISHED'">Finished</span>
                  <span class="badge badge-danger" *ngIf="element.state === 'FAILED'">Failed</span>
                </td>
                <td>{{element.createdAt}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
          <pagination-controls [maxSize]="10"
                               (pageChange)="sendPage($event)"
                               *ngIf="pagination != null">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
