<div id="page-container" class="main-content-boxed">

  <!-- Main Container -->
  <main id="main-container">

    <!-- Page Content -->
    <div class="bg-image" style="background-image: url('./assets/media/photos/photo34@2x.jpg');">
      <div class="row mx-0 bg-black-op">
        <div class="hero-static col-md-6 col-xl-8 d-none d-md-flex align-items-md-end">
          <div class="p-30 invisible" data-toggle="appear">
            <p class="font-size-h3 font-w600 text-white">
              Get Inspired and Create.
            </p>
            <p class="font-italic text-white-op">
              Copyright &copy; <span class="js-year-copy">2018</span>
            </p>
          </div>
        </div>
        <div class="hero-static col-md-6 col-xl-4 d-flex align-items-center bg-white invisible" data-toggle="appear"
             data-class="animated fadeInRight">
          <div class="content content-full">
            <!-- Header -->
            <div class="px-30 py-10">
              <a class="link-effect font-w700" href="index.html">
                <span class="font-size-xl text-primary-dark">trip</span><span class="font-size-xl">makery</span>
              </a>
              <h1 class="h3 font-w700 mt-30 mb-10">Welcome to your Dashboard</h1>
              <h2 class="h5 font-w400 text-muted mb-0">Please sign in</h2>
            </div>
            <!-- END Header -->

            <!-- Sign In Form -->
            <!-- jQuery Validation (.js-validation-signin class is initialized in js/pages/op_auth_signin.js) -->
            <!-- For more examples you can check out https://github.com/jzaefferer/jquery-validation -->
            <form class="px-30" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <div class="form-group row" [ngClass]="{'is-invalid': loginForm.get('email').untouched === false && loginForm.get('email').status === 'INVALID'}">
                <div class="col-12">
                  <div class="form-material floating">
                    <input type="text" class="form-control" id="login-email"
                           formControlName="email">
                    <label for="login-email">Email</label>
                  </div>
                </div>
              </div>
              <div class="form-group row" [ngClass]="{'is-invalid': loginForm.get('password').untouched === false && loginForm.get('password').status === 'INVALID'}">
                <div class="col-12">
                  <div class="form-material floating">
                    <input type="password" class="form-control" id="login-password"
                           formControlName="password">
                    <label for="login-password">Password</label>
                  </div>
                  <div class="invalid-feedback" *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)">
                    <div *ngIf="loginForm.get('password').errors.required">Password is required</div>
                    <div *ngIf="loginForm.get('password').errors.invalid">Password is invalid</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-12">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="login-remember-me" name="login-remember-me" formControlName="rememberLogin">
                    <label class="custom-control-label" for="login-remember-me">Remember Me</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-sm btn-hero btn-alt-primary">
                  <i class="si si-login mr-10"></i> Sign In
                </button>
                <!--<div class="mt-30">-->
                <!--<a class="link-effect text-muted mr-10 mb-5 d-inline-block" href="op_auth_reminder2.html">-->
                <!--<i class="fa fa-warning mr-5"></i> Forgot Password-->
                <!--</a>-->
                <!--</div>-->
              </div>
            </form>
            <!-- END Sign In Form -->
          </div>
        </div>
      </div>
    </div>
    <!-- END Page Content -->

  </main>
  <!-- END Main Container -->
</div>
<!-- END Page Container -->
