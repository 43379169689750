import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingIndicatorService } from '../../../services/loading-indicator/loading-indicator.service';
import { BreadCrumbElement } from '../../../shared/entities/breadcrumb.element.entity';
import { AngularParams } from '../../pages/destination/destination-search/interfaces/angular.params.interface';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';

declare const Codebase;

@Component({
  selector: 'app-generated-text-get-by-keys',
  templateUrl: './generated-text-get-by-keys.component.html',
  styleUrls: ['./generated-text-get-by-keys.component.css']
})
export class GeneratedTextGetByKeysComponent implements OnInit {

  /* */
  public page = 1;
  public limit = 25;

  /* */
  public sort = 'stars';

  /* */
  public sorting = -1;

  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};
  public elements: Array<any> = [];
  public paginationData;
  public data;

  public form: FormGroup;


  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private activeRoute: ActivatedRoute,
              private fb: FormBuilder,
  ) {
    /* */
    this.form = new FormGroup({
      'identifier': new FormControl({value: '', disabled: false}),
      'keys': new FormArray([])
    });
  }

  ngOnInit() {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Pages', 'javascript:void(0)'),
      new BreadCrumbElement('Generated Text', 'javascript:void(0)'),
    ];

    this.activeRoute.queryParams.subscribe((params: AngularParams) => {
      console.log('Angular URL Params: ' + JSON.stringify(params));
      this.mapAngularQueryParamsToForm(params);

      const httpParams: HttpParams = this.setHttpParamsFromFilledForm();
      console.log('Created HTTP Params: ' + httpParams);
      // this.sendRequest(httpParams);
    });

    Codebase.helper('core-tab');
  }

  public submitForm() {
    const httpParams = this.setHttpParamsFromFilledForm();
    this.sendRequest(httpParams);
  }

  public getControls() {
    return (this.form.get('keys') as FormArray).controls;
  }

  public addNextKeys() {
    (this.form.controls['keys'] as FormArray).push(this.createKeysItem());
  }

  private createKeysItem(key?: string,
                         value?: string) {
    return this.fb.group({
      key: [key],
      value: [value],
    });
  }

  public mapAngularQueryParamsToForm(params: AngularParams) {
    for (const field in params) {
      if (params.hasOwnProperty(field)) {

        /* */
        if (field === 'page') {
          const page = params[field];
          if (page !== '' && !isNaN(parseInt(page, 10))) {
            this.page = parseInt(page, 10);
          }
        }

        /* */
        if (field === 'limit') {
          const limit = params[field];
          if (limit !== '' && !isNaN(parseInt(limit, 10))) {
            this.limit = parseInt(limit, 10);
          }
        }
      }
    }
  }

  public setHttpParamsFromFilledForm() {
    let params: HttpParams = new HttpParams();

    /* */
    if (this.page != null) {
      params = params.append('page', this.page.toString(10));
    }

    /* */
    if (this.limit != null) {
      params = params.append('limit', this.limit.toString(10));
    }

    /* */
    const formVals = this.form.value;

    const keyValueParamArray = [];
    if (formVals.keys.length > 0) {
      for (const formValElement of formVals.keys) {
        if (formValElement.value == null || formValElement.value.trim() === '') {
          continue;
        }
        if (formValElement.key == null || formValElement.key.trim() === '') {
          continue;
        }

        keyValueParamArray.push({key: formValElement.key, value: formValElement.value});
      }
      params = params.append('keyValues', JSON.stringify(keyValueParamArray));
    }

    return params;
  }

  public sendRequest(params: HttpParams) {
    this.loadingIndicatorService.showLoadingBar();

    /* Server Anfrage */
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/generated-text/get-by-keys', {
      params: params
    })
      .subscribe(
        (response: any) => {
          this.data = response;
        },
        (error) => {

        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  /* */
  public sendPage(page: number) {
    this.page = page;
    this.submitForm();
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public getValuesOfKeyValues(form) {
    return form.controls.values.controls;
  }

  public addNextKeysSub(form) {
    (form.controls['values'] as FormArray).push(this.createKeysItem());
  }
}



