import { Component, OnInit } from '@angular/core';
import { BreadCrumbElement } from '../../../../shared/entities/breadcrumb.element.entity';
import { PagingService } from '../../../../services/pagingservice/paging.service';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IGeneratedTextContainer } from '../interfaces/generated-text-container.interface';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EventsService } from '../../../../services/events/events.service';
import { FormUtils } from '../../../../shared/utils/form-utils';

declare const Codebase;

@Component({
  selector: 'app-generated-text-destination-info',
  templateUrl: './generated-text-destination-info.component.html',
  styleUrls: ['./generated-text-destination-info.component.css']
})
export class GeneratedTextDestinationInfoComponent implements OnInit {

  /* */
  public page = 1;
  public limit = 25;

  /* */
  public sort = 'stars';

  /* */
  public sorting = -1;

  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};
  public elements: Array<any> = [];
  public paginationData;
  public elementId;
  public elementData: IGeneratedTextContainer;

  public form: FormGroup;
  public patterns: Array<{ _id: string, patternName: string, description: string }> = [];

  // TODO https://stackblitz.com/edit/angular-ztueuu?file=src%2Fapp%2Fapp.component.ts
  // TODO https://stackoverflow.com/questions/51449195/dynamically-add-a-set-of-fields-to-a-reactive-form
  // https://stackblitz.com/edit/deep-nested-reactive-form?file=app%2Fapp.component.html

  constructor(private pagingService: PagingService,
              private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private fb: FormBuilder,
              private eventsService: EventsService,
  ) {
    /* */
    this.form = new FormGroup({
      'patternId': new FormControl({value: '', disabled: false}),
      'identifier': new FormControl({value: '', disabled: false}),
      'state': new FormControl({value: '', disabled: false}),
      'description': new FormControl({value: '', disabled: false}),
      'enabled': new FormControl({value: false, disabled: false}),
      'keys': new FormArray([]),
      'keyValues': new FormArray([])
    });

    /* */
    this.handleReceiveAvailablePatterns();

    this.elementId = this.activatedRoute.snapshot.params['destinationId'];
    this.requestData();
  }

  ngOnInit() {
    Codebase.helper('core-tab');
  }

  public submitForm() {
    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/generated-text/destination/' + this.elementId,
      this.buildPatchBodyFromForm()
    )
      .subscribe(
        (response: any) => {
          this.elementData = response;
        },
        (err) => {
          if (err.error.message === 'E_VALIDATION_FAILED') {
            console.log('Just a validation Error');
            try {
              for (const error of err.error.errors) {
                this.form.get(error.property).setErrors(error.constraints);
                this.form.get(error.property).markAsDirty();

                console.log(error.property, error.constraints);
              }
            } catch (error) {
              console.log('Catched E', error);
            }
            return;
          } else if (err.error.message === 'E_EMAIL_ALREADY_IN_USE') {
            return;
          }

          console.log(err);

          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
        }
      );
  }

  private buildPatchBodyFromForm() {
    const formValues = this.form.value;

    const mappedFormKeyValues: Array<{ key: string, values: string[] }> = [];
    for (const element of formValues.keyValues) {
      const elementsInList = [];
      for (const elementInValues of element.values) {
        if (elementInValues.key === '' || elementInValues.key === null) {
          continue;
        }

        elementsInList.push(elementInValues.key);
      }

      mappedFormKeyValues.push({key: element.key, values: elementsInList});
    }

    return {
      'description': formValues.description,
      'enabled': formValues.enabled,
      'identifier': formValues.identifier,
      'patternId': formValues.patternId,
      'state': formValues.state,
      // 'keys': formValues.keys.filter(element => element.key != null && element.key.trim() !== '').map(element => element.key),
      'keyValues': mappedFormKeyValues,
    };
  }

  public addNextKeys() {
    (this.form.controls['keys'] as FormArray).push(this.createKeysItem());
  }

  createKeysItem(value?: string) {
    return this.fb.group({
      key: [value],
    });
  }

  createKeyValuesItem(key: string,
                      values: string[]) {
    return this.fb.group({
      key: [key],
      // values: this.fb.array(values)
      values: this.fb.array(values.map(element => this.fb.group({key: [element]})))
    });
  }

  public getValuesOfKeyValues(form) {
    return form.controls.values.controls;
  }

  public addNextKeyValueElement(key: string) {
    console.log((this.form.controls['keyValue'] as FormArray));
    (this.form.controls['keyValue'] as FormArray).push(this.createKeysItem());
  }

  public addNextKeysSub(form) {
    (form.controls['values'] as FormArray).push(this.createKeysItem());
  }

  public requestData() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/generated-text/destination/' + this.elementId)
      .subscribe((response: any) => {
          console.log(response);
          this.loadingIndicatorService.hideLoadingBar();
          this.elementData = response;

          /* */
          this.mapDestinationDataToForm();

          this.updateBreadcrumb();
        },
        err => {
          this.loadingIndicatorService.hideLoadingBar();
        });
  }

  updateBreadcrumb() {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Pages', 'javascript:void(0)'),
      new BreadCrumbElement('Generated Text', 'javascript:void(0)'),
      new BreadCrumbElement('Destinations', 'javascript:void(0)'),
      new BreadCrumbElement(this.elementId, 'javascript:void(0)'),
    ];
  }

  public handleReceiveAvailablePatterns() {
    /* */
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/ai-pattern/all')
      .subscribe(
        (response: any) => {
          this.patterns = response;
        },
        (err) => {
          console.log(err);

          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
        }
      );
  }

  public mapDestinationDataToForm() {

    /* */
    this.form.get('description').setValue(this.elementData.description);
    this.form.get('identifier').setValue(this.elementData.identifier);
    this.form.get('patternId').setValue(this.elementData.patternId);
    this.form.get('state').setValue(this.elementData.state);
    this.form.get('enabled').setValue(this.elementData.enabled);

    /* */
    for (const key of this.elementData.keys) {
      (this.form.controls['keys'] as FormArray).push(this.createKeysItem(key));
    }

    /* */
    for (const element of this.elementData.keyValues) {
      const key = element.key;
      const values = [];
      for (const value of element.values) {
        values.push(value);
      }
      (this.form.controls['keyValues'] as FormArray).push(this.createKeyValuesItem(key, values));


      console.log(this.form.controls['keyValues']);
    }
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public submit() {
    console.log(this.form.value);

    console.log(this.buildPatchBodyFromForm());
  }

}



