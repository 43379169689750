import {Component, OnInit} from '@angular/core';
import {BreadCrumbElement} from '../../../../shared/entities/breadcrumb.element.entity';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {PagingService} from '../../../../services/pagingservice/paging.service';
import {Amenity, HousingType} from '@tripbakers/enums';
import {FormControl, FormGroup} from '@angular/forms';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LoadingIndicatorService} from '../../../../services/loading-indicator/loading-indicator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FilledFormObject} from './interfaces/filledFormObject';
import {AngularParams} from './interfaces/angular.params.interface';
import {VariableObject} from './interfaces/variableobject.interface';
import {ObjectToCreateStandardParam} from './interfaces/object.to.create.standard.param.interface';
import { FieldsBasicPlaceDetails } from '../../../../shared/consts/fields-basic-place-details';

declare const Codebase;

// @TODO Raffi: Der Parameter Page wird nicht korrekt aus der URL geladen. Pagination zeigt beim Aufruf der URL quasi immer Seite 1 an
// @TODO Raffi: Debugmeldungen minimieren
@Component({
  selector: 'app-housing-search',
  templateUrl: './housing-search.component.html',
  styleUrls: ['./housing-search.component.css']
})
export class HousingSearchComponent implements OnInit {

  /* */
  public page = 1;

  /* */
  public sort = 'stars';

  /* */
  public hotelCounter = 0;
  public hotelTotalCounter = 0;
  public destinationId;
  public cityDestination;
  public countryDestination;
  public standardParams = {};

  /* */
  public sorting = -1;

  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};
  public options = {
    fields: FieldsBasicPlaceDetails,
  };
  public housings: Array<any> = [];
  public paginationData;
  public form: FormGroup;
  public form2: FormGroup;
  public amenities: Array<any> = [];
  public housingTypes: Array<string> = [];
  public googleLocation: any;

  public parameter: string;

  constructor(private pagingService: PagingService,
              private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private route: ActivatedRoute,
              private router: Router,
              private activeRoute: ActivatedRoute
  ) {
    this.housingTypes = Object.values(HousingType);
    this.amenities = Object.values(Amenity);

    const formGroupData = {
      'countryCode': new FormControl({value: '0', disabled: false}),
      'destination': new FormControl({value: '', disabled: false}),
      'radius': new FormControl({value: '', disabled: false}),
      'stars-0': new FormControl({value: false, disabled: false}),
      'stars-1': new FormControl({value: false, disabled: false}),
      'stars-2': new FormControl({value: false, disabled: false}),
      'stars-3': new FormControl({value: false, disabled: false}),
      'stars-4': new FormControl({value: false, disabled: false}),
      'stars-5': new FormControl({value: false, disabled: false}),
      'rating-bad': new FormControl({value: false, disabled: false}),
      'rating-ok': new FormControl({value: false, disabled: false}),
      'rating-good': new FormControl({value: false, disabled: false}),
      'rating-fine': new FormControl({value: false, disabled: false}),
      'rating-best': new FormControl({value: false, disabled: false}),
      'rooms': new FormControl({value: '', disabled: false}),
      'roomsMax': new FormControl({value: '', disabled: false}),
      'partner-standard': new FormControl({value: false, disabled: false}),
      'partner-top': new FormControl({value: false, disabled: false}),
      'partner-extranet': new FormControl({value: false, disabled: false}),
      'hotel-name': new FormControl({value: '', disabled: false}),
      'hotel-email': new FormControl({value: '', disabled: false}),
      'hotel-id': new FormControl({value: '', disabled: false}),
      'booking-url': new FormControl({value: false, disabled: false}),
      'no-trivago-id': new FormControl({value: false, disabled: false}),
      'no-images': new FormControl({value: false, disabled: false}),
      'no-preview-image': new FormControl({value: false, disabled: false}),
      'no-offer-image': new FormControl({value: false, disabled: false}),
      'no-preview-image-or-no-offer-image': new FormControl({value: false, disabled: false}),
      'deleted-only': new FormControl({value: false, disabled: false}),
      'hide-deleted': new FormControl({value: false, disabled: false}),
      'non-checked-only': new FormControl({value: false, disabled: false}),
      'checked-only': new FormControl({value: false, disabled: false})
    };

    for (const housingType of this.housingTypes) {
      formGroupData['type-' + housingType] = new FormControl({value: false, disabled: false});
    }
    for (const amenity of this.amenities) {
      formGroupData['type-' + amenity] = new FormControl({value: false, disabled: false});
    }

    this.form = new FormGroup(formGroupData);

    this.form2 = new FormGroup({
      'limit': new FormControl({value: "25", disabled: false}),
      'sortingMode': new FormControl({value: "1", disabled: false}),
      'sortingField': new FormControl({value: "name", disabled: false})
    })

  }

  public evaluateDestinationId(params: AngularParams) {
    if (params.destination) {
      const destinationId = params.destination;
      return destinationId;
    }
  }

  public evaluateDestinationCountry(params: AngularParams) {
    if (params.destinationCountry) {
      const destinationCountry = params.destinationCountry;
      return destinationCountry;
    }
  }

  public evaluateDestinationName(params: AngularParams) {
    if (params.destinationName) {
      const destinationName = params.destinationName;
      return destinationName;
    }
  }

  public evaluateCoordinates(params: AngularParams) {
    if (params.destinationCoordinatesLat && params.destinationCoordinatesLng) {
      const destinationCoordinates = [];
      destinationCoordinates.push(params.destinationCoordinatesLat);
      destinationCoordinates.push(params.destinationCoordinatesLng);
      return destinationCoordinates;
    }
  }

  public chooseCityDestinationFormat(destinationId: string) {
    if (!this.googleLocation) {
      this.cityDestination = destinationId;
    }
  }

  public chooseCountryDestinationFormat(destinationCountry: string) {
    this.countryDestination = destinationCountry;
  }

  public createGoogleFakeObject(destinationId: string, destinationCountry: string, destinationName: string, destinationCoordinates) {
    if (!this.googleLocation) {
      const googleFakeObject: { destinationId: string, destinationCountry: string, destinationName: string, geometry: { location: { destinationCoordinates: Array<number> } } } = {
        destinationId: '',
        destinationCountry: '',
        destinationName: '',
        geometry: {location: {destinationCoordinates: []}},
      };
      if (destinationId) {
        googleFakeObject.destinationId = destinationId;
      }
      if (destinationCountry) {
        googleFakeObject.destinationCountry = destinationCountry;
      }
      if (destinationName) {
        googleFakeObject.destinationName = destinationName;
      }

      if (destinationCoordinates) {
        console.log('DESTINATIONCOORDINATES ', destinationCoordinates);
        const destinationCoordinatesArray = destinationCoordinates;
        googleFakeObject.geometry.location.destinationCoordinates = destinationCoordinatesArray;
      }
      return googleFakeObject;
    }
  }

  // @TODO Raffi: Typensicherheit
  // @TODO Raffi: Machs nicht unnötig kompliziert: Bau dir ein fertiges googleFakeObjekt und mach am ende nur noch this.googleLocation = googleFakeObject - sonst mappen wir bei jedem Kleinscheiß irgendwie wo was rum
  public setGoogleLocationIfEmpty(googleFakeObject) {
    if (!this.googleLocation) {
      if (googleFakeObject.destinationId !== '' && googleFakeObject.destinationName !== '' && googleFakeObject.geometry.location.destinationCoordinates !== '') {
        if (googleFakeObject.destinationId) {
          this.googleLocation = {
            geometry: {
              location: {
                lat: 0,
                lng: 0
              }
            }
          };
          this.googleLocation.place_id = googleFakeObject.destinationId;
          this.googleLocation.name = googleFakeObject.destinationName;
          this.googleLocation.types = ['city'];
          if (googleFakeObject.geometry.location.destinationCoordinates) {
          this.googleLocation.geometry.location.lat = googleFakeObject.geometry.location.destinationCoordinates[0];
          this.googleLocation.geometry.location.lng = googleFakeObject.geometry.location.destinationCoordinates[1];

          }
          console.log('FAKEOBJECTCOORDINATES ', googleFakeObject.geometry.location);


        }
        if (googleFakeObject.destinationCountry) {
          this.googleLocation.address_components[0].short_name = googleFakeObject.destinationCountry;
        }
      }
    }
  }

  ngOnInit() {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Housings', 'javascript:void(0)')
    ];

    this.activeRoute.queryParams.subscribe((params: AngularParams) => {
      console.log('angularparams: ' + JSON.stringify(params));

      this.mapParamsToForm(params);

      /* Handling Destinations */
      const destinationId: string = this.evaluateDestinationId(params);
      const destinationCountry: string = this.evaluateDestinationCountry(params);
      const destinationName: string = this.evaluateDestinationName(params);
      const destinationCoordinates: Array<number> = this.evaluateCoordinates(params);
      console.log('DESTINATIONCORDS', destinationCoordinates);

      const googleFakeObject = this.createGoogleFakeObject(destinationId, destinationCountry, destinationName, destinationCoordinates);
      console.log('fake objekt: ' + JSON.stringify(googleFakeObject));
      this.setGoogleLocationIfEmpty(googleFakeObject);
      console.log(' googleLocation: ' + JSON.stringify(this.googleLocation));
      this.chooseCityDestinationFormat(destinationId);
      this.chooseCountryDestinationFormat(destinationCountry);

      /* Querying Data based on Mapped Form*/
      const filledFormObject: FilledFormObject = this.readFormValues(destinationName, destinationId, destinationCountry);
      const httpParams: HttpParams = this.setHttpParamsFromFilledForm(filledFormObject, destinationId, destinationCountry, destinationName);
      console.log('httpParams: ' + httpParams);
      this.sendRequest(httpParams);
    });

    Codebase.helper('core-tab');
  }

  public sendRequest(params: HttpParams) {
    this.loadingIndicatorService.showLoadingBar();

    /* Server Anfrage */
    this.http.get(environment.urls.service + '/api/v2/authenticated/admin/housing', {
      params: params
    })
      .subscribe(
        (response: any) => {

          this.housings = response.elements;
          this.paginationData = response.pagination;
          this.hotelTotalCounter = response.pagination.total;
          // @TODO Raffi: Der ganze if Kram ist ein Codesmell, überarbeiten!.... dies uaf die gesamtzahlen beziehen und den if kram wegmachen
          if (this.hotelTotalCounter !== 0
            && this.hotelTotalCounter !== 1
            && this.hotelTotalCounter !== 2
            && this.hotelTotalCounter !== 3
            && this.hotelTotalCounter !== 4) {
            this.hotelCounter = response.pagination.limit;
          } else {
            this.hotelCounter = this.hotelTotalCounter;
          }
          // @TODO: Exception Handling wenn zB der Server überlastet ist
        },
      (error) => {

      },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public createHousingTypeArray() {
    const housingTypeArray: Array<string> = [];

    const housingTypesToCheck = Object.values(HousingType);

    for (const field of housingTypesToCheck) {
      if (this.form.get('type-' + field).value === true) {
        housingTypeArray.push(field.replace('type-', ''));
      }
    }
    return housingTypeArray;
  }

  public createRoomAmount() {
    const roomAmount: string = this.form.get('rooms').value.replace(/\D/g, '');
    return roomAmount;
  }

  public createRoomMaxAmount() {
    const roomAmount: string = this.form.get('roomsMax').value.replace(/\D/g, '');
    return roomAmount;
  }

  public createPartnerTypeArray() {
    const partnerTypeArray: Array<string> = [];
    const partnerTypeToCheck = ['partner-standard', 'partner-top', 'partner-extranet'];
    for (const field of partnerTypeToCheck) {
      if (this.form.get(field).value === true) {
        partnerTypeArray.push(field.replace('partner-', ''));
      }
    }
    return partnerTypeArray;
  }

  public createAmenitiesArray() {
    const amenitiesArray: Array<string> = [];
    const amenitiesToCheck = Object.values(Amenity);
    for (const field of amenitiesToCheck) {
      if (this.form.get('type-' + field).value === true) {
        amenitiesArray.push(field.replace('type-', ''));
      }
    }
    return amenitiesArray;
  }

  public createCoords() {
    console.log('glocation ', this.googleLocation);
    if (this.googleLocation !== undefined && this.googleLocation !== null && this.googleLocation !== '') {
      if (this.googleLocation.geometry) {
        const latLng = this.googleLocation.geometry.location;
        const coords: Array<number> = [];
        return coords;
        /*@TODO: raffmann*/
      }
    }
  }

  /**/
  public readFormValues(receivedDestinationName, receivedDestinationId, receivedDestinationCountry) {
    let starsArray: Array<string>;
    let ratingArray: Array<string>;
    let housingTypeArray: Array<string>;
    let roomAmount: string;
    let roomMaxAmount: string;
    let partnerTypeArray: Array<string>;
    let amenitiesArray: Array<string>;
    let coords;
    /*@TODO: Coord Typisierung... Lat und Lng einzeln übergeben und im Backend anpassen*/

    const destinationCityId = receivedDestinationId;
    const destinationCountry = receivedDestinationCountry;

    // Coords
    coords = this.createCoords();

    // Radius
    const radius: string = this.form.get('radius').value;
    starsArray = this.createStarsArray();
    ratingArray = this.createRatingArray();
    housingTypeArray = this.createHousingTypeArray();
    roomAmount = this.createRoomAmount();
    roomMaxAmount = this.createRoomMaxAmount();
    partnerTypeArray = this.createPartnerTypeArray();
    amenitiesArray = this.createAmenitiesArray();
    const hotelName: string = this.form.get('hotel-name').value;
    const hotelMail = this.form.get('hotel-email').value;
    const hotelId = this.form.get('hotel-id').value;
    const noBookingUrl = this.form.get('booking-url').value;
    const noTrivagoId = this.form.get('no-trivago-id').value;
    const noImages = this.form.get('no-images').value;
    const noPreviewImage = this.form.get('no-preview-image').value;
    const noOfferImage = this.form.get('no-offer-image').value;
    const noPreviewImageOrNoOfferImage = this.form.get('no-preview-image-or-no-offer-image').value;
    const deletedOnly = this.form.get('deleted-only').value;
    const hideDeleted = this.form.get('hide-deleted').value;
    const nonCheckedOnly = this.form.get('non-checked-only').value;
    const checkedOnly = this.form.get('checked-only').value;

    const filledFormObject: FilledFormObject = {
      destination: destinationCityId,
      destinationCountry: destinationCountry,
      coords: coords,
      radius: radius,
      starsArray: starsArray,
      ratingArray: ratingArray,
      housingTypeArray: housingTypeArray,
      roomAmount: roomAmount,
      roomMaxAmount: roomMaxAmount,
      partnerTypeArray: partnerTypeArray,
      amenitiesArray: amenitiesArray,
      hotelName: hotelName,
      hotelMail: hotelMail,
      hotelId: hotelId,
      noBookingUrl: noBookingUrl,
      noTrivagoId: noTrivagoId,
      noImages: noImages,
      noPreviewImage: noPreviewImage,
      noOfferImage: noOfferImage,
      deletedOnly: deletedOnly,
      hideDeleted: hideDeleted,
      nonCheckedOnly: nonCheckedOnly,
      checkedOnly: checkedOnly,
      noPreviewImageOrNoOfferImage: noPreviewImageOrNoOfferImage
    };
    return filledFormObject;
  }

  public setHttpParamsFromFilledForm(filledFormObject, destinationId, destinationCountry, destinationName) {
    let params: HttpParams = new HttpParams();
    params = this.appendCoordsToHttpParams(params, filledFormObject);
    params = this.appendRadiusToHttpParams(params, filledFormObject);
    params = this.appendDestinationToHttpParams(params, filledFormObject, destinationId);
    params = this.appendDestinationCountryToHttpParams(params, filledFormObject, destinationCountry);
    params = this.appendDestinationNameToHttpParams(params, filledFormObject, destinationName);
    params = this.appendStarsArrayToHttpParams(params, filledFormObject);
    params = this.appendRatingArrayToHttpParams(params, filledFormObject);
    params = this.appendHousingTypeArrayToHttpParams(params, filledFormObject);
    params = this.appendAmenityArrayToHttpParams(params, filledFormObject);
    params = this.appendPartnerTypeArrayToHttpParams(params, filledFormObject);
    params = this.appendRoomAmountToHttpParams(params, filledFormObject);
    params = this.appendRoomMaxAmountToHttpParams(params, filledFormObject);
    params = this.appendHotelNameToHttpParams(params, filledFormObject);
    params = this.appendHotelMailToHttpParams(params, filledFormObject);
    params = this.appendHotelIdToHttpParams(params, filledFormObject);
    params = this.appendPageToHttpParams(params);
    params = this.appendLimitToHttpParams(params, this.form2.get('limit').value);
    params = this.appendSortToHttpParams(params, this.form2.get('sortingField').value);
    params = this.appendSortingToHttpParams(params, this.form2.get('sortingMode').value);
    params = this.appendNoBookingUrlToHttpParams(params, filledFormObject);
    params = this.appendNoTrivagoIdToHttpParams(params, filledFormObject);
    params = this.appendNoImagesToHttpParams(params, filledFormObject);
    params = this.appendNoPreviewImageToHttpParams(params, filledFormObject);
    params = this.appendNoOfferImageToHttpParams(params, filledFormObject);
    params = this.appendNoPreviewImageOrNoOfferImageToHttpParams(params, filledFormObject);
    params = this.appendDeletedOnlyToHttpParams(params, filledFormObject);
    params = this.appendHideDeletedToHttpParams(params, filledFormObject);
    params = this.appendNonCheckedOnlyToHttpParams(params, filledFormObject);
    params = this.appendCheckedOnlyToHttpParams(params, filledFormObject);
    return params;
  }

  public submitForm() {
    const variableObject = this.createVariablesForStandardParams();
    this.createStandardParams(variableObject);
    this.router.navigate(['./'], {
      relativeTo: this.activeRoute,
      queryParams: this.standardParams, // hier die normalen Params rein nicht die HTTP Params
      replaceUrl: true
    });
  }

  public setPageStandardParam() {
    const page: string = this.page.toString();
    return page;
  }

  public setDestinationStandardParam(variableObject: VariableObject) {
    if (variableObject.destinationCountry) {
      const destination: string = variableObject.destinationCountry;
      return destination;
    }
  }

  public setStarsStandardParam(variableObject: VariableObject) {
    if (variableObject.starsArray.length > 0) {
      const stars: Array<number> = variableObject.starsArray;
      return stars;
    }
  }

  public setRatingStandardParam(variableObject: VariableObject) {
    if (variableObject.ratingArray.length > 0) {
      const rating: Array<string> = variableObject.ratingArray;
      return rating;
    }
  }

  public setHousingTypesStandardParam(variableObject: VariableObject) {
    if (variableObject.housingTypeArray.length > 0) {
      const housingTypes: Array<string> = variableObject.housingTypeArray;
      return housingTypes;
    }
  }

  public setRoomsStandardParam(variableObject: VariableObject) {
    if (variableObject.roomAmount) {
      const rooms: string = variableObject.roomAmount;
      return rooms;
    }
  }

  public setRoomsMaxStandardParam(variableObject: VariableObject) {
    if (variableObject.roomMaxAmount) {
      const rooms: string = variableObject.roomMaxAmount;
      return rooms;
    }
  }

  public setPartnerTypesStandardParam(variableObject: VariableObject) {
    if (variableObject.partnerTypeArray.length > 0) {
      const partnerTypes: Array<string> = variableObject.partnerTypeArray;
      return partnerTypes;
    }
  }

  public setAmenityStandardParam(variableObject: VariableObject) {
    if (variableObject.amenitiesArray.length > 0) {
      const amenities: Array<string> = variableObject.amenitiesArray;
      return amenities;
    }
  }

  public setRadiusStandardParam(variableObject: VariableObject) {
    if (variableObject.radius) {
      const radius: number = variableObject.radius;
      return radius;
    }
  }

  public setHotelNameStandardParam(variableObject: VariableObject) {
    if (variableObject.hotelName) {
      const hotelName: string = variableObject.hotelName;
      return hotelName;
    }
  }

  public setHotelMailStandardParam(variableObject: VariableObject) {
    if (variableObject.hotelMail) {
      const hotelMail: string = variableObject.hotelMail;
      return hotelMail;
    }
  }

  public setHotelIdStandardParam(variableObject: VariableObject) {
    if (variableObject.hotelId) {
      const hotelId: string = variableObject.hotelId;
      return hotelId;
    }
  }

  public setNoBookingUrlStandardParam(variableObject: VariableObject) {
    if (variableObject.noBookingUrl) {
      const noBookingUrl: string = variableObject.noBookingUrl;
      return noBookingUrl;
    }
  }

  public setNoTrivagoIdStandardParam(variableObject: VariableObject) {
    if (variableObject.noTrivagoId) {
      const noBookingUrl: string = variableObject.noTrivagoId;
      return noBookingUrl;
    }
  }

  public setNoImagesStandardParam(variableObject: VariableObject) {
    if (variableObject.noImages) {
      const noImages: string = variableObject.noImages;
      return noImages;
    }
  }

  public setNoPreviewImageStandardParam(variableObject: VariableObject) {
    if (variableObject.noPreviewImage) {
      const noPreviewImage: string = variableObject.noPreviewImage;
      return noPreviewImage;
    }
  }

  public setNoOfferImageStandardParam(variableObject: VariableObject) {
    if (variableObject.noOfferImage) {
      const noOfferImage: string = variableObject.noOfferImage;
      return noOfferImage;
    }
  }

  public setNoPreviewImageOrNoOfferImageStandardParam(variableObject: VariableObject) {
    if (variableObject.noPreviewOrNoOfferImage) {
      const noOfferImage: string = variableObject.noPreviewOrNoOfferImage;
      return noOfferImage;
    }
  }

  public setDeletedOnlyStandardParam(variableObject: VariableObject) {
    if (variableObject.deletedOnly) {
      return variableObject.deletedOnly;
    }
  }

  public setHideDeletedStandardParam(variableObject: VariableObject) {
    if (variableObject.hideDeleted) {
      return variableObject.hideDeleted;
    }
  }

  public setNonCheckedOnlyStandardParam(variableObject: VariableObject) {
    if (variableObject.nonCheckedOnly) {
      return variableObject.nonCheckedOnly;
    }
  }

  public setCheckedOnlyStandardParam(variableObject: VariableObject) {
    if (variableObject.checkedOnly) {
      return variableObject.checkedOnly;
    }
  }

  public setDestinationIdStandardParam(variableObject: VariableObject) {
    if (variableObject.destinationName) {
      const destination: string = variableObject.destinationCityId;
      return destination;
    }
  }

  public setDestinationNameStandardParam(variableObject: VariableObject) {
    if (variableObject.destinationName) {
      const destinationName: string = variableObject.destinationName;
      return destinationName;
    }
  }

  public setDestinationCoordinatesLatStandardParam(variableObject: VariableObject) {
    if (variableObject.destinationCoordinates) {
      const destinationCoordinatesLat = variableObject.destinationCoordinates.lat;
      console.log(destinationCoordinatesLat);
      return destinationCoordinatesLat;
    }
  }

  public setDestinationCoordinatesLngStandardParam(variableObject: VariableObject) {
    if (variableObject.destinationCoordinates) {
      const destinationCoordinatesLng = variableObject.destinationCoordinates.lng;
      return destinationCoordinatesLng;
    }
  }

  public createStandardParams(variableObject: VariableObject) {
    const objectToCreateStandardParam: ObjectToCreateStandardParam = {
      page: this.setPageStandardParam(),
      limit: this.form2.get('limit').value,
      sorting: this.form2.get('sortingMode').value,
      sort: this.form2.get('sortingField').value,
      destinationCountry: this.setDestinationStandardParam(variableObject),
      destination: this.setDestinationIdStandardParam(variableObject),
      destinationName: this.setDestinationNameStandardParam(variableObject),
      destinationCoordinatesLat: this.setDestinationCoordinatesLatStandardParam(variableObject),
      destinationCoordinatesLng: this.setDestinationCoordinatesLngStandardParam(variableObject),
      stars: this.setStarsStandardParam(variableObject),
      rating: this.setRatingStandardParam(variableObject),
      housingTypes: this.setHousingTypesStandardParam(variableObject),
      rooms: this.setRoomsStandardParam(variableObject),
      roomsMax: this.setRoomsMaxStandardParam(variableObject),
      partnerTypes: this.setPartnerTypesStandardParam(variableObject),
      amenities: this.setAmenityStandardParam(variableObject),
      radius: this.setRadiusStandardParam(variableObject),
      hotelName: this.setHotelNameStandardParam(variableObject),
      hotelMail: this.setHotelMailStandardParam(variableObject),
      hotelId: this.setHotelIdStandardParam(variableObject),
      noBookingUrl: this.setNoBookingUrlStandardParam(variableObject),
      noTrivagoId: this.setNoTrivagoIdStandardParam(variableObject),
      noImages: this.setNoImagesStandardParam(variableObject),
      noPreviewImage: this.setNoPreviewImageStandardParam(variableObject),
      noOfferImage: this.setNoOfferImageStandardParam(variableObject),
      noPreviewImageOrNoOfferImage: this.setNoPreviewImageOrNoOfferImageStandardParam(variableObject),
      deletedOnly: this.setDeletedOnlyStandardParam(variableObject),
      hideDeleted: this.setHideDeletedStandardParam(variableObject),
      nonCheckedOnly: this.setNonCheckedOnlyStandardParam(variableObject),
      checkedOnly: this.setCheckedOnlyStandardParam(variableObject),
      rnd: Math.random().toString()
    };

    this.standardParams = objectToCreateStandardParam;
  }

  public createVariablesForStandardParams() {
    let destinationCityId: string;
    let destinationName: string;
    let destinationCountry: string;
    let destinationCoordinates: any;
    let starsArray: Array<number>;
    let ratingArray: Array<string>;
    let housingTypeArray: Array<string>;
    let roomAmount: string;
    let roomMaxAmount: string;
    let partnerTypeArray: Array<string>;
    let amenitiesArray: Array<string>;
    let radius: number;
    let hotelName: string;
    let hotelMail: string;
    let hotelId: string;
    let noBookingUrl: string;
    let noTrivagoId: string;
    let noImages: string;
    let noPreviewImage: string;
    let noPreviewImageOrNoOfferImage: string;
    let noOfferImage: string;
    let deletedOnly: string;
    let hideDeleted: string;
    let nonCheckedOnly: string;
    let checkedOnly: string;

    // Bereich Destination
    if (this.form.get('destination').value.trim() === '') {
      this.googleLocation = null;
    }
    const destinationCityObject: { city_id: string, name: string } = this.createDestinationForStandardVariable();
    const destinationCountryObject: { country: string, name: string } = this.createDestinationCountryForStandardVariable();
    if (destinationCityObject) {
      destinationName = destinationCityObject.name;
      destinationCityId = destinationCityObject.city_id;
    } else if (destinationCountryObject) {
      destinationName = destinationCountryObject.name;
      destinationCountry = destinationCountryObject.country;
    }
    destinationCoordinates = this.createDestinationCoordinatesForStandardVariable();
    starsArray = this.createStarsArrayForStandardVariable();
    ratingArray = this.createRatingArrayForStandardVariable();
    housingTypeArray = this.createHousingTypeArrayForStandardVariable();
    partnerTypeArray = this.createPartnerTypeArrayForStandardVariable();
    amenitiesArray = this.createAmenityArrayForStandardVariable();
    radius = this.createRadiusForStandardVariable();
    hotelName = this.createHotelNameForStandardVariable();
    hotelMail = this.createHotelMailForStandardVariable();
    hotelId = this.createHotelIdForStandardVariable();
    noBookingUrl = this.createNoBookingUrlForStandardVariable();
    noTrivagoId = this.createNoTrivagoIdForStandardVariable();
    noImages = this.createNoImagesForStandardVariable();
    noPreviewImage = this.createNoPreviewImageForStandardVariable();
    noOfferImage = this.createNoOfferImageForStandardVariable();
    noPreviewImageOrNoOfferImage = this.createNoPreviewImageOrNoOfferImageForStandardVariable();
    roomAmount = this.createRoomAmountForStandardVariable();
    roomMaxAmount = this.createRoomMaxAmountForStandardVariable();
    deletedOnly = this.createDeletedOnlyForStandardVariable();
    hideDeleted = this.createHideDeletedForStandardVariable();
    nonCheckedOnly = this.createNonCheckedOnlyForStandardVariable();
    checkedOnly = this.createCheckedOnlyForStandardVariable();

    const variableObject: VariableObject = {
      destinationName: destinationName,
      destinationCityId: destinationCityId,
      destinationCountry: destinationCountry,
      destinationCoordinates: destinationCoordinates,
      starsArray: starsArray,
      ratingArray: ratingArray,
      housingTypeArray: housingTypeArray,
      partnerTypeArray: partnerTypeArray,
      amenitiesArray: amenitiesArray,
      radius: radius,
      hotelName: hotelName,
      hotelMail: hotelMail,
      hotelId: hotelId,
      noBookingUrl: noBookingUrl,
      noTrivagoId: noTrivagoId,
      noImages: noImages,
      noPreviewImage: noPreviewImage,
      noOfferImage: noOfferImage,
      roomAmount: roomAmount,
      roomMaxAmount: roomMaxAmount,
      deletedOnly: deletedOnly,
      hideDeleted: hideDeleted,
      nonCheckedOnly: nonCheckedOnly,
      checkedOnly: checkedOnly,
      noPreviewOrNoOfferImage: noPreviewImageOrNoOfferImage,
      limit: this.form2.get('limit').value,
      sort: this.form2.get('sortingField').value,
      sorting: this.form2.get('sortingMode').value,
    };

    return variableObject;
  }

  public mapDestinationParamToForm(field: string, params: AngularParams) {
    if (field === 'destination') {
      this.form.patchValue({destination: params.destinationName});
    }
  }

  public mapStarParamToForm(field: string, params: AngularParams) {
    if (field === 'stars') {
      for (let i = 0; i <= params.stars.length; i++) {
        if (params.stars[i] === '0') {
          this.form.patchValue({'stars-0': true});
        }
        if (params.stars[i] === '1') {
          this.form.patchValue({'stars-1': true});
        }
        if (params.stars[i] === '2') {
          this.form.patchValue({'stars-2': true});
        }
        if (params.stars[i] === '3') {
          this.form.patchValue({'stars-3': true});
        }
        if (params.stars[i] === '4') {
          this.form.patchValue({'stars-4': true});
        }
        if (params.stars[i] === '5') {
          this.form.patchValue({'stars-5': true});
        }
      }
    }
  }

  public mapRatingParamToForm(field: string, params: AngularParams) {
    const arrayForSingleParam: Array<string> = [];
    if (field === 'rating') {
      if (typeof params.rating === 'string') {
        arrayForSingleParam.push(params.rating);
      } else {
        for (let i = 0; i < params.rating.length; i++) {
          arrayForSingleParam.push(params.rating[i]);
        }
      }
      console.log('paramsarray' + arrayForSingleParam);
      for (let i = 0; i < params.rating.length; i++) {
        console.log('i: ' + params.rating[i]);
        console.log(typeof (params.rating));
        if (arrayForSingleParam[i] === 'bad') {
          this.form.patchValue({'rating-bad': true});
        }
        if (arrayForSingleParam[i] === 'ok') {
          this.form.patchValue({'rating-ok': true});
        }
        if (arrayForSingleParam[i] === 'good') {
          this.form.patchValue({'rating-good': true});
        }
        if (arrayForSingleParam[i] === 'fine') {
          this.form.patchValue({'rating-fine': true});
        }
        if (arrayForSingleParam[i] === 'best') {
          this.form.patchValue({'rating-best': true});
        }
      }
    }
  }


  public mapHousingTypeParamToForm(field: string, params: AngularParams) {
    const arrayForSingleParam: Array<string> = [];
    if (field === 'housingTypes') {
      if (typeof params.housingTypes === 'string') {
        arrayForSingleParam.push(params.housingTypes);
      } else {
        for (let i = 0; i < params.housingTypes.length; i++) {
          arrayForSingleParam.push(params.housingTypes[i]);
        }
      }
      for (let i = 0; i <= arrayForSingleParam.length; i++) {
        this.form.patchValue({['type-' + arrayForSingleParam[i]]: true});
      }
    }
  }

  public mapRoomParamToForm(field: string, params: AngularParams) {
    if (field === 'rooms') {
      this.form.patchValue({rooms: params.rooms});
    }
  }

  public mapRoomMaxParamToForm(field: string, params: AngularParams) {
    if (field === 'roomsMax') {
      this.form.patchValue({roomsMax: params.roomsMax});
    }
  }

  public mapPartnerTypeParamToForm(field: string, params: AngularParams) {
    const arrayForSingleParam: Array<string> = [];
    if (field === 'partnerTypes') {
      if (typeof params.partnerTypes === 'string') {
        arrayForSingleParam.push(params.partnerTypes);
      } else {
        for (let i = 0; i <= params.partnerTypes.length; i++) {
          arrayForSingleParam.push(params.partnerTypes[i]);
        }
      }
      for (let i = 0; i <= arrayForSingleParam.length; i++) {

        if (arrayForSingleParam[i] === 'standard') {
          this.form.patchValue({'partner-standard': true});
        }
        if (arrayForSingleParam[i] === 'top') {
          this.form.patchValue({'partner-top': true});
        }
        if (arrayForSingleParam[i] === 'extranet') {
          this.form.patchValue({'partner-extranet': true});
        }
      }
    }
  }

  public mapAmenityParamToForm(field: string, params: AngularParams) {
    const arrayForSingleParam: Array<string> = [];
    if (field === 'amenities') {
      if (typeof params.amenities === 'string') {
        arrayForSingleParam.push(params.amenities);
      } else {
        for (let i = 0; i <= params.amenities.length; i++) {
          arrayForSingleParam.push(params.amenities[i]);
        }
      }
      for (let i = 0; i < arrayForSingleParam.length; i++) {
        this.form.patchValue({['type-' + arrayForSingleParam[i]]: true});
      }
    }
  }

  public mapRadiusParamToForm(field: string, params: AngularParams) {
    if (field === 'radius') {
      this.form.patchValue({'radius': params.radius});
    }
  }

  public mapHotelNameParamToForm(field: string, params: AngularParams) {
    if (field === 'hotelName') {
      this.form.patchValue({'hotel-name': params.hotelName});
    }
  }


  public mapHotelMailParamToForm(field: string, params: AngularParams) {
    if (field === 'hotelMail') {
      this.form.patchValue({'hotel-email': params.hotelMail});
    }
  }

  public mapHotelIdParamToForm(field: string, params: AngularParams) {
    if (field === 'hotelId') {
      this.form.patchValue({'hotel-id': params.hotelId});
    }
  }

  public mapNoBookingUrlParamToForm(field: string) {
    if (field === 'noBookingUrl') {
      this.form.patchValue({'booking-url': true});
    }
  }

  public mapNoTrivagoIdParamToForm(field: string) {
    if (field === 'noTrivagoId') {
      this.form.patchValue({'no-trivago-id': true});
    }
  }

  public mapNoImageParamToForm(field: string) {
    if (field === 'noImages') {
      this.form.patchValue({'no-images': true});
    }
  }

  public mapNoPreviewImageParamToForm(field: string) {
    if (field === 'noPreviewImage') {
      this.form.patchValue({'no-preview-image': true});
    }
  }

  public mapNoPreviewImageOrNoOfferImageParamToForm(field: string) {
    if (field === 'noPreviewImageOrNoOfferImage') {
      this.form.patchValue({'no-preview-image-or-no-offer-image': true});
    }
  }

  public mapNoOfferImageParamToForm(field: string) {
    if (field === 'noOfferImage') {
      this.form.patchValue({'no-offer-image': true});
    }
  }

  public mapDeletedOnlyToForm(field: string) {
    if (field === 'deletedOnly') {
      this.form.patchValue({'deleted-only': true});
    }
  }

  public mapHideDeletedToForm(field: string) {
    if (field === 'hideDeleted') {
      this.form.patchValue({'hide-deleted': true});
    }
  }

  public mapNonCheckedOnlyToForm(field: string) {
    if (field === 'nonCheckedOnly') {
      this.form.patchValue({'non-checked-only': true});
    }
  }

  public mapLimitToForm(field: string, params: AngularParams) {
    if (field === 'limit') {
      this.form2.patchValue({'limit': params.limit});
    }
  }

  public mapSortToForm(field: string, params: AngularParams) {
    if (field === 'sort') {
      this.form2.patchValue({'sortingField': params.sort});
    }
  }

  public mapSortingToForm(field: string, params: AngularParams) {
    if (field === 'sorting') {
      this.form2.patchValue({'sortingMode': params.sorting});
    }
  }

  public mapCheckedOnlyToForm(field: string) {
    if (field === 'checkedOnly') {
      this.form.patchValue({'checked-only': true});
    }
  }

  public mapParamsToForm(params: AngularParams) {
    for (const field in params) {
      if (params.hasOwnProperty(field)) {
        this.mapLimitToForm(field, params);
        this.mapSortToForm(field, params);
        this.mapSortingToForm(field, params);
        this.mapDestinationParamToForm(field, params);
        this.mapStarParamToForm(field, params);
        this.mapRatingParamToForm(field, params);
        this.mapHousingTypeParamToForm(field, params);
        this.mapRoomParamToForm(field, params);
        this.mapRoomMaxParamToForm(field, params);
        this.mapPartnerTypeParamToForm(field, params);
        this.mapAmenityParamToForm(field, params);
        this.mapRadiusParamToForm(field, params);
        this.mapHotelNameParamToForm(field, params);
        this.mapHotelMailParamToForm(field, params);
        this.mapHotelIdParamToForm(field, params);
        this.mapNoBookingUrlParamToForm(field);
        this.mapNoTrivagoIdParamToForm(field);
        this.mapNoImageParamToForm(field);
        this.mapNoPreviewImageParamToForm(field);
        this.mapNoOfferImageParamToForm(field);
        this.mapNoPreviewImageOrNoOfferImageParamToForm(field);
        this.mapDeletedOnlyToForm(field);
        this.mapHideDeletedToForm(field);
        this.mapNonCheckedOnlyToForm(field);
        this.mapCheckedOnlyToForm(field);
      }
    }
  }


  /**/
  public handleAddressChangeOfCity(address: Address) {
    this.googleLocation = JSON.parse(JSON.stringify(address));
    console.log('GOOGLELOCATION ', this.googleLocation);
  }

  /**/
  private sendPage(page: number) {
    this.page = page;
    this.submitForm();
  }

  /* Standard Variable Methods*/

  public createDestinationForStandardVariable() {
    if (this.googleLocation != null && this.googleLocation.place_id != null && this.googleLocation.types[0] !== 'country') {
      const destination: { city_id: string, name: string } = {city_id: '', name: ''};
      destination.city_id = this.googleLocation.place_id;
      destination.name = this.googleLocation.name;
      return destination;
    } else if (this.cityDestination) {
      const destination: { city_id: string, name: string } = {city_id: '', name: ''};
      destination.city_id = this.cityDestination.place_id;
      destination.name = this.cityDestination.name;
      return destination;
    }
  }

  public createDestinationCountryForStandardVariable() {
    console.log("glocationAGAIN", this.googleLocation);
    if (this.googleLocation != null && this.googleLocation.types[0] === 'country') {
      const destinationCountry: { country: string, name: string } = {country: '', name: ''};
      destinationCountry.country = this.googleLocation.address_components[0].short_name;
      destinationCountry.name = this.googleLocation.address_components[0].short_name;
      // @TODO: England/Irland und wahrscheinlich Ähnliches wird noch nicht richtig als Land anerkannt
      return destinationCountry;
    }
  }

  public createDestinationCoordinatesForStandardVariable() {
    if (this.googleLocation != null && this.googleLocation.geometry.location != null) {
      const destinationCoordinates = this.googleLocation.geometry.location;
      return destinationCoordinates;
    }
  }

  public createStarsArrayForStandardVariable() {
    const starsArray: Array<number> = [];
    const fieldsToCheck: Array<string> = ['stars-0', 'stars-1', 'stars-2', 'stars-3', 'stars-4', 'stars-5'];

    for (const field of fieldsToCheck) {
      if (this.form.get(field).value === true) {
        starsArray.push(parseFloat(field.replace('stars-', '')));
      }
    }
    return starsArray;
  }

  public createRatingArrayForStandardVariable() {
    const ratingArray: Array<string> = [];
    const ratingFields: Array<string> = ['rating-bad', 'rating-ok', 'rating-good', 'rating-fine', 'rating-best'];

    for (const field of ratingFields) {
      if (this.form.get(field).value === true) {
        ratingArray.push(field.replace('rating-', ''));

      }
    }
    return ratingArray;
  }

  public createHousingTypeArrayForStandardVariable() {
    const housingTypeArray: Array<string> = [];

    const housingTypesToCheck: Array<string> = Object.values(HousingType);

    for (const field of housingTypesToCheck) {
      if (this.form.get('type-' + field).value === true) {
        housingTypeArray.push(field.replace('type-', ''));
      }
    }
    return housingTypeArray;
  }

  public createRoomAmountForStandardVariable() {
    const roomAmount: string = this.form.get('rooms').value.replace(/\D/g, '');
    return roomAmount;
  }

  public createRoomMaxAmountForStandardVariable() {
    const roomAmount: string = this.form.get('roomsMax').value.replace(/\D/g, '');
    return roomAmount;
  }

  public createDeletedOnlyForStandardVariable() {
    return this.form.get('deleted-only').value;
  }

  public createHideDeletedForStandardVariable() {
    return this.form.get('hide-deleted').value;
  }

  public createNonCheckedOnlyForStandardVariable() {
    return this.form.get('non-checked-only').value;
  }

  public createCheckedOnlyForStandardVariable() {
    return this.form.get('checked-only').value;
  }

  public createPartnerTypeArrayForStandardVariable() {
    const partnerTypeArray: Array<string> = [];
    const partnerTypeToCheck: Array<string> = ['partner-standard', 'partner-top', 'partner-extranet'];
    for (const field of partnerTypeToCheck) {
      if (this.form.get(field).value === true) {
        partnerTypeArray.push(field.replace('partner-', ''));
      }
    }
    return partnerTypeArray;
  }

  public createAmenityArrayForStandardVariable() {
    const amenitiesArray: Array<string> = [];
    const amenitiesToCheck: Array<string> = Object.values(Amenity);
    for (const field of amenitiesToCheck) {
      if (this.form.get('type-' + field).value === true) {
        amenitiesArray.push(field.replace('type-', ''));
      }
    }
    return amenitiesArray;
  }

  public createRadiusForStandardVariable() {
    const radius: number = this.form.get('radius').value;
    return radius;
  }

  public createHotelNameForStandardVariable() {
    const hotelName: string = this.form.get('hotel-name').value;
    return hotelName;
  }

  public createHotelMailForStandardVariable() {
    const hotelMail: string = this.form.get('hotel-email').value;
    return hotelMail;
  }

  public createHotelIdForStandardVariable() {
    const hotelId: string = this.form.get('hotel-id').value;
    return hotelId;
  }

  public createNoBookingUrlForStandardVariable() {
    const noBookingUrl: string = this.form.get('booking-url').value;
    return noBookingUrl;
  }

  public createNoTrivagoIdForStandardVariable() {
    const noBookingUrl: string = this.form.get('no-trivago-id').value;
    return noBookingUrl;
  }

  public createNoImagesForStandardVariable() {
    const noImages: string = this.form.get('no-images').value;
    return noImages;
  }

  public createNoPreviewImageForStandardVariable() {
    const noPreviewImage: string = this.form.get('no-preview-image').value;
    return noPreviewImage;
  }

  public createNoPreviewImageOrNoOfferImageForStandardVariable() {
    const noPreviewImage: string = this.form.get('no-preview-image-or-no-offer-image').value;
    return noPreviewImage;
  }

  public createNoOfferImageForStandardVariable() {
    const noOfferImage: string = this.form.get('no-offer-image').value;
    return noOfferImage;
  }

  /* Http Params Methods*/

  public setDestinationForHttpParams() {
    let destination: { place_id: string, country: string } = {place_id: '', country: ''};
    if (!this.destinationId) {

      // Bereich Destination
      if (this.googleLocation != null && this.googleLocation.place_id != null && this.googleLocation.types[0] !== 'country') {
        destination.place_id = this.googleLocation.place_id;
      }

      if (this.googleLocation != null && this.googleLocation.types[0] === 'country') {
        destination.country = this.googleLocation.address_components[0].short_name;
        // @TODO: England/Irland und wahrscheinlich Ähnliches wird noch nicht richtig als Land anerkannt
      }
    } else {
      destination = this.destinationId;
      if (this.googleLocation != null && this.googleLocation.types[0] === 'country') {
        destination['country'] = this.googleLocation.address_components[0].short_name;
        // @TODO: England/Irland und wahrscheinlich Ähnliches wird noch nicht richtig als Land anerkannt
      }
    }
    return destination;
  }

  public createStarsArray() {
    const starsArray: Array<string> = [];
    const fieldsToCheck: Array<string> = ['stars-0', 'stars-1', 'stars-2', 'stars-3', 'stars-4', 'stars-5'];

    for (const field of fieldsToCheck) {
      if (this.form.get(field).value === true) {
        starsArray.push(field.replace('stars-', ''));
      }
    }
    return starsArray;
  }

  public createRatingArray() {
    const ratingArray: Array<string> = [];
    const ratingFields: Array<string> = ['rating-bad', 'rating-ok', 'rating-good', 'rating-fine', 'rating-best'];

    for (const field of ratingFields) {
      if (this.form.get(field).value === true) {
        ratingArray.push(field.replace('rating-', ''));
      }
    }
    return ratingArray;
  }

  /* Http Param Hilfsfunktionen */

  public appendCoordsToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.coords) {
      params = params.append('coordinates[]', filledFormObject.coords);
    }
    return params;
  }

  public appendDestinationNameToHttpParams(params: HttpParams, filledFormObject: FilledFormObject, destinationName: string) {
    if (destinationName) {
      params = params.append('destinationName', destinationName);
    }
    return params;
  }

  public appendRadiusToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.radius) {
      params = params.append('radius', filledFormObject.radius);
    }
    return params;
  }

  public appendDestinationToHttpParams(params: HttpParams, filledFormObject: FilledFormObject, destinationId: string) {
    if (destinationId) {
      params = params.append('destination', destinationId);
    } else if (filledFormObject.destination !== undefined && filledFormObject.destination !== null && this.googleLocation !== '') {
      params = params.append('destination', filledFormObject.destination);
    }
    return params;
  }

  public appendDestinationCountryToHttpParams(params: HttpParams, filledFormObject: FilledFormObject, destinationCountry: string) {
    if (destinationCountry) {
      params = params.append('destinationCountry', destinationCountry);
    } else if (filledFormObject.destinationCountry !== undefined &&
      filledFormObject.destinationCountry !== null &&
      filledFormObject.destinationCountry !== '' &&
      this.googleLocation !== '') {
      params = params.append('destinationCountry', filledFormObject.destinationCountry);
    }
    return params;
  }

  public appendStarsArrayToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.starsArray) {
      filledFormObject.starsArray.forEach((star) => {
        params = params.append('stars[]', star);
      });
    }
    return params;
  }

  public appendRatingArrayToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.ratingArray) {
      filledFormObject.ratingArray.forEach((rating: string) => {
        params = params.append('rating[]', rating);
      });
    }
    return params;
  }

  public appendHousingTypeArrayToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.housingTypeArray) {
      filledFormObject.housingTypeArray.forEach((housing: string) => {
        params = params.append('housingType[]', housing);
      });
    }
    return params;
  }

  public appendAmenityArrayToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.amenitiesArray) {
      filledFormObject.amenitiesArray.forEach((amenity: string) => {
        params = params.append('amenity[]', amenity);
      });
    }
    return params;
  }

  public appendPartnerTypeArrayToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.partnerTypeArray) {
      filledFormObject.partnerTypeArray.forEach((partner: string) => {
        params = params.append('partnerType[]', partner);
      });
    }
    return params;
  }

  public appendRoomAmountToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.roomAmount) {
      params = params.append('rooms', filledFormObject.roomAmount);
    }
    return params;
  }

  public appendRoomMaxAmountToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.roomMaxAmount) {
      params = params.append('roomsMax', filledFormObject.roomMaxAmount);
    }
    return params;
  }

  public appendHotelNameToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.hotelName) {
      params = params.append('hotelName', filledFormObject.hotelName.trim());
    }
    return params;
  }

  public appendHotelMailToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.hotelMail) {
      params = params.append('hotelMail', filledFormObject.hotelMail.trim());
    }
    return params;
  }

  public appendHotelIdToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.hotelId) {
      params = params.append('hotelId', filledFormObject.hotelId.trim());
    }
    return params;
  }

  public appendPageToHttpParams(params: HttpParams) {
    params = params.append('page', this.page.toString());
    return params;
  }

  // @TODO Limit CONST für default Wert
  public appendLimitToHttpParams(params: HttpParams, limit: string) {
    params = params.append('limit', limit);
    return params;
  }

  public appendSortToHttpParams(params: HttpParams, field: string) {
    params = params.append('sort', field);
    return params;
  }

  public appendSortingToHttpParams(params: HttpParams, sortingMode: string) {
    params = params.append('sorting', sortingMode);
    return params;
  }

  public appendNoBookingUrlToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.noBookingUrl) {
      params = params.append('noBookingUrl', filledFormObject.noBookingUrl);
    }
    return params;
  }

  public appendNoTrivagoIdToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.noTrivagoId) {
      params = params.append('noTrivagoId', filledFormObject.noTrivagoId);
    }
    return params;
  }

  public appendNoImagesToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.noImages) {
      params = params.append('noImages', filledFormObject.noImages);
    }
    return params;
  }

  public appendNoPreviewImageToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.noPreviewImage) {
      params = params.append('noPreviewImage', filledFormObject.noPreviewImage);
    }
    return params;
  }

  public appendNoPreviewImageOrNoOfferImageToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.noPreviewImageOrNoOfferImage) {
      params = params.append('noPreviewImageOrNoOfferImage', filledFormObject.noPreviewImageOrNoOfferImage);
    }
    return params;
  }

  public appendNoOfferImageToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.noOfferImage) {
      params = params.append('noOfferImage', filledFormObject.noOfferImage);
    }
    return params;
  }

  public appendDeletedOnlyToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.deletedOnly) {
      params = params.append('deletedOnly', filledFormObject.deletedOnly);
    }
    return params;
  }

  public appendHideDeletedToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.hideDeleted) {
      params = params.append('hideDeleted', filledFormObject.hideDeleted);
    }
    return params;
  }

  public appendNonCheckedOnlyToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.nonCheckedOnly) {
      params = params.append('nonCheckedOnly', filledFormObject.nonCheckedOnly);
    }
    return params;
  }

  public appendCheckedOnlyToHttpParams(params: HttpParams, filledFormObject: FilledFormObject) {
    if (filledFormObject.checkedOnly) {
      params = params.append('checkedOnly', filledFormObject.checkedOnly);
    }
    return params;
  }
}



