import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-instant-booking-details-housing',
  templateUrl: './housing.component.html',
  styleUrls: ['./housing.component.css']
})
export class InstantBookingDetailsHousingComponent implements OnInit {
  @Input('housing') housing;

  constructor() {
  }

  ngOnInit() {
  }

}
