import { Component, OnInit } from '@angular/core';
import {BreadCrumbElement} from "../../../shared/entities/breadcrumb.element.entity";
import {ActivatedRoute} from "@angular/router";
import {EventsService} from "../../../services/events/events.service";
import {LoadingIndicatorService} from "../../../services/loading-indicator/loading-indicator.service";
import {HttpClient} from "@angular/common/http";
import {PagingService} from "../../../services/pagingservice/paging.service";
import * as moment from "moment";
import {environment} from "../../../../environments/environment";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.css']
})
export class ValidationErrorComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public validationErrors: Array<any> = [];
  public activePage: number = 1;
  public limit: number = 25;

  /* */
  form: FormGroup;

  /* */
  public moment;
  public paginationData;

  constructor(private activatedRoute: ActivatedRoute,
              private eventsService: EventsService,
              private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient,
              private pagingService: PagingService) {
    this.moment = moment();

    this.form = new FormGroup({
      'state': new FormControl({value: 'NEW', disabled: false}),
    });
  }

  ngOnInit() {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Developer', 'javascript:void(0)'),
      new BreadCrumbElement('Validation Error', 'javascript:void(0)')
    ];

    /* */
    if (this.activatedRoute.snapshot.queryParams['page'] != null) {
      this.activePage = this.activatedRoute.snapshot.queryParams['page'];
    } else {
      this.activePage = 1;
    }

    /* */
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (this.activatedRoute.snapshot.queryParams['page'] != null) {
        this.activePage = this.activatedRoute.snapshot.queryParams['page'];
      } else {
        this.activePage = 1;
      }
      this.requestData();
    });

    /* */
    this.requestData();
  }

  public requestData() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/validation-error?page=' + this.activePage + '&type=' + this.form.get('state').value)
    // this.http.get(environment.urls.service + '/api/v1/authenticated/admin/validation-error')
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
          this.validationErrors = response.validationErrors;

          this.paginationData = this.pagingService.getPagingServiceItems(response.pagination.total, response.pagination.page, response.pagination.limit);
          console.log(this.paginationData);
        },
        err => {
          this.loadingIndicatorService.hideLoadingBar();
        });
  }

}
