import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../guards/auth/auth.guard';
import { HousingEditComponent } from './housing/housing-edit/housing-edit.component';
import { HousingInfoComponent } from './housing/housing-info/housing-info.component';
import { OverviewComponent } from './housing/housing-info/overview/overview.component';
import { DetailsComponent } from './housing/housing-info/details/details.component';
import { StatisticsComponent } from './housing/housing-info/statistics/statistics.component';
import { InstantBookingsComponent } from './admin/instant-bookings/instant-bookings.component';
import { InstantBookingDetailsComponent } from './admin/instant-booking-details/instant-booking-details.component';
import { ReportRoutes } from './admin/report/report.routes';
import { HousingSearchComponent } from './housing/housing-search/housing-search/housing-search.component';
import { BoundariesActivityRoutes } from './developer/boundaries-activity/boundaries-activity.routes';
import { TopPartnerComponent } from './housing/housing-info/top-partner/top-partner.component';
import { HousingAddComponent } from './housing/housing-add/housing-add.component';
import { HousingRequestComponent } from './housing-request/housing-request.component';
import { DeveloperRoutes } from './developer/developer.routes';
import { ChannelmanagerComponent } from './housing/housing-info/channelmanager/channelmanager.component';
import { AutoDisqualificationComponent } from './request/auto-disqualification/auto-disqualification.component';
import { FollowUpComponent } from './request/follow-up/follow-up.component';
import { BookingChangeRequestsComponent } from './admin/booking-change-requests/booking-change-requests.component';
import { PhoneCallbackRequestComponent } from './admin/phone-callback/phone-callback-request.component';
import { UserSearchComponent } from './user/user-search/user-search/user-search.component';
import { OfferChangeRequestsComponent } from './admin/offer-change-requests/offer-change-requests.component';
import { DestinationOverrideListComponent } from './admin/destination-override/list/destination-override-list.component';
import { DestinationOverrideAddComponent } from './admin/destination-override/add/destination-override-add.component';
import { DestinationOverrideDetailsComponent } from './admin/destination-override/details/destination-override-details.component';
import { UserInfoComponent } from './user/user-info/user-info.component';
import { DestinationSearchComponent } from './pages/destination/destination-search/destination-search.component';
import { DestinationInfoComponent } from './pages/destination/destination-info/destination-info.component';
import { OfferMessagesComponent } from './admin/offer-messages/offer-messages.component';
import {RequestDetailsComponent} from './request/request-details/details/request-details.component';
import {RequestSearchComponent} from './request/request-details/search/request-search.component';
import {OfferDetailsComponent} from './offer/offer-details/details/offer-details.component';
import {SiteCreationComponent} from './pages/site-creation/site-creation/site-creation.component';
import { BlKeywordSearchComponent } from './pages/bl-keyword/bl-keyword-search/bl-keyword-search.component';
import { BlKeywordInfoComponent } from './pages/bl-keyword/bl-keyword-info/bl-keyword-info.component';
import { BlSearchComponent } from './pages/bl/bl-search/bl-search.component';
import { HousingRequestFilterCheckComponent } from './housing-request/housing-request-filter-check/housing-request-filter-check.component';
import {
  GeneratedTextDestinationSearchComponent
} from './generated-text/destination/destination-search/generated-text-destination-search.component';
import {
  GeneratedTextDestinationInfoComponent
} from './generated-text/destination/destination-info/generated-text-destination-info.component';
import { GeneratedTextGetByKeysComponent } from './generated-text/get-by-keys/generated-text-get-by-keys.component';
import { GeneratedTextBaseComponent } from './generated-text/base/generated-text-base.component';

export const DashboardRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'admin/booking/direct-search/:bookingId', component: InstantBookingDetailsComponent
      },
      {
        path: 'admin/booking/bookings/change-requests', component: BookingChangeRequestsComponent
      },
      {
        path: 'admin/offer/change-requests', component: OfferChangeRequestsComponent
      },
      {
        path: 'admin/booking/direct-search', component: InstantBookingsComponent
      },
      {
        path: 'admin/phone-callback/requests', component: PhoneCallbackRequestComponent,
      },
      {
        path: 'offer/messages', component: OfferMessagesComponent,
      },
      {
        path: 'offer/messages/:offerId/:offerMessageId', component: OfferMessagesComponent,
      },
      {
        path: 'requests/auto-disqualification', component: AutoDisqualificationComponent
      },
      {
        path: 'requests/follow-up', component: FollowUpComponent
      },
      {
        path: 'requests/:requestId', component: RequestDetailsComponent
      },
      {
        path: 'requests', component: RequestSearchComponent
      },
      {
        path: 'offers/:offerId', component: OfferDetailsComponent
      },
      {
        path: 'users/search', component: UserSearchComponent,
      },
      {
        path: 'users/:userId', component: UserInfoComponent,
      },
      {
        path: 'housings/housings-edit/:housingId', component: HousingEditComponent
      },
      {
        path: 'housings/search', component: HousingSearchComponent,
      },
      {
        path: 'housings/add', component: HousingAddComponent,
      },
      {
        path: 'housing-requests/add', component: HousingRequestComponent,
      },
      {
        path: 'housing-requests/request-filter-check', component: HousingRequestFilterCheckComponent,
      },
      {
        path: 'destination-override', component: DestinationOverrideListComponent,
      },
      {
        path: 'destination-override/add', component: DestinationOverrideAddComponent,
      },
      {
        path: 'destination-override/:destinationId', component: DestinationOverrideDetailsComponent,
      },
      {
        path: 'pages/site-creation', component: SiteCreationComponent,
      },
      {
        path: 'pages/destinations/search', component: DestinationSearchComponent,
      },
      {
        path: 'pages/destinations/:destinationId', component: DestinationInfoComponent,
      },
      {
        path: 'pages/generated-text', component: GeneratedTextBaseComponent,
      },
      {
        path: 'pages/generated-text/get-by-keys', component: GeneratedTextGetByKeysComponent,
      },
      {
        path: 'pages/generated-text/destination', component: GeneratedTextDestinationSearchComponent,
      },
      {
        path: 'pages/generated-text/destination/:destinationId', component: GeneratedTextDestinationInfoComponent,
      },
      {
        path: 'pages/bl/search', component: BlSearchComponent,
      },
      {
        path: 'pages/bl-keyword/search', component: BlKeywordSearchComponent,
      },
      {
        path: 'pages/bl-keyword/:blKeywordId', component: BlKeywordInfoComponent,
      },
      {
        path: 'housings/:housingId', component: HousingInfoComponent,
        children: [
          {
            path: 'overview', component: OverviewComponent
          },
          {
            path: 'details', component: DetailsComponent
          },
          {
            path: 'statistics', component: StatisticsComponent
          },
          {
            path: 'channelmanager', component: ChannelmanagerComponent
          },
          {
            path: 'top-partner-program', component: TopPartnerComponent
          },
        ]
      },
      ...BoundariesActivityRoutes,
      ...ReportRoutes,
      ...DeveloperRoutes
    ]
  },
];
