<app-content-header i18n-title title="Boundaries" i18n-subTitle
                    subTitle="Edit Boundaries"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">
  <div class="row">
    <div class="col-4">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Boundary Data</h3>
        </div>
        <div class="block-content-full block-content">
          <div class="row">
            <div class="col-md-6 text-left">
              <button class="btn btn-sm btn-default" (click)="handleButtonExportClick()">Export Coordinates</button>

            </div>
            <div class="col-md-6 text-right">
              <button class="btn btn-sm btn-success" (click)="testBoundaryDetailsNew()">Run tests</button>
            </div>
          </div>
        </div>
        <hr>
        <div class="block-content-full block-content">
          <textarea class="form-control" id="example-textarea-input" name="example-textarea-input" rows="6" placeholder="Content..">{{pathsOutput}}</textarea>
        </div>
        <hr>
        <div class="block-content-full block-content">
          <div class="row">
            <div class="col-md-3 text-left">Housings:</div>
            <div class="col-md-3 text-left">{{amountHousings}}</div>
            <div class="col-md-3 text-left">Requests:</div>
            <div class="col-md-3 text-left">{{amountRequests}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col-12">
          <div class="block block-bordered">
            <div class="block-header block-header-default">
              <h3 class="block-title">Boundary
              </h3>
            </div>
            <agm-map [zoom]="3" (mapDblClick)="addPolygon($event)">
            </agm-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
