<form [formGroup]="form" (submit)="onSubmitForm()">
  <div class="input-group"
       [ngClass]="{'is-invalid': form.get('url').dirty === true && form.get('url').status === 'INVALID'}">
    <label class="col-12" for="url">{{title}} URL</label>

    <input type="text" class="form-control" id="url" name="url" placeholder="Please enter the URL"
           formControlName="url">
    <div class="input-group-append">
      <button type="button" class="btn btn-secondary" (click)="onSubmitForm()" [disabled]="isLoading">
        <i class="fa fa-check mr-5" *ngIf="isLoading === false && isUpdated === true"></i>
        <i class="fa fa-save mr-5" *ngIf="isLoading === false && isUpdated === false"></i>
        <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>
      </button>
    </div>
    <div class="invalid-feedback"
         *ngFor="let error of extractValidationErrorsAsArray(form.get('url').errors)">
      {{error}}
    </div>

  </div>
</form>
