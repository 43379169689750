<app-content-header i18n-title title="Add a new Housing" i18n-subTitle
                    subTitle="Please enter the required basic information"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">

  <div class="row">
    <div class="col-md-12">
      <app-housing-add-basic-info></app-housing-add-basic-info>
    </div>
  </div>

</div>

