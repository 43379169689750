import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { EventsService } from '../../../../services/events/events.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'tripmakery-user-info-housing-access-add-modal',
  templateUrl: './user-info-housing-access-add-modal.component.html',
})
export class UserInfoHousingAccessAddModalComponent implements OnInit {
  public bookingChangeRequestId: string;
  public bookingChangeRequestData: any;
  public form: FormGroup;
  public environment_;
  public isLoading: boolean = false;

  public isEmailInUse: boolean;
  public emailInUseUserId: string;
  public emailInUseUrl: string;

  public errHousingIdNotFound: boolean = false;
  public errUserAlreadyHasAccessRights: boolean = false;
  public errHousingAlreadyHasAccessRights: boolean = false;
  public errHousingAlreadyHasAccessRightsUserId: string;
  public errHousingAlreadyHasAccessRightsUserLink: string;

  @ViewChild('childModal') public childModal: ModalDirective;
  @Input() public user;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
              private router: Router
  ) {
    this.form = new FormGroup({
      'housingId': new FormControl({value: '', disabled: false}),
    });
    this.environment_ = environment;
  }

  public ngOnInit() {

  }

  public showChildModal(): void {
    this.errHousingIdNotFound = false;
    this.errUserAlreadyHasAccessRights = false;
    this.errHousingAlreadyHasAccessRights = false;
    this.form.get('housingId').setValue('');

    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    this.showLoading();

    this.errHousingIdNotFound = false;
    this.errUserAlreadyHasAccessRights = false;
    this.errHousingAlreadyHasAccessRights = false;

    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/user/' + this.user._id + '/housing-access-rights', {
      housingId: this.createNullIfEmptyString(this.form.get('housingId').value),
      accessRights: ['MASTER_ACCOUNT'],
    })
      .subscribe(
        (response: any) => {
          this.eventsService.broadcast('userEditReloadHousingAccessRights');
        },
        (err) => {
          if (err.error.message === 'E_VALIDATION_FAILED') {
            console.log('Just a validation Error');
            try {
              for (const error of err.error.errors) {
                this.form.get(error.property).setErrors(error.constraints);
                this.form.get(error.property).markAsDirty();

                console.log(error.property, error.constraints);
              }
            } catch (error) {
              console.log('Catched E', error);
            }
            this.hideLoading();
            return;
          } else if (err.error.message === 'E_HOUSING_NOT_FOUND') {
            this.errHousingIdNotFound = true;
            this.hideLoading();
            return;
          } else if (err.error.message === 'E_USER_ALREADY_HAS_ACCESS_RIGHTS') {
            this.errUserAlreadyHasAccessRights = true;
            this.emailInUseUserId = err.error.userId;
            this.emailInUseUrl = environment.urls.tool + '/users/' + err.error.userId;
            this.hideLoading();
            return;
          } else if (err.error.message === 'E_HOUSING_ALREADY_HAS_ACCESS_RIGHTS') {
            this.errHousingAlreadyHasAccessRights = true;
            this.errHousingAlreadyHasAccessRightsUserId = err.error.userId;
            this.errHousingAlreadyHasAccessRightsUserLink = environment.urls.tool + '/users/' + err.error.userId;
            this.hideLoading();
            return;
          }

          console.log(err);

          this.hideLoading();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
          this.hideChildModal();
        }
      );
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    return s;
  }
}
