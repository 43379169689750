<h2 class="content-heading">Overview</h2>
<div class="row gutters-tiny">
  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo"
               data-to="requests">
            {{amountOfHousingRequests}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Requests Received</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->
  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{amountOfNoOffers}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-danger">Requests Declined</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->
  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{amountOfPreOffers}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Offers Created</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- Stock -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-check fa-2x text-success-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-success js-count-to-enabled" data-toggle="countTo"
               data-to="85">{{amountOfOffers}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Offers Forwarded</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END Stock -->

  <!-- Stock -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-check fa-2x text-success-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-success js-count-to-enabled" data-toggle="countTo"
               data-to="85">{{amountOfBookings}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Bookings</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END Stock -->

  <!-- Delete Product -->
  <div class="col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-archive fa-2x text-danger-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-danger js-count-to-enabled" data-toggle="countTo"
               data-to="85">{{amountOfDeclinedOffersByUser}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Offers declined by user</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END Delete Product -->

  <!-- Delete Product -->
  <div class="col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-archive fa-2x text-danger-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-success js-count-to-enabled" data-toggle="countTo"
               data-to="85">{{amountOfPictures}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Images</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END Delete Product -->

  <!-- Delete Product -->
  <div class="col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-archive fa-2x text-danger-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-success js-count-to-enabled" data-toggle="countTo"
               data-to="85">{{amountOfPriceHistoryEntriesBooking}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Booking Price Data</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END Delete Product -->

  <div class="col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-archive fa-2x text-danger-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-success js-count-to-enabled" data-toggle="countTo"
               data-to="85">{{amountOfPriceHistoryEntriesTrivago}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Trivago Price Data</div>
        </div>
      </div>
    </a>
  </div>

</div>

<div class="row gutters-tiny">
  <div class="col-xl-6">
    <tripmakery-housing-info-overview-housing-requests [housingId]="housingId"></tripmakery-housing-info-overview-housing-requests>
  </div>
  <div class="col-xl-6">
    <tripmakery-housing-info-overview-preoffers [housingId]="housingId"></tripmakery-housing-info-overview-preoffers>
  </div>
  <div class="col-xl-6">
    <tripmakery-housing-info-overview-offers [housingId]="housingId"></tripmakery-housing-info-overview-offers>
  </div>
  <div class="col-xl-6">
    <tripmakery-housing-info-overview-no-offers [housingId]="housingId"></tripmakery-housing-info-overview-no-offers>
  </div>
  <div class="col-xl-6">
    <tripmakery-housing-info-overview-bookings [housingId]="housingId"></tripmakery-housing-info-overview-bookings>
  </div>

</div>

<div class="row gutters-tiny">
  <div class="col-xl-6">
    <tripmakery-housing-info-overview-availability [housingId]="housingId"></tripmakery-housing-info-overview-availability>
  </div>
</div>

<div class="row gutters-tiny">
  <div class="col-xl-6">
<!--    <tripmakery-housing-info-overview-price-crawler-jobs [housingId]="housingId" [section]="'trivago'" [sectionTitle]="'Trivago'"></tripmakery-housing-info-overview-price-crawler-jobs>-->
  </div>
  <div class="col-xl-6">
<!--    <tripmakery-housing-info-overview-price-crawler-jobs [housingId]="housingId" [section]="'booking'" [sectionTitle]="'Booking'"></tripmakery-housing-info-overview-price-crawler-jobs>-->
  </div>
  <div class="col-xl-6">
<!--    <tripmakery-housing-info-price-data [housingId]="housingId" [section]="'trivago'" [sectionTitle]="'Trivago'"></tripmakery-housing-info-price-data>-->
  </div>
  <div class="col-xl-6">
<!--    <tripmakery-housing-info-price-data [housingId]="housingId" [section]="'booking'" [sectionTitle]="'Booking'"></tripmakery-housing-info-price-data>-->
  </div>
</div>


<tripmakery-housing-info-overview-modal-price-crawler-creation [housingId]="housingId"></tripmakery-housing-info-overview-modal-price-crawler-creation>
