import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ReactiveFormsModule} from "@angular/forms";
import {AppRoutingModule} from "./app-routing.module";
import {RouterModule} from "@angular/router";
import {AccountService} from "./services/accountservice/account.service";
import {LoginComponent} from './login/login.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ConfigService} from "./services/configservice/config.service";
import {AuthService, initConfiguration} from "./services/authservice/auth.service";
import {NgSelectModule} from "@ng-select/ng-select";
import {CarouselModule, ModalModule, PopoverModule, TabsModule} from "ngx-bootstrap";
import {EventsService} from "./services/events/events.service";
import {LoadingIndicatorService} from "./services/loading-indicator/loading-indicator.service";
import {ErrorOutputComponent} from './error-output/error-output.component';
import {AgmCoreModule} from '@agm/core';
import {SharedModule} from "./shared/shared/shared.module";
import {JwtInterceptor} from "./shared/interceptor/jwt.interceptor";
import {PagingService} from "./services/pagingservice/paging.service";
import {NgxPaginationModule} from 'ngx-pagination';
import {OpenStreetMapsService} from "./services/open-street-maps/open-street-maps.service";
import { VERSION } from './shared/consts/version.const';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorOutputComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    NgSelectModule,
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyBAYwMtK6qx73DckoiQ7DlchfD_UVHGm80'
      apiKey: 'AIzaSyDXXs4jWV-OZUeyMu_jSg-0D0p-sGs-YjU'
    }),
    SharedModule
  ],
  providers: [
    OpenStreetMapsService,
    AuthService,
    AccountService,
    ConfigService,
    EventsService,
    LoadingIndicatorService,
    PagingService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfiguration,
      deps: [AuthService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
  ],
  exports: [
    RouterModule,
    NgSelectModule,
    PopoverModule,
    ModalModule,
    SharedModule,
    NgxPaginationModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    console.log(`Running on ${VERSION.hash}`);
  }
}
