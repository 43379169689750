import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'delimitednumber'})
export class DelimitedNumberPipe implements PipeTransform {
    locale: string;

    constructor() {
        this.locale = 'de-DE';

        if (this.locale == null) {
            this.locale = 'en-US';
        }
    }

    transform(value: number, maxFraction): string {
        if (value != null) {
            return value.toLocaleString(this.locale, {
                maximumFractionDigits: maxFraction,
                minimumFractionDigits: maxFraction
            })
        }
    }
}
