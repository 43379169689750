import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-instant-booking-details-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class InstantBookingDetailsUserComponent implements OnInit {
  @Input('user') user;

  constructor() { }

  ngOnInit() {
  }

}
