<div bsModal #childModal="bs-modal" class="modal" id="modal-normal" tabindex="-1" role="dialog"
     aria-labelledby="modal-normal" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document" *ngIf="image != null">
    <div class="modal-content">
      <div class="modal-body">
        <img #myImage src="{{environment.urls.blob}}/{{housingId}}/raw/{{image}}" class="img-fluid"
             (load)="dosomething()">
      </div>
      <!--<img src="{{environment.urls.blob}}/{{housingId}}/raw/{{image}}">-->
      <div class="modal-footer" style="max-width:1200px">
        <div class="col-md-1 text-left">
          <button type="button" class="btn btn-alt-secondary" (click)="showPrevious()"><i class="fa fa-arrow-left"></i>
          </button>
        </div>
        <div class="col-md-10 text-center">
          {{imageWidth | delimitednumber:0 }}x{{imageHeight | delimitednumber:0}}
        </div>
        <div class="col-md-1 text-right">
          <button type="button" class="btn btn-alt-secondary" (click)="showNext()"><i class="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
