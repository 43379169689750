<footer id="page-footer">
  <div class="content py-20 font-size-xs clearfix">
    <div class="float-right">
      tripbakery GmbH
    </div>
    <div class="float-left">
      <a class="font-w600" href="https://tripmakery.com" target="_blank">tripmakery-admin-ngx</a>
    </div>
  </div>
</footer>
