import {Component, OnInit} from '@angular/core';
import {BreadCrumbElement} from "../../../../shared/entities/breadcrumb.element.entity";
import {FormControl, FormGroup} from "@angular/forms";
import * as moment from "moment";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {EventsService} from "../../../../services/events/events.service";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";
import {ActivatedRoute} from "@angular/router";
import {CONST_DIRECT_SEARCH_DESTINATIONS} from "../../../../shared/utils/direct-search-destinations";

@Component({
  selector: 'app-request-destinations',
  templateUrl: './request-destinations.component.html',
  styleUrls: ['./request-destinations.component.css']
})
export class RequestDestinationsComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public formGeneral: FormGroup;

  public DIRECT_SEARCH_DESTINATIONS = CONST_DIRECT_SEARCH_DESTINATIONS;

  /* */
  public result = [];

  constructor(private activatedRoute: ActivatedRoute,
              private eventsService: EventsService,
              private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient) {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Reports', 'javascript:void(0)'),
      new BreadCrumbElement('Request', 'javascript:void(0)'),
      new BreadCrumbElement('Destinations', 'javascript:void(0)')
    ];

    this.formGeneral = new FormGroup({
      'dateStart': new FormControl(moment().startOf("month").toDate()),
      'dateEnd': new FormControl(moment().endOf("month").toDate()),
      'mode': new FormControl('REQUEST')
    });
  }

  ngOnInit() {
    this.requestData();
  }

  public requestData() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/report/request/destinations?dateStart=' + moment(this.formGeneral.get('dateStart').value).format("YYYY-MM-DD") + '&dateEnd=' + moment(this.formGeneral.get('dateEnd').value).format("YYYY-MM-DD") + '&mode='+this.formGeneral.get('mode').value)
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
          this.result = response;
        },
        err => {
          this.loadingIndicatorService.hideLoadingBar();
        });
  }

}
