<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-popin"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" bsModal>
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">{{modalTitle}}</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" data-dismiss="modal" aria-label="Close"
                    (click)="hideChildModal()">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content">
          <form [formGroup]="form">

            <div class="form-group row">
              <label class="col-4">Goodie</label>
              <div class="col-4">
                <select class="form-control" formControlName="goody"
                        [ngClass]="{'is-invalid': (form.get('goody').touched || form.get('goody').dirty) && form.get('goody').invalid}">
                  <option value="COMMISSION_EXCLUSION">No Commission</option>
                </select>
                <div class="invalid-feedback"
                     *ngFor="let error of FormUtils.extractValidationErrorsAsArray(form.get('goody').errors)">
                  {{error}}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4">How often should it get applied</label>
              <div class="col-4">
                <input type="text" class="form-control"
                       placeholder="i.e. 10 times"
                       autocomplete="off"
                       id="amountSet"
                       formControlName="amountSet"
                       [ngClass]="{'is-invalid': (form.get('amountSet').touched || form.get('amountSet').dirty) && form.get('amountSet').invalid}">
                <div class="invalid-feedback"
                     *ngFor="let error of FormUtils.extractValidationErrorsAsArray(form.get('amountSet').errors)">
                  {{error}}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4">How often got it applied already</label>
              <div class="col-4">
                <input type="text" class="form-control"
                       placeholder="i.e. 4 out of 10 times"
                       autocomplete="off"
                       id="amountCurrent"
                       formControlName="amountCurrent"
                       [ngClass]="{'is-invalid': (form.get('amountCurrent').touched || form.get('amountCurrent').dirty) && form.get('amountCurrent').invalid}">
                <div class="invalid-feedback"
                     *ngFor="let error of FormUtils.extractValidationErrorsAsArray(form.get('amountCurrent').errors)">
                  {{error}}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 mt-5" for="active">Active</label>
              <div class="col-8">
                <label class="css-control css-control-success css-switch">
                  <input type="checkbox" class="css-control-input" id="active" name="active"
                         formControlName="active"
                         [ngClass]="{'is-invalid': (form.get('active').touched || form.get('active').dirty) && form.get('active').invalid}">
                  <span class="css-control-indicator"></span>
                </label>
                <div class="invalid-feedback"
                     *ngFor="let error of FormUtils.extractValidationErrorsAsArray(form.get('active').errors)">
                  {{error}}
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-alt-secondary" data-dismiss="modal" (click)="hideChildModal()">Abort
        </button>
        <button type="button" class="btn btn-alt-success" (click)="handleClick()">
          <i class="fa fa-check"></i> Save
        </button>
      </div>
    </div>
  </div>
</div>

