<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-popin"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" bsModal>
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">Price Crawler Job Creation</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" data-dismiss="modal" aria-label="Close">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="form">
                <div class="form-group row">
                  <label class="col-12">For which services should we create crawler jobs?</label>
                  <div class="col-12">
                    <div class="custom-control custom-checkbox custom-control-inline mb-5">
                      <input class="custom-control-input" type="checkbox" name="service-booking"
                             id="service-booking" value="true"
                             formControlName="service-booking"
                      >
                      <label class="custom-control-label" for="service-booking">Booking.com</label>
                    </div>
                    <div class="custom-control custom-checkbox custom-control-inline mb-5">
                      <input class="custom-control-input" type="checkbox" name="service-trivago"
                             id="service-trivago" value="true"
                             formControlName="service-trivago"
                      >
                      <label class="custom-control-label" for="service-trivago">Trivago.de</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12">Please define the period that should be crawled</label>
                  <div class="col-12">
                    <input type="text" class="form-control mb-3"
                    placeholder="Click here to open the menu" bsDaterangepicker formControlName="range"/>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12">Should all single days get crawled or can I optimize your selection automatically?</label>
                  <div class="col-12">
                    <div class="custom-control custom-radio custom-control-inline mb-5">
                      <input class="custom-control-input" type="radio"
                             name="crawling-mode"
                             formControlName="crawling-mode"
                             id="crawling-mode-1" value="ALL" checked="">
                      <label class="custom-control-label" for="crawling-mode-1">Crawl everything</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-5">
                      <input class="custom-control-input" type="radio"
                             name="crawling-mode"
                             formControlName="crawling-mode"
                             id="crawling-mode-2" value="OPTIMIZED">
                      <label class="custom-control-label" for="crawling-mode-2">Optimize selection</label>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-alt-secondary" data-dismiss="modal">Abort</button>
        <button type="button" class="btn btn-alt-success" (click)="handleClick()">
          <i class="fa fa-check"></i> Create Jobs
        </button>
      </div>
    </div>
  </div>
</div>

