import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {HttpClient, HttpParams} from "@angular/common/http";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";
import {EventsService} from "../../../../services/events/events.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-housing-crawl-housing',
  templateUrl: './crawl-housing.component.html',
  styleUrls: ['./crawl-housing.component.css']
})
export class CrawlHousingComponent implements OnInit {
  @Input() title: string;
  @Input() baseUrl: string;
  @Output() housingData = new EventEmitter();

  public form: FormGroup;
  public isLoading: boolean = false;
  public isUpdated: boolean = false;

  constructor(private http: HttpClient,
              private router: Router,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {

    this.form = new FormGroup({
      'url': new FormControl({value: '', disabled: false}),
    });

  }

  onSubmitForm() {
    if (this.form.status !== 'VALID') {
      return;
    }
    this.showLoading();

    this.postData().subscribe(
      (response: any) => {
        console.log(response);
        this.housingData.emit(response);
        this.hideLoading();
        this.isUpdated = true;
      },
      err => {
        console.log("Catched an error", err);

        if (err.error.message === "E_VALIDATION_FAILED") {
          console.log("Just a validation Error");
          try {
            for (const error of err.error.errors) {
              this.form.get(error.property).setErrors(error.constraints);
              this.form.get(error.property).markAsDirty();

              console.log(error.property, error.constraints);
            }
          } catch (error) {
            console.log("Catched E", error)
          }
          this.hideLoading();
          return;
        }

        this.hideLoading();
        this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));
      }
    )
  }

  private postData() {
    const urlBase64 = btoa(this.form.get('url').value);
    const params = new HttpParams().set('url', urlBase64);
    return this.http.post(this.baseUrl, {}, {params});
  }

  ngOnInit() {

  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  private createEmptyStringIfNull(s: string) {
    if (s == null) {
      return '';
    }

    return s;
  }

  private createNullIfEmptyStringOrReturnFloat(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return null;
    }
    if (typeof s === "number") {
      return s;
    }
    const d = parseFloat(s);

    if (isNaN(d)) {
      return s;
    }

    return d;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

}
