<form [formGroup]="form" (submit)="onSubmitForm()">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Whitelabel Configuration</h3>
      <div class="block-options">
        <button type="submit" class="btn btn-sm btn-alt-primary" (click)="onSubmitForm()"
                [disabled]="housing == null || isLoading === true">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
        </button>
      </div>
    </div>
    <div class="block-content block-content-full">

      <div class="form-group row">
        <label class="col-4" for="enabled">Enable HBT</label>
        <div class="col-8">
          <label class="css-control css-control-success css-switch">
            <input type="checkbox" class="css-control-input" id="enabled" name="enabled"
                   formControlName="enabled">
            <span class="css-control-indicator"></span>
          </label>
        </div>
      </div>

      <h5>Assets / Images</h5>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="logoImageUrlBig">Logo URL (big)</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('logoImageUrlBig').touched || form.get('logoImageUrlBig').dirty) && form.get('logoImageUrlBig').invalid}">
          <input type="text" class="form-control" id="logoImageUrlBig" name="logoImageUrlBig"
                 placeholder="Absolute URL to Logo (big)" formControlName="logoImageUrlBig">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('logoImageUrlBig').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="widthImageSmall">Width (big)</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('widthImageBig').touched || form.get('widthImageBig').dirty) && form.get('widthImageBig').invalid}">
          <input type="text" class="form-control" id="widthImageBig" name="widthImageBig"
                 placeholder="Width of the image (big)" formControlName="widthImageBig">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('widthImageBig').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label" for="heightImageSmall">Height (big)</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('heightImageBig').touched || form.get('heightImageBig').dirty) && form.get('heightImageBig').invalid}">
          <input type="text" class="form-control" id="heightImageBig" name="heightImageBig"
                 placeholder="Height of the image (big)" formControlName="heightImageBig">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('heightImageBig').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-12"><hr></div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="logoImageUrlSmall">Logo URL (small)</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('logoImageUrlSmall').touched || form.get('logoImageUrlSmall').dirty) && form.get('logoImageUrlSmall').invalid}">
          <input type="text" class="form-control" id="logoImageUrlSmall" name="logoImageUrlSmall"
                 placeholder="Absolute URL to Logo (big)" formControlName="logoImageUrlSmall">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('logoImageUrlSmall').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label" for="widthImageSmall">Width (small)</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('widthImageSmall').touched || form.get('widthImageSmall').dirty) && form.get('widthImageSmall').invalid}">
          <input type="text" class="form-control" id="widthImageSmall" name="widthImageSmall"
                 placeholder="Width of the image (small)" formControlName="widthImageSmall">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('widthImageSmall').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label" for="heightImageSmall">Height (small)</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('heightImageSmall').touched || form.get('heightImageSmall').dirty) && form.get('heightImageSmall').invalid}">
          <input type="text" class="form-control" id="heightImageSmall" name="heightImageSmall"
                 placeholder="Height of the image (small)" formControlName="heightImageSmall">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('heightImageSmall').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-12"><hr></div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="logoImageUrlEmail">Logo URL (Email)</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('logoImageUrlEmail').touched || form.get('logoImageUrlEmail').dirty) && form.get('logoImageUrlEmail').invalid}">
          <input type="text" class="form-control" id="logoImageUrlEmail" name="logoImageUrlEmail"
                 placeholder="Absolute URL to Logo (Email)" formControlName="logoImageUrlEmail">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('logoImageUrlEmail').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="widthImageEmail">Width (Email)</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('widthImageEmail').touched || form.get('widthImageEmail').dirty) && form.get('widthImageEmail').invalid}">
          <input type="text" class="form-control" id="widthImageEmail" name="widthImageEmail"
                 placeholder="Width of the image (Email)" formControlName="widthImageEmail">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('widthImageEmail').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label" for="heightImageEmail">Height (Email)</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('heightImageEmail').touched || form.get('heightImageEmail').dirty) && form.get('heightImageEmail').invalid}">
          <input type="text" class="form-control" id="heightImageEmail" name="heightImageEmail"
                 placeholder="Height of the image (Email)" formControlName="heightImageEmail">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('heightImageEmail').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <h5>Theme / Colors</h5>
      <div class="form-group row">
        <label class="col-4 col-form-label" for="primaryColor">Primary Color</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('primaryColor').touched || form.get('primaryColor').dirty) && form.get('primaryColor').invalid}">
          <input type="text" class="form-control" id="primaryColor" name="primaryColor"
                 placeholder="Primary Color (Hex)" formControlName="primaryColor">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('primaryColor').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="textPrimary">Text Primary</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('textPrimary').touched || form.get('textPrimary').dirty) && form.get('textPrimary').invalid}">
          <input type="text" class="form-control" id="textPrimary" name="textPrimary"
                 placeholder="Text Primary Color (Hex)" formControlName="textPrimary">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('textPrimary').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="bgPrimary">BG Primary</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('bgPrimary').touched || form.get('bgPrimary').dirty) && form.get('bgPrimary').invalid}">
          <input type="text" class="form-control" id="bgPrimary" name="bgPrimary"
                 placeholder="BG Primary Color (Hex)" formControlName="bgPrimary">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('bgPrimary').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <h5>Documents</h5>
      <div class="form-group row">
        <label class="col-4 col-form-label" for="gtc">GTC</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('gtc').touched || form.get('gtc').dirty) && form.get('gtc').invalid}">
          <input type="text" class="form-control" id="gtc" name="gtc"
                 placeholder="Absolute URL to General terms and conditions" formControlName="gtc">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('gtc').errors)">
            {{error}}
          </div>
        </div>
      </div>

    </div>
  </div>
</form>
<!-- END Sauce -->
