<app-content-header i18n-title title="Generated Text - Destinations" i18n-subTitle
                    subTitle="Filter our AI generated destination texts"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">

  <div class="row">
    <div class="col-md-3">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>
        <div class="block-content block-content-full">
          <form [formGroup]="form" (ngSubmit)="submitForm()">

            <div class="row">
              <div class="col-4">
                <label class="mt-5" for="identifier">Identifier</label>
              </div>
              <div class="col-8">
                <input type="text" class="form-control" id="identifier"
                       name="identifier"
                       placeholder="Identifier" formControlName="identifier">
                <div class="invalid-feedback"
                     *ngFor="let error of extractValidationErrorsAsArray(form.get('identifier').errors)">{{ error }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                &nbsp;
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label>Key / Value Pairs</label>
              </div>
              <div class="col-12">
                <div formArrayName="keys"
                     *ngFor="let item of getControls(); let i = index">
                  <div [formGroupName]="i.toString(10)">
                    <input type="text" class="form-control" formControlName='key' placeholder="Key">
                    <input type="text" class="form-control" formControlName='value' placeholder="Value">
                  </div>
                  <div class="row">
                    <div class="col-12">
                      &nbsp;
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-right">
                    <button type="button" class="btn btn-sm btn-info" (click)="addNextKeys()">Add Element
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>

        <div class="block-header block-header-default text-center">
          <button type="button" (click)="submitForm()" class="btn btn-info">Submit</button>
        </div>
      </div>

    </div>

    <div class="col-md-9">
      <div class="block" *ngIf="data != null">
        <div class="block-header block-header-default">
          &nbsp;
        </div>
        <div class="block-content block-content-full">
          <div class="row">
            <div class="col-12">
              <code>{{ data.text }}</code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
