<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-popin"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" bsModal>
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">Which message should be sent to the partner?</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" data-dismiss="modal" aria-label="Close"
                    (click)="hideChildModal()">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content" *ngIf="offerChangeRequestData != null">
          <form [formGroup]="form">

            <div class="row">
              <label class="col-3">RequestId</label>
              <div class="col-4">
                {{ offerChangeRequestData.requestId }}
              </div>
              <div class="col-5 mb-5">
                <a class="btn btn-sm btn-alt-secondary"
                   href="{{offerChangeRequestData.frontend.urlUserRequest}}"
                   target="_blank">User</a>&nbsp;
                <a class="btn btn-sm btn-alt-secondary"
                   href="{{environment_.urls.kitchen}}/pipeline/untouched?source=search&requestId={{offerChangeRequestData.requestId}}"
                   target="_blank">Kitchen</a>
              </div>
            </div>
            <div class="row">
              <label class="col-3">OfferId</label>
              <div class="col-4">
                {{ offerChangeRequestData.offerId }}
              </div>
              <div class="col-5">
                <a class="btn btn-sm btn-alt-secondary"
                   href="{{offerChangeRequestData.frontend.urlUserOffer}}"
                   target="_blank">User</a>&nbsp;
                <a class="btn btn-sm btn-alt-secondary"
                   href="{{environment_.urls.kitchen}}/requests/{{offerChangeRequestData.requestId}}/offer/{{offerChangeRequestData.offerId}}"
                   target="_blank">Kitchen</a>&nbsp;
                <a class="btn btn-sm btn-alt-secondary"
                   href="{{environment_.urls.partner}}/h/{{offerChangeRequestData.housingId}}/offer-detail/{{offerChangeRequestData.preOfferId}}?admin=true"
                   target="_blank">Partner</a>
              </div>
            </div>
            <div class="row">
              <label class="col-3">AutoOffer</label>
              <div class="col-4">
                {{ offerChangeRequestData.isCMOffer }}
              </div>
              <div class="col-5">

              </div>
            </div>
            <div class="row">
              <label class="col-3">CreatedAt</label>
              <div class="col-4">
                {{ offerChangeRequestData.createdAt }}
              </div>
              <div class="col-2">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr>
              </div>
              <div class="col-12">
                <strong>Fast actions without sending an Email</strong><br><br>
                <button type="button" class="btn btn-sm btn-alt-success"
                        (click)="handleFastStateSwitchWithoutEmail('FINISHED')">
                  Finish
                </button>&nbsp;
                <button type="button" class="btn btn-sm btn-alt-info"
                        (click)="handleFastStateSwitchWithoutEmail('IN_PROGRESS')">
                  In Progress
                </button>&nbsp;
                <button type="button" class="btn btn-sm btn-alt-danger"
                        (click)="handleFastStateSwitchWithoutEmail('ABORTED')">
                  Abort
                </button>&nbsp;
                <button type="button" class="btn btn-sm btn-alt-warning pull-right"
                        (click)="handleMoveOfferChangeRequestToMessage()">
                  Move to Message
                </button>&nbsp;
              </div>
              <div class="col-12">
                <hr>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div>
                  <tabset>
                    <!-- -->
                    <tab heading="Change Request Details" id="tab1" *ngIf="hasOfferChangeRequestDetails === true">
                      <div class="block-content-full block-content">
                        <div class="row" *ngIf="hasDateChanges === true">
                          <div class="col-md-4">
                            <strong>Date</strong>
                          </div>
                          <div class="col-md-8">
                            {{ dateChangeFrom }}
                            - {{ dateChangeTo }}
                          </div>
                        </div>
                        <div class="row" *ngIf="hasBoardingDetailsChanges === true">
                          <div class="col-md-4">
                            <strong>Boarding</strong>
                          </div>
                          <div class="col-md-8">
                            {{ offerChangeRequestData.changeRequestDetails.boardingDetails.boardingType }}
                          </div>
                        </div>
                        <div class="row" *ngIf="hasRoomDetailsChanges === true">
                          <div class="col-md-4">
                            <strong>Rooms</strong>
                          </div>
                          <div class="col-md-8">
                            {{ roomDetailsChangesTransformed }}
                          </div>
                        </div>
                      </div>
                    </tab>

                    <!-- -->
                    <tab heading="Message to partner" id="tab2">
                      <div class="block-content-full block-content">
      <textarea class="form-control" id="messageOverride" name="messageOverride" rows="6"
                placeholder="Content.."
                formControlName="messageOverride">{{ offerChangeRequestData.messageOverride }}</textarea>
                        <div class="alert alert-info mt-5" *ngIf="offerChangeRequestData.isCMOffer === false">
                          This message will be sent to the partner
                        </div>
                        <div class="alert alert-warning mt-5" *ngIf="offerChangeRequestData.isCMOffer === true">
                          Attention: AutoOffer! The partner won't be notified!
                        </div>
                        <button class="btn btn-sm btn-primary"
                                [hidden]="offerChangeRequestData.state === 'NEW' || offerChangeRequestData.emailStatus.admin.creationNotification.sent === false || offerChangeRequestData.isCMOffer === true"
                                (click)="handleResendPartnerMail()">Send again
                        </button>
                      </div>
                    </tab>
                    <tab heading="Notification States" *ngIf="offerChangeRequestData.state !== 'NEW'">
                      <div class="block-content-full block-content">
                        <h1 class="h5">Partner Emails</h1>
                        <div class="table-responsive">
                          <table class="table table-sm">
                            <thead>
                            <tr>
                              <th>Email Type</th>
                              <th>Sent</th>
                              <th>Sent At</th>
                              <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>Change Request Notification</td>
                              <td>{{ offerChangeRequestData.emailStatus.housing.creationNotification.sent }}</td>
                              <td>{{ offerChangeRequestData.emailStatus.housing.creationNotification.sentAt }}</td>
                              <td>
                                <button class="btn btn-sm btn-primary"
                                        [hidden]="offerChangeRequestData.isCMOffer === true"
                                        (click)="handleResendPartnerMail()">Send again
                                </button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <h1 class="h5">Admin Emails</h1>
                        <div class="table-responsive">
                          <table class="table table-sm">
                            <thead>
                            <tr>
                              <th>Email Type</th>
                              <th>Sent</th>
                              <th>Sent At</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>Change Request Notification</td>
                              <td>{{ offerChangeRequestData.emailStatus.admin.creationNotification.sent }}</td>
                              <td>{{ offerChangeRequestData.emailStatus.admin.creationNotification.sentAt }}</td>
                            </tr>
                            <tr>
                              <td>Missing Housing Reaction</td>
                              <td>{{ offerChangeRequestData.emailStatus.admin.missingHousingReactionNotification.sent }}</td>
                              <td>{{ offerChangeRequestData.emailStatus.admin.missingHousingReactionNotification.sentAt }}</td>
                            </tr>
                            <tr>
                              <td>Housing Reaction</td>
                              <td>{{ offerChangeRequestData.emailStatus.admin.housingReactionNotification.sent }}</td>
                              <td>{{ offerChangeRequestData.emailStatus.admin.housingReactionNotification.sentAt }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>


                      </div>
                    </tab>
                  </tabset>
                </div>

              </div>
            </div>
            <div class="row" [hidden]="offerChangeRequestData.state === 'NEW'">
              <div class="col-12">
                <hr>
              </div>
            </div>

            <div class="row" [hidden]="offerChangeRequestData.state === 'NEW'">
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-7">New State</label>
                  <div class="col-5">
                    <select class="form-control" formControlName="state">
                      <option value="NEW">New</option>
                      <option value="IN_PROGRESS">In Progress</option>
                      <option value="FINISHED">Finished</option>
                      <option value="ABORTED">Aborted</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>
      <div class="modal-footer" *ngIf="offerChangeRequestData != null">
        <button type="button" class="btn btn-alt-secondary" data-dismiss="modal" (click)="hideChildModal()">Abort
        </button>
        <button type="button" class="btn btn-alt-success" (click)="handleClick()">
          <i class="fa fa-check"></i>
          <ng-container *ngIf="offerChangeRequestData.state === 'NEW'">Send</ng-container>
          <ng-container *ngIf="offerChangeRequestData.state !== 'NEW'">Save</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>

