import {AfterViewInit, Component, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {LoadingIndicatorService} from '../../../services/loading-indicator/loading-indicator.service';
import {ConfigService} from '../../../services/configservice/config.service';
import {EventsService} from '../../../services/events/events.service';
import {BreadCrumbElement} from '../../../shared/entities/breadcrumb.element.entity';
import {FormControl, FormGroup} from '@angular/forms';
import {FormUtils} from '../../../shared/utils/form-utils';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-offer-change-requests',
  templateUrl: './offer-change-requests.component.html',
  styleUrls: ['./offer-change-requests.component.css']
})
export class OfferChangeRequestsComponent implements OnInit, AfterViewInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public page = 1;
  public limit = 25;

  /* */
  public pagination: any;
  public offerChangeRequests;
  public form: FormGroup;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private activatedRoute: ActivatedRoute,
              private http: HttpClient) {

    /* */
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Offers', 'javascript:void(0)'),
      new BreadCrumbElement('Change Requests', 'javascript:void(0)')
    ];

    /* */
    this.form = new FormGroup({
      'offerChangeRequestId': new FormControl({value: '', disabled: false}),
      'requestId': new FormControl({value: '', disabled: false}),
      'offerId': new FormControl({value: '', disabled: false}),
      'state': new FormControl({value: '', disabled: false}),
      'missingInteractionAfterDays': new FormControl({value: '', disabled: false}),
    });

  }

  ngOnInit() {
    /* */
    this.eventsService.on('refreshOfferChangeRequestsList', (bookingChangeRequestId) => {
      this.loadChangeRequestsToCheck();
    });

    /* */
    this.loadChangeRequestsToCheck();
  }

  ngAfterViewInit() {
    /* */
    if (this.activatedRoute.snapshot.queryParams['id'] != null) {
      this.requestChangeRequestDetails(this.activatedRoute.snapshot.queryParams['id']);
    }
  }

  public loadChangeRequestsToCheck() {
    this.loadingIndicatorService.showLoadingBar();

    /* Build params */
    let params = new HttpParams();
    params = params.append('page', this.page.toString());
    params = params.append('limit', this.limit.toString());

    params = params.append('offerChangeRequestId', FormUtils.returnAsNullIfEmpty(this.form.get('offerChangeRequestId').value));
    params = params.append('requestId', FormUtils.returnAsNullIfEmpty(this.form.get('requestId').value));
    params = params.append('offerId', FormUtils.returnAsNullIfEmpty(this.form.get('offerId').value));
    params = params.append('state', FormUtils.returnAsNullIfEmpty(this.form.get('state').value));
    params = params.append('missingInteractionAfterDays', FormUtils.returnAsNullIfEmpty(this.form.get('missingInteractionAfterDays').value));

    /* Remove empty params */
    let paramsFinal = new HttpParams();
    for (const paramsKey of params.keys()) {
      if (params.get(paramsKey) != null && params.get(paramsKey).trim() !== '') {
        paramsFinal = paramsFinal.append(paramsKey, params.get(paramsKey));
      }
    }

    /* Send Request and map data */
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/offer/change-requests', {params: paramsFinal})
      .subscribe((response: any) => {

          /* Date Mapping */
          for (const element of response.elements) {
            element.createdAt = moment(element.createdAt).format('DD.MM.YYYY');
            element.updatedAt = moment(element.updatedAt).format('DD.MM.YYYY');
            element.dealtWithAt = (element.dealtWithAt) != null ? moment(element.dealtWithAt).format('DD.MM.YYYY') : '-';
          }

          this.offerChangeRequests = response.elements;
          this.pagination = response.pagination;

        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public requestChangeRequestDetails(id: string) {
    this.eventsService.broadcast('requestShowModalOfferChangeRequestEdit', id);
  }

  public sendPage(e: number) {
    this.page = e;
    this.loadChangeRequestsToCheck();
  }
}
