<div [ngClass]="containerClass" [ngStyle]="containerBackgroundImage">
    <div [ngClass]="subContainerClass" [ngStyle]="subContainerBackgroundImage">
        <div [ngClass]="contentClass">
            <div [ngClass]="pyClass">
                <h1 [ngClass]="h1class">{{title}}</h1>
                <h2 [ngClass]="h2class">{{subTitle}}</h2>
            </div>

        </div>
    </div>
</div>
