<header id="page-header">
  <!-- Header Content -->
  <div class="content-header">
    <!-- Left Section -->
    <div class="content-header-section">
      <!-- Toggle Sidebar -->
      <!-- Layout API, functionality initialized in Codebase() -> uiApiLayout() -->
      <button type="button" class="btn btn-circle btn-dual-secondary" data-toggle="layout" data-action="sidebar_toggle">
        <i class="fa fa-navicon"></i>
      </button>
      <!-- END Toggle Sidebar -->

      <!-- Open Search Section -->
      <!-- Layout API, functionality initialized in Codebase() -> uiApiLayout() -->
      <!--<button type="button" class="btn btn-circle btn-dual-secondary" data-toggle="layout" data-action="header_search_on">-->
      <!--<i class="fa fa-search"></i>-->
      <!--</button>-->
      <!-- END Open Search Section -->

      <!-- Layout Options (used just for demonstration) -->
      <!-- Layout API, functionality initialized in Codebase() -> uiApiLayout() -->
      <!--<div class="btn-group" role="group">-->
      <!--<button type="button" class="btn btn-circle btn-dual-secondary" id="page-header-options-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
      <!--<i class="fa fa-wrench"></i>-->
      <!--</button>-->
      <!--<div class="dropdown-menu" aria-labelledby="page-header-options-dropdown">-->
      <!--<h6 class="dropdown-header">Header</h6>-->
      <!--<button type="button" class="btn btn-sm btn-block btn-alt-secondary" data-toggle="layout" data-action="header_fixed_toggle">Fixed Mode</button>-->
      <!--<button type="button" class="btn btn-sm btn-block btn-alt-secondary d-none d-lg-block mb-10" data-toggle="layout" data-action="header_style_classic">Classic Style</button>-->
      <!--<div class="d-none d-xl-block">-->
      <!--<h6 class="dropdown-header">Main Content</h6>-->
      <!--<button type="button" class="btn btn-sm btn-block btn-alt-secondary mb-10" data-toggle="layout" data-action="content_layout_toggle">Toggle Layout</button>-->
      <!--</div>-->
      <!--<div class="dropdown-divider"></div>-->
      <!--<a class="dropdown-item" href="be_layout_api.html">-->
      <!--<i class="si si-chemistry"></i> All Options (API)-->
      <!--</a>-->
      <!--</div>-->
      <!--</div>-->
      <!-- END Layout Options -->

      <!-- Color Themes (used just for demonstration) -->
      <!-- Themes functionality initialized in Codebase() -> uiHandleTheme() -->
      <!--<div class="btn-group" role="group">-->
      <!--<button type="button" class="btn btn-circle btn-dual-secondary" id="page-header-themes-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
      <!--<i class="fa fa-paint-brush"></i>-->
      <!--</button>-->
      <!--<div class="dropdown-menu min-width-150" aria-labelledby="page-header-themes-dropdown">-->
      <!--<h6 class="dropdown-header text-center">Color Themes</h6>-->
      <!--<div class="row no-gutters text-center mb-5">-->
      <!--<div class="col-4 mb-5">-->
      <!--<a class="text-default" data-toggle="theme" data-theme="default" href="javascript:void(0)">-->
      <!--<i class="fa fa-2x fa-circle"></i>-->
      <!--</a>-->
      <!--</div>-->
      <!--<div class="col-4 mb-5">-->
      <!--<a class="text-elegance" data-toggle="theme" data-theme="assets/css/themes/elegance.min.css" href="javascript:void(0)">-->
      <!--<i class="fa fa-2x fa-circle"></i>-->
      <!--</a>-->
      <!--</div>-->
      <!--<div class="col-4 mb-5">-->
      <!--<a class="text-pulse" data-toggle="theme" data-theme="assets/css/themes/pulse.min.css" href="javascript:void(0)">-->
      <!--<i class="fa fa-2x fa-circle"></i>-->
      <!--</a>-->
      <!--</div>-->
      <!--<div class="col-4 mb-5">-->
      <!--<a class="text-flat" data-toggle="theme" data-theme="assets/css/themes/flat.min.css" href="javascript:void(0)">-->
      <!--<i class="fa fa-2x fa-circle"></i>-->
      <!--</a>-->
      <!--</div>-->
      <!--<div class="col-4 mb-5">-->
      <!--<a class="text-corporate" data-toggle="theme" data-theme="assets/css/themes/corporate.min.css" href="javascript:void(0)">-->
      <!--<i class="fa fa-2x fa-circle"></i>-->
      <!--</a>-->
      <!--</div>-->
      <!--<div class="col-4 mb-5">-->
      <!--<a class="text-earth" data-toggle="theme" data-theme="assets/css/themes/earth.min.css" href="javascript:void(0)">-->
      <!--<i class="fa fa-2x fa-circle"></i>-->
      <!--</a>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div class="dropdown-divider"></div>-->
      <!--<button type="button" class="btn btn-sm btn-block btn-alt-secondary mb-10" data-toggle="layout" data-action="sidebar_style_inverse_toggle">Sidebar Style</button>-->
      <!--<div class="dropdown-divider"></div>-->
      <!--<a class="dropdown-item" href="be_ui_color_themes.html">-->
      <!--<i class="fa fa-paint-brush"></i> All Color Themes-->
      <!--</a>-->
      <!--</div>-->
      <!--</div>-->
      <!-- END Color Themes -->
    </div>
    <!-- END Left Section -->

    <!-- Right Section -->
    <div class="content-header-section">
      <!-- User Dropdown -->
      <!--<div class="btn-group" role="group">-->
      <!--<button type="button" class="btn btn-rounded btn-dual-secondary" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
      <!--J. Smith<i class="fa fa-angle-down ml-5"></i>-->
      <!--</button>-->
      <!--<div class="dropdown-menu dropdown-menu-right min-width-150" aria-labelledby="page-header-user-dropdown">-->
      <!--<a class="dropdown-item" href="be_pages_generic_profile.html">-->
      <!--<i class="si si-user mr-5"></i> Profile-->
      <!--</a>-->
      <!--<a class="dropdown-item d-flex align-items-center justify-content-between" href="be_pages_generic_inbox.html">-->
      <!--<span><i class="si si-envelope-open mr-5"></i> Inbox</span>-->
      <!--<span class="badge badge-primary">3</span>-->
      <!--</a>-->
      <!--<a class="dropdown-item" href="be_pages_generic_invoice.html">-->
      <!--<i class="si si-note mr-5"></i> Invoices-->
      <!--</a>-->
      <!--<div class="dropdown-divider"></div>-->

      <!--&lt;!&ndash; Toggle Side Overlay &ndash;&gt;-->
      <!--&lt;!&ndash; Layout API, functionality initialized in Codebase() -> uiApiLayout() &ndash;&gt;-->
      <!--<a class="dropdown-item" href="javascript:void(0)" data-toggle="layout" data-action="side_overlay_toggle">-->
      <!--<i class="si si-wrench mr-5"></i> Settings-->
      <!--</a>-->
      <!--&lt;!&ndash; END Side Overlay &ndash;&gt;-->

      <!--<div class="dropdown-divider"></div>-->
      <!--<a class="dropdown-item" href="op_auth_signin.html">-->
      <!--<i class="si si-logout mr-5"></i> Sign Out-->
      <!--</a>-->
      <!--</div>-->
      <!--</div>-->
      <!-- END User Dropdown -->

      <!-- Toggle Side Overlay -->
      <!-- Layout API, functionality initialized in Codebase() -> uiApiLayout() -->
      <!--<button type="button" class="btn btn-circle btn-dual-secondary" data-toggle="layout" data-action="side_overlay_toggle">-->
      <!--<i class="fa fa-tasks"></i>-->
      <!--</button>-->
      <!-- END Toggle Side Overlay -->
    </div>
    <!-- END Right Section -->
  </div>
  <!-- END Header Content -->

  <!-- Header Search -->
  <div id="page-header-search" class="overlay-header">
    <div class="content-header content-header-fullrow">
      <form action="javascript:void(0)" method="post">
        <div class="input-group">
          <div class="input-group-prepend">
            <!-- Close Search Section -->
            <!-- Layout API, functionality initialized in Codebase() -> uiApiLayout() -->
            <button type="button" class="btn btn-secondary" data-toggle="layout" data-action="header_search_off">
              <i class="fa fa-times"></i>
            </button>
            <!-- END Close Search Section -->
          </div>
          <input type="text" class="form-control" placeholder="Search or hit ESC.." id="page-header-search-input" name="page-header-search-input">
          <div class="input-group-append">
            <button type="submit" class="btn btn-secondary">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- END Header Search -->

  <!-- Header Loader -->
  <app-loading-indicator></app-loading-indicator>
  <!-- END Header Loader -->
</header>
<!-- END Header -->
