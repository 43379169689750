<app-content-header i18n-title title="Destination Information" i18n-subTitle
                    subTitle="Information about {{destinationId}}"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
                    *ngIf="destination != null"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">
  <div class="row">
    <div class="col-6 offset-3">
      <tripmakery-destination-info-destination-edit *ngIf="destination != null" [destination]="destination"></tripmakery-destination-info-destination-edit>
    </div>
  </div>
</div>

