import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LoadingIndicatorService } from '../../../services/loading-indicator/loading-indicator.service';
import { ConfigService } from '../../../services/configservice/config.service';
import { EventsService } from '../../../services/events/events.service';
import { BreadCrumbElement } from '../../../shared/entities/breadcrumb.element.entity';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-bounces-details',
  templateUrl: './email-bounces-details.component.html'
})
export class EmailBouncesDetailsComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public bounceId: string;

  public bounceDetails: any;
  public relatedHousing: any;
  public housingsWithMail: any;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private router: ActivatedRoute,
              private http: HttpClient) {

    /* */
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Developer', 'javascript:void(0)'),
      new BreadCrumbElement('Email Bounces', 'javascript:void(0)')
    ];
  }

  ngOnInit() {
    this.bounceId = this.router.snapshot.params['id'];
    this.loadEmailBounceDetails();
  }

  public loadEmailBounceDetails() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/email-bounces/' + this.bounceId)
      .subscribe((response: any) => {

          /* */
          this.breadCrumbElements = [
            new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
            new BreadCrumbElement('Developer', 'javascript:void(0)'),
            new BreadCrumbElement('Email Bounces', 'javascript:void(0)'),
            new BreadCrumbElement(response.bounce._id, 'javascript:void(0)')
          ];

          response.bounce.rawMessageStringified = JSON.stringify(response.bounce.rawMessage, null, 2);

          /* */
          this.bounceDetails = response.bounce;
          this.relatedHousing = response.relatedHousing;
          this.housingsWithMail = response.housingsWithMail;

          /* */
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        }
      );
  }

  public markAsDone(id: string) {
    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/email-bounces/' + id + '/simple-edit', {
      state: 'DONE',
      action: 'SIMPLE'
    })
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

}
