import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private userId: string;
  private loggedIn: boolean = false;
  private token: string;
  private roles: Array<string> = [];

  constructor() {
  }

  public setUserId(s: string) {
    this.userId = s;
    return this;
  }

  public getUserId() {
    return this.userId
  }

  public isLoggedIn(): boolean {
    return this.loggedIn;
  }

  public setIsLoggedIn(b: boolean) {
    this.loggedIn = b;
    return this;
  }

  public setToken(s: string) {
    this.token = s;
    return this;
  }

  public getToken() {
    return this.token;
  }

  public setRoles(s: Array<string>) {
    this.roles = s;
    return this;
  }

  public getRoles() {
    return this.roles;
  }

}
