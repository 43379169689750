<app-content-header i18n-title title="User Search" i18n-subTitle
                    subTitle="Filter our User DB"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">

  <div class="row">
    <div class="col-md-3">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>
        <div class="block-content block-content-full">
          <form [formGroup]="form">
            <div class="row">

              <div class="col-md-12">

                <div class="form-group row">
                  <label class="col-12" for="email">Email</label>
                  <div class="col-md-12">
                    <input type="text" class="form-control" id="email" name="email"
                           placeholder="Email"
                           autocomplete="off"
                           formControlName="email"
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12" for="firstName">First Name</label>
                  <div class="col-md-12">
                    <input type="text" class="form-control" id="firstName" name="firstName"
                           placeholder="First Name"
                           autocomplete="off"
                           formControlName="firstName"
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12" for="lastName">Last Name</label>
                  <div class="col-md-12">
                    <input type="text" class="form-control" id="lastName" name="lastName"
                           placeholder="Last Name"
                           autocomplete="off"
                           formControlName="lastName"
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-12">
                    <hr>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-12">
                    <div class="custom-control custom-checkbox custom-control-inline mb-5"
                    >
                      <input class="custom-control-input" type="checkbox" name="isB2BUser"
                             id="isB2BUser"
                             formControlName="isB2BUser">
                      <label class="custom-control-label" for="isB2BUser">Pro User only</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-12">
                    <div class="custom-control custom-checkbox custom-control-inline mb-5"
                    >
                      <input class="custom-control-input" type="checkbox" name="isHousingUserAccount"
                             id="isHousingUserAccount"
                             formControlName="isHousingUserAccount">
                      <label class="custom-control-label" for="isHousingUserAccount">Housing Account only</label>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <hr>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">&nbsp;</div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <button class="btn btn-alt-success" (click)="submitForm()">Submit</button>
              </div>
            </div>

          </form>
        </div>
      </div>

    </div>

    <div class="col-md-9">
      <div class="block" *ngIf="paginationData != null">
        <div class="block-header block-header-default">
          <h3 class="block-title">{{userTotal}} Users</h3>
          <label>Add new User</label>&nbsp;<button class="btn btn-sm btn-success" (click)="showAddUserModal()" tooltip="Add a new user"><i class="fa fa-plus"></i></button>
        </div>
        <div class="block-content block-content-full">
          <div class="table-responsive">
            <table class="table table-striped table-vcenter">
              <thead>
              <tr>
                <th class="text-center"><i class="fa fa-user"></i></th>
                <th class="text-left">Name</th>
                <th class="text-left">Email</th>
                <th class="text-left">Roles</th>
                <th class="text-center">Actions</th>
              </tr>
              </thead>
              <tr
                *ngFor="let user of users | paginate: {
              itemsPerPage: paginationData.limit,
              currentPage: paginationData.page,
              totalItems: paginationData.total}"
              >
                <td class="text-center"><img class="img-avatar img-avatar48" src="assets/media/avatars/avatar14.jpg"
                                             alt=""></td>
                <td class="text-left">
                  <ng-container *ngIf="user.firstName == null || user.lastName == null"><em>No Name</em></ng-container>
                  <ng-container
                    *ngIf="user.firstName != null && user.lastName != null">{{user.firstName}} {{user.lastName}}</ng-container>
                </td>
                <td class="text-left">{{user.email}}</td>
                <td class="text-left">
                  <ng-container *ngFor="let role of user.roles">
                    <span class="badge badge-success">{{role}}</span>&nbsp;
                  </ng-container>
                </td>
                <td class="text-center">
                  <div class="btn-group">
                      <a class="btn btn-sm btn-primary" [routerLink]="['/users', user._id]"><i class="fa fa-pencil"></i></a>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="block-content block-content-full block-content-sm text-center">
          <pagination-controls [maxSize]="10"
                               (pageChange)="sendPage($event)"
          ></pagination-controls>
        </div>
      </div>


    </div>


  </div>

</div>
<tripmakery-user-add-user-modal #userAddUserModalComponent></tripmakery-user-add-user-modal>
