import { Injectable } from '@angular/core';
import { HousingFilter } from '@tripbakers/enums/dist/enums/housing-filter.enum';

@Injectable({
  providedIn: 'root'
})
export class HousingRequestFilterTranslationService {

  constructor() {
  }

  public translateHousingRequestFilterToReadableString(h: HousingFilter) {
    const translationDb = {
      [HousingFilter.Budget]: 'Group Budget',
      [HousingFilter.BudgetDetailed]: 'Group Budget',
      [HousingFilter.HousingClosed]: 'Closing times',
      [HousingFilter.ForbiddenRoomTypes]: 'Excluded room types',
      [HousingFilter.ForbiddenRoomTypeCombinations]: 'Excluded room type combinations',
      [HousingFilter.AcceptedRoomTypes]: 'Accepted room types',
      [HousingFilter.ForbiddenGroupTypes]: 'Group type',
      [HousingFilter.MinLos]: 'Minimum stay',
      [HousingFilter.ArrivalDepartureDays]: 'Fixed arrival/departure days',
      [HousingFilter.PreferredBoardingTypes]: 'Boarding',
      [HousingFilter.LeadTime]: 'Lead time',
      [HousingFilter.AmountOfRooms]: 'Number of rooms',
      [HousingFilter.AmountOfPersons]: 'Group size',
      [HousingFilter.MeetingRooms]: 'Meeting room',
      [HousingFilter.GroupRooms]: 'Group room',
      [HousingFilter.BusParkings]: 'Bus Parking',
      [HousingFilter.MinAge]: 'Minimum age',
      [HousingFilter.AccessibleRooms]: 'Accessible rooms',
    };

    return translationDb[h];
  }

  public translateHousingRequestFilterToReadablePartnerURL(h: HousingFilter) {
    const translationDb = {
      [HousingFilter.Budget]: 'budget-detailed',
      [HousingFilter.BudgetDetailed]: 'budget-detailed',
      [HousingFilter.HousingClosed]: 'housing-closed',
      [HousingFilter.ForbiddenRoomTypes]: 'forbidden-room-type-combinations',
      [HousingFilter.ForbiddenRoomTypeCombinations]: 'forbidden-room-type-combinations',
      [HousingFilter.AcceptedRoomTypes]: 'accepted-room-types',
      [HousingFilter.ForbiddenGroupTypes]: 'forbidden-group-types',
      [HousingFilter.MinLos]: 'min-los',
      [HousingFilter.ArrivalDepartureDays]: 'arrival-departure-days',
      [HousingFilter.PreferredBoardingTypes]: 'preferred-boarding-types',
      [HousingFilter.LeadTime]: 'lead-time',
      [HousingFilter.AmountOfRooms]: 'amount-of-rooms',
      [HousingFilter.AmountOfPersons]: 'amount-of-persons',
      [HousingFilter.MeetingRooms]: 'meeting-room',
      [HousingFilter.GroupRooms]: 'group-room',
      [HousingFilter.BusParkings]: 'bus-parking',
      [HousingFilter.MinAge]: 'min-age',
      [HousingFilter.AccessibleRooms]: 'accessible-rooms',
    };

    return translationDb[h];
  }
}
