import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as moment from "moment";

@Component({
  selector: 'tripmakery-housing-info-overview-housing-requests',
  templateUrl: './housing-requests.component.html',
  styleUrls: ['./housing-requests.component.css']
})
export class HousingRequestsComponent implements OnInit {

  constructor(private http: HttpClient) {
  }

  @Input() public housingId;
  public section = 'housingRequests';

  public housingRequests;
  public paginationData;
  public currentPage = 1;

  ngOnInit() {
    const httpParams = this.createParams();
    this.requestBookingDataFromBackend(httpParams);
  }

  public createParams() {
    let params = new HttpParams();
    params = params.append('page', this.currentPage.toString());
    params = params.append('limit', '15');
    return params;
  }

  public requestBookingDataFromBackend(httpParams) {
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/housing-requests', {params: httpParams})
      .subscribe(
        (response: any) => {

          /* */
          for (const housingRequest of response.elements) {
            housingRequest.created = moment(housingRequest.created).locale("de").format("L")
          }

          /* */
          this.housingRequests = response.elements;
          this.paginationData = response.pagination;
        }
      );
  }

  public sendPage(page: number) {
    this.currentPage = page;
    const changePageParams = this.createParams();
    this.requestBookingDataFromBackend(changePageParams);
  }

}
