import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {HousingType} from "@tripbakers/enums";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {HttpClient} from "@angular/common/http";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";
import {GoogleLocationMapper} from "../../../../services/googlemapper/google-location-mapper.service";
import {EventsService} from "../../../../services/events/events.service";
import {environment} from "../../../../../environments/environment";
import {Router} from "@angular/router";
import {ModalDirective} from "ngx-bootstrap";
import { FieldsBasicPlaceDetails } from '../../../../shared/consts/fields-basic-place-details';

@Component({
  selector: 'app-housing-add-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css']
})
export class HousingAddBasicInfoComponent implements OnInit {
  @ViewChild('childModal')
  public childModal: ModalDirective;

  form: FormGroup;
  formExtraUrls: FormGroup;
  housing: any = {};
  housingTypes: Array<string>;
  public options = {
    fields: FieldsBasicPlaceDetails,
  };
  public housingLocation = {};
  public cityLocation = {};
  public googleLocationMapper: GoogleLocationMapper;
  public isLoading: boolean = false;
  public hasHousingError: boolean = false;
  public housingInDb: any;
  public googleAddress: Address;
  public googleCity: Address;
  public Environment;

  /* */
  private housingDataBooking: any;
  private housingDataTripadvisor: any;
  private housingDataTrivago: any;

  constructor(private http: HttpClient,
              private router: Router,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.googleLocationMapper = new GoogleLocationMapper();
    this.housingTypes = Object.values(HousingType);
    this.Environment = environment;

    this.form = new FormGroup({
      'name': new FormControl({value: '', disabled: false}),
      'email': new FormControl({value: '', disabled: false}),
      'phone': new FormControl({value: '', disabled: false}),
      'homepage': new FormControl({value: '', disabled: false}),
      'stars': new FormControl({value: '', disabled: false}),
      'displayStarsAsPoints': new FormControl({value: false, disabled: false}),
      'price': new FormControl({value: '', disabled: false}),
      'checkIn': new FormControl({value: '', disabled: false}),
      'checkOut': new FormControl({value: '', disabled: false}),
      'isPartner': new FormControl({value: false, disabled: false}),
      'isTopPartner': new FormControl({value: false, disabled: false}),
      'isExtranetPartner': new FormControl({value: false, disabled: false}),
      'marketplace': new FormControl({value: false, disabled: false}),
      'city': new FormControl({value: '', disabled: false}),
      'address': new FormControl({value: '', disabled: false}),
      'numberOfRooms': new FormControl({value: '', disabled: false}),
      'distanceToCityCenter': new FormControl({value: '', disabled: false}),
      'priceCalculationLockedByAdmin': new FormControl({value: false, disabled: false}),
    });

    this.formExtraUrls = new FormGroup({
      'bookingUrl': new FormControl({value: '', disabled: false}),
      'trivagoUrl': new FormControl({value: '', disabled: false}),
      'tripadvisorUrl': new FormControl({value: '', disabled: false}),
    });

    for (const housingType of this.housingTypes) {
      this.form.addControl('housingType_' + housingType, new FormControl({value: false, disabled: false}));
    }
  }

  public showHelpModal() {
    this.childModal.show();
  }

  onSubmitForm() {
    if (this.form.status !== 'VALID' || this.googleCity == null || this.cityLocation == null) {
      return;
    }
    this.showLoading();

    this.postData().subscribe(
      (response: any) => {
        this.hideLoading();
        this.router.navigate(['housings', response._id, 'details'])
      },
      err => {
        console.log("Catched an error", err);

        if (err.error.message === "E_VALIDATION_FAILED") {
          console.log("Just a validation Error");
          try {
            for (const error of err.error.errors) {
              this.form.get(error.property).setErrors(error.constraints);
              this.form.get(error.property).markAsDirty();

              console.log(error.property, error.constraints);
            }
          } catch (error) {
            console.log("Catched E", error)
          }
          this.hideLoading();
          return;
        } else {
          if (err.error.error === 'E_GOOGLE_PLACE_ID_ALREADY_IN_USE') {
            this.housingInDb = err.error.housing;
            this.hasHousingError = true;
            this.hideLoading();
            return;
          }
        }

        this.hideLoading();
        this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));
      }
    )
  }

  private postData() {

    const body = {
      name: this.form.get('name').value,
      email: this.form.get('email').value,
      phone: this.form.get('phone').value,
      homepage: this.form.get('homepage').value,
      stars: this.createNullIfEmptyStringOrReturnFloat(this.form.get('stars').value),
      displayStarsAsPoints: this.form.get('displayStarsAsPoints').value === true,
      price: this.createNullIfEmptyStringOrReturnFloat(this.form.get('price').value),
      checkIn: this.form.get('checkIn').value,
      checkOut: this.form.get('checkOut').value,
      isPartner: this.form.get('isPartner').value,
      isTopPartner: this.form.get('isTopPartner').value,
      isExtranetPartner: this.form.get('isExtranetPartner').value,
      marketplace: this.form.get('marketplace').value,
      city: this.form.get('city').value,
      address: this.form.get('address').value,
      numberOfRooms: this.createNullIfEmptyStringOrReturnFloat(this.form.get('numberOfRooms').value),
      distanceToCityCenter: this.form.get('distanceToCityCenter').value,
      priceCalculationLockedByAdmin: this.form.get('priceCalculationLockedByAdmin').value,
    };
    body["housingLocation"] = this.housingLocation;

    const selectedHousingTypes = [];
    for (const housingType of this.housingTypes) {
      if (this.form.get('housingType_' + housingType).value === true) {
        selectedHousingTypes.push(housingType);
      }
    }
    body["types"] = selectedHousingTypes;

    body["housingDataBooking"] = this.housingDataBooking;
    body["housingDataTrivago"] = this.housingDataTrivago;
    body["housingDataTripadvisor"] = this.housingDataTripadvisor;

    return this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing', body);
  }

  public handleAddressChangeOfCity(address: Address) {
    /* */
    this.googleCity = address;

    /* */
    if (this.housing.location == null) {
      this.housing.location = {};
      this.housing.location.locationGooglePlaceIds = [];
    }
    this.housing.location.cityGooglePlaceId = address.place_id;
    const position = this.housing.location.locationGooglePlaceIds.indexOf(this.housing.location.cityGooglePlaceId);
    if (position === -1) {
      this.housing.location.locationGooglePlaceIds.push(this.housing.location.cityGooglePlaceId);
    }

    /* */
    const mappedLocation = this.googleLocationMapper.map(address);
    this.housing.location.city = mappedLocation.city;

    this.calculateDistanceIfPossibleAndSetValue();
  }

  public handleAddressChangeOfAddress(address: Address) {
    this.googleAddress = address;

    const oldHousingLocation = this.housingLocation;

    this.housingLocation = this.googleLocationMapper.map(address);
    this.housingLocation["cityGooglePlaceId"] = (this.housing.location) ? this.housing.location.cityGooglePlaceId : null;
    this.housingLocation["locationGooglePlaceIds"] = (this.housing.location) ? this.housing.location.locationGooglePlaceIds : null;

    if (this.housingLocation["city"] == null && oldHousingLocation["city"] != null) {
      this.housingLocation["city"] = oldHousingLocation["city"];
    }

    this.calculateDistanceIfPossibleAndSetValue();
    console.log(this.housingLocation);
  }


  ngOnInit() {
    this.housingInDb = null;
    this.hasHousingError = false;
  }

  private enableDisableFormCtrl() {
    const enable = (this.housing != null);
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (enable) {
          this.form.controls[key].enable();
        } else {
          this.form.controls[key].disable();
        }
      }
    }
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  private createEmptyStringIfNull(s: string) {
    if (s == null) {
      return '';
    }

    return s;
  }

  private createNullIfEmptyStringOrReturnFloat(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return null;
    }
    if (typeof s === "number") {
      return s;
    }
    const d = parseFloat(s);

    if (isNaN(d)) {
      return s;
    }

    return d;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

  private calculateDistanceIfPossibleAndSetValue(): number {
    if (this.googleCity == null || this.googleAddress == null) {
      return;
    }

    const val = this.calculateDistanceBetweenTwoPoints(this.googleCity.geometry.location.lng(), this.googleCity.geometry.location.lat(),
      this.googleAddress.geometry.location.lng(), this.googleAddress.geometry.location.lat());

    this.form.get('distanceToCityCenter').setValue(val);
  }

  private calculateDistanceBetweenTwoPoints(lon1: number, lat1: number,
                                            lon2: number, lat2: number) {
    const p = 0.017453292519943295;    // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((lat2 - lat1) * p) / 2 +
      c(lat1 * p) * c(lat2 * p) *
      (1 - c((lon2 - lon1) * p)) / 2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }

  public saveBookingData(data: any) {
    this.mergeBookingDataToForm(data);
    this.housingDataBooking = data;
  }

  mergeBookingDataToForm(data: any) {
    this.addToFormIfNotSetAlreadyAndValueNotNull('name', data.name);
    this.addToFormIfNotSetAlreadyAndValueNotNull('stars', data.stars);
    this.addToFormIfNotSetAlreadyAndValueNotNull('checkIn', data.checkIn);
    this.addToFormIfNotSetAlreadyAndValueNotNull('checkOut', data.checkOut);
    if (data.type != null && Array.isArray(data.type)) {
      for (const type of data.type) {
        this.addToFormIfNotSetAlreadyAndValueNotNull('housingType_' + type, true);
      }
    }
  }

  public saveTrivagoData(data: any) {
    this.mergeTrivagoDataToForm(data);
    this.housingDataTrivago = data;
  }

  mergeTrivagoDataToForm(data: any) {
    this.addToFormIfNotSetAlreadyAndValueNotNull('name', data.name);
    this.addToFormIfNotSetAlreadyAndValueNotNull('stars', data.stars);
    this.addToFormIfNotSetAlreadyAndValueNotNull('email', data.email);
    this.addToFormIfNotSetAlreadyAndValueNotNull('phone', data.phone);
    this.addToFormIfNotSetAlreadyAndValueNotNull('checkIn', data.checkIn);
    this.addToFormIfNotSetAlreadyAndValueNotNull('checkOut', data.checkOut);
    this.addToFormIfNotSetAlreadyAndValueNotNull('city', data.location.city);
    this.addToFormIfNotSetAlreadyAndValueNotNull('address', data.location.street);
    this.addToFormIfNotSetAlreadyAndValueNotNull('numberOfRooms', data.numberOfRooms);
    if (data.type != null && Array.isArray(data.type)) {
      for (const type of data.type) {
        this.addToFormIfNotSetAlreadyAndValueNotNull('housingType_' + type, true);
      }
    }
  }

  public saveTripadvisorData(data: any) {
    this.mergeTripadvisorDataToForm(data);
    this.housingDataTripadvisor = data;
  }

  mergeTripadvisorDataToForm(data: any) {
    this.addToFormIfNotSetAlreadyAndValueNotNull('name', data.name);
    this.addToFormIfNotSetAlreadyAndValueNotNull('stars', data.stars);
    this.addToFormIfNotSetAlreadyAndValueNotNull('email', data.email);
    this.addToFormIfNotSetAlreadyAndValueNotNull('phone', data.phone);
    this.addToFormIfNotSetAlreadyAndValueNotNull('address', data.location.street);
    this.addToFormIfNotSetAlreadyAndValueNotNull('numberOfRooms', data.numberOfRooms);
    this.addToFormIfNotSetAlreadyAndValueNotNull('price', data.price);
    if (data.type != null && Array.isArray(data.type)) {
      for (const type of data.type) {
        this.addToFormIfNotSetAlreadyAndValueNotNull('housingType_' + type, true);
      }
    }
  }

  addToFormIfNotSetAlreadyAndValueNotNull(fieldName: string,
                                          value: string | boolean) {
    const formVal = this.form.get(fieldName).value;

    if (formVal != null &&
      (typeof formVal === 'boolean' ? this.form.get(fieldName).value === false : true) &&
      (typeof formVal === 'string' ? this.form.get(fieldName).value.trim() == '' : true)) {
      this.form.get(fieldName).setValue(value);
    }
  }
}
