<div class="col-xl-12" *ngIf="paginationData != null">
  <h2 class="content-heading">Latest Bookings</h2>

  <!-- -->
  <div class="block block-rounded" *ngIf="bookings != null && bookings.length > 0">
    <div class="block-content">
      <div class="table-responsive">
        <table class="table table-borderless table-striped">
          <thead>
          <tr>
            <th>Booking</th>
            <th>Request</th>
            <th>User</th>
            <th>Date Range</th>
            <th>Value</th>
            <th>Created</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let booking of bookings | paginate: {
              id: 'ctrls_dp_' + section,
              itemsPerPage: paginationData.limit,
              currentPage: paginationData.page,
              totalItems: paginationData.total}">
            <td>
              {{booking._id}}
            </td>
            <td>
              {{booking.request}}
            </td>
            <td>
              {{booking.user}}
            </td>
            <td>
              @TODO DateRange
            </td>
            <td>
              @TODO €
            </td>
            <td class="d-none d-sm-table-cell">
              {{booking.created}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
      <pagination-controls
        id="ctrls_dp_{{section}}"
        [maxSize]="paginationData.limit"
        (pageChange)="sendPage($event)"
      ></pagination-controls>
    </div>
  </div>

  <!-- -->
  <div class="block block-rounded" *ngIf="bookings == null || bookings.length === 0">
    <div class="block-content block-content-full text-center">
      No results to display
    </div>
  </div>

</div>
