<div bsModal #childModal="bs-modal" [config]="bsConfig" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-popin"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" bsModal>
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">Which message should be sent to the partner?</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" data-dismiss="modal" aria-label="Close"
                    (click)="hideChildModal()">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content" *ngIf="offerMessageData != null">
          <form [formGroup]="form">

            <div class="row">
              <label class="col-3">RequestId</label>
              <div class="col-4">
                {{offerMessageData.requestId}}
              </div>
              <div class="col-5 mb-5">
                <a class="btn btn-sm btn-alt-secondary"
                   href="{{offerMessageData.frontend.urlUserRequest}}"
                   target="_blank">User</a>&nbsp;
                <a class="btn btn-sm btn-alt-secondary"
                   href="{{environment_.urls.kitchen}}/pipeline/untouched?source=search&requestId={{offerMessageData.requestId}}"
                   target="_blank">Kitchen</a>
              </div>
            </div>
            <div class="row">
              <label class="col-3">OfferId</label>
              <div class="col-4">
                {{offerMessageData.offerId}}
              </div>
              <div class="col-5">
                <a class="btn btn-sm btn-alt-secondary"
                   href="{{offerMessageData.frontend.urlUserOffer}}"
                   target="_blank">User</a>&nbsp;
                <a class="btn btn-sm btn-alt-secondary"
                   href="{{environment_.urls.kitchen}}/requests/{{offerMessageData.requestId}}/offer/{{offerMessageData.offerId}}"
                   target="_blank">Kitchen</a>&nbsp;
                <a class="btn btn-sm btn-alt-secondary"
                   href="{{environment_.urls.partner}}/h/{{offerMessageData.housingId}}/offer-detail/{{offerMessageData.preOfferId}}?admin=true"
                   target="_blank">Partner</a>
              </div>
            </div>
            <div class="row">
              <label class="col-3">AutoOffer</label>
              <div class="col-4">
                {{offerMessageData.isAutoOffer}}
              </div>
              <div class="col-5">

              </div>
            </div>
            <div class="row">
              <label class="col-3">CreatedAt</label>
              <div class="col-4">
                {{offerMessageData.message.created}}
              </div>
              <div class="col-2">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div>
                  <tabset>
                    <tab heading="Message to partner" id="tab1">
                      <div class="block-content-full block-content">
      <textarea class="form-control" id="adminText" name="adminText" rows="6"
                placeholder="Content.."
                formControlName="adminText">{{offerMessageData.adminText}}</textarea>
                      </div>
                    </tab>
                  </tabset>
                </div>

              </div>
            </div>
            <div class="row" [hidden]="offerMessageData.state === 'NEW'">
              <div class="col-12">
                <hr>
              </div>
            </div>

            <div class="row" [hidden]="offerMessageData.state === 'NEW'">
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-7">New State</label>
                  <div class="col-5">
                    <select class="form-control" formControlName="state">
                      <option value="NEW">New (Team not notified)</option>
                      <option value="WAITING_FOR_TEAM">New (Team notified)</option>
                      <option value="SENDING_EMAIL_FROM_TEAM">Send Email (Partner)</option>
                      <option value="SENDING_EMAIL_FROM_TEAM_FAILED">Send Email Failed (Partner)</option>
                      <option value="WAITING_FOR_RESPONSE">Waiting for Response</option>
                      <option value="FINISHED">Finished</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>
      <div class="modal-footer" *ngIf="offerMessageData != null">
        <button type="button" class="btn btn-alt-secondary" data-dismiss="modal" (click)="hideChildModal()">Abort
        </button>
        <button type="button" class="btn btn-alt-success" (click)="handleClick()">
          <i class="fa fa-check"></i>
          Save
        </button>
      </div>
    </div>
  </div>
</div>

