<app-content-header i18n-title title="Email Bounces Details" i18n-subTitle
                    subTitle="Detailed information about specific email bounces"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>
<div class="content">
  <div class="row" *ngIf="bounceDetails != null">

    <!-- meta -->
    <div class="col-md-3">

      <div class="row">

        <div class="col-md-12">
          <div class="block block-bordered">
            <div class="block-header block-header-default">
              <h3 class="block-title">Meta Information</h3>
            </div>

            <!-- -->
            <div class="block-content block-content-full">
              <div class="row">
                <ul>
                  <li>Notification Type: {{bounceDetails.notificationDetails?.notificationType}}</li>
                  <li>Email: {{bounceDetails.email}}</li>
                  <li>RequestId: {{bounceDetails.meta.requestId}}</li>
                  <li>HousingId: {{bounceDetails.meta.housingId}}</li>
                  <li>Sender: {{bounceDetails.meta.sender}}</li>
                  <li>Mail Type: {{bounceDetails.meta.mailType}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="block block-bordered">
            <div class="block-header block-header-default">
              <h3 class="block-title">Actions</h3>
            </div>

            <!-- -->
            <div class="block-content block-content-full">
              <button class="btn btn-lg btn-success col-12" (click)="markAsDone(bounceDetails._id)">Done</button>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- Details -->
    <div class="col-md-9">

      <div class="row">

        <div class="col-md-12">

          <div class="block block-bordered">
            <div class="block-header block-header-default">
              <h3 class="block-title">Bounce Details</h3>
            </div>

            <!-- -->
            <div class="block-content block-content-full">
              <h1 class="h5">Issue description</h1>
              <ng-container *ngIf="bounceDetails.bounceDetails.detectedIssue === 'ADDRESS_DOESNT_EXIST'">
                <p>We received the response, that this email address <strong>doesn't exist</strong>.</p>
              </ng-container>
              <ng-container *ngIf="bounceDetails.bounceDetails.detectedIssue === 'UNKNOWN'">
                <p>We received <strong>an unknown error</strong> while trying to send this email.</p>
              </ng-container>
              <ng-container *ngIf="bounceDetails.bounceDetails.detectedIssue === 'MAIL_SERVER_NOT_FOUND'">
                <p>We received the response, that the email server of this hotel <strong>is not reachable</strong>.</p>
              </ng-container>
              <ng-container *ngIf="bounceDetails.bounceDetails.detectedIssue === 'TECHNICAL_PROBLEMS'">
                <p>We received the response, that there are <strong>technical problems</strong> while trying to send
                  this email.<br>
                  This issue can be a <strong>temporary problem</strong>. You might need to check the technical bounce
                  details below if this issue persists.</p>
              </ng-container>
              <ng-container *ngIf="bounceDetails.bounceDetails.detectedIssue === 'MAILBOX_FULL'">
                <p>We received the response, that the receivers <strong>mailbox is full</strong>.</p>
              </ng-container>
              <ng-container *ngIf="bounceDetails.bounceDetails.detectedIssue === 'BLOCK_SPAMFILTER'">
                <p>We received the response, that our mail was <strong>blocked by a spamfilter</strong>.</p>
              </ng-container>
              <ng-container *ngIf="bounceDetails.bounceDetails.detectedIssue === 'BLOCK_BLACKLIST'">
                <p>We received the response, that our email address was <strong>blocked by a blacklist</strong>.</p>
              </ng-container>
              <ng-container *ngIf="bounceDetails.bounceDetails.detectedIssue === 'AUTO_ANSWER'">
                <p>We received the response, that our mail was <strong>answered automatically</strong>.</p>
              </ng-container>
              <h1 class="h5">Next steps</h1>
              <ul>
                <li *ngFor="let action of bounceDetails.actions.suggested">
                  <ng-container *ngIf="action === 'CHECK_HOUSING_EXISTS'">Please check if the housing still exists (i.e.
                    Website reachable, Booking)
                  </ng-container>
                  <ng-container *ngIf="action === 'CHECK_MAIL_ADDRESS'">Please check if the email address is correct
                    (i.e. Check Website)
                  </ng-container>
                  <ng-container *ngIf="action === 'CONTACT_HOUSING'">Get in touch with the housing to solve this issue
                  </ng-container>
                  <ng-container *ngIf="action === 'REQUEST_WHITELIST'">Get in touch with the housing and request a
                    whitelist for our addresses
                  </ng-container>
                  <ng-container *ngIf="action === 'CHECK_HOTELIER_UNAVAILABLE'">Please check if the hotelier is
                    unavailable
                  </ng-container>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-12">

          <div class="block block-bordered">
            <div class="block-header block-header-default">
              <h3 class="block-title">Technical Bounce Details</h3>
            </div>

            <!-- -->
            <div class="block-content block-content-full">
              <div class="row">
                <div class="col-md-12">
                  <strong>Bounce Type</strong>
                </div>
                <div class="col-md-12">
                  <code>{{bounceDetails.bounceDetails?.bounceType}}</code>
                </div>
                <div class="col-md-12">
                  <strong>Bounce Sub Type</strong>
                </div>
                <div class="col-md-12">
                  <code>{{bounceDetails.bounceDetails?.bounceSubType}}</code>
                </div>
                <div class="col-md-12">
                  <strong>Action</strong>
                </div>
                <div class="col-md-12">
                  <code>{{bounceDetails.bounceDetails?.action}}</code>
                </div>
                <div class="col-md-12">
                  <strong>Status</strong>
                </div>
                <div class="col-md-12">
                  <code>{{bounceDetails.bounceDetails?.status}}</code>
                </div>
                <div class="col-md-12">
                  <strong>Diagnostic Code</strong>
                </div>
                <div class="col-md-12">
                  <code>{{bounceDetails.bounceDetails?.diagnosticCode}}</code>
                </div>
                <div class="col-md-12">
                  <strong>Detected Issue</strong>
                </div>
                <div class="col-md-12">
                  <code>{{bounceDetails.bounceDetails?.detectedIssue}}</code>
                </div>
                <div class="col-md-12">
                  <hr>
                </div>
                <div class="col-md-12" *ngIf="bounceDetails.source !== 'GOOGLE'">
                  <strong>Raw message</strong>
                </div>
                <div class="col-md-12" *ngIf="bounceDetails.source !== 'GOOGLE'">
                  <code style="white-space: pre-wrap">{{bounceDetails.rawMessageStringified}}</code>
                </div>
                <div class="col-md-12" *ngIf="bounceDetails.source === 'GOOGLE'">
                  <strong>Snippet</strong>
                </div>
                <div class="col-md-12" *ngIf="bounceDetails.source === 'GOOGLE'">
                  <code style="white-space: pre-wrap">{{bounceDetails.googleResponse.snippet}}</code>
                </div>
                <div class="col-md-12" *ngIf="bounceDetails.source === 'GOOGLE'">
                  <strong>Encoded</strong>
                </div>
                <div class="col-md-12" *ngIf="bounceDetails.source === 'GOOGLE'">
                  <code style="white-space: pre-wrap">{{bounceDetails.googleResponse.encoded}}</code>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
