import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";
import {LoadingIndicatorService} from "../../../../../services/loading-indicator/loading-indicator.service";
import {HousingInfoService} from "../../../../../services/housing-info/housing-info.service";
import {EventsService} from "../../../../../services/events/events.service";

@Component({
  selector: 'app-housing-info-details-distances',
  templateUrl: './distances.component.html',
  styleUrls: ['./distances.component.css']
})
export class HousingInfoDetailsDistancesComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  form: FormGroup;
  isLoading: boolean = false;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'cityCenter': new FormControl({value: '', disabled: true}),
      'publicTransport': new FormControl({value: '', disabled: true}),
      'airport': new FormControl({value: '', disabled: true}),
      'beach': new FormControl({value: '', disabled: true}),
      'highway': new FormControl({value: '', disabled: true}),
      'slope': new FormControl({value: '', disabled: true})
    });
  }

  onSubmitForm() {
    if (this.form.status !== 'VALID') {
      return;
    }
    this.showLoading();

    this.postData().subscribe(
      (response: any) => {
        this.hideLoading();
        this.housingInfoService.setSelectedHousing(response);
      },
      err => {

        if (err.error.message === "E_VALIDATION_FAILED") {
          try {
            for (const error of err.error.errors) {
              this.form.get(error.property).setErrors(error.constraints);
              this.form.get(error.property).markAsDirty();
            }
          } catch (error) {
            console.log("Catched E", error)
          }
          this.hideLoading();
          return;
        }

        this.hideLoading();
        this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));
      }
    )
  }

  private postData() {
    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/distances', {
      cityCenter: this.createNullIfEmptyStringOrReturnFloat(this.form.get('cityCenter').value),
      publicTransport: this.createNullIfEmptyStringOrReturnFloat(this.form.get('publicTransport').value),
      airport: this.createNullIfEmptyStringOrReturnFloat(this.form.get('airport').value),
      beach: this.createNullIfEmptyStringOrReturnFloat(this.form.get('beach').value),
      highway: this.createNullIfEmptyStringOrReturnFloat(this.form.get('highway').value),
      slope: this.createNullIfEmptyStringOrReturnFloat(this.form.get('slope').value),
    });
  }

  private createNullIfEmptyStringOrReturnFloat(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return null;
    }
    const d = parseFloat(s);

    if (isNaN(d)) {
      return s;
    }

    return d;
  }

  ngOnInit() {
    if (this.housing != null) {
      this.mapHousingToForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  private mapHousingToForm() {
    const vals = {
      cityCenter: this.returnEmptyStringWhenNull(this.housing.distance),
      publicTransport: this.returnEmptyStringWhenNull(this.housing.distanceDetails.publicTransport.average),
      airport: this.returnEmptyStringWhenNull(this.housing.distanceDetails.airport.average),
      beach: this.returnEmptyStringWhenNull(this.housing.distanceDetails.beach.average),
      highway: this.returnEmptyStringWhenNull(this.housing.distanceDetails.highway.average),
      slope: this.returnEmptyStringWhenNull(this.housing.distanceDetails.slope.average),
    };

    this.form.setValue(
      vals
    );

    this.enableDisableFormCtrl();
  }

  private enableDisableFormCtrl() {
    const enable = (this.housing != null);
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (enable) {
          this.form.controls[key].enable();
        } else {
          this.form.controls[key].disable();
        }
      }
    }
  }

  public returnEmptyStringWhenNull(element) {
    return (element == null) ? '' : element;
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

}
