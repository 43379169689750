<form [formGroup]="form" (submit)="onSubmitForm()">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Amenities</h3>
      <div class="block-options">
        <button type="button" class="btn btn-sm btn-alt-primary" (click)="toggleVisibility()">
          <i class="fa fa-angle-down" *ngIf="displayForm === false"></i>
          <i class="fa fa-angle-up" *ngIf="displayForm === true"></i>
        </button>
        &nbsp;
        <button type="submit" class="btn btn-sm btn-alt-primary" [disabled]="housing == null || isLoading === true">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
        </button>
      </div>
    </div>
    <div class="block-content block-content-full" *ngIf="displayForm === true">

      <div class="form-group row" *ngFor="let amenity of amenityData">
        <label class="col-12">{{amenity.identifier}}</label>
        <div class="col-12">
          <div class="custom-control custom-checkbox custom-control-inline mb-5"
               *ngFor="let amenity_ of amenity.amenities">
            <input class="custom-control-input" type="checkbox" name="{{amenity_}}"
                   id="{{amenity_}}"
                   formControlName="{{amenity_}}">
            <label class="custom-control-label" for="{{amenity_}}">{{amenity_}}</label>
          </div>
        </div>
      </div>

    </div>
  </div>
</form>
