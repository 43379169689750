<app-content-header i18n-title title="Boundaries" i18n-subTitle
                    subTitle="Edit Boundaries"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">
  <div class="row">
    <div class="col-4">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Boundary Data</h3>
        </div>
        <div class="block-content block-content-full" *ngIf="boundary && boundary.googleResponse != null">
          <div class="row">
            <div class="col-3"><strong>Name</strong></div>
            <div class="col-9">{{boundary.googleResponse.formatted_address}}</div>
          </div>
          <div class="row">
            <div class="col-3"><strong>GPlaceId</strong></div>
            <div class="col-9">{{boundary.googlePlaceId}}</div>
          </div>
          <div class="row">
            <div class="col-3"><strong>GTypes</strong></div>
            <div class="col-9">
              <ul>
                <li *ngFor="let type of boundary.googleResponse.types">{{type}}</li>
              </ul>
            </div>
          </div>
        </div>

        <hr>

        <div class="block-content" *ngIf="boundary ">
          <form [formGroup]="formGeneral" (ngSubmit)="calculateOptimizedBoundary()">
            <div class="form-group row">
              <label class="col-lg-3 col-form-label" for="boundaries-edit-rounds">Rounds</label>
              <div class="col-lg-7">
                <div class="input-group">
                  <input type="text" formControlName="rounds" class="form-control" id="boundaries-edit-rounds"
                         name="boundaries-edit-rounds" placeholder="Email">
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-secondary">Submit</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-3 col-form-label" for="boundaries-edit-margin">Margin</label>
              <div class="col-lg-7">
                <div class="input-group">
                  <input type="text" formControlName="margin" class="form-control" id="boundaries-edit-margin"
                         name="boundaries-edit-margin" placeholder="Margin">
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-secondary">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <hr>

        <div class="block-content" *ngIf="boundary ">
          <form [formGroup]="formValidation">
            <div class="form-group row">
              <div class="col-12">
                <div class="custom-control custom-checkbox mb-5">
                  <input class="custom-control-input" type="checkbox" name="boundaries-edit-validated"
                         id="boundaries-edit-validated"
                         value="option1" checked="" formControlName="validated">
                  <label class="custom-control-label" for="boundaries-edit-validated">Validated</label>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-control custom-checkbox mb-5">
                  <input class="custom-control-input" type="checkbox" name="boundaries-edit-accepted"
                         id="boundaries-edit-accepted"
                         value="option1" checked="" formControlName="accepted">
                  <label class="custom-control-label" for="boundaries-edit-accepted">Accepted</label>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-control custom-checkbox mb-5">
                  <input class="custom-control-input" type="checkbox" name="boundaries-edit-accepted"
                         id="boundaries-edit-customEntry"
                         value="option1" checked="" formControlName="customEntry">
                  <label class="custom-control-label" for="boundaries-edit-customEntry">Custom Entry</label>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-control custom-checkbox mb-5">
                  <input class="custom-control-input" type="checkbox" name="boundaries-edit-accepted"
                         id="boundaries-edit-selectableOnMarketplace"
                         value="option1" checked="" formControlName="selectableOnMarketplace">
                  <label class="custom-control-label" for="boundaries-edit-selectableOnMarketplace">Selectable on marketplace</label>
                </div>
              </div>
            </div>
          </form>
        </div>

        <hr>

        <div class="block-content block-content-full" *ngIf="boundary ">
          <div class="row">
            <div class="col-12 text-center">
              <button class="btn btn-success" (click)="testBoundaryDetailsNew()">Test Boundary</button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center" *ngIf="returnedHousings">
              <small *ngIf="returnedHousings > 0">({{returnedHousings}} Hotels in {{performanceDatabaseQuery}}s)</small>
              <small *ngIf="returnedHousings === -1" class="text-danger">Test Failed!</small>
            </div>
          </div>
        </div>

        <hr>

        <div class="block-content block-content-full" *ngIf="boundary ">
          <div class="row">
            <div class="col-12 text-center">
              <button class="btn btn-success" (click)="patchBoundaryDetails()" [disabled]="returnedHousings === -1" [ngClass]="{'disabled': returnedHousings === -1}">Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col-12">
          <div class="block block-bordered">
            <div class="block-header block-header-default">
              <h3 class="block-title">Original Boundary
                <small>{{amountBoundaryPoints}}</small>
              </h3>
            </div>
            <agm-map [fitBounds]="boundingBox">
              <agm-polygon [paths]="boundaryPaths"></agm-polygon>
            </agm-map>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="block block-bordered">
            <div class="block-header block-header-default">
              <h3 class="block-title">Optimized Boundary
                <small>{{amountBoundaryPointsOptimized}}</small>
              </h3>
            </div>
            <agm-map [fitBounds]="boundingBox">
              <agm-polygon [paths]="boundaryPathsOptimizedGMaps"></agm-polygon>
            </agm-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
