<app-content-header i18n-title title="Offer Change Requests" i18n-subTitle
                    subTitle="An overview of all offer related change requests"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>
<div class="content">
  <div class="row">
    <div class="col-md-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>

        <!-- -->
        <div class="block-content block-content-full">
          <form [formGroup]="form" (ngSubmit)="loadChangeRequestsToCheck()">
            <div class="row">
              <label class="col-12" for="state">State</label>
              <div class="col-12">
                <select class="form-control" formControlName="state" id="state">
                  <option value="">Please select..</option>
                  <option value="NEW">New</option>
                  <option value="IN_PROGRESS">In Progress</option>
                  <option value="FINISHED">Finished</option>
                  <option value="ABORTED">Aborted</option>
                  <option value="DECLINED">Declined by Partner</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <hr>
              </div>
            </div>
            <div class="row">
              <label class="col-12" for="offerChangeRequestId">ChangeRequestId</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="ChangeRequestId"
                       autocomplete="off"
                       id="offerChangeRequestId"
                       formControlName="offerChangeRequestId">
              </div>
            </div>

            <div class="row">
              <label class="col-12" for="requestId">RequestId</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="RequestId"
                       autocomplete="off"
                       id="requestId"
                       formControlName="requestId">
              </div>
            </div>

            <div class="row">
              <label class="col-12" for="offerId">OfferId</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="OfferId"
                       autocomplete="off"
                       id="offerId"
                       formControlName="offerId">
              </div>
            </div>

            <div class="row">
              <label class="col-12" for="offerId">Missing Interaction (days)</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="Amount of days after notification"
                       autocomplete="off"
                       id="missingInteractionAfterDays"
                       formControlName="missingInteractionAfterDays">
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <hr>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <button class="btn btn-info btn-sm" type="submit">Apply Filter</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Change Requests</h3>
        </div>
        <div class="block-content block-content-full">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>Id</th>
                <th>OfferId</th>
                <th>RequestId</th>
                <th>State</th>
                <th>CreatedAt</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody *ngIf="offerChangeRequests != null && pagination != null">
              <tr *ngFor="let changeRequests of offerChangeRequests | paginate: {
              itemsPerPage: pagination.limit,
              currentPage: pagination.page,
              totalItems: pagination.total}"
              >
                <td>{{changeRequests._id}}</td>
                <td>{{changeRequests.offerId}}</td>
                <td>{{changeRequests.requestId}}</td>
                <td>
                  <span class="badge badge-warning" *ngIf="changeRequests.state === 'NEW'">New</span>
                  <span class="badge badge-info" *ngIf="changeRequests.state === 'IN_PROGRESS'">In Progress</span>
                  <span class="badge badge-success" *ngIf="changeRequests.state === 'FINISHED'">Finished</span>
                  <span class="badge badge-danger" *ngIf="changeRequests.state === 'ABORTED'">Aborted</span>
                  <span class="badge badge-danger" *ngIf="changeRequests.state === 'DECLINED'">Declined by Partner</span>
                </td>
                <td>{{changeRequests.createdAt}}</td>
                <td>
                  <button class="btn btn-sm btn-primary" (click)="requestChangeRequestDetails(changeRequests._id)">
                    Edit
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
          <pagination-controls [maxSize]="10"
                               (pageChange)="sendPage($event)"
                               *ngIf="pagination != null">

          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
<tripmakery-offer-change-requests-edit-modal></tripmakery-offer-change-requests-edit-modal>
