<div class="block">
  <div class="block-content-full" style="height:250px;" *ngIf="image != null">
    <div [ngStyle]="bgStyle" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
      <div class="row" *ngIf="visible===true">
        <div class="col-md-2 offset-md-1 text-center">
          <button class="btn btn-sm btn-circle btn-alt-primary mr-5 mb-5 align-bottom" type="button"
                  tooltip="Preview image" (click)="previewImageInModal(image)"><i class="fa fa-search"></i></button>
        </div>
        <div class="col-md-2 text-center">
          <button class="btn btn-sm btn-circle btn-alt-success mr-5 mb-5 align-bottom" type="button"
                  tooltip="Set as preview image" (click)="setPreviewImage()"><i class="fa fa-image"></i>
          </button>
        </div>
        <div class="col-md-2 text-center">
          <a
            href="http://makery-user-stage.azurewebsites.net/ib/housing/rkDcd5exKhb?bI={{environment.urls.blob}}/{{housing._id}}/raw/{{image}}"
            target="_blank" class="btn btn-sm btn-circle btn-alt-secondary mr-5 mb-5"
            tooltip="Preview as offer image"><i class="fa fa-search"></i></a>
        </div>
        <div class="col-md-2 text-center">
          <button class="btn btn-sm btn-circle btn-alt-warning mr-5 mb-5" type="button" tooltip="Set as offer image"
                  (click)="setOfferImage()"><i class="fa fa-object-group"></i>
          </button>
        </div>
        <div class="col-md-2 text-center">
          <button class="btn btn-sm btn-circle btn-alt-danger mr-5 mb-5" type="button" tooltip="Delete"
                  (dblclick)="deleteImage()"><i class="fa fa-ban"></i></button>
        </div>
      </div>
      <span style="position: absolute; background-color:white; color:black;bottom:0;">{{imageWidth | delimitednumber:0}}x{{imageHeight|delimitednumber:0}}</span>
      <img src="{{environment.urls.blob}}/{{housing._id}}/raw/{{image}}" #myImage (load)="dosomething()"
           style="display: none;">
    </div>
  </div>
</div>
