import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HousingInfoService} from "../../../../services/housing-info/housing-info.service";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";
import {EventsService} from "../../../../services/events/events.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-top-partner',
  templateUrl: './top-partner.component.html',
  styleUrls: ['./top-partner.component.css']
})
export class TopPartnerComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean = false;
  housing: any;

  constructor(private http: HttpClient,
              public housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) { }

  ngOnInit() {
    this.housing = this.housingInfoService.getSelectedHousing();
  }

  onSubmitForm() {

  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }
}
