import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardRoutes} from './dashboard/dashboard.routes';
import {LoginRoutes} from './login/login.routes';

const routes: Routes = [
  ...LoginRoutes,
  ...DashboardRoutes,
 { path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
