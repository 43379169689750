<app-content-header i18n-title title="Site Creation" i18n-subTitle
                    subTitle="Trigger the creation of new SEO sites after adding destinations or keywords"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">

  <div class="row">
    <div class="col-md-6 offset-md-3">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Site Creation Trigger</h3>
        </div>
        <div class="block-content block-content-full">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-sm">
                <thead>
                <tr>
                  <th>Section</th>
                  <th class="text-center">Action</th>
                  <th class="text-center">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>GL Pages</td>
                  <td class="text-center"><button class="btn btn-sm btn-default" (click)="handleCreatePagesCall('gl')">Create Pages</button></td>
                  <td class="text-center">
                    <i class="fa fa-cog fa-spin mr-5" *ngIf="isGlCreationRunning === true"></i>
                    <i class="fa fa-check" *ngIf="isGlCreationRunning === false"></i>
                  </td>
                </tr>
                <tr>
                  <td>BL Pages</td>
                  <td class="text-center"><button class="btn btn-sm btn-default" (click)="handleCreatePagesCall('bl')">Create Pages</button></td>
                  <td class="text-center">
                    <i class="fa fa-cog fa-spin mr-5" *ngIf="isBlCreationRunning === true"></i>
                    <i class="fa fa-check" *ngIf="isBlCreationRunning === false"></i>
                  </td>
                </tr>
                <tr>
                  <td>AL Pages</td>
                  <td class="text-center"><button class="btn btn-sm btn-default" (click)="handleCreatePagesCall('al')">Create Pages</button></td>
                  <td class="text-center">
                    <i class="fa fa-cog fa-spin mr-5" *ngIf="isAlCreationRunning === true"></i>
                    <i class="fa fa-check" *ngIf="isAlCreationRunning === false"></i>
                  </td>
                </tr>
                <tr>
                  <td>HP Pages</td>
                  <td class="text-center"><button class="btn btn-sm btn-default" (click)="handleCreatePagesCall('hp')">Create Pages</button></td>
                  <td class="text-center">
                    <i class="fa fa-cog fa-spin mr-5" *ngIf="isHPCreationRunning === true"></i>
                    <i class="fa fa-check" *ngIf="isHPCreationRunning === false"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="row">
    <div class="col-md-6 offset-md-3">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Housing Lists Trigger</h3>
        </div>
        <div class="block-content block-content-full">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-sm">
                <thead>
                <tr>
                  <th>Section</th>
                  <th class="text-center">Action</th>
                  <th class="text-center">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>All Pages</td>
                  <td class="text-center"><button class="btn btn-sm btn-default" (click)="handleCreateHousingListCall()">Create Housing Lists</button></td>
                  <td class="text-center">
                    <i class="fa fa-cog fa-spin mr-5" *ngIf="isHousingListCreationRunning === true"></i>
                    <i class="fa fa-check" *ngIf="isHousingListCreationRunning === false"></i>
                  </td>
                </tr>
                <tr>
                  <td>Destination Pages</td>
                  <td class="text-center"><button class="btn btn-sm btn-default" (click)="handleCreateHousingListPerPageCall('destinations')">Create Housing Lists</button></td>
                  <td class="text-center">
                    <i class="fa fa-cog fa-spin mr-5" *ngIf="isDestFillDbRunning === true"></i>
                    <i class="fa fa-check" *ngIf="isDestFillDbRunning === false"></i>
                  </td>
                </tr>
                <tr>
                  <td>GL Pages</td>
                  <td class="text-center"><button class="btn btn-sm btn-default" (click)="handleCreateHousingListPerPageCall('gl')">Create Housing Lists</button></td>
                  <td class="text-center">
                    <i class="fa fa-cog fa-spin mr-5" *ngIf="isGlFillDbRunning === true"></i>
                    <i class="fa fa-check" *ngIf="isGlFillDbRunning === false"></i>
                  </td>
                </tr>
                <tr>
                  <td>BL Pages</td>
                  <td class="text-center"><button class="btn btn-sm btn-default" (click)="handleCreateHousingListPerPageCall('bl')">Create Housing Lists</button></td>
                  <td class="text-center">
                    <i class="fa fa-cog fa-spin mr-5" *ngIf="isBlFillDbRunning === true"></i>
                    <i class="fa fa-check" *ngIf="isBlFillDbRunning === false"></i>
                  </td>
                </tr>
                <tr>
                  <td>AL Pages</td>
                  <td class="text-center"><button class="btn btn-sm btn-default" (click)="handleCreateHousingListPerPageCall('al')">Create Housing Lists</button></td>
                  <td class="text-center">
                    <i class="fa fa-cog fa-spin mr-5" *ngIf="isAlFillDbRunning === true"></i>
                    <i class="fa fa-check" *ngIf="isAlFillDbRunning === false"></i>
                  </td>
                </tr>
                <tr>
                  <td>HP Pages</td>
                  <td class="text-center"><button class="btn btn-sm btn-default" (click)="handleCreateHousingListPerPageCall('hp')">Create Housing Lists</button></td>
                  <td class="text-center">
                    <i class="fa fa-cog fa-spin mr-5" *ngIf="isHPFillDbRunning === true"></i>
                    <i class="fa fa-check" *ngIf="isHPFillDbRunning === false"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>
