import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-housing-info-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.css']
})
export class HousingInfoDetailsGoogleMapsComponent implements OnInit {
  @Input('housing') housing;

  constructor() { }

  ngOnInit() {
  }

}
