<div class="col-xl-12">
  <h2 class="content-heading">
    Housing availability
  </h2>

  <!-- -->
  <div class="block block-rounded" *ngIf="this.data">
    <div class="block-header block-header-default">

      <input type="text"
             class="form-control"
             [bsConfig]="bsConfig"
             #dp="bsDatepicker"
             (bsValueChange)="onValueChange($event)"
             bsDatepicker [(bsValue)]="bsValue">
    </div>
    <div class="block-content">
      <table class="table table-borderless table-condensed table-sm table-striped">
        <thead>
        <tr>
          <th>Date</th>
          <th class="text-center">Has availabilities</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let element of daysInMonth; let i = index;">
          <td>
            {{daysInMonthFormatted[i]}}
          </td>
          <td [ngClass]="{'bg-danger': this.availabilityMapped[element] !== true, 'bg-success': this.availabilityMapped[element] === true}" class="text-right">


        </tr>
        <tr>
        </tbody>
      </table>
    </div>
  </div>


  <!-- -->
  <div class="block block-rounded" *ngIf="data == null">
    <div class="block-content block-content-full text-center">
      <p>No results to display</p>
    </div>
  </div>
</div>
