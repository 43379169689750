<div class="block block-rounded block-themed">
  <div class="block-header bg-gd-primary">
    <h3 class="block-title">Housing Goodies</h3>
    <div class="block-options">
      <button type="submit" class="btn btn-sm btn-alt-primary" (click)="requestHousingGoodyDetails(housing._id,null)"
              [disabled]="housingGoodies == null">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
  <div class="block-content block-content-full" *ngIf="housingGoodies != null">

    <div class="row">
      <div class="col-md-12 text-center" *ngIf="housingGoodies.length === 0">
        <em>This housing has no goodies yet</em>
      </div>

      <table class="table" *ngIf="housingGoodies.length > 0">
        <thead>
        <tr>
        <th>Goody</th>
        <th>Maximum Amount</th>
        <th>Current Amount</th>
        <th class="text-center">Active</th>
        <th class="text-center">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let goody of housingGoodies">
          <td>
            <span *ngIf="goody.goody === 'COMMISSION_EXCLUSION'">No Commission</span>
          </td>
          <td>{{goody.amountSet}}</td>
          <td>{{goody.amountCurrent}}</td>
          <td class="text-center">
            <i class="fa fa-check" *ngIf="goody.active === true"></i>
            <i class="fa fa-close" *ngIf="goody.active === false"></i>
          </td>
          <td class="text-center">
            <button class="btn btn-sm btn-danger" (click)="handleClickDelete(goody._id)"><i class="fa fa-close"></i></button>&nbsp;
            <button class="btn btn-sm btn-info" (click)="requestHousingGoodyDetails(housing._id, goody._id)"><i class="fa fa-pencil"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>

<app-housing-info-details-goodies-details-modal></app-housing-info-details-goodies-details-modal>
