import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";
import {ConfigService} from "../../../../services/configservice/config.service";
import {EventsService} from "../../../../services/events/events.service";
import {AgmMap, LatLngBoundsLiteral, MouseEvent, PolyMouseEvent} from "@agm/core";
import {OpenStreetMapsService} from "../../../../services/open-street-maps/open-street-maps.service";
import {BoundaryContainerMode} from "../../../../shared/enums/boundary-container-mode.enum";
import {FormControl, FormGroup} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import * as mapTypes from "@agm/core/services/google-maps-types";

@Component({
  selector: 'app-boundaries-v2-boundary-container',
  templateUrl: './boundary-container.component.html'
})
export class BoundaryContainerComponent implements OnInit {
  @ViewChild(AgmMap) map: AgmMap;
  @Input() coordinatesPrefilled: Array<Array<{ lat: number, lng: number }>> | Array<Array<Array<{ lat: number, lng: number }>>> | Array<Array<Array<Array<{ lat: number, lng: number }>>>>;
  @Input() mode: BoundaryContainerMode;
  @Input() googleLocation: any;

  polygons: mapTypes.Polygon;
  marker: mapTypes.Marker;
  coordinates: Array<Array<{ lat: number, lng: number }>> | Array<Array<Array<{ lat: number, lng: number }>>> | Array<Array<Array<Array<{ lat: number, lng: number }>>>>;

  public hasBoundaryBeenTestedSuccessfully = false;
  public editmode = false;
  public formOptimization: FormGroup;

  public errorDuringDbQuery: boolean = false;
  public outputFoundHousings;
  public outputPerformance;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private osmService: OpenStreetMapsService,
              private http: HttpClient) {
  }

  async ngOnInit() {
    this.formOptimization = new FormGroup({
      'rounds': new FormControl(''),
      'margin': new FormControl(''),
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.coordinatesPrefilled != null &&
      (changes.coordinatesPrefilled.currentValue != null ||
      changes.googleLocation.currentValue != null)) {
      this.editmode = false;
      this.coordinates = changes.coordinatesPrefilled.currentValue;
      await this.createPolygonByCoords(this.coordinates, this.editmode);
    }
  }

  public async minimizeBoundary(rounds: number) {
    const coordinates = this.osmService.transformGoogleMapsCoordinatesToNumberArray(this.coordinates);
    const coordinatesMinimized = this.osmService.minimizeBoundary(coordinates, rounds);

    /* */
    this.coordinates = this.osmService.transformNumberArrayToGoogleMapsCoordinates(coordinatesMinimized);
    await this.createPolygonByCoords(this.coordinates, this.editmode);
  }

  public async marginBoundary(rounds: number) {
    const coordinates = this.osmService.transformGoogleMapsCoordinatesToNumberArray(this.coordinates);
    const coordinatesMinimized = this.osmService.marginBoundary(coordinates, rounds);

    /* */
    this.coordinates = this.osmService.transformNumberArrayToGoogleMapsCoordinates(coordinatesMinimized);
    await this.createPolygonByCoords(this.coordinates, this.editmode);
  }

  public async toggleEditMode() {
    this.editmode = !this.editmode;
    this.coordinates = this.extractCoordinatesAsLatLng() as any;
    await this.createPolygonByCoords(this.coordinates, this.editmode);
  }

  public async addPolygon(event: MouseEvent) {
    /* */
    const coordsTotal = this.extractCoordinatesAsLatLng() as Array<any>;

    /* */
    const mod = this.getDistanceByZoomLevel(this.map.zoom);
    const coords = [
      {lat: event.coords.lat + mod, lng: event.coords.lng + mod},
      {lat: event.coords.lat + mod, lng: event.coords.lng - mod},
      {lat: event.coords.lat - mod, lng: event.coords.lng - mod},
      {lat: event.coords.lat - mod, lng: event.coords.lng + mod}
    ];
    coordsTotal.push(coords);

    /* */
    this.coordinates = coordsTotal;

    /* */
    await this.createPolygonByCoords(this.coordinates, this.editmode);
  }

  private getDistanceByZoomLevel(zoom: number) {
    return 1 / zoom;
  }

  public resetPolygon() {
    if (this.polygons != null) {
      this.polygons.setMap(null);
      this.polygons = null;
    }

    if (this.marker != null) {
      this.marker.setMap(null);
      this.marker = null;
    }

    this.hasBoundaryBeenTestedSuccessfully = false;
  }

  public requestBaseBoundary() {
    this.eventsService.broadcast("BOUNDARIES_SECONDARY_REQUESTS_BASE_BOUNDARY");
  }

  public async createPolygonByCoords(coords: Array<any>, editable: boolean) {
    this.resetPolygon();

    if (coords != null) {
      const polygon = await (this.map as any)._mapsWrapper.createPolygon({
        paths: coords,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.3,
        editable: editable,
        draggable: false,
        clickable: true,
      });
      polygon.addListener('click', async (event) => {
        await this.handleMouseClick(event);
      });
      this.polygons = polygon;

      if (this.googleLocation != null) {
        this.marker = await (this.map as any)._mapsWrapper.createMarker({
          position: this.googleLocation.geometry.location,
          title: this.googleLocation.formatted_address
        });
      }

      if (this.map != null && this.map.fitBounds != null && this.googleLocation != null) {

        await (this.map as any)._mapsWrapper.fitBounds(
          {
            east: this.googleLocation.geometry.viewport.northeast.lng,
            north: this.googleLocation.geometry.viewport.northeast.lat,
            west: this.googleLocation.geometry.viewport.southwest.lng,
            south: this.googleLocation.geometry.viewport.southwest.lat,
          } as LatLngBoundsLiteral
        );
        // if (coords.length > 0) {
        //   console.log("Length check pos");
        //   console.log(coords);
        //
        //   // Create the bounds object
        //   var bounds = new google.maps.LatLngBounds();
        //
        //   // Get paths from polygon and set event listeners for each path separately
        //   polygon.getPath().forEach(function (path, index) {
        //     bounds.extend(path);
        //   });
        //
        //   // Fit Polygon path bounds
        //   await (this.map as any)._mapsWrapper.map.fitBounds(bounds);
        //
        // } else {
        //   console.log("Google Location");
        //   console.log(this.googleLocation.geometry);
        //   await (this.map as any)._mapsWrapper.fitBounds(
        //     {
        //       east: this.googleLocation.geometry.viewport.northeast.lng,
        //       north: this.googleLocation.geometry.viewport.northeast.lat,
        //       west: this.googleLocation.geometry.viewport.southwest.lng,
        //       south: this.googleLocation.geometry.viewport.southwest.lat,
        //     } as LatLngBoundsLiteral
        //   );
        // }
      }
    }
  }

  public extractCoordinatesAsLatLng(): Array<{ lat: number, lng: number }> |
    Array<Array<{ lat: number, lng: number }>> |
    Array<Array<Array<{ lat: number, lng: number }>>> |
    Array<Array<Array<Array<{ lat: number, lng: number }>>>> {

    if (this.polygons == null) {
      return [];
    }

    const polygonPaths = this.polygons.getPaths() as any;
    const transformedPaths = [];

    for (let i = 0; i < polygonPaths.getLength(); i++) {
      const verticesArray = [];
      const vertices = polygonPaths.getAt(i);

      for (let j = 0; j < vertices.getLength(); j++) {
        const entry = vertices.getAt(j);
        verticesArray.push({lat: entry.lat(), lng: entry.lng()});
      }

      transformedPaths.push(verticesArray);
    }

    return transformedPaths;
  }

  public async handleMouseClick(event: PolyMouseEvent) {

    /* */
    const path = event.path;
    const vertex = event.vertex;

    const coordContainer = this.extractCoordinatesAsLatLng() as any;

    if (path != null && vertex != null) {
      for (const coords of coordContainer) {
        coords.forEach((entry, index) => {
          if (entry.lat === event.latLng.lat() &&
            entry.lng === event.latLng.lng()) coords.splice(index, 1);
        });
      }
    }

    this.coordinates = coordContainer;
    await this.createPolygonByCoords(coordContainer, this.editmode);
  }

  public async optimizeBoundary() {
    const rounds = parseFloat(this.formOptimization.get('rounds').value);
    const margin = parseFloat(this.formOptimization.get('margin').value);

    if (!isNaN(rounds)) {
      await this.minimizeBoundary(rounds);
    }

    if (!isNaN(margin)) {
      await this.marginBoundary(margin);
    }
  }

  public testBoundaryDetailsNew() {
    this.errorDuringDbQuery = false;
    this.outputPerformance = null;
    this.outputFoundHousings = null;

    this.loadingIndicatorService.showLoadingBar();

    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/boundary/test-query',
      {
        boundariesOptimized: this.osmService.transformGoogleMapsCoordinatesToMongoDBNumberArray(this.extractCoordinatesAsLatLng()),
      })
      .subscribe((response: any) => {
          // console.log(response);

          this.outputFoundHousings = response.amountHousings;
          this.outputPerformance = parseFloat(response.performance[0] + '.' + (response.performance[1] / 1000000));

          if (this.outputFoundHousings === -1) {
            this.outputPerformance = null;
            this.outputFoundHousings = null;
            this.errorDuringDbQuery = true;
            this.hasBoundaryBeenTestedSuccessfully = false;
          } else {
            this.hasBoundaryBeenTestedSuccessfully = true;
          }
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }
}
