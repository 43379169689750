import {Component, OnInit} from '@angular/core';
import {HousingInfoService} from "../../../../services/housing-info/housing-info.service";
import {EventsService} from "../../../../services/events/events.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";
import {environment} from "../../../../../environments/environment";
import {DateRangeUtils} from "../../../../shared/utils/date-range.utils";
import * as moment from "moment";
import {AgeQualifyingCode} from "@tripbakers/enums/dist/enums/channelmanager/ota/unversioned/age-qualifying-code.enum";
import {IRatesGuestsDetailed} from "./interfaces/rates-guests-detailed.interface";
import {FormControl, FormGroup} from "@angular/forms";
import {IColumnElement} from "./interfaces/column-element.interface";

@Component({
  selector: 'app-housing-cm',
  templateUrl: './channelmanager.component.html',
  styleUrls: ['./channelmanager.component.css']
})
export class ChannelmanagerComponent implements OnInit {
  private housingId: string;
  private housing: string;
  public isLoading: boolean = false;
  public priceData: any;

  public form: FormGroup;


  public lineDataDates = [];
  public bookingLimits = [];
  public ratesMaxLos = [];
  public ratesMinLos = [];
  public closedToArrival = [];
  public closedToDeparture = [];
  public closedMaster = [];
  public ratesGuestsDetailed = [];
  public priceChilds = [];
  public priceInfants = [];
  public pricePerRoom = [];
  public priceSeniors = [];
  public priceAdditionalChild = [];
  public priceAdditionalAdult = [];

  constructor(private activatedRoute: ActivatedRoute,
              public housingInfoService: HousingInfoService,
              private eventsService: EventsService,
              private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient) {
    this.form = new FormGroup({
      'range': new FormControl({value: [moment().toDate(), moment().endOf('month').toDate()], disabled: false}),
      'ratePlanCode': new FormControl({value: '00RP0001', disabled: false}),
      'invCode': new FormControl({value: '00RT0001A', disabled: false}),
    })
  }


  ngOnInit() {

    this.activatedRoute.parent.paramMap.subscribe(params => {
      this.housingId = params.get("housingId");
      this.getPriceData();
    });

  }

  public getPriceData() {
// api/v1/authenticated/admin/housing xxx B1tFlqTGlt3Z 20190905
    // http://localhost:4200/housings/LBG07PGfL/channelmanager
    // LBG07PGfL

    /* */
    this.showLoading();

    /* */
    const mDateStart = moment(this.form.get('range').value[0], 'YYYY-MM-DD');
    const mDateEnd = moment(this.form.get('range').value[1], 'YYYY-MM-DD');
    const invCode = this.form.get('invCode').value;
    const ratePlanCode = this.form.get('ratePlanCode').value;
    const channelManager = 'spiderconnect';

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/channelmanager/' + channelManager + '?dateStart=' + mDateStart.format('YYYY-MM-DD') + '&dateEnd=' + mDateEnd.format('YYYY-MM-DD') + '&ratePlanCode=' + ratePlanCode + '&invCode=' + invCode, {})
      .subscribe((response: any) => {

          /* */
          const dateRange = DateRangeUtils.createDateListFromDateRangeComplete(mDateStart, mDateEnd);

          const optimizedLineData: IColumnElement[] = [
            {type: 'BOOKING_LIMITS', data: []}
          ];

          /* */
          const lineDataDates = [];
          const bookingLimits = [];
          const priceChilds = [];
          const priceAdditionalChild = [];
          const priceAdditionalAdult = [];
          const priceInfants = [];
          const pricePerRoom = [];
          const priceSeniors = [];
          const ratesMaxLos = [];
          const ratesMinLos = [];
          const ratesGuestsDetailed: IRatesGuestsDetailed[] = [];
          const closedToArrival = [];
          const closedToDeparture = [];
          const closedMaster = [];

          /* */
          for (const dateNum of dateRange.datesNum) {
            lineDataDates.push(moment(dateNum, 'YYYYMMDD').format('dd <br> DD.MM'));
            const element = response.prices.find((element) => element.dateNum === dateNum);

            /* */
            const maxOccupants = this.getMaxOccupantsByInventoryCode(invCode);

            /* Add empty data */
            if (element == null) {
              bookingLimits.push(null);
              ratesMaxLos.push(null);
              ratesMinLos.push(null);
              closedToArrival.push(null);
              closedToDeparture.push(null);
              closedMaster.push(null);

              for (let i = 1; i <= maxOccupants; i++) {
                let rateElement = ratesGuestsDetailed.find((element) => element.numberOfGuests === i);
                if (rateElement == null) {
                  ratesGuestsDetailed.push({numberOfGuests: i, elements: []});
                  rateElement = ratesGuestsDetailed.find((element) => element.numberOfGuests === i);
                }

                rateElement.elements.push(null);
              }

              priceInfants.push(null);
              pricePerRoom.push(null);
              priceChilds.push(null);
              priceSeniors.push(null);
              priceAdditionalChild.push(null);
              priceAdditionalAdult.push(null);

            } else {

              bookingLimits.push(element.bookingLimit);
              ratesMaxLos.push(element.rules.maxLOS);
              ratesMinLos.push(element.rules.minLOS);
              closedToArrival.push(element.rules.restrictionArrival);
              closedToDeparture.push(element.rules.restrictionDeparture);
              closedMaster.push(element.rules.restrictionMaster);

              /* */
              for (let i = 1; i <= maxOccupants; i++) {
                let rateElement = ratesGuestsDetailed.find((element) => element.numberOfGuests === i);
                if (rateElement == null) {
                  ratesGuestsDetailed.push({numberOfGuests: i, elements: []});
                  rateElement = ratesGuestsDetailed.find((element) => element.numberOfGuests === i);
                }

                const relevantEntry = element.rates.guests.find((element) => element.rate.ageQualifyingCode === 10 && element.rate.numberOfGuests === i);

                if (relevantEntry == null) {
                  rateElement.elements.push(null);
                } else {
                  rateElement.elements.push(relevantEntry.rate.amount);
                }
              }

              if (element.rates.guests.length === 1) {
                if (element.rates.guests[0].rate.rateType === "PER_ROOM") {
                  pricePerRoom.push(element.rates.guests[0].rate.amountAfterTax)
                } else {
                  pricePerRoom.push(null);
                }
              } else {
                pricePerRoom.push(null);
              }

              /* */
              const infantElement = element.rates.guests.find((element) => element.rate.ageQualifyingCode === AgeQualifyingCode.INFANT);
              if (infantElement == null) {
                priceInfants.push(null);
              } else {
                priceInfants.push(infantElement.rate.amount);
              }

              /* */
              const childElement = element.rates.guests.find((element) => element.rate.ageQualifyingCode === AgeQualifyingCode.CHILD);
              if (childElement == null) {
                priceChilds.push(null);
              } else {
                priceChilds.push(childElement.rate.amount);
              }

              /* */
              const seniorElement = element.rates.guests.find((element) => element.rate.ageQualifyingCode === AgeQualifyingCode.SENIOR);
              if (seniorElement == null) {
                priceSeniors.push(null);
              } else {
                priceSeniors.push(seniorElement.rate.amount);
              }

              /* */
              const additionalGuestChildElement = element.rates.additionalGuests.find((element) => element.rate.ageQualifyingCode === AgeQualifyingCode.CHILD);
              if (additionalGuestChildElement == null) {
                priceAdditionalChild.push(null);
              } else {
                priceAdditionalChild.push(additionalGuestChildElement.rate.amount);
              }

              /* */
              const additionalGuestAdultElement = element.rates.additionalGuests.find((element) => element.rate.ageQualifyingCode === AgeQualifyingCode.ADULT);
              if (additionalGuestAdultElement == null) {
                priceAdditionalAdult.push(null);
              } else {
                priceAdditionalAdult.push(additionalGuestAdultElement.rate.amount);
              }
            }

          }

          /* */
          this.ratesGuestsDetailed = ratesGuestsDetailed;
          this.lineDataDates = lineDataDates;
          this.bookingLimits = bookingLimits;
          this.ratesMaxLos = ratesMaxLos;
          this.ratesMinLos = ratesMinLos;
          this.closedToArrival = closedToArrival;
          this.closedToDeparture = closedToDeparture;
          this.closedMaster = closedMaster;
          this.priceChilds = priceChilds;
          this.priceInfants = priceInfants;
          this.pricePerRoom = pricePerRoom;
          this.priceAdditionalAdult = priceAdditionalAdult;
          this.priceAdditionalChild = priceAdditionalChild;

          /* */
          this.priceData = response;

          this.loadingIndicatorService.hideLoadingBar();
          this.isLoading = false;
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          console.log("Error", error);
          this.loadingIndicatorService.hideLoadingBar();
          this.isLoading = false;
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        },
        () => {
          this.hideLoading();
        }
      );
  }

  private getMaxOccupantsByInventoryCode(invCode: string) {
    if (invCode === '00RT0001A') {
      return 1;
    }
    if (invCode === '00RT0002A') {
      return 2;
    }
    if (invCode === '00RT0002B') {
      return 2;
    }
    if (invCode === '00RT0003A') {
      return 3;
    }
    if (invCode === '00RT0004A') {
      return 4;
    }
    if (invCode === '00RT0005A') {
      return 5;
    }
    if (invCode === '00RT0006A') {
      return 6;
    }
    if (invCode === '00RT0007A') {
      return 7;
    }
    if (invCode === '00RT0008A') {
      return 8;
    }
    if (invCode === '00RT0009A') {
      return 9;
    }
    if (invCode === '00RT0010A') {
      return 10;
    }
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

}
