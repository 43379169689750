import {Injectable} from '@angular/core';
import {EventsService} from '../events/events.service';

@Injectable()
export class LoadingIndicatorService {

    constructor(private eventsService: EventsService) {

    }

    public showLoadingBar() {
        this.eventsService.broadcast('requestShowLoadingIndicator', true);
    }

    public hideLoadingBar() {
        this.eventsService.broadcast('requestShowLoadingIndicator', false);
    }

}
