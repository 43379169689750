<div class="col-xl-12" *ngIf="paginationData != null">
  <h2 class="content-heading">Latest Offers (Forwarded Offers)</h2>

  <!-- -->
  <div class="block block-rounded" *ngIf="offers != null && offers.length > 0">
    <div class="block-content">
      <div class="table-responsive">
        <table class="table table-borderless table-striped">
          <thead>
          <tr>
            <th>Id</th>
            <th>Request</th>
            <th>Creator</th>
            <th>State</th>
            <th>Created</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let offer of offers | paginate: {
              id: 'ctrls_dp_' + section,
              itemsPerPage: paginationData.limit,
              currentPage: paginationData.page,
              totalItems: paginationData.total}">
            <td>
              {{offer._id}}
            </td>
            <td>
              {{offer.request}}
            </td>
            <td class="text-left">
              <ng-container *ngIf="offer.preOffer != null">
                <span class="badge badge-primary" *ngIf="offer.preOffer.status.createdByAdmin === true">Admin</span>
                <span class="badge badge-info" *ngIf="offer.preOffer.status.createdBySytem === true">System</span>
                <span class="badge badge-success"
                      *ngIf="(offer.preOffer.status.createdBySytem === false || offer.preOffer.status.createdBySytem == null) && (offer.preOffer.status.createdByAdmin === false || offer.preOffer.status.createdByAdmin == null)">Hotel</span>
              </ng-container>
            </td>
            <td class="text-left">
              <ng-container *ngIf="offer.preOffer != null">
                <span class="badge badge-warning" *ngIf="offer.preOffer.state === 'WAITING_FOR_APPROVAL'">Approval required</span>
                <span class="badge badge-success" *ngIf="offer.preOffer.state === 'BOOKED'">Booked</span>
                <span class="badge badge-warning" *ngIf="offer.preOffer.state === 'EXPIRED'">Expired</span>
                <span class="badge badge-danger"
                      *ngIf="offer.preOffer.state === 'USER_DECLINED'">Declined by user</span>
                <span class="badge badge-info" *ngIf="offer.preOffer.state === 'USER_INFORMED'">Forwarded</span>
              </ng-container>
            </td>
            <td class="d-none d-sm-table-cell">
              {{offer.created}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
      <pagination-controls
        id="ctrls_dp_{{section}}"
        [maxSize]="paginationData.limit"
        (pageChange)="sendPage($event)"
      ></pagination-controls>
    </div>
  </div>

  <!-- -->
  <div class="block block-rounded" *ngIf="offers == null || offers.length === 0">
    <div class="block-content block-content-full text-center">
      No results to display
    </div>
  </div>

</div>
