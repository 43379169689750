<form [formGroup]="form" (submit)="onSubmitForm()">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Onboarding Details</h3>
      <div class="block-options">
        <button type="submit" class="btn btn-sm btn-alt-primary" (click)="onSubmitForm()"
                [disabled]="housing == null || isLoading === true">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
        </button>
      </div>
    </div>
    <div class="block-content block-content-full">

      <div class="form-group row">
        <label class="col-4 col-form-label" for="housingOnboardingTalksState">State</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('state').touched || form.get('state').dirty) && form.get('state').invalid}">
          <select class="form-control" id="housingOnboardingTalksState" name="housingOnboardingTalksState"
                  formControlName="state">
            <option value="NONE">None</option>
            <option value="CONTACTED">Contacted</option>
            <option value="WAITING_FOR_RESPONSE">Waiting for response</option>
            <option value="DONT_CONTACT">Don't Contact</option>
            <option value="TALK_PLANNED">Talk planned</option>
            <option value="CONNECTION_AUTHORIZED">Connection authorized</option>
            <option value="COOPERATION_AUTHORIZED">Cooperation authorized</option>
            <option value="NO_COOPERATION">No Cooperation</option>
          </select>
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('state').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="housingOnboardingTalksCallState">Call State</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('callState').touched || form.get('callState').dirty) && form.get('callState').invalid}">
          <select class="form-control" id="housingOnboardingTalksCallState" name="housingOnboardingTalksCallState"
                  formControlName="callState">
            <option value="CALL">Call</option>
            <option value="TALK_PLANNED">Talk Planned</option>
            <option value="NOT_REACHED">Not reached</option>
            <option value="ALREADY_CALLED">Already Called</option>
          </select>
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('callState').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="lastContactAt">Last Contact</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('lastContactAt').touched || form.get('lastContactAt').dirty) && form.get('lastContactAt').invalid}">
          <input type="text"
                 class="form-control"
                 bsDatepicker
                 [bsConfig]="{dateInputFormat:'DD.MM.YYYY'}"
                 placeholder="Datepicker"
                 formControlName="lastContactAt"
                 name="lastContactAt"
                 id="lastContactAt">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('lastContactAt').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4" for="contactIndividually">Contact individually</label>
        <div class="col-8">
          <label class="css-control css-control-success css-switch"
                 [ngClass]="{disabled: housing != null && (housing.chain == null || housing.chain.trim() === '')}">
            <input type="checkbox" class="css-control-input" id="contactIndividually" name="contactIndividually"
                   formControlName="contactIndividually">
            <span class="css-control-indicator"></span>
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="contactTeam">Contact Team</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('contactTeam').touched || form.get('contactTeam').dirty) && form.get('contactTeam').invalid}">
          <input type="text" class="form-control" id="contactTeam" name="contactTeam"
                 placeholder="Contact within our team" formControlName="contactTeam">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('contactTeam').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="contactAccommodation">Contact Accommodation</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('contactAccommodation').touched || form.get('contactAccommodation').dirty) && form.get('contactAccommodation').invalid}">
          <input type="text" class="form-control" id="contactAccommodation" name="contactAccommodation"
                 placeholder="Contact within the accommodation" formControlName="contactAccommodation">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('contactAccommodation').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="emailAccommodation">Email Accommodation</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('email').touched || form.get('email').dirty) && form.get('email').invalid}">
          <input type="text" class="form-control" id="emailAccommodation" name="email"
                 placeholder="Email contact" formControlName="email">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('email').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="emailAccommodation">Phone Accommodation</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('phone').touched || form.get('phone').dirty) && form.get('phone').invalid}">
          <input type="text" class="form-control" id="phone" name="phone"
                 placeholder="Phone number" formControlName="phone">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('phone').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <hr>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4" for="contactIndividually">Self Service</label>
        <div class="col-8">
          <label class="css-control css-control-success css-switch">
            <input type="checkbox" class="css-control-input" id="selfService" name="selfService"
                   formControlName="selfService">
            <span class="css-control-indicator"></span>
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4" for="contactIndividually">Finished Self Service</label>
        <div class="col-8">
          <label class="css-control css-control-success css-switch">
            <input type="checkbox" class="css-control-input" id="hasFinishedSelfService" name="hasFinishedSelfService"
                   formControlName="hasFinishedSelfService">
            <span class="css-control-indicator"></span>
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <hr>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-12" for="comments">Comments</label>
        <div class="col-12">
          <textarea class="form-control" formControlName="comments" id="comments" name="comments" rows="6"
                    placeholder="Please enter relevant comments.."></textarea>
        </div>
      </div>

    </div>
  </div>
</form>
<!-- END Sauce -->
