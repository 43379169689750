<app-content-header i18n-title title="Housing Search" i18n-subTitle
                    subTitle="Filter our Housing DB"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">

  <div class="row">
    <div class="col-md-3">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>
        <div class="block-content block-content-full">
          <form [formGroup]="form">
            <div class="row">

              <div class="col-md-12">

                <div class="form-group row">
                  <label class="col-12" for="destination">Destination</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" id="destination" name="destination"
                           placeholder="Destination"
                           autocomplete="off"
                           formControlName="destination"
                           ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                           (onAddressChange)="handleAddressChangeOfCity($event)"
                    >
                  </div>
                  <div class="col-md-4">

                    <input type="text" class="form-control"
                           placeholder="Radius"
                           autocomplete="off"
                           formControlName="radius">
                  </div>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <hr>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="block">
                  <ul class="nav nav-tabs nav-tabs-alt" data-toggle="tabs" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" href="#btabs-static-prios">Top Filter</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#btabs-static-profile">Amenities</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#btabs-static-finished">Developer</a>
                    </li>
                  </ul>
                  <div class="block-content tab-content">
                    <div class="tab-pane active" id="btabs-static-prios" role="tabpanel">

                      <div class="row">
                        <label class="col-12">Stars</label>
                        <div class="col-2">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="stars-0"
                                   id="stars-0"
                                   formControlName="stars-0">
                            <label class="custom-control-label" for="stars-0">0</label>
                          </div>
                        </div>

                        <div class="col-2">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="stars-1"
                                   id="stars-1"
                                   formControlName="stars-1">
                            <label class="custom-control-label" for="stars-1">1</label>
                          </div>
                        </div>

                        <div class="col-2">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="stars-2"
                                   id="stars-2"
                                   formControlName="stars-2">
                            <label class="custom-control-label" for="stars-2">2</label>
                          </div>
                        </div>

                        <div class="col-2">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="stars-3"
                                   id="stars-3"
                                   formControlName="stars-3">
                            <label class="custom-control-label" for="stars-3">3</label>
                          </div>
                        </div>

                        <div class="col-2">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="stars-4"
                                   id="stars-4"
                                   formControlName="stars-4">
                            <label class="custom-control-label" for="stars-4">4</label>
                          </div>
                        </div>

                        <div class="col-2">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="stars-5"
                                   id="stars-5"
                                   formControlName="stars-5">
                            <label class="custom-control-label" for="stars-5">5</label>
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <hr>
                        </div>
                      </div>

                      <div class="row">
                        <label class="col-12">Rating</label>
                        <div class="col-2">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="rating-bad"
                                   id="rating-bad"
                                   formControlName="rating-bad">
                            <label class="custom-control-label" for="rating-bad">Bad</label>
                          </div>
                        </div>

                        <div class="col-2">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="rating-ok"
                                   id="rating-ok"
                                   formControlName="rating-ok">
                            <label class="custom-control-label" for="rating-ok">Ok</label>
                          </div>
                        </div>

                        <div class="col-2">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="rating-good"
                                   id="rating-good"
                                   formControlName="rating-good">
                            <label class="custom-control-label" for="rating-good">Good</label>
                          </div>
                        </div>

                        <div class="col-2">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="rating-fine"
                                   id="rating-fine"
                                   formControlName="rating-fine">
                            <label class="custom-control-label" for="rating-fine">Fine</label>
                          </div>
                        </div>

                        <div class="col-2">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="rating-best"
                                   id="rating-best"
                                   formControlName="rating-best">
                            <label class="custom-control-label" for="rating-best">Best</label>
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <hr>
                        </div>
                      </div>


                      <div class="row">
                        <label class="col-12">Housing Type</label>
                        <div class="col-6" *ngFor="let housingType of housingTypes">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="type-{{housingType}}"
                                   id="type-{{housingType}}"
                                   formControlName="type-{{housingType}}">
                            <label class="custom-control-label" for="type-{{housingType}}">{{housingType}}</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <hr>
                        </div>
                      </div>

                      <div class="row">
                        <label class="col-12">Rooms</label>
                        <div class="col-md-12">
                          <input type="text" class="form-control" id="rooms" name="rooms"
                                 placeholder="Minimum amount of rooms"
                                 autocomplete="off"
                                 formControlName="rooms"
                          >
                        </div><div class="col-md-12">
                          <input type="text" class="form-control" id="roomsMax" name="roomsMax"
                                 placeholder="Maximum amount of rooms"
                                 autocomplete="off"
                                 formControlName="roomsMax"
                          >
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-md-12">
                          <hr>
                        </div>
                      </div>


                      <div class="row">
                        <label class="col-12">Partner Type</label>
                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5">
                            <input class="custom-control-input" type="checkbox" name="partner-standard"
                                   id="partner-standard"
                                   formControlName="partner-standard">
                            <label class="custom-control-label" for="partner-standard">Standard</label>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="partner-top"
                                   id="partner-top"
                                   formControlName="partner-top">
                            <label class="custom-control-label" for="partner-top">Top</label>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="partner-extranet"
                                   id="partner-extranet"
                                   formControlName="partner-extranet">
                            <label class="custom-control-label" for="partner-extranet">Extranet</label>
                          </div>
                        </div>

                      </div>


                      <div class="row">
                        <div class="col-md-12">
                          <hr>
                        </div>
                      </div>


                      <div class="row">
                        <label class="col-12">Hotel Name</label>
                        <div class="col-md-12">
                          <input type="text" class="form-control" id="hotel-name" name="hotel-name"
                                 placeholder="Hotel Name"
                                 autocomplete="off"
                                 formControlName="hotel-name"
                          >
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <hr>
                        </div>
                      </div>

                      <div class="row">
                        <label class="col-12">Hotel Email</label>
                        <div class="col-md-12">
                          <input type="text" class="form-control" id="hotel-email" name="hotel-email"
                                 placeholder="Hotel Email"
                                 autocomplete="off"
                                 formControlName="hotel-email"
                          >
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <hr>
                        </div>
                      </div>

                      <div class="row">
                        <label class="col-12">Hotel Id</label>
                        <div class="col-md-12">
                          <input type="text" class="form-control" id="hotel-id" name="hotel-id"
                                 placeholder="Hotel Id"
                                 autocomplete="off"
                                 formControlName="hotel-id"
                          >
                        </div>
                      </div>


                    </div>
                    <div class="tab-pane" id="btabs-static-profile" role="tabpanel">
                      <div class="row">
                        <label class="col-12">Amenities</label>
                        <div class="col-12" *ngFor="let amenity of amenities">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="type-{{amenity}}"
                                   id="type-{{amenity}}"
                                   formControlName="type-{{amenity}}">
                            <label class="custom-control-label" for="type-{{amenity}}">{{amenity}}</label>
                          </div>
                        </div>
                      </div>

                    </div>


                    <div class="tab-pane" id="btabs-static-finished" role="tabpanel">

                      <div class="row">
                        <label class="col-12">Source URLs</label>
                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="booking-url"
                                   id="booking-url"
                                   formControlName="booking-url">
                            <label class="custom-control-label" for="booking-url">Missing or invalid Booking URL</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="no-trivago-id"
                                   id="no-trivago-id"
                                   formControlName="no-trivago-id">
                            <label class="custom-control-label" for="no-trivago-id">Missing or invalid Trivago
                              Id</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">&nbsp;</div>
                      </div>

                      <div class="row">
                        <label class="col-12">Images</label>
                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="no-images"
                                   id="no-images"
                                   formControlName="no-images">
                            <label class="custom-control-label" for="no-images">No Images</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="no-preview-image"
                                   id="no-preview-image"
                                   formControlName="no-preview-image">
                            <label class="custom-control-label" for="no-preview-image">No Preview Image</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="no-offer-image"
                                   id="no-offer-image"
                                   formControlName="no-offer-image">
                            <label class="custom-control-label" for="no-offer-image">No Offer Image</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox"
                                   name="no-preview-image-or-no-offer-image"
                                   id="no-preview-image-or-no-offer-image"
                                   formControlName="no-preview-image-or-no-offer-image">
                            <label class="custom-control-label" for="no-preview-image-or-no-offer-image">No Preview
                              Image or No Offer Image</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">&nbsp;</div>
                      </div>

                      <div class="row">
                        <label class="col-12">Deleted</label>
                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="deleted-only"
                                   id="deleted-only"
                                   formControlName="deleted-only">
                            <label class="custom-control-label" for="deleted-only">Show Deleted only</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="hide-deleted"
                                   id="hide-deleted"
                                   formControlName="hide-deleted">
                            <label class="custom-control-label" for="hide-deleted">Hide Deleted</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">&nbsp;</div>
                      </div>

                      <div class="row">
                        <label class="col-12">Misc</label>
                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="non-checked-only"
                                   id="non-checked-only"
                                   formControlName="non-checked-only">
                            <label class="custom-control-label" for="non-checked-only">Show Non Checked only</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="custom-control custom-checkbox custom-control-inline mb-5"
                          >
                            <input class="custom-control-input" type="checkbox" name="checked-only"
                                   id="checked-only"
                                   formControlName="checked-only">
                            <label class="custom-control-label" for="checked-only">Show Checked only</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">&nbsp;</div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">&nbsp;</div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <button class="btn btn-alt-success" (click)="submitForm()">Submit</button>
              </div>
            </div>

          </form>
        </div>
      </div>

    </div>

    <div class="col-md-9">
      <h2 class="content-heading" style="padding-top:0;">
        <form [formGroup]="form2">
          <div class="dropdown float-right">

            <select class="form-control" (change)="submitForm()" formControlName="limit">
              <option value="25" selected>25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="250">250</option>
              <option value="500">500</option>
            </select>

          </div>

          <div class="dropdown float-right">

            <select class="form-control" (change)="submitForm()" formControlName="sortingMode">
              <option value="1" selected>ASC</option>
              <option value="-1">DESC</option>
            </select>

          </div>

          <div class="dropdown float-right">

            <select class="form-control" (change)="submitForm()" formControlName="sortingField">
              <option value="name" selected>Name</option>
              <option value="stars">Stars</option>
              <option value="rating">Rating</option>
              <option value="internalInfo.matcherPoints.total">Matcher Points</option>
            </select>

          </div>
        </form>

        {{hotelCounter}} of {{hotelTotalCounter}} Hotels
      </h2>


      <ng-container *ngIf="paginationData != null">
        <div class="row"
             *ngFor="let housing of housings | paginate: {
              itemsPerPage: paginationData.limit,
              currentPage: paginationData.page,
              totalItems: paginationData.total}"
        >
          <div class="col-md-12">
            <app-housing-search-housing-element [housing]="housing"></app-housing-search-housing-element>
          </div>
        </div>

        <pagination-controls [maxSize]="10"
                             (pageChange)="sendPage($event)"
        ></pagination-controls>

      </ng-container>


    </div>


  </div>

</div>
