<div class="content-heading" xmlns="http://www.w3.org/1999/html">
  CM
  <small class="d-none d-sm-inline">Overview</small>
</div>

<div class="row gutters-tiny">
  <div class="col-md-2">
    <div class="block">
      <div class="block-header block-header-default">
        <h3 class="block-title">Update Filters</h3>
      </div>
      <div class="block-content">
        <form [formGroup]="form">
          <div class="form-group">
            <label for="frmRange">Select the date range</label>
            <input type="text" class="form-control mb-3"
                   placeholder="Click here to open the menu" bsDaterangepicker formControlName="range" id="frmRange"/>
          </div>
          <div class="form-group">
            <label for="frmInvCode">Inventory Code</label>
            <select class="form-control" formControlName="invCode" id="frmInvCode">
              <option>00RT0001A</option>
              <option>00RT0002A</option>
              <option>00RT0002B</option>
              <option>00RT0003A</option>
              <option>00RT0004A</option>
              <option>00RT0005A</option>
              <option>00RT0006A</option>
              <option>00RT0007A</option>
              <option>00RT0008A</option>
              <option>00RT0009A</option>
              <option>00RT00010A</option>
              <option>00RT00011A</option>
              <option>00RT00011A</option>
            </select>
          </div>
          <div class="form-group">
            <label for="frmRatePlanCode">Rate Plan Code</label>
            <select class="form-control" formControlName="ratePlanCode" id="frmRatePlanCode">
              <option>00RP0001</option>
              <option>00RP0002</option>
              <option>00RP0003</option>
              <option>00RP0004</option>
              <option>00RP0005</option>
            </select>
          </div>
          <div class="form-group">
            <button (click)="getPriceData()" class="btn btn-default">Update Filters</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- -->
  <div class="col-md-10">
    <div class="block">
      <div class="block-header block-header-default">
        <h3 class="block-title">Price Data</h3>
      </div>
      <div class="block-content">
        <div class="table-responsive">
          <table *ngIf="priceData != null" class="table table-striped table-sm">
            <thead>
            <tr>
              <th></th>
              <th *ngFor="let date of lineDataDates" class="text-center" [innerHTML]="date"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Availability</td>
              <td *ngFor="let bookingLimit of bookingLimits" class="text-center">{{bookingLimit}}</td>
            </tr>

            <tr *ngFor="let rate of ratesGuestsDetailed">
              <td>{{rate.numberOfGuests}} Persons</td>
              <td *ngFor="let cta of rate.elements" class="text-center">{{cta}}</td>
            </tr>

            <tr>
              <td>Price Per Room</td>
              <td *ngFor="let cta of pricePerRoom" class="text-center">{{cta}}</td>
            </tr>

            <tr>
              <td>Infants</td>
              <td *ngFor="let cta of priceInfants" class="text-center">{{cta}}</td>
            </tr>
            <tr>
              <td>Children</td>
              <td *ngFor="let cta of priceChilds" class="text-center">{{cta}}</td>
            </tr>
<!--            <tr>-->
<!--              <td>Seniors</td>-->
<!--              <td *ngFor="let cta of priceSeniors">{{cta}}</td>-->
<!--            </tr>-->
            <tr>
              <td>Additional Child</td>
              <td *ngFor="let cta of priceAdditionalChild" class="text-center">{{cta}}</td>
            </tr>
            <tr>
              <td>Additional Adult</td>
              <td *ngFor="let cta of priceAdditionalAdult" class="text-center">{{cta}}</td>
            </tr>

            <tr>
              <td>Minimum Length Of Stay</td>
              <td *ngFor="let cta of ratesMinLos" class="text-center">{{cta}}</td>
            </tr>
            <tr>
              <td>Maximum Length Of Stay</td>
              <td *ngFor="let cta of ratesMaxLos" class="text-center">{{cta}}</td>
            </tr>
            <tr>
              <td>Closed to arrival</td>
              <td *ngFor="let cta of closedToArrival" class="text-center">
                <i *ngIf="cta === 'Open'" class="fa fa-unlock-alt text-success" tooltip="Open"></i>
                <i *ngIf="cta === 'Close'" class="fa fa-lock text-danger" tooltip="Closed"></i>
              </td>
            </tr>
            <tr>
              <td>Closed to departure</td>
              <td *ngFor="let ctd of closedToDeparture" class="text-center">
                <i *ngIf="ctd === 'Open'" class="fa fa-unlock-alt text-success" tooltip="Open"></i>
                <i *ngIf="ctd === 'Close'" class="fa fa-lock text-danger" tooltip="Closed"></i>
              </td>
            </tr>
            <tr>
              <td>Closed (Master)</td>
              <td *ngFor="let ctd of closedMaster" class="text-center">
                <i *ngIf="ctd === 'Open'" class="fa fa-unlock-alt text-success" tooltip="Open"></i>
                <i *ngIf="ctd === 'Close'" class="fa fa-lock text-danger" tooltip="Closed"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
