import {Component} from '@angular/core';
import {EventsService} from "../../../services/events/events.service";

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.css']
})
export class LoadingIndicatorComponent {
  public show: boolean = false;

  constructor(private eventsService: EventsService) {
    this.initializeListener();
  }

  private initializeListener() {
    this.eventsService.on('requestShowLoadingIndicator', (show: boolean) => {
      this.show = show;
    });
  }
}
