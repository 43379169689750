<form [formGroup]="form" (submit)="onSubmitForm()">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">ChannelManager Settings</h3>
      <div class="block-options">
        <button type="submit" class="btn btn-sm btn-alt-primary" (click)="onSubmitForm()"
                [disabled]="housing == null || isLoading === true">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
        </button>
      </div>
    </div>
    <div class="block-content block-content-full">
      <div class="form-group row">
        <label class="col-4 col-form-label" for="housing-cm-preferred-provider">Preferred Provider</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('preferredProvider').touched || form.get('preferredProvider').dirty) && form.get('preferredProvider').invalid}">
          <select class="form-control" id="housing-cm-preferred-provider" name="housing-cm-preferred-provider"
                  formControlName="preferredProvider">
            <option value="">None</option>
            <option value="notinterested">Not Interested</option>
            <option value="aaron">AutoUpdate</option>
            <option value="apaleo">Apaleo</option>
            <option value="assd">ASSD</option>
            <option value="avirato">Avirato</option>
            <option value="beds24">Beds24</option>
            <option value="bestwestern">BestWestern</option>
            <option value="bookworld">Book.World</option>
            <option value="busyrooms">Busy Rooms</option>
            <option value="cbooking">C-Booking</option>
            <option value="casablanka">Casablanka</option>
            <option value="cdsoft">CdSoft</option>
            <option value="channelrush">ChannelRush</option>
            <option value="cloudbeds">Cloudbeds</option>
            <option value="concerto">Concerto</option>
            <option value="cubilis">Cubilis</option>
            <option value="cultuzz">Cultuzz</option>
            <option value="cultswitch">Cultswitch</option>
            <option value="derbysoft">DerbySoft</option>
            <option value="dirs21">DIRS21</option>
            <option value="dedge">D-Edge</option>
            <option value="dorm">DORM Project</option>
            <option value="easybooking">Easybooking</option>
            <option value="easyguest">Easy Guest Management</option>
            <option value="eviivo">Eviivo</option>
            <option value="feratel">Feratel</option>
            <option value="fidelio">Fidelio</option>
            <option value="gastrodat">Gastrodat</option>
            <option value="ghix">GHIX</option>
            <option value="globres">Globres</option>
            <option value="gms">GMS</option>
            <option value="hoteliers">Hoteliers.com</option>
            <option value="hotellink">Hotel Link</option>
            <option value="hotelnetsolutions">Hotelnetsolutions</option>
            <option value="hotelpartner">Hotelpartner</option>
            <option value="hotelprofi">Hotel Profi</option>
            <option value="spiderconnect">HotelSpider</option>
            <option value="hotelwebservice">Hotel Webservice</option>
            <option value="hotline">Hotline</option>
            <option value="ibelsa">Ibelsa</option>
            <option value="ideas">IDEAS</option>
            <option value="idiso">Idiso</option>
            <option value="leonardo">Leonardo</option>
            <option value="myallocator">MyAllocator</option>
            <option value="opera">Opera</option>
            <option value="obs">OBS Buchungs Service</option>
            <option value="onlinechannel">Online Channel</option>
            <option value="optirez">OptiRez</option>
            <option value="otaandgds">OTA&GDS</option>
            <option value="parityrate">Parity Rate</option>
            <option value="profitroom">ProfitRoom</option>
            <option value="protel">Protel</option>
            <option value="qualityreservation">Quality Reservation</option>
            <option value="rategain">Rategain</option>
            <option value="ratetiger">Ratetiger</option>
            <option value="reservento">Reservento</option>
            <option value="rezexchange">RezExchange</option>
            <option value="roomcloud">RoomCloud</option>
            <option value="roomlynx">RoomLynx</option>
            <option value="salesbox">Salesbox</option>
            <option value="seekda">Seekda</option>
            <option value="simplebooking">Simplebooking</option>
            <option value="siteminder">Siteminder</option>
            <option value="smarthotel">Smarthotel</option>
            <option value="staah">STAAH</option>
            <option value="synergy">Synergy</option>
            <option value="synixsabre">Synix Sabre</option>
            <option value="tomas">TOMAS</option>
            <option value="travelclick">TravelClick</option>
            <option value="verticalbooking">Verticalbooking</option>
            <option value="velox">VELOX</option>
            <option value="viato">Viato</option>
            <option value="vioma">VIOMA</option>
            <option value="yieldmanagement">Yield Management</option>
            <option value="yieldplanet">Yield Planet</option>
            <option value="zimmersoftware">ZimmerSoftware</option>
            <option value="other">Other..</option>
          </select>
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('preferredProvider').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="housing-cm-provider">Provider</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('provider').touched || form.get('provider').dirty) && form.get('provider').invalid}">
          <select class="form-control" id="housing-cm-provider" name="housing-cm-provider"
                  formControlName="provider">
            <option value="">None</option>
            <option value="aaron">AutoUpdate</option>
            <option value="dirs21">DIRS21</option>
            <option value="hns">HotelNetSolutions</option>
            <option value="spiderconnect">HotelSpider</option>
            <option value="hyperguest">HyperGuest</option>
            <option value="rategain">RateGain</option>
            <option value="siteminder">SiteMinder</option>
            <option value="travelclick">TravelClick</option>
            <option value="tripmakery">Tripmakery</option>
            <option value="viato">Viato</option>
            <option value="verticalbooking">VerticalBooking</option>
            <option value="zimmersoftware">ZimmerSoftware</option>
          </select>
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('provider').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label" for="housing-cm-provider">Onboarding State</label>
        <div class="col-8"
             [ngClass]="{'is-invalid': (form.get('onboardingState').touched || form.get('onboardingState').dirty) && form.get('onboardingState').invalid}">
          <select class="form-control" id="housing-cm-onboarding-state" name="housing-cm-onboarding-state"
                  formControlName="onboardingState">
            <option value="NONE">None</option>
            <option value="ONBOARDING">CM Onboarding</option>
            <option value="RATE_MAPPING">CM Rate Mapping</option>
            <option value="TESTING">CM Testing</option>
            <option value="FINISHED">Finished</option>
          </select>
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('onboardingState').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4">Enable AutoOffer Reservations</label>
        <div class="col-8">
          <label class="css-control css-control-success css-switch">
            <input type="checkbox" class="css-control-input" id="housing-cm-enable-auto-offer-reservation" name="housing-cm-enable-auto-offer-reservation"
                   formControlName="autoOfferReservations">
            <span class="css-control-indicator"></span>
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4">Enable HousingRequest if no AutoOffer Profile matches</label>
        <div class="col-8">
          <label class="css-control css-control-success css-switch">
            <input type="checkbox" class="css-control-input" id="housing-cm-enable-housingrequest" name="housing-cm-enable-housingrequest"
                   formControlName="cmEnableHousingRequestWhenNoAutoOfferProfileMatches">
            <span class="css-control-indicator"></span>
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4">Enable AutoOffers / DynOffers</label>
        <div class="col-8">
          <label class="css-control css-control-success css-switch">
            <input type="checkbox" class="css-control-input" id="housing-cm-enable" name="housing-cm-enable"
                   formControlName="enabled">
            <span class="css-control-indicator"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</form>
<!-- END Sauce -->
