import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LoadingIndicatorService} from '../../../../../services/loading-indicator/loading-indicator.service';
import {HousingInfoService} from '../../../../../services/housing-info/housing-info.service';
import {HttpClient} from '@angular/common/http';
import {EventsService} from '../../../../../services/events/events.service';
import { IHousingGoodyElement } from './interfaces/housing-goody-element.interface';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-housing-info-details-goodies',
  templateUrl: './goodies.component.html',
  styleUrls: ['./goodies.component.css']
})
export class HousingInfoDetailsGoodiesComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  isLoading = false;

  public housingGoodies: IHousingGoodyElement[] = [];

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {

  }
  ngOnInit() {
    if (this.housing != null) {
      this.mapHousingGoodiesToInternalGoodies();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingGoodiesToInternalGoodies();
    }
  }

  private mapHousingGoodiesToInternalGoodies() {
    this.housingGoodies = [];
    if (this.housing != null && this.housing.internalInfo != null && this.housing.internalInfo.goodies != null) {
      this.housingGoodies = this.housing.internalInfo.goodies;
    }
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

  public requestHousingGoodyDetails(housingId: string, goodyId: string) {
    this.eventsService.broadcast('requestShowModalHousingGoodyDetails', housingId, goodyId);
  }

  public handleClickDelete(goodyId: string) {
    const text = 'Are you really sure you want to delete goody?';
    if (confirm(text) === true) {
      this.showLoading();
      this.http.delete(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/goodies/' + goodyId)
        .subscribe(
          (response: any) => {
            this.housingInfoService.setSelectedHousing(response.housing);
          },
          (err) => {
            console.log(err);

            this.hideLoading();
            this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
          },
          () => {
            this.hideLoading();
          }
        );
    }
  }

}
