<h2 class="content-heading">Request Filter Statistics</h2>
<div class="row gutters-tiny" *ngIf="housingFilterStatistics != null">
  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo"
               data-to="requests">
            {{housingFilterStatistics["BUDGET"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Group Budget (v1)</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo"
               data-to="requests">
            {{housingFilterStatistics["BUDGET_DETAILED"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Group Budget (v2)</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["HOUSING_CLOSED"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Closing times</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["FORBIDDEN_ROOM_TYPES"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Excluded room types</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["FORBIDDEN_ROOM_TYPE_COMBINATIONS"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Excluded room type combinations</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["ACCEPTED_ROOM_TYPES"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Accepted room types</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["FORBIDDEN_GROUP_TYPES"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Group Types</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["MIN_LOS"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Minimum Stay</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["ARRIVAL_DEPARTURE_DAYS"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Fixed arrival / departure days</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["PREFERRED_BOARDING_TYPES"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Boarding</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["LEAD_TIME"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Lead Time</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["AMOUNT_OF_ROOMS"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Number of Rooms</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["AMOUNT_OF_PERSONS"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Group Size</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["MEETING_ROOMS"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Meeting Room</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["GROUP_ROOMS"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Group Room</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["BUS_PARKINGS"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Bus parking</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["MIN_AGE"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Minimum Age</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->

  <!-- In Orders -->
  <div class="col-md-6 col-xl-4">
    <a class="block block-rounded block-link-shadow" href="javascript:void(0)">
      <div class="block-content block-content-full block-sticky-options">
        <div class="block-options">
          <div class="block-options-item">
            <i class="fa fa-shopping-basket fa-2x text-info-light"></i>
          </div>
        </div>
        <div class="py-20 text-center">
          <div class="font-size-h2 font-w700 mb-0 text-info js-count-to-enabled" data-toggle="countTo" data-to="39">
            {{housingFilterStatistics["ACCESSIBLE_ROOMS"]}}
          </div>
          <div class="font-size-sm font-w600 text-uppercase text-muted">Accessible rooms</div>
        </div>
      </div>
    </a>
  </div>
  <!-- END In Orders -->
</div>

<h2 class="content-heading">Request Filter History</h2>
<div class="row">
  <div class="col-md-3">

    <div class="block">
      <div class="block-header block-header-default">
        <h3 class="block-title">Filter</h3>
      </div>
      <div class="block-content block-content-full">
        <form [formGroup]="form">
          <div class="row">
            <label class="col-12">Housing Request Filter</label>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="BUDGET"
                       id="BUDGET"
                       formControlName="BUDGET">
                <label class="custom-control-label" for="BUDGET">Group Budget (v1)</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="BUDGET_DETAILED"
                       id="BUDGET_DETAILED"
                       formControlName="BUDGET_DETAILED">
                <label class="custom-control-label" for="BUDGET_DETAILED">Group Budget (v2)</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="HOUSING_CLOSED"
                       id="HOUSING_CLOSED"
                       formControlName="HOUSING_CLOSED">
                <label class="custom-control-label" for="HOUSING_CLOSED">Closing Times</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="FORBIDDEN_ROOM_TYPES"
                       id="FORBIDDEN_ROOM_TYPES"
                       formControlName="FORBIDDEN_ROOM_TYPES">
                <label class="custom-control-label" for="FORBIDDEN_ROOM_TYPES">Excluded Room Types</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="FORBIDDEN_ROOM_TYPE_COMBINATIONS"
                       id="FORBIDDEN_ROOM_TYPE_COMBINATIONS"
                       formControlName="FORBIDDEN_ROOM_TYPE_COMBINATIONS">
                <label class="custom-control-label" for="FORBIDDEN_ROOM_TYPE_COMBINATIONS">Excluded Room Type
                  Combinations</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="ACCEPTED_ROOM_TYPES"
                       id="ACCEPTED_ROOM_TYPES"
                       formControlName="ACCEPTED_ROOM_TYPES">
                <label class="custom-control-label" for="ACCEPTED_ROOM_TYPES">Accepted Room Types</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="FORBIDDEN_GROUP_TYPES"
                       id="FORBIDDEN_GROUP_TYPES"
                       formControlName="FORBIDDEN_GROUP_TYPES">
                <label class="custom-control-label" for="FORBIDDEN_GROUP_TYPES">Group Types</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="MIN_LOS"
                       id="MIN_LOS"
                       formControlName="MIN_LOS">
                <label class="custom-control-label" for="MIN_LOS">Minimum Stay</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="ARRIVAL_DEPARTURE_DAYS"
                       id="ARRIVAL_DEPARTURE_DAYS"
                       formControlName="ARRIVAL_DEPARTURE_DAYS">
                <label class="custom-control-label" for="ARRIVAL_DEPARTURE_DAYS">Arrival Departure Days</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="PREFERRED_BOARDING_TYPES"
                       id="PREFERRED_BOARDING_TYPES"
                       formControlName="PREFERRED_BOARDING_TYPES">
                <label class="custom-control-label" for="PREFERRED_BOARDING_TYPES">Boarding</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="LEAD_TIME"
                       id="LEAD_TIME"
                       formControlName="LEAD_TIME">
                <label class="custom-control-label" for="LEAD_TIME">Lead Time</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="AMOUNT_OF_ROOMS"
                       id="AMOUNT_OF_ROOMS"
                       formControlName="AMOUNT_OF_ROOMS">
                <label class="custom-control-label" for="AMOUNT_OF_ROOMS">Number of Rooms</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="AMOUNT_OF_PERSONS"
                       id="AMOUNT_OF_PERSONS"
                       formControlName="AMOUNT_OF_PERSONS">
                <label class="custom-control-label" for="AMOUNT_OF_PERSONS">Group Size</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="MEETING_ROOMS"
                       id="MEETING_ROOMS"
                       formControlName="MEETING_ROOMS">
                <label class="custom-control-label" for="MEETING_ROOMS">Meeting Rooms</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="GROUP_ROOMS"
                       id="GROUP_ROOMS"
                       formControlName="GROUP_ROOMS">
                <label class="custom-control-label" for="GROUP_ROOMS">Group Rooms</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="BUS_PARKINGS"
                       id="BUS_PARKINGS"
                       formControlName="BUS_PARKINGS">
                <label class="custom-control-label" for="BUS_PARKINGS">Bus Parking</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="MIN_AGE"
                       id="MIN_AGE"
                       formControlName="MIN_AGE">
                <label class="custom-control-label" for="MIN_AGE">Minimum Age</label>
              </div>
            </div>

            <!-- -->
            <div class="col-12">
              <div class="custom-control custom-checkbox custom-control-inline mb-5">
                <input class="custom-control-input" type="checkbox" name="ACCESSIBLE_ROOMS"
                       id="ACCESSIBLE_ROOMS"
                       formControlName="ACCESSIBLE_ROOMS">
                <label class="custom-control-label" for="ACCESSIBLE_ROOMS">Accessible rooms</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-center">
              <button class="btn btn-alt-success" (click)="requestHousingFilters()">Submit</button>
            </div>
          </div>

        </form>
      </div>
    </div>

  </div>

  <div class="col-md-9">
    <div class="block">
      <div class="block-header block-header-default">
        <h3 class="block-title">Overview</h3>
      </div>
      <div class="block-content block-content-full">
        <div class="row" *ngIf="blockedRequestsPagination != null">
          <div class="col-12">
            <table class="table table-sm">
              <thead>
              <tr>
                <th style="width:25%">RequestId</th>
                <th style="width:75%">Filter</th>
              <tr>
              </thead>
              <tbody>
              <tr
                *ngFor="let blockedRequest of blockedRequests | paginate: {
              itemsPerPage: blockedRequestsPagination.limit,
              currentPage: blockedRequestsPagination.page,
              totalItems: blockedRequestsPagination.total}"
              >
                <td>{{blockedRequest.requestId}}</td>
                <td>
                  <span class="badge badge-info mr-5"
                        *ngFor="let filter of blockedRequest.filters">{{housingRequestFilterTranslationService.translateHousingRequestFilterToReadableString(filter)}}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 text-center">
            <pagination-controls [maxSize]="10"
                                 (pageChange)="sendPage($event)"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
