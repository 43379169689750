<app-content-header i18n-title title="Booking Overview" i18n-subTitle
                    subTitle="A list of all bookings via direct search"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">
  <div class="block">
    <div class="block-header block-header-default">
      <h3 class="block-title">Overview</h3>
    </div>
    <div class="block-content block-content-full">
      <table class="table table-borderless table-striped">
        <thead>
        <tr>
          <th>ID</th>
          <th>From</th>
          <th>To</th>
          <th class="text-right">Nights</th>
          <th class="text-right">Adults</th>
          <th class="text-right">Price</th>
          <th class="text-center">Reservation</th>
          <th>Created At</th>
          <th class="text-center">Link</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let entry of bookings">
          <td>
            {{entry._id}}
          </td>
          <td>
            {{moment.utc(entry.dateStart).format("YYYY-MM-DD")}}
          </td>
          <td>
            {{moment.utc(entry.dateEnd).format("YYYY-MM-DD")}}
          </td>
          <td class="text-right">
            {{entry.nights}}
          </td>
          <td class="text-right">
            {{entry.adults}} <span *ngIf="entry.children !== 0">({{entry.children}} children)</span>
          </td>
          <td class="text-right">
            {{entry.priceSleepingTotal | delimitednumber:2}}
          </td>
          <td class="text-center">
            <span class="badge badge-success" *ngIf="entry.isInstantBooking === true">Yes</span>
            <span class="badge badge-warning" *ngIf="entry.isInstantBooking === false">No</span>
          </td>
          <td>
            {{moment.utc(entry.createdAt).format("YYYY-MM-DD")}}
          </td>
          <td class="text-center">
            <a [routerLink]="['/admin', 'booking', 'direct-search', entry._id]">Details</a>
          </td>
        </tr>
        </tbody>
      </table>
      <nav aria-label="Orders navigation">
        <ul class="pagination justify-content-end" *ngIf="paginationData != null">
          <li class="page-item" *ngFor="let page of paginationData.pages">
            <a class="page-link" [routerLink]="['/admin', 'booking', 'direct-search']" [queryParams]="{page: page}">{{page}}</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
