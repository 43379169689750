<form>
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Images</h3>
      <div class="block-options">
        <button type="submit" class="btn btn-sm btn-alt-primary" (click)="onSubmitImport()" [disabled]="housing == null || isLoading === true" *ngIf="housing != null && housing.sourcePages.bk != null">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Import from Booking
        </button>
      </div>
    </div>
    <div class="block-content block-content-full">
      <div class="row">
        <div class="col-md-4">
          <h3 class="h5">Preview</h3>
          <app-housing-info-image-element *ngIf="housing != null && housing.imagesOptimized != null" [housing]="housing" [image]="housing.imagesOptimized.preview"></app-housing-info-image-element>
        </div>
        <div class="col-md-4">
          <h3 class="h5">Offer</h3>
          <app-housing-info-image-element *ngIf="housing != null && housing.imagesOptimized != null" [housing]="housing" [image]="housing.imagesOptimized.offer"></app-housing-info-image-element>
        </div>
        <div class="col-md-4">
          <h3 class="h5">Upload</h3>
          <div class="form-group row">
            <div class="col-12">
              <div class="custom-file mb-5">
                <input type="file" #fileInput class="custom-file-input js-custom-file-input-enabled" id="example-file-multiple-input-custom" name="example-file-multiple-input-custom" data-toggle="custom-file-input" multiple="">
                <label class="custom-file-label" for="example-file-multiple-input-custom">Choose files</label>
                <label class="custom-file-label" >Upload</label>
              </div>
              <button type="button" class="btn btn-alt-primary" (click)="upload()">Upload</button>
            </div>
          </div>
        </div>
      </div>
      <h3 class="h5">Images</h3>
      <div class="row items-push">
        <div class="col-md-6 col-lg-4 col-xl-3 animated fadeIn" *ngFor="let image of images; let i = index;">
          <app-housing-info-image-element [housing]="housing" [image]="image" [imageId]="i"></app-housing-info-image-element>
        </div>
      </div>
    </div>
  </div>
</form>

<app-housing-info-image-modal *ngIf="housing != null" [housingId]="housing._id" [images]="images" [image]="imageSelected" #imageModalComponent></app-housing-info-image-modal>
