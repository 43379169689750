import { Component, OnInit } from '@angular/core';
import {BreadCrumbElement} from "../../../shared/entities/breadcrumb.element.entity";
import {ActivatedRoute} from "@angular/router";
import {EventsService} from "../../../services/events/events.service";
import {LoadingIndicatorService} from "../../../services/loading-indicator/loading-indicator.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-validation-error-details',
  templateUrl: './validation-error-details.component.html',
  styleUrls: ['./validation-error-details.component.css']
})
export class ValidationErrorDetailsComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  public validationErrorDetails: any;
  public validationErrorId: string;

  constructor(private activatedRoute: ActivatedRoute,
              private eventsService: EventsService,
              private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient) {
  }

  ngOnInit() {
    this.validationErrorId = this.activatedRoute.snapshot.params['validationErrorId'];
    this.requestData();
    this.updateBreadcrumb();
  }

  public requestData() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/validation-error/' + this.validationErrorId)
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
          this.validationErrorDetails = response;
          this.updateBreadcrumb();
        },
        err => {
          this.loadingIndicatorService.hideLoadingBar();
        });
  }

  updateBreadcrumb() {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Developer', 'javascript:void(0)'),
      new BreadCrumbElement('Validation Error', 'javascript:void(0)'),
      new BreadCrumbElement(this.validationErrorId, 'javascript:void(0)')
    ];
  }
}
