import {Component, Input} from '@angular/core';
import {BreadCrumbElement} from '../../entities/breadcrumb.element.entity';

@Component({
    selector: 'app-content-breadcrumb',
    templateUrl: './breadcrumb.component.html'
})
export class BreadCrumbComponent {
    @Input() public elements: Array<BreadCrumbElement> = [];
}
