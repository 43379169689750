import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AccountService} from "../services/accountservice/account.service";
import {Router} from "@angular/router";
import {ConfigService} from "../services/configservice/config.service";
import {EventsService} from "../services/events/events.service";

declare const Codebase: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public email: string;
  public password: string;
  public rememberLogin: string;

  constructor(private router: Router,
              private http: HttpClient,
              private accountService: AccountService,
              private configService: ConfigService,
              private eventsService: EventsService) {
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      'email': new FormControl(this.email, Validators.required),
      'password': new FormControl(this.password, Validators.required),
      'rememberLogin': new FormControl(this.rememberLogin)
    });
    Codebase.init();
  }

  onSubmit() {
    console.log(this.loginForm.get('rememberLogin').value);

    if (this.loginForm.status !== 'VALID') {
      return;
    }

    this.requestLogin().subscribe(
      (response: any) => {
        this.accountService.setToken(response.jwt.access_token);
        this.accountService.setRoles(response.user.roles);
        this.accountService.setUserId(response.user._id);

        // if (this.loginForm.get('rememberLogin').value === true) {
          this.configService.setJWT(response.jwt.access_token);
        // }

        this.router.navigate(['/']);
      },
      error => {
        // @TODO
        console.log(error);
        this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
      }
    )
  }

  private requestLogin() {
    return this.http.post(environment.urls.service + '/api/v1/user/login', {
      email: this.loginForm.get('email').value,
      password: this.loginForm.get('password').value
    });

  }
}
