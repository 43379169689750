import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ModalDirective} from 'ngx-bootstrap';
import {FormControl, FormGroup} from '@angular/forms';
import { EventsService } from '../../../../../../services/events/events.service';
import { LoadingIndicatorService } from '../../../../../../services/loading-indicator/loading-indicator.service';
import { environment } from '../../../../../../../environments/environment';
import { FormUtils } from '../../../../../../shared/utils/form-utils';
import { HousingInfoService } from '../../../../../../services/housing-info/housing-info.service';

@Component({
  selector: 'app-housing-info-details-goodies-details-modal',
  templateUrl: './modal-goodies-details.component.html',
  styleUrls: ['./modal-goodies-details.component.css']
})
export class ModalHousingInfoDetailsGoodiesDetailsComponent implements OnInit {
  public readonly FormUtils = FormUtils;

  public housingGoodyId: string;
  public housingId: string;
  public formMode: number;
  public form: FormGroup;
  public environment_;
  public modalTitle: string;

  @ViewChild('childModal') public childModal: ModalDirective;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
              private housingInfoService: HousingInfoService) {
    this.form = new FormGroup({
      'goody': new FormControl({value: '', disabled: false}),
      'amountSet': new FormControl({value: '', disabled: false}),
      'amountCurrent': new FormControl({value: '', disabled: false}),
      'active': new FormControl({value: false, disabled: false}),
    });
    this.environment_ = environment;
  }

  ngOnInit() {
    this.eventsService.on('requestShowModalHousingGoodyDetails', (housingId, housingGoodyId) => {
      /* */
      this.housingGoodyId = housingGoodyId;
      this.housingId = housingId;

      /* Set Form Mode to "Add Mode" (0) in case GoodyId is missing */
      this.formMode = this.housingGoodyId == null ? 0 : 1;

      /* */
      if (this.formMode === 0) {
        this.modalTitle = 'Add a new housing goodie';
      }
      if (this.formMode === 1) {
        this.modalTitle = 'Edit a housing goodie';
      }

      /* */
      this.resetForm();

      /* */
      this.loadChangeRequestDetails();
      this.showChildModal();
    });
  }

  private resetForm() {
    this.form.get('goody').setValue('');
    this.form.get('amountSet').setValue('');
    this.form.get('amountCurrent').setValue('');
    this.form.get('active').setValue(false);
  }

  private loadChangeRequestDetails() {
    if (this.housingGoodyId == null) {
      return;
    }

    this.loadingIndicatorService.showLoadingBar();
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/goodies/' + this.housingGoodyId)
      .subscribe(
        (response: any) => {
          this.form.get('goody').setValue(response.goody.goody);
          this.form.get('amountSet').setValue(response.goody.amountSet);
          this.form.get('amountCurrent').setValue(response.goody.amountCurrent);
          this.form.get('active').setValue(response.goody.active);
        },
        (error) => {
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public showChildModal(): void {
    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    /* Build Body */
    const body = {
      goody: this.form.get('goody').value,
      section: 'BOOKING',
      amountSet: FormUtils.returnAsNumberOrNullIfEmptyOrNoNumber(this.form.get('amountSet').value),
      amountCurrent: FormUtils.returnAsNumberOrNullIfEmptyOrNoNumber(this.form.get('amountCurrent').value),
      active: this.form.get('active').value,
    };

    /* */
    let localObservable;

    /* */
    if (this.formMode === 0) {
      localObservable = this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/goodies', body);
    } else if (this.formMode === 1) {
      localObservable = this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/goodies/' + this.housingGoodyId, body);
    }

    localObservable
      .subscribe(
        (response: any) => {
          this.housingInfoService.setSelectedHousing(response.housing);
        },
        (err) => {
          if (err.error.message === 'E_VALIDATION_FAILED') {
            try {
              for (const error of err.error.errors) {
                this.form.get(error.property).setErrors(error.constraints);
                this.form.get(error.property).markAsDirty();
              }
            } catch (error) {
              console.log('Catched Error', error);
            }
            return;
          } else {
            this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
          }

        },
        () => {
          this.hideChildModal();
        }
      );
  }

}
