import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BreadCrumbComponent} from '../components/breadcrumb/breadcrumb.component';
import {HeaderComponent} from '../components/header/header.component';
import {BsDatepickerModule, CarouselModule, ModalModule, TooltipModule} from "ngx-bootstrap";
import {LightboxModule} from "ngx-lightbox";
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {ChartsModule} from "ng2-charts";
import {DelimitedNumberPipe} from "../pipes/delimitednumber.pipe";
import {ArrayFromNumberPipe} from "../pipes/array-from-number.pipe";
import {TruncatePipe} from "../pipes/truncate.pipe";


@NgModule({
  imports: [
    CommonModule,
    CarouselModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    GooglePlaceModule,
    ChartsModule,
    LightboxModule
  ],
  declarations: [
    BreadCrumbComponent,
    HeaderComponent,
    DelimitedNumberPipe,
    ArrayFromNumberPipe,
    TruncatePipe
  ],
  exports: [
    BreadCrumbComponent,
    HeaderComponent,
    CarouselModule,
    LightboxModule,
    GooglePlaceModule,
    BsDatepickerModule,
    ModalModule,
    TooltipModule,
    ChartsModule,
    DelimitedNumberPipe,
    ArrayFromNumberPipe,
    TruncatePipe
  ]
})
export class SharedModule {
}
