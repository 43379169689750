<app-content-header i18n-title title="Generated Text - Destination Text" i18n-subTitle
                    subTitle="Update relevant text entries"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">

  <div class="row">
    <div class="col-md-12">
      <div class="block" *ngIf="elementData != null">
        <div class="block-header block-header-default">
          Details
        </div>
        <div class="block-content block-content-full">

          <form [formGroup]="form" (ngSubmit)="submitForm()">
            <div class="row">
              <div class="col-4">
                <label>Description</label>
              </div>
              <div class="col-8">
                <input type="text" class="form-control" id="description"
                       name="description"
                       placeholder="Description" formControlName="description">
                <div class="invalid-feedback"
                     *ngFor="let error of extractValidationErrorsAsArray(form.get('description').errors)">{{ error }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <label>Identifier</label>
              </div>
              <div class="col-8">
                <input type="text" class="form-control" id="identifier"
                       name="identifier"
                       placeholder="Identifier" formControlName="identifier">
                <div class="invalid-feedback"
                     *ngFor="let error of extractValidationErrorsAsArray(form.get('identifier').errors)">{{ error }}
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{'is-invalid': form.get('patternId').dirty === true && form.get('patternId').status === 'INVALID'}">
              <label class="col-4" for="identifier">Pattern</label>
              <div class="col-8">
                <select class="form-control" formControlName="patternId" *ngIf="patterns != null">
                  <option selected>Please select..</option>
                  <option *ngFor="let element of patterns"
                          value="{{element._id}}">{{ element.patternName }}
                  </option>
                </select>
              </div>
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('patternId').errors)">{{ error }}
              </div>
            </div>

            <!-- -->
            <div class="form-group row"
                 [ngClass]="{'is-invalid': form.get('state').dirty === true && form.get('state').status === 'INVALID'}">
              <label class="col-4" for="state">State</label>
              <div class="col-8">
                <select class="form-control" formControlName="state" id="state">
                  <option selected>Please select..</option>
                  <option value="CREATED">Created</option>
                  <option value="WAIT_FOR_TEXT_GENERATION">Wait for Text Generation</option>
                  <option value="KEY_VALUES_GENERATED">Key Values Generated</option>
                  <option value="TEXT_KEY_VALUES_GENERATED">Text Key Values Generated</option>
                  <option value="FINISHED">Finished</option>
                </select>
              </div>
              <div class="invalid-feedback"
                   *ngFor="let error of extractValidationErrorsAsArray(form.get('state').errors)">{{ error }}
              </div>
            </div>

            <!-- -->
            <div class="row">
              <div class="col-12">
                <hr>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4">Enabled</label>
              <div class="col-8">
                <label class="css-control css-control-success css-switch">
                  <input type="checkbox" class="css-control-input" id="enabled" name="enabled"
                         formControlName="enabled">
                  <span class="css-control-indicator"></span>
                </label>
              </div>
            </div>

            <!-- -->
            <div class="row">
              <div class="col-12">
                <hr>
              </div>
            </div>

            <!-- -->
            <div class="row">
              <div class="col-4">
                <label>Keys</label>
              </div>
              <div class="col-8">
                <div formArrayName="keys"
                     *ngFor="let item of form.controls['keys'].controls; let i = index">
                  <div [formGroupName]="i.toString(10)">
                    <input type="text" class="form-control" formControlName='key' placeholder="Key name">
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-right">
                    <button type="button" class="btn btn-sm btn-info text-right" (click)="addNextKeys()">Add
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- -->
            <div class="row">
              <div class="col-12">
                <hr>
              </div>
            </div>

            <!-- -->
            <div class="row">
              <div class="col-4">
                <label>Key Values</label>
              </div>
              <div class="col-8">
                <div formArrayName="keyValues"
                     *ngFor="let item of form.controls['keyValues'].controls; let i = index">
                  <div [formGroupName]="i.toString(10)">
                    <h4>{{ item.value.key }}</h4>

                    <div formArrayName="values"
                         *ngFor="let valueItem of getValuesOfKeyValues(item); let indexValue = index">
                      <div [formGroupName]="indexValue.toString(10)">
                        <input type="text" class="form-control" formControlName='key' placeholder="Value">
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 text-right">
                        <button type="button" class="btn btn-info btn-sm" (click)="addNextKeysSub(item)">Add</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>

          <button class="btn btn-info" type="button" (click)="submitForm()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
