<app-content-header i18n-title title="Housing Information" i18n-subTitle
                    subTitle="Information about {{housingInfoService.getSelectedHousing().name}}"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
*ngIf="housingInfoService.getSelectedHousing() != null"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">

  <div class="row gutters-tiny push">
    <div class="col-6 col-md-3 col-xl-3">
      <a class="block block-rounded block-bordered block-link-shadow text-center" href="javascript:void(0)" [routerLink]="['/housings', housingId, 'overview']">
        <div class="block-content">
          <p class="mt-5">
            <i class="si si-home fa-3x text-muted"></i>
          </p>
          <p class="font-w600">Overview</p>
        </div>
      </a>
    </div>
    <div class="col-6 col-md-3 col-xl-3">
      <a class="block block-rounded block-bordered block-link-shadow text-center" href="javascript:void(0)" [routerLink]="['/housings', housingId, 'details']">
        <div class="block-content">
          <p class="mt-5">
            <i class="si si-info fa-3x text-muted"></i>
          </p>
          <p class="font-w600">Details</p>
        </div>
      </a>
    </div>
    <div class="col-6 col-md-3 col-xl-3">
      <a class="block block-rounded block-bordered block-link-shadow text-center" href="javascript:void(0)" [routerLink]="['/housings', housingId, 'statistics']">
        <div class="block-content">
          <p class="mt-5">
            <i class="si si-bar-chart fa-3x text-muted"></i>
          </p>
          <p class="font-w600">Statistics</p>
        </div>
      </a>
    </div>
    <div class="col-6 col-md-3 col-xl-3">
      <a class="block block-rounded block-bordered block-link-shadow text-center" href="javascript:void(0)" [routerLink]="['/housings', housingId, 'top-partner-program']">
        <div class="block-content">
          <p class="mt-5">
            <i class="si si-bar-chart fa-3x text-muted"></i>
          </p>
          <p class="font-w600">Top Partner Program</p>
        </div>
      </a>
    </div>
    <div class="col-6 col-md-3 col-xl-3" *ngIf="housingInfoService.getSelectedHousing() != null && housingInfoService.getSelectedHousing().channelManager.provider != null">
      <a class="block block-rounded block-bordered block-link-shadow text-center" href="javascript:void(0)" [routerLink]="['/housings', housingId, 'channelmanager']">
        <div class="block-content">
          <p class="mt-5">
            <i class="si si-basket fa-3x text-muted"></i>
          </p>
          <p class="font-w600">Channel Manager</p>
        </div>
      </a>
    </div>
  </div>

  <router-outlet></router-outlet>

</div>

