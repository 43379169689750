import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HousingInfoService } from '../../../../../services/housing-info/housing-info.service';
import { LoadingIndicatorService } from '../../../../../services/loading-indicator/loading-indicator.service';
import { EventsService } from '../../../../../services/events/events.service';
import * as moment from 'moment';

@Component({
  selector: 'app-housing-info-details-onboarding-talks',
  templateUrl: './onboarding-talks.component.html',
  styleUrls: ['./onboarding-talks.component.css']
})
export class HousingInfoDetailsOnboardingTalksComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  form: FormGroup;
  isLoading: boolean = false;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'state': new FormControl({value: '', disabled: false}),
      'callState': new FormControl({value: '', disabled: false}),
      'lastContactAt': new FormControl({value: '', disabled: false}),
      'contactIndividually': new FormControl({value: false, disabled: false}),
      'contactTeam': new FormControl({value: '', disabled: false}),
      'contactAccommodation': new FormControl({value: '', disabled: false}),
      'email': new FormControl({value: '', disabled: false}),
      'phone': new FormControl({value: '', disabled: false}),
      'comments': new FormControl({value: '', disabled: false}),
      'selfService': new FormControl({value: false, disabled: false}),
      'hasFinishedSelfService': new FormControl({value: false, disabled: false}),
    });
  }

  onSubmitForm() {
    if (this.form.status !== 'VALID') {
      this.validateAllFormFields(this.form);
      return;
    }
    this.showLoading();

    this.postData().subscribe(
      (response: any) => {
        this.hideLoading();
        this.housingInfoService.setSelectedHousing(response);
      },
      err => {

        if (err.error.message === 'E_VALIDATION_FAILED') {

          for (const error of err.error.errors) {
            try {
              this.form.get(error.property).setErrors(error.constraints);
              this.form.get(error.property).markAsDirty();
            } catch (error) {
              console.log('Catched Error while setting validation errors', error);
            }
          }

          this.hideLoading();
          return;
        }

        this.hideLoading();
        this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
      }
    );
  }

  private postData() {
    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/onboarding-details', {
      state: this.createNullIfEmptyString(this.form.get('state').value),
      callState: this.createNullIfEmptyString(this.form.get('callState').value),
      lastContactAt: this.form.get('lastContactAt').value,
      contactIndividually: this.createNullIfEmptyString(this.form.get('contactIndividually').value),
      contactTeam: this.createNullIfEmptyString(this.form.get('contactTeam').value),
      contactAccommodation: this.createNullIfEmptyString(this.form.get('contactAccommodation').value),
      email: this.createNullIfEmptyString(this.form.get('email').value),
      phone: this.createNullIfEmptyString(this.form.get('phone').value),
      comments: this.createNullIfEmptyString(this.form.get('comments').value),
      selfService: this.createNullIfEmptyString(this.form.get('selfService').value),
      hasFinishedSelfService: this.createNullIfEmptyString(this.form.get('hasFinishedSelfService').value),
    });
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    return s;
  }

  ngOnInit() {
    if (this.housing != null) {
      this.mapHousingToForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private mapHousingToForm() {
    const vals = {
      state: (this.housing.onboardingDetails == null || this.housing.onboardingDetails.state == null) ? 'NONE' : this.housing.onboardingDetails.state,
      callState: (this.housing.onboardingDetails == null || this.housing.onboardingDetails.callState == null) ? null : this.housing.onboardingDetails.callState,
      lastContactAt: (this.housing.onboardingDetails == null || this.housing.onboardingDetails.lastContactAt == null) ? null : moment(this.housing.onboardingDetails.lastContactAt).toDate(),
      contactIndividually: (this.housing.onboardingDetails == null || this.housing.onboardingDetails.contactIndividually == null) ? false : this.housing.onboardingDetails.contactIndividually,
      contactTeam: (this.housing.onboardingDetails == null || this.housing.onboardingDetails.contactTeam == null) ? null : this.housing.onboardingDetails.contactTeam,
      contactAccommodation: (this.housing.onboardingDetails == null || this.housing.onboardingDetails.contactAccommodation == null) ? null : this.housing.onboardingDetails.contactAccommodation,
      email: (this.housing.onboardingDetails == null || this.housing.onboardingDetails.email == null) ? null : this.housing.onboardingDetails.email,
      phone: (this.housing.onboardingDetails == null || this.housing.onboardingDetails.phone == null) ? null : this.housing.onboardingDetails.phone,
      comments: (this.housing.onboardingDetails == null || this.housing.onboardingDetails.comments == null) ? null : this.housing.onboardingDetails.comments,
      selfService: (this.housing.onboardingDetails == null || this.housing.onboardingDetails.selfService == null) ? false : this.housing.onboardingDetails.selfService,
      hasFinishedSelfService: (this.housing.onboardingDetails == null || this.housing.onboardingDetails.hasFinishedSelfService == null) ? false : this.housing.onboardingDetails.hasFinishedSelfService,
    };
    this.form.setValue(
      vals
    );

    /* */
    this.enableDisableFormCtrl();

    /* */
    if (this.housing.chain == null || this.housing.chain.trim() === '') {
      this.disableContactIndividually();
    }
  }

  public getDisplayStateOfContactIndividuallyDependingOnChain() {
    return (this.housing != null && (this.housing.chain == null || this.housing.chain.trim() === '')) ? 'none' : 'flex';
  }

  private enableDisableFormCtrl() {
    const enable = (this.housing != null);
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (enable) {
          this.form.controls[key].enable();
        } else {
          this.form.controls[key].disable();
        }
      }
    }
  }

  private disableContactIndividually() {
    this.form.controls['contactIndividually'].disable();
  }

  public returnEmptyStringWhenNull(element) {
    return (element == null) ? '' : element;
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }
}
