<form [formGroup]="form" (submit)="onSubmitForm()">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Sources</h3>
      <div class="block-options">
        <button type="submit" class="btn btn-sm btn-alt-primary" (click)="onSubmitForm()"
                [disabled]="housing == null || isLoading === true">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
        </button>
      </div>
    </div>
    <div class="block-content block-content-full">

      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-source-booking-id">Booking</label>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('bookingId').touched || form.get('bookingId').dirty) && form.get('bookingId').invalid}">
          <input type="text" class="form-control" id="housing-source-booking-id" name="housing-source-booking-id"
                 placeholder="Booking Id" formControlName="bookingId">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('bookingId').errors)">
            {{error}}
          </div>
        </div>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('bookingWebsite').touched || form.get('bookingWebsite').dirty) && form.get('bookingWebsite').invalid}">
          <input type="text" class="form-control" id="housing-source-booking-website"
                 name="housing-source-booking-website"
                 placeholder="Booking Website" formControlName="bookingWebsite">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('bookingWebsite').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-source-hrs-id">HRS</label>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('hrsId').touched || form.get('hrsId').dirty) && form.get('hrsId').invalid}">
          <input type="text" class="form-control" id="housing-source-hrs-id" name="housing-source-hrs-id"
                 placeholder="HRS Id" formControlName="hrsId">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('hrsId').errors)">
            {{error}}
          </div>
        </div>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('hrsWebsite').touched || form.get('hrsWebsite').dirty) && form.get('hrsWebsite').invalid}">
          <input type="text" class="form-control" id="housing-source-hrs-website" name="housing-source-hrs-website"
                 placeholder="HRS Website" formControlName="hrsWebsite">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('hrsWebsite').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-source-tripadvisor-id">Tripadvisor</label>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('tripadvisorId').touched || form.get('tripadvisorId').dirty) && form.get('tripadvisorId').invalid}">
          <input type="text" class="form-control" id="housing-source-tripadvisor-id"
                 name="housing-source-tripadvisor-id"
                 placeholder="Tripadvisor Id" formControlName="tripadvisorId">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('tripadvisorId').errors)">
            {{error}}
          </div>
        </div>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('tripadvisorWebsite').touched || form.get('tripadvisorWebsite').dirty) && form.get('tripadvisorWebsite').invalid}">
          <input type="text" class="form-control" id="housing-source-tripadvisor-website"
                 name="housing-source-tripadvisor-website"
                 placeholder="Tripadvisor Website" formControlName="tripadvisorWebsite">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('tripadvisorWebsite').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-source-trivago-id">Trivago</label>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('trivagoId').touched || form.get('trivagoId').dirty) && form.get('trivagoId').invalid}">
          <input type="text" class="form-control" id="housing-source-trivago-id" name="housing-source-trivago-id"
                 placeholder="Trivago Id" formControlName="trivagoId">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('trivagoId').errors)">
            {{error}}
          </div>
        </div>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('trivagoWebsite').touched || form.get('trivagoWebsite').dirty) && form.get('trivagoWebsite').invalid}">
          <input type="text" class="form-control" id="housing-source-trivago-website"
                 name="housing-source-trivago-website"
                 placeholder="Trivago Website" formControlName="trivagoWebsite">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('trivagoWebsite').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-source-hostelworld-id">HostelWorld</label>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('hostelworldId').touched || form.get('hostelworldId').dirty) && form.get('hostelworldId').invalid}">
          <input type="text" class="form-control" id="housing-source-hostelworld-id" name="housing-source-hostelworld-id"
                 placeholder="HostelWorld Id" formControlName="hostelworldId">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('hostelworldId').errors)">
            {{error}}
          </div>
        </div>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('hostelworldWebsite').touched || form.get('hostelworldWebsite').dirty) && form.get('hostelworldWebsite').invalid}">
          <input type="text" class="form-control" id="housing-source-hostelworld-website"
                 name="housing-source-hostelworld-website"
                 placeholder="HostelWorld Website" formControlName="hostelworldWebsite">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('hostelworldWebsite').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-source-hostelworld-id">TravelClick</label>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('travelclickId').touched || form.get('travelclickId').dirty) && form.get('travelclickId').invalid}">
          <input type="text" class="form-control" id="housing-source-travelclick-id" name="housing-source-travelclick-id"
                 placeholder="TravelClick Id" formControlName="travelclickId">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('travelclickId').errors)">
            {{error}}
          </div>
        </div>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('travelclickWebsite').touched || form.get('travelclickWebsite').dirty) && form.get('travelclickWebsite').invalid}">
          <input type="text" class="form-control" id="housing-source-travelclick-website"
                 name="housing-source-travelclick-website"
                 placeholder="TravelClick Website" formControlName="travelclickWebsite">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('travelclickWebsite').errors)">
            {{error}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-source-giata-id">GIATA</label>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('giataId').touched || form.get('giataId').dirty) && form.get('giataId').invalid}">
          <input type="text" class="form-control" id="housing-source-giata-id" name="housing-source-giata-id"
                 placeholder="GIATA Id" formControlName="giataId">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('giataId').errors)">
            {{error}}
          </div>
        </div>
        <div class="col-5"
             [ngClass]="{'is-invalid': (form.get('giataWebsite').touched || form.get('giataWebsite').dirty) && form.get('giataWebsite').invalid}">
          <input type="text" class="form-control" id="housing-source-giata-website"
                 name="housing-source-giata-website"
                 placeholder="GIATA Website" formControlName="giataWebsite">
          <div class="invalid-feedback"
               *ngFor="let error of extractValidationErrorsAsArray(form.get('giataWebsite').errors)">
            {{error}}
          </div>
        </div>
      </div>



    </div>
  </div>
</form>
<!-- END Sauce -->
