<div class="row" *ngIf="accessRights.length !== 0">
  <div class="col-md-12">
    <div class="alert alert-info">
      <h4>This housing is using the account system</h4>
      <table class="table table-sm table-borderless">
        <tr>
          <th>User Id</th>
          <th>Email Address</th>
          <th>Profile Link</th>
        </tr>
        <tr *ngFor="let accessRight of accessRights">
          <td>{{accessRight.user._id}}</td>
          <td>{{accessRight.user.email}}</td>
          <td><a href="/users/{{accessRight.user._id}}">Profile</a></td>
        </tr>
      </table>
    </div>
  </div>
</div>

