<!-- Alternative Sidebar -->
<aside id="side-overlay">

  <!-- Side Overlay Scroll Container -->
  <div id="side-overlay-scroll">
    <!-- Side Header -->
    <div class="content-header content-header-fullrow">
      <div class="content-header-section align-parent">
        <!-- Close Side Overlay -->
        <!-- Layout API, functionality initialized in Codebase() -> uiApiLayout() -->
        <button type="button" class="btn btn-circle btn-dual-secondary align-v-r" data-toggle="layout" data-action="side_overlay_close">
          <i class="fa fa-times text-danger"></i>
        </button>
        <!-- END Close Side Overlay -->

        <!-- User Info -->
        <!--<div class="content-header-item">-->
        <!--<a class="img-link mr-5" href="be_pages_generic_profile.html">-->
        <!--<img class="img-avatar img-avatar32" src="http://avatar.erepublik.tools/citizen/0.jpg" alt="">-->
        <!--</a>-->
        <!--<a class="align-middle link-effect text-primary-dark font-w600" href="be_pages_generic_profile.html">John Smith</a>-->
        <!--</div>-->
        <!-- END User Info -->
      </div>
    </div>
    <!-- END Side Header -->

  </div>
</aside>
