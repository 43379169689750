<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-popin"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" bsModal>
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">Add a new housing to the access right list</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" data-dismiss="modal" aria-label="Close"
                    (click)="hideChildModal()">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content block-content-full">
          <div class="alert alert-info" *ngIf="errHousingIdNotFound === true">
            <h4>HousingId not found</h4>
            We didn't find a housing by the provided housingId.
          </div>
          <div class="alert alert-info" *ngIf="errUserAlreadyHasAccessRights === true">
            <h4>User already has access</h4>
            This user already has access to this housing.
          </div>
          <div class="alert alert-info" *ngIf="errHousingAlreadyHasAccessRights === true">
            <h4>Housing has master account already</h4>
            This housing has a <a href="{{errHousingAlreadyHasAccessRightsUserLink}}">master account</a> already.
          </div>

          <form [formGroup]="form">
            <div class="row">

              <div class="col-md-12">

                <div class="form-group row"
                     [ngClass]="{'is-invalid': form.get('housingId').dirty === true && form.get('housingId').status === 'INVALID'}">
                  <label class="col-12" for="user-info-housing-access-housingId">HousingId</label>
                  <div class="col-md-12">
                    <input type="text" class="form-control"
                           id="user-info-housing-access-housingId"
                           name="housingId"
                           placeholder="HousingId"
                           autocomplete="off"
                           formControlName="housingId"
                    >
                    <div class="invalid-feedback"
                         *ngFor="let error of extractValidationErrorsAsArray(form.get('housingId').errors)">
                      {{error}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-alt-secondary" data-dismiss="modal" (click)="hideChildModal()">Abort
        </button>
        <button type="button" class="btn btn-alt-success" (click)="handleClick()">
          <i class="fa fa-check"></i> Add Access
        </button>
      </div>
    </div>
  </div>
</div>

