import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HousingInfoService} from '../../../../../services/housing-info/housing-info.service';
import {LoadingIndicatorService} from '../../../../../services/loading-indicator/loading-indicator.service';
import {EventsService} from '../../../../../services/events/events.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-housing-info-details-channel-manager-settings-hyperguest',
  templateUrl: './channel-manager-settings-hyperguest.component.html',
  styleUrls: ['./channel-manager-settings-hyperguest.component.css']
})
export class HousingInfoDetailsChannelManagerSettingsHyperguestComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  form: FormGroup;
  isLoading: boolean = false;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'subscriptionId': new FormControl({value: '', disabled: true}),
    });
  }

  onSubmitFunction(obs: Observable<any>) {
    this.showLoading();

    obs.subscribe(
      (response: any) => {
        this.hideLoading();
        /* */
        const relevantObject = response.response;
        this.eventsService.broadcast('requestShowModalCMSettingsHyperguestResponse', relevantObject);

        /* */
        this.housingInfoService.setSelectedHousing(response.housing);
      },
      err => {
        this.hideLoading();
        this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
      }
    );
  }

  public handleRequestSubscribe() {
    return this.onSubmitFunction(this.requestSubscribe());
  }

  private requestSubscribe() {
    return this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/channel-manager/subscription/subscribe', {});
  }

  public handleRequestSubscriptionDetails() {
    return this.onSubmitFunction(this.requestSubscriptionDetails());
  }

  private requestSubscriptionDetails() {
    return this.http.get(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/channel-manager/subscription/details');
  }

  public handleRequestFullAriSync() {
    return this.onSubmitFunction(this.requestFullAriSync());
  }

  private requestFullAriSync() {
    return this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/channel-manager/subscription/full-ari-sync', {});
  }

  public handleRequestDisableSubscription() {
    return this.onSubmitFunction(this.requestDisableSubscription());
  }

  private requestDisableSubscription() {
    return this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/channel-manager/subscription/disable-subscription', {});
  }

  public handleRequestEnableSubscription() {
    return this.onSubmitFunction(this.requestEnableSubscription());
  }

  private requestEnableSubscription() {
    return this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/channel-manager/subscription/enable-subscription', {});
  }

  public handleRequestUnsubscribeSubscription() {
    return this.onSubmitFunction(this.requestUnsubscribeSubscription());
  }

  private requestUnsubscribeSubscription() {
    return this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/channel-manager/subscription/unsubscribe', {});
  }

  ngOnInit() {
    if (this.housing != null) {
      this.mapHousingToForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  private mapHousingToForm() {
    const vals = {
      subscriptionId: this.housing.channelManager.cmSubscriptionId == null ? '' : this.housing.channelManager.cmSubscriptionId,
    };
    this.form.setValue(
      vals
    );
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }
}
