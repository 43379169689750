import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { ConfigService } from '../../../../services/configservice/config.service';
import { EventsService } from '../../../../services/events/events.service';
import { BreadCrumbElement } from '../../../../shared/entities/breadcrumb.element.entity';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FieldsBasicPlaceDetails } from '../../../../shared/consts/fields-basic-place-details';

@Component({
  selector: 'app-destination-override-list',
  templateUrl: './destination-override-list.component.html',
  styleUrls: ['./destination-override-list.component.css']
})
export class DestinationOverrideListComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public page = 1;
  public limit = 25;
  public section = 1;

  /* */
  public pagination: any;
  public destinations;
  public form: FormGroup;

  public destinationGooglePlaceDetails;
  public options = {
    fields: FieldsBasicPlaceDetails,
  };

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private activatedRoute: ActivatedRoute,
              private http: HttpClient) {

    /* */
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Destination Override', 'javascript:void(0)'),
      new BreadCrumbElement('List Overrides', 'javascript:void(0)')
    ];

    /* */
    this.form = new FormGroup({
      'googleAddressText': new FormControl({value: '', disabled: false}),
    });

  }

  ngOnInit() {
    /* */
    this.eventsService.on('refreshDestinationOverrideList', (bookingChangeRequestId) => {
      this.loadChangeRequestsToCheck();
    });

    /* */
    this.loadChangeRequestsToCheck();
  }

  public async handleGooglePlaceIdChangeOriginal(address: Address) {
    this.destinationGooglePlaceDetails = address;
  }

  public loadChangeRequestsToCheck() {
    this.loadingIndicatorService.showLoadingBar();

    /* Build params */
    let params = new HttpParams();
    params = params.append('page', this.page.toString());
    params = params.append('limit', this.limit.toString());
    params = params.append('googlePlaceId', this.destinationGooglePlaceDetails != null && this.form.get('googleAddressText').value.trim() !== '' ? this.destinationGooglePlaceDetails.place_id : null);

    /* Remove empty params */
    let paramsFinal = new HttpParams();
    for (const paramsKey of params.keys()) {
      if (params.get(paramsKey) != null && params.get(paramsKey).trim() !== '') {
        paramsFinal = paramsFinal.append(paramsKey, params.get(paramsKey));
      }
    }

    /* Send Request and map data */
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/destination-override', {params: paramsFinal})
      .subscribe((response: any) => {
          this.destinations = response.destinationOverrides;
          this.pagination = response.pagination;
        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public async deleteDestination(id: string) {
    this.loadingIndicatorService.showLoadingBar();

    this.http.delete(environment.urls.service + '/api/v1/authenticated/admin/destination-override/' + id,
    )
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
          this.loadChangeRequestsToCheck();
        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          console.log('Error', error);
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));

        }
      );
  }

  public requestChangeRequestDetails(id: string) {
    this.eventsService.broadcast('requestShowModalBookingChangeRequestEdit', id);
  }

  public sendPage(e: number) {
    this.page = e;
    this.loadChangeRequestsToCheck();
  }
}
