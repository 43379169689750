<app-content-header i18n-title title="Generated Text - Destinations" i18n-subTitle
                    subTitle="Filter our AI generated destination texts"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">

  <div class="row">
    <div class="col-md-3">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>
        <div class="block-content block-content-full">
          <form [formGroup]="form" (ngSubmit)="submitForm()">

          <div class="row">
            <div class="col-4">
              <label>Keys</label>
            </div>
            <div class="col-8">
              <div formArrayName="keys"
                   *ngFor="let item of getControls(); let i = index">
                <div [formGroupName]="i.toString(10)">
                  <input type="text" class="form-control" formControlName='key' placeholder="Key name">
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-right">
                  <button type="button" class="btn btn-sm btn-info text-right" (click)="addNextKeys()">Add Element
                  </button>
                </div>
              </div>
            </div>
          </div>

          </form>
        </div>

        <div class="block-content block-content-full block-content-sm text-center">
          <button type="button" (click)="submitForm()" class="btn btn-info">Submit</button>
        </div>
      </div>

    </div>

    <div class="col-md-9">
      <div class="block" *ngIf="paginationData != null">
        <div class="block-header block-header-default">
         &nbsp;
        </div>
        <div class="block-content block-content-full">
          <div class="table-responsive">
            <table class="table table-striped table-vcenter">
              <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Identifier</th>
                <th class="text-left">State</th>
                <th class="text-left">aiSessionUuid</th>
                <th class="text-left">text</th>
                <th class="text-left">Key\Value</th>
              </tr>
              </thead>
              <tr
                *ngFor="let element of elements | paginate: {
              itemsPerPage: paginationData.limit,
              currentPage: paginationData.page,
              totalItems: paginationData.total}"
              >
                <td class="text-left align-text-top">
                  {{element._id}}
                </td>
                <td class="text-left align-text-top">
                  {{element.identifier}}
                </td>
                <td class="text-left align-text-top">
                  {{element.state}}
                </td>
                <td class="text-left align-text-top">
                  {{element.aiSessionUuid}}
                </td>
                <td class="text-left align-text-top">
                  <code>{{element.text}}</code>
                </td>
                <td class="text-left align-text-top">
                  <ul *ngFor="let keyValueData of element.keyValues">
                    <li>Key: {{keyValueData.key}}</li>
                    <li>Value: {{keyValueData.value}}</li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="block-content block-content-full block-content-sm text-center">
          <pagination-controls [maxSize]="10"
                               (pageChange)="sendPage($event)"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
