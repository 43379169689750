import {Component, OnInit} from '@angular/core';
import {HousingInfoService} from "../../../../services/housing-info/housing-info.service";
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  public housing;
  public environment = environment;

  constructor(public housingInfoService: HousingInfoService) {
      // this.housing = this.housingInfoService.getSelectedHousing();
  }

  ngOnInit() {
  }

}
