import {Routes} from '@angular/router';
import {AddComponent} from "./add/add.component";

export const BoundariesV2Routes: Routes = [
  {
    path: 'developer/boundaries-v2/add', component: AddComponent
  },
  {
    path: 'developer/boundaries-v2/:boundaryId/edit', component: AddComponent
  }
];
