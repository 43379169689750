import { Component, ViewChild, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { ConfigService } from '../../../../services/configservice/config.service';
import { EventsService } from '../../../../services/events/events.service';
import { BreadCrumbElement } from '../../../../shared/entities/breadcrumb.element.entity';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FieldsBasicPlaceDetails } from '../../../../shared/consts/fields-basic-place-details';

@Component({
  selector: 'app-destination-override-details',
  templateUrl: './destination-override-details.component.html',
  styleUrls: ['./destination-override-details.component.css']
})
export class DestinationOverrideDetailsComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  public form: FormGroup;

  public options = {
    fields: FieldsBasicPlaceDetails,
  };
  public googleLocationNew = null;
  public googleLocationNewReadable = null;
  public googleLocationOriginal = null;
  public googleLocationOriginalReadable = null;

  public destinationId = null;

  @ViewChild('originalDestinationInput') public originalDestinationInput;
  @ViewChild('newDestinationInput') public newDestinationInput;


  constructor(private route: ActivatedRoute,
              private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private activatedRoute: ActivatedRoute,
              private http: HttpClient) {

    /* */
    this.destinationId = this.route.snapshot.params.destinationId;

    /* */
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Destination Override', 'javascript:void(0)'),
      new BreadCrumbElement('Destination Details', 'javascript:void(0)'),
      new BreadCrumbElement(this.destinationId, 'javascript:void(0)'),
    ];
  }

  public ngOnInit() {
    this.getDestinationDetails();
  }

  public async handleGooglePlaceIdChangeOriginal(address: Address) {
    this.googleLocationOriginal = address;
    this.googleLocationOriginalReadable = JSON.stringify(this.googleLocationOriginal, null, 2);
  }

  public async handleGooglePlaceIdChangeNew(address: Address) {
    this.googleLocationNew = address;
    this.googleLocationNewReadable = JSON.stringify(this.googleLocationNew, null, 2);
  }

  public async postNewDestinationOverride() {
    if (this.googleLocationOriginal == null ||
      this.googleLocationNew == null) {
      return;
    }

    this.loadingIndicatorService.showLoadingBar();

    this.http.put(environment.urls.service + '/api/v1/authenticated/admin/destination-override/' + this.destinationId,
      {
        selectedDestination: this.googleLocationOriginal,
        replacedDestination: this.googleLocationNew,
        isActive: true,
        autocompletionName: this.googleLocationOriginal.formatted_address,
      })
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();

        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          console.log('Error', error);
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));

        }
      );
  }

  public async getDestinationDetails() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/destination-override/' + this.destinationId)
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();


          this.googleLocationOriginal = this.cleanupGoogleLocationResponse(response.selectedDestination);
          this.googleLocationNew = this.cleanupGoogleLocationResponse(response.replacedDestination);

          this.googleLocationOriginalReadable = JSON.stringify(this.googleLocationOriginal, null, 2);
          this.googleLocationNewReadable = JSON.stringify(this.googleLocationNew, null, 2);

          // TODO Details in Form eintragen
          this.originalDestinationInput.nativeElement.value = this.googleLocationOriginal.formatted_address;
          this.newDestinationInput.nativeElement.value = this.googleLocationNew.formatted_address;
        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          console.log('Error', error);
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));

        }
      );
  }

  private cleanupGoogleLocationResponse(googleResponse: any) {
    // if (Object.keys(googleResponse.geometry.viewport.northeast).length === 0) {
    //   delete googleResponse.geometry.viewport.northeast;
    // }
    // if (Object.keys(googleResponse.geometry.viewport.southwest).length === 0) {
    //   delete googleResponse.geometry.viewport.southwest;
    // }

    return googleResponse;
  }

}
