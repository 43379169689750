<app-content-header i18n-title title="Destination Search" i18n-subTitle
                    subTitle="Filter our SEO Destination Pages DB"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">

  <div class="row">
    <div class="col-md-3">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>
        <div class="block-content block-content-full">
          <form [formGroup]="form">
            <div class="row">

              <div class="col-md-12">

                <div class="form-group row">
                  <label class="col-12" for="name">Name</label>
                  <div class="col-md-12">
                    <input type="text" class="form-control" id="name" name="name"
                           placeholder="Name"
                           autocomplete="off"
                           formControlName="name"
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12" for="googlePlaceIdDirect">GooglePlaceId</label>
                  <div class="col-md-12">
                    <input type="text" class="form-control" id="googlePlaceIdDirect" name="googlePlaceIdDirect"
                           placeholder="Google Place Id"
                           autocomplete="off"
                           formControlName="googlePlaceIdDirect"
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-12">
                    <hr>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-12">
                    <div class="custom-control custom-checkbox custom-control-inline mb-5"
                    >
                      <input class="custom-control-input" type="checkbox" name="isEnabled"
                             id="isEnabled"
                             formControlName="isEnabled">
                      <label class="custom-control-label" for="isEnabled">Is Enabled</label>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <hr>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">&nbsp;</div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <button class="btn btn-alt-success" (click)="submitForm()">Submit</button>
              </div>
            </div>

          </form>
        </div>
      </div>

    </div>

    <div class="col-md-9">
      <div class="block" *ngIf="paginationData != null">
        <div class="block-header block-header-default">
          <h3 class="block-title">{{userTotal}} Destination Pages</h3>
          <label>Add new Destination</label>&nbsp;<button class="btn btn-sm btn-success" (click)="showAddDestinationModal()"
                                                          tooltip="Add a new destination"><i class="fa fa-plus"></i></button>
        </div>
        <div class="block-content block-content-full">
          <div class="table-responsive">
            <table class="table table-striped table-vcenter">
              <thead>
              <tr>
                <th class="text-left">Public Id</th>
                <th class="text-left">Name</th>
                <th class="text-left">Place Id</th>
                <th class="text-left">Enabled</th>
                <th class="text-center">Actions</th>
              </tr>
              </thead>
              <tr
                *ngFor="let destination of destinations | paginate: {
              itemsPerPage: paginationData.limit,
              currentPage: paginationData.page,
              totalItems: paginationData.total}"
              >
                <td class="text-left">
                  {{destination.publicId}}
                </td>
                <td class="text-left">
                  {{destination.destinationName.de}}
                </td>
                <td class="text-left">
                  {{destination.googlePlaceId}}
                </td>
                <td class="text-left">
                  {{destination.isEnabled}}
                </td>
                <td class="text-center">
                  <div class="btn-group">
                    <a class="btn btn-sm btn-primary" [routerLink]="['/pages/destinations', destination._id]"><i
                      class="fa fa-pencil"></i></a>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="block-content block-content-full block-content-sm text-center">
          <pagination-controls [maxSize]="10"
                               (pageChange)="sendPage($event)"
          ></pagination-controls>
        </div>
      </div>


    </div>


  </div>

</div>
<tripmakery-destination-add-destination-modal #destinationAddDestinationModalComponent></tripmakery-destination-add-destination-modal>
