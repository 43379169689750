import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ModalDirective} from "ngx-bootstrap";
import {FormControl, FormGroup} from "@angular/forms";
import {EventsService} from "../../../../services/events/events.service";
import {environment} from "../../../../../environments/environment";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";
import {FormUtils} from "../../../../shared/utils/form-utils";
import * as moment from "moment";

@Component({
  selector: 'tripmakery-phone-callback-request-edit-modal',
  templateUrl: './modal-phone-callback-request-edit.component.html',
  styleUrls: ['./modal-phone-callback-request-edit.component.css']
})
export class ModalPhoneCallbackRequestEditComponent implements OnInit {
  public bookingChangeRequestId: string;
  public phoneCallbackData: any;
  public phoneCallbacksRequest: any;
  public phoneCallbacksUserId: any;
  public form: FormGroup;
  public environment_;
  public isLoading: boolean = false;

  @ViewChild('childModal') public childModal: ModalDirective;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'state': new FormControl({value: '', disabled: false}),
    });
    this.environment_ = environment;
  }

  ngOnInit() {
    this.eventsService.on('requestShowModalPhoneCallbackRequestEdit', (bookingChangeRequestId) => {
      this.bookingChangeRequestId = bookingChangeRequestId;
      this.loadChangeRequestDetails();
      this.showChildModal()
    });
  }

  private loadChangeRequestDetails() {
    this.loadingIndicatorService.showLoadingBar();
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/phone-callback/' + this.bookingChangeRequestId)
      .subscribe(
        (response: any) => {
          this.phoneCallbackData = response;
          this.form.get('state').setValue(response.state);

          this.loadChangeRequests(this.phoneCallbackData.requestId, null);
          this.loadChangeRequests(null, this.phoneCallbackData.userId);
        },
        (error) => {
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  private loadChangeRequests(requestId: string,
                             userId: string) {
    this.loadingIndicatorService.showLoadingBar();

    /* Build params */
    let params = new HttpParams();
    params = params.append('page', '1');
    params = params.append('limit', '10');

    params = params.append('requestId', FormUtils.returnAsNullIfEmpty(requestId));
    params = params.append('userId', FormUtils.returnAsNullIfEmpty(userId));

    /* Remove empty params */
    let paramsFinal = new HttpParams();
    for (const paramsKey of params.keys()) {
      if (params.get(paramsKey) != null && params.get(paramsKey).trim() != '') {
        paramsFinal = paramsFinal.append(paramsKey, params.get(paramsKey));
      }
    }

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/phone-callback', {params: paramsFinal})
      .subscribe(
        (response: any) => {
          /* Date Mapping */
          for (const element of response.elements) {
            element.createdAt = moment(element.createdAt).format('DD.MM.YYYY');
            element.updatedAt = moment(element.updatedAt).format('DD.MM.YYYY');
          }

          if (requestId != null) {
            this.phoneCallbacksRequest = response.elements;
          }
          if (userId != null) {
            this.phoneCallbacksUserId = response.elements;
          }
        },
        (error) => {
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public showChildModal(): void {
    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    this.isLoading = true;
    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/phone-callback/' + this.bookingChangeRequestId + '/state', {
      state: this.createNullIfEmptyString(this.form.get('state').value),
    })
      .subscribe(
        (response: any) => {
          this.eventsService.broadcast('refreshPhoneCallRequestsList');
        },
        (error) => {
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        },
        () => {
          this.isLoading = false;
          this.hideChildModal();
        }
      );
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return null;
    }
    return s;
  }

  private createFalseIfEmptyString(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return false;
    }
    return s;
  }

}
