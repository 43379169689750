import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LoadingIndicatorService } from '../../../services/loading-indicator/loading-indicator.service';
import { ConfigService } from '../../../services/configservice/config.service';
import { EventsService } from '../../../services/events/events.service';
import { BreadCrumbElement } from '../../../shared/entities/breadcrumb.element.entity';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-import-onboardings',
  templateUrl: './import-onboardings.component.html'
})
export class ImportOnboardingsComponent implements OnInit {
  fileData: File = null;
  @ViewChild('fileInput') fileInput;
  @ViewChild('fileInputChain') fileInputChain;
  @ViewChild('fileInputHubspot') fileInputHubspot;

  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];
  public isLoading = false;

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public page = 1;
  public limit = 25;
  public section = 1;

  /* */
  public pagination: any;
  public elements;
  public formFilter: FormGroup;
  public formAddition: FormGroup;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private http: HttpClient) {

    /* */
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Developer', 'javascript:void(0)'),
      new BreadCrumbElement('Imports', 'javascript:void(0)'),
      new BreadCrumbElement('Housing Onboardings', 'javascript:void(0)'),
    ];

  }

  upload() {
    const fileBrowser = this.fileInput.nativeElement;
    const formData = new FormData();

    if (fileBrowser.files && fileBrowser.files[0]) {
      for (const file of fileBrowser.files) {
        console.log(file);
        formData.append('files', file);
      }
    }
    console.log(JSON.stringify(formData));
    this._upload(formData);
  }

  _upload(formData) {
    this.loadingIndicatorService.showLoadingBar();

    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/import/housing/onboarding-details', formData)
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
          if (response.housingsNotFound.length !== 0) {
            this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify({housingsNotFound: response.housingsNotFound}));
          }
        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          console.log('Error', error);
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));

        }
      );
  }

  uploadChain() {
    const fileBrowser = this.fileInputChain.nativeElement;
    const formData = new FormData();

    if (fileBrowser.files && fileBrowser.files[0]) {
      for (const file of fileBrowser.files) {
        console.log(file);
        formData.append('files', file);
      }
    }
    console.log(JSON.stringify(formData));
    this._uploadChain(formData);
  }

  _uploadChain(formData) {
    this.loadingIndicatorService.showLoadingBar();

    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/import/housing/onboarding-chain-details', formData)
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();

        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          console.log('Error', error);
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));

        }
      );
  }

  uploadHubspot() {
    const fileBrowser = this.fileInputHubspot.nativeElement;
    const formData = new FormData();

    if (fileBrowser.files && fileBrowser.files[0]) {
      for (const file of fileBrowser.files) {
        console.log(file);
        formData.append('files', file);
      }
    }
    console.log(JSON.stringify(formData));
    this._uploadHubspot(formData);
  }

  _uploadHubspot(formData) {
    this.loadingIndicatorService.showLoadingBar();

    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/import/housing/onboarding-details/hubspot', formData)
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();

        },
        error => {
          if (error.error === 'Unauthorized') {
          }
          console.log('Error', error);
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));

        }
      );
  }

  ngOnInit() {
  }

}
