import {Component, OnInit} from '@angular/core';
import {BreadCrumbElement} from '../../../../shared/entities/breadcrumb.element.entity';
import {ActivatedRoute} from '@angular/router';
import {EventsService} from '../../../../services/events/events.service';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LoadingIndicatorService} from '../../../../services/loading-indicator/loading-indicator.service';
import {HousingInfoService} from '../../../../services/housing-info/housing-info.service';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.css']
})
export class OfferDetailsComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [
    new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
    new BreadCrumbElement('Offers', 'javascript:void(0)'),
    new BreadCrumbElement('Offer Details', 'javascript:void(0)')
  ];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public request;
  public pipeline;
  public offer;
  public preOffer;

  /* */
  public relevantBoarding;
  public isBoardingIncluded: boolean = false;
  public chosenBoarding: string;

  /* */
  public offerId: string;

  /* */
  constructor(private activatedRoute: ActivatedRoute,
              public housingInfoService: HousingInfoService,
              private eventsService: EventsService,
              private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient) {

  }

  public ngOnInit() {
    this.offerId = this.activatedRoute.snapshot.params['offerId'];
    this.requestRequestAndPipelineDataFromService();

  }

  public requestRequestAndPipelineDataFromService() {
    this.http.get(environment.urls.service + '/api/v2/authenticated/admin/offer/' + this.offerId)
      .subscribe(
        (response: any) => {

          /* */
          this.pipeline = response.pipeline;
          this.request = response.request;
          this.offer = response.offer;
          this.preOffer = response.preOffer;

          /* */
          const boardings = this.offer.boardingDetails.filter(element => element.available === true);
          const relevantBoarding = boardings[boardings.length - 1];

          /* */
          this.relevantBoarding = relevantBoarding;
          this.isBoardingIncluded = relevantBoarding.included;
          this.chosenBoarding = relevantBoarding.boardingType;
        }
      );
  }

}
