import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HousingInfoService } from '../../../../services/housing-info/housing-info.service';
import { EventsService } from '../../../../services/events/events.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { environment } from '../../../../../environments/environment';
import { HousingFilter } from '@tripbakers/enums/dist/enums/housing-filter.enum';
import { FormControl, FormGroup } from '@angular/forms';
import {
  HousingRequestFilterTranslationService
} from '../../../../services/housing-request-filter/housing-request-filter-translation.service';

@Component({
  selector: 'app-housing-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
  private housingId: string;
  public housingFilterStatistics;
  public blockedRequests;
  public blockedRequestsPagination;
  public blockedRequestsPage = 1;
  public isLoading: boolean = false;
  public form: FormGroup;

  constructor(private activatedRoute: ActivatedRoute,
              public housingInfoService: HousingInfoService,
              private eventsService: EventsService,
              private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient,
              private changeDetectorRef: ChangeDetectorRef,
              public housingRequestFilterTranslationService: HousingRequestFilterTranslationService,
              ) {

    this.form = new FormGroup({
      'BUDGET': new FormControl({value: true, disabled: false}),
      'BUDGET_DETAILED': new FormControl({value: true, disabled: false}),
      'HOUSING_CLOSED': new FormControl({value: true, disabled: false}),
      'FORBIDDEN_ROOM_TYPES': new FormControl({value: true, disabled: false}),
      'FORBIDDEN_ROOM_TYPE_COMBINATIONS': new FormControl({value: true, disabled: false}),
      'ACCEPTED_ROOM_TYPES': new FormControl({value: true, disabled: false}),
      'FORBIDDEN_GROUP_TYPES': new FormControl({value: true, disabled: false}),
      'MIN_LOS': new FormControl({value: true, disabled: false}),
      'ARRIVAL_DEPARTURE_DAYS': new FormControl({value: true, disabled: false}),
      'PREFERRED_BOARDING_TYPES': new FormControl({value: true, disabled: false}),
      'LEAD_TIME': new FormControl({value: true, disabled: false}),
      'AMOUNT_OF_ROOMS': new FormControl({value: true, disabled: false}),
      'AMOUNT_OF_PERSONS': new FormControl({value: true, disabled: false}),
      'MEETING_ROOMS': new FormControl({value: true, disabled: false}),
      'GROUP_ROOMS': new FormControl({value: true, disabled: false}),
      'BUS_PARKINGS': new FormControl({value: true, disabled: false}),
      'MIN_AGE': new FormControl({value: true, disabled: false}),
      'ACCESSIBLE_ROOMS': new FormControl({value: true, disabled: false}),
    });

  }


  ngOnInit() {
    this.activatedRoute.parent.paramMap.subscribe(params => {
      this.housingId = params.get('housingId');
      console.log(this.housingId);
      this.requestFilterStatistics();
      this.requestHousingFilters();
    });
  }

  public async requestFilterStatistics() {
    try {
      const response = await this.http.get(environment.urls.service + '/api/v1/authenticated/admin/housing-filter-statistic/' + this.housingId).toPromise();

      /* */
      const housingFilterFields = Object.values(HousingFilter);
      const housingFilterDetails = {};
      for (const filter of housingFilterFields) {
        housingFilterDetails[filter] = 0;
      }

      /* */
      for (const blockingFilter of (response as any).blockingFilters) {
        housingFilterDetails[blockingFilter.filter] = blockingFilter.numberOfBlockedRequests;
      }

      /* */
      this.housingFilterStatistics = housingFilterDetails;

      /* */
      this.changeDetectorRef.detectChanges();
    } catch (error) {

    }
  }

  public async requestHousingFilters() {
    try {

      let params = new HttpParams();
      params = params.append('page', this.blockedRequestsPage.toString(10));
      params = params.append('limit', '10');

      for (const filter of this.getBlockedRequestsFilterFromForm()) {
        params = params.append('filters', filter);
      }

      const response = await this.http.get(environment.urls.service + '/api/v1/authenticated/admin/housing-filter-statistic/' + this.housingId + '/blocked-requests', {
        params,
      }).toPromise();

      /* */
      this.blockedRequests = (response as any).blockedRequests;
      this.blockedRequestsPagination = (response as any).pagination;

      /* */
      this.changeDetectorRef.detectChanges();
    } catch (error) {

    }
  }

  private getBlockedRequestsFilterFromForm(): string[] {
    const filter = [];

    const housingFilterFields = Object.values(HousingFilter);
    for (const housingFilter of housingFilterFields) {
        if (this.form.get(housingFilter) != null &&
          this.form.get(housingFilter).value === true) {
          filter.push(housingFilter);
        }
    }

    return filter;
  }

  public sendPage(page: number) {
    this.blockedRequestsPage = page;
    this.requestHousingFilters();
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

}
