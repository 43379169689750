import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {LoadingIndicatorService} from "../../../../../services/loading-indicator/loading-indicator.service";
import {HousingInfoService} from "../../../../../services/housing-info/housing-info.service";
import {HttpClient} from "@angular/common/http";
import {EventsService} from "../../../../../services/events/events.service";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-housing-info-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.css']
})
export class HousingInfoDetailsRoomsComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  form: FormGroup;
  isLoading: boolean = false;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'numberOfRooms': new FormControl({value: '', disabled: true}),
      'amount_singleBed': new FormControl({value: '', disabled: true}),
      'amount_doubleBed': new FormControl({value: '', disabled: true}),
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  onSubmitForm() {
    if (this.form.status !== 'VALID') {
      return;
    }
    this.showLoading();

    this.postData().subscribe(
      (response: any) => {
        this.hideLoading();
        this.housingInfoService.setSelectedHousing(response);
      },
      err => {

        if (err.error.message === "E_VALIDATION_FAILED") {
          try {
            for (const error of err.error.errors) {
              this.form.get(error.property).setErrors(error.constraints);
              this.form.get(error.property).markAsDirty();
            }
          } catch (error) {
            console.log("Catched E", error)
          }
          this.hideLoading();
          return;
        }

        this.hideLoading();
        this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));
      }
    )
  }

  private postData() {
    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/rooms-simple', {
      numberOfRooms: this.createNullIfEmptyStringOrReturnFloat(this.form.get('numberOfRooms').value),
      amount_singleBed: this.createNullIfEmptyStringOrReturnFloat(this.form.get('amount_singleBed').value),
      amount_doubleBed: this.createNullIfEmptyStringOrReturnFloat(this.form.get('amount_doubleBed').value),
    });
  }

  private createNullIfEmptyStringOrReturnFloat(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return null;
    }
    const d = parseFloat(s);

    if (isNaN(d)) {
      return s;
    }

    return d;
  }

  private mapHousingToForm() {
    /* Mapping */
    let doubleBedMapping = this.extractDoubleRooms(this.housing.roomDetailsNew);
    if (doubleBedMapping === 0 && this.housing.roomDetails.doubleBed != null) {
      doubleBedMapping = this.housing.roomDetails.doubleBed;
    }

    const vals = {
      amount_singleBed: this.extractSingleRooms(this.housing.roomDetailsNew),
      amount_doubleBed: this.returnEmptyStringWhenNull(doubleBedMapping),
      numberOfRooms: (this.housing.numberOfRooms != null) ? this.housing.numberOfRooms : 0
    };
    this.form.setValue(
      vals
    );

    this.enableDisableFormCtrl();
  }

  public returnEmptyStringWhenNull(element) {
    return (element == null) ? '' : element;
  }

  private enableDisableFormCtrl() {
    const enable = (this.housing != null);
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (enable) {
          this.form.controls[key].enable();
        } else {
          this.form.controls[key].disable();
        }
      }
    }
  }

  private extractSingleRooms(roomDetailsNew: any) {
    if (roomDetailsNew == null) {
      return 0;
    }

    for (const room of roomDetailsNew.rooms) {
      if (room.type === "SINGLE_BED") {
        return room.count;
      }
    }

    return 0;
  }

  private extractDoubleRooms(roomDetailsNew: any) {
    if (roomDetailsNew == null) {
      return 0;
    }

    for (const room of roomDetailsNew.rooms) {
      if (room.type === "DOUBLE_BED") {
        return room.count;
      }
    }

    return 0;
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }
}
