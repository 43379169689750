<div bsModal #childModal="bs-modal" class="modal" id="modal-large" tabindex="-1" role="dialog"
     aria-labelledby="modal-large" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">Import a boundary from Open Street Maps</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option"  aria-label="Close" (click)="childModal.hide()">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content">
          <form [formGroup]="formSearch" (ngSubmit)="getBoundariesFromAPI()">
            <div class="form-group row">
              <label class="col-4" for="destination">Search for</label>
              <div class="col-8">
                <!--              <input type="text" class="form-control" id="destination" name="destination"-->
                <!--                     placeholder="Please enter the destination you would like to look for"-->
                <!--                     autocomplete="off"-->
                <!--              >-->
                <div class="input-group">
                  <input type="text" class="form-control" id="destination" name="destination" formControlName="destination"
                         placeholder="Please enter the destination you would like to look for">
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="getBoundariesFromAPI()">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-md-12">
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">

              <div class="list-group push">
                <ng-container *ngFor="let boundary of openStreetMapBoundaries; let i = index;">
                  <a class="list-group-item list-group-item-action justify-content-between align-items-center"
                     (click)="selectBoundaryByIndex(i)">
                    {{boundary.display_name | truncate:24}} <br>
                    <span class="badge badge-secondary">{{boundary.osm_type}}</span>&nbsp;
                    <span class="badge badge-primary">{{boundary.type}}</span>
                  </a>
                </ng-container>
              </div>

            </div>

            <div class="col-md-8">
              <ng-container *ngIf="openStreetMapSelectedElement != null && googleLocation != null && boundingBox != null">
                <div class="block block-bordered">
                  <agm-map [latitude]="openStreetMapSelectedElement.lat" [longitude]="openStreetMapSelectedElement.lon">
<!--                    <agm-data-layer [geoJson]="openStreetMapSelectedElementGeoJson"></agm-data-layer>-->
<!--                    <agm-polygon [paths]="boundaryPath" *ngFor="let boundaryPath of boundaryPaths"></agm-polygon>-->
                    <agm-polygon [paths]="boundaryPaths"></agm-polygon>
                    <agm-marker [latitude]="googleLocation.geometry.location.lat" [longitude]="googleLocation.geometry.location.lng">
                    </agm-marker>
                  </agm-map>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <h5>Boundary Details</h5>
                    <div class="table-responsive">
                      <table>
                        <tr>
                          <td>Display Name</td>
                          <td>{{openStreetMapSelectedElement.display_name}}</td>
                        </tr>
                        <tr>
                          <td>Importance</td>
                          <td>{{openStreetMapSelectedElement.importance}}</td>
                        </tr>
                        <tr>
                          <td>Lat / Lng</td>
                          <td>{{openStreetMapSelectedElement.lat}} / {{openStreetMapSelectedElement.lon}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">&nbsp;</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-alt-secondary" data-dismiss="modal" (click)="childModal.hide()">Abort
        </button>
        <button type="button" class="btn btn-alt-success" data-dismiss="modal" (click)="propagateImport()">
          <i class="fa fa-check"></i> Import
        </button>
      </div>
    </div>
  </div>
</div>
