import {Component, OnInit} from '@angular/core';
import {EventsService} from "../../../services/events/events.service";
import {ConfigService} from "../../../services/configservice/config.service";
import {LoadingIndicatorService} from "../../../services/loading-indicator/loading-indicator.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {FormControl, FormGroup} from "@angular/forms";
import * as Offset from "polygon-offset";
import {BreadCrumbElement} from "../../../shared/entities/breadcrumb.element.entity";

@Component({
  selector: 'app-boundaries-edit',
  templateUrl: './boundaries-edit.component.html',
  styleUrls: ['./boundaries-edit.component.css']
})
export class BoundariesEditComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  public boundaryId: string;
  public boundary: any;

  public boundaryPaths = [];
  public amountBoundaryPoints = 0;
  public boundaryPathsOptimized = [];
  public boundaryPathsOptimizedGMaps = [];
  public amountBoundaryPointsOptimized = 0;

  public returnedHousings: number;
  public performanceDatabaseQuery: number;

  public boundingBox = {
    east: 0,
    south: 0,
    west: 0,
    north: 0
  };

  public lat: number;
  public lng: number;

  public formGeneral: FormGroup;
  public formValidation: FormGroup;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private http: HttpClient,
              private router: ActivatedRoute) {
  }

  ngOnInit() {
    this.boundaryId = this.router.snapshot.params["boundaryId"];

    this.formGeneral = new FormGroup({
      'rounds': new FormControl(''),
      'margin': new FormControl(''),
      'padding': new FormControl('')
    });

    this.formValidation = new FormGroup({
      'validated': new FormControl(''),
      'accepted': new FormControl(''),
      'customEntry': new FormControl(''),
      'selectableOnMarketplace': new FormControl(''),
    });

    this.loadBoundaryDetails();
  }

  public loadBoundaryDetails() {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});
    this.loadingIndicatorService.showLoadingBar();

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/boundary/' + this.boundaryId, {headers: headers})
      .subscribe((response: any) => {
          this.boundary = response;
          this.updateBoundaryGmaps();

          this.formGeneral.setValue({
            rounds: response.optimization.rounds,
            margin: response.optimization.margin,
            padding: response.optimization.padding,
          });

          this.formValidation.setValue({
            validated: response.validated,
            accepted: response.accepted,
            customEntry: response.customEntry,
            selectableOnMarketplace: response.selectableOnMarketplace,
          });

          this.calculateOptimizedBoundary();
          this.loadingIndicatorService.hideLoadingBar();

          this.breadCrumbElements = [
            new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
            new BreadCrumbElement('Boundaries', 'javascript:void(0)'),
            new BreadCrumbElement('Edit', 'javascript:void(0)'),
            new BreadCrumbElement(this.boundary.googleResponse.formatted_address, 'javascript:void(0)'),
          ]
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }

  public updateBoundaryGmaps() {
    const globalBoundaries = [];
    this.amountBoundaryPoints = 0;

    if (this.boundary.osmResponse.geojson.type === "MultiPolygon") {
      for (const entryGlobal of this.boundary.osmResponse.geojson.coordinates) {
        const boundary = [];

        for (const entryMin of entryGlobal) {
          const boundaryInternal = [];
          for (const entry of entryMin) {
            boundaryInternal.push({lng: entry[0], lat: entry[1]})
          }
          this.amountBoundaryPoints += boundaryInternal.length;
          globalBoundaries.push(boundaryInternal);
        }

        globalBoundaries.push(boundary);
      }
    } else if (this.boundary.osmResponse.geojson.type === "Polygon") {
      for (const entryGlobal of this.boundary.osmResponse.geojson.coordinates) {
        const boundary = [];
        for (const entry of entryGlobal) {
          boundary.push({lng: entry[0], lat: entry[1]})
        }
        this.amountBoundaryPoints += boundary.length;
        globalBoundaries.push(boundary);
      }
    }

    this.boundingBox = {
      east: parseFloat(this.boundary.osmResponse.boundingbox[0]),
      south: parseFloat(this.boundary.osmResponse.boundingbox[1]),
      west: parseFloat(this.boundary.osmResponse.boundingbox[2]),
      north: parseFloat(this.boundary.osmResponse.boundingbox[3])
    };
    this.boundaryPaths = globalBoundaries;
  }

  public calculateOptimizedBoundary() {
    const offset = new Offset();
    let globalBoundariesGMaps = [];
    const globalBoundariesOptimized = [];
    this.amountBoundaryPointsOptimized = 0;

    if (this.boundary.osmResponse == null || this.formGeneral.get('margin').value === '') {
      return;
    }

    if (this.boundary.osmResponse.geojson.type === "MultiPolygon") {
      for (const entryGlobal of this.boundary.osmResponse.geojson.coordinates) {

        for (const entryMin of entryGlobal) {
          const minimizedBoundary = [this.minimizeBoundary(entryMin)];
          const margin = parseFloat(this.formGeneral.get('margin').value);
          const margined = margin === 0 ? minimizedBoundary : offset.data(minimizedBoundary).margin(margin);

          const boundariesInternalGmaps = [];
          const boundariesInternalOptimized = [];

          for (const x of margined) {
            for (const entry of x) {
              boundariesInternalGmaps.push({lng: entry[0], lat: entry[1]});
              boundariesInternalOptimized.push(entry);
            }
          }

          if (boundariesInternalOptimized[0] != boundariesInternalOptimized[boundariesInternalOptimized.length - 1]) {
            boundariesInternalOptimized.push(boundariesInternalOptimized[0]);
          }

          if (boundariesInternalOptimized.length > 3) {
            globalBoundariesGMaps.push(boundariesInternalGmaps);
            globalBoundariesOptimized.push(boundariesInternalOptimized);

            this.amountBoundaryPointsOptimized += boundariesInternalOptimized.length;
          }
        }
      }
    } else if (this.boundary.osmResponse.geojson.type === "Polygon") {
      const minimizedBoundary = [this.minimizeBoundary(this.boundary.osmResponse.geojson.coordinates[0])];
      const margined = offset.data(minimizedBoundary).margin(parseFloat(this.formGeneral.get('margin').value));

      for (const entryGlobal of margined) {
        const boundary = [];
        const boundaryOptimized = [];

        for (const entry of entryGlobal) {
          boundaryOptimized.push(entry);
          boundary.push({lng: entry[0], lat: entry[1]})
        }

        if (boundaryOptimized[0] != boundaryOptimized[boundaryOptimized.length - 1]) {
          boundaryOptimized.push(boundaryOptimized[0]);
        }

        this.amountBoundaryPointsOptimized += boundary.length;
        globalBoundariesGMaps.push(boundary);
        globalBoundariesOptimized.push(boundaryOptimized);
      }
    }

    this.boundaryPathsOptimized = globalBoundariesOptimized;
    this.boundaryPathsOptimizedGMaps = globalBoundariesGMaps;
  }

  public minimizeBoundary(data: Array<Array<number>>) {
    const rounds = parseFloat(this.formGeneral.get('rounds').value);
    let dataArray = data;

    for (let i = 0; i < rounds; i++) {
      dataArray = dataArray.filter(function (_, i) {
        return (i + 1) % 2;
      })
    }

    return dataArray;
  }

  public patchBoundaryDetails() {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});
    this.loadingIndicatorService.showLoadingBar();

    console.log(this.formValidation.get('validated').value);
    console.log(this.formValidation.get('validated'));

    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/boundary/' + this.boundaryId,
      {
        boundariesOptimized: this.boundaryPathsOptimized,
        rounds: parseFloat(this.formGeneral.get('rounds').value),
        margin: parseFloat(this.formGeneral.get('margin').value),
        validated: this.formValidation.get('validated').value,
        accepted: this.formValidation.get('accepted').value,
        customEntry: this.formValidation.get('customEntry').value,
        selectableOnMarketplace: this.formValidation.get('selectableOnMarketplace').value
      },
      {headers: headers})
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }

  public testBoundaryDetails() {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});
    this.loadingIndicatorService.showLoadingBar();


    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/boundary/' + this.boundaryId + '/test-query',
      {headers: headers})
      .subscribe((response: any) => {
          console.log(response);
          this.returnedHousings = response.amountHousings;
          this.performanceDatabaseQuery = parseFloat(response.performance[0] + '.' + (response.performance[1] / 1000000));
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }

  public testBoundaryDetailsNew() {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});
    this.loadingIndicatorService.showLoadingBar();


    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/boundary/test-query',
      {
        boundariesOptimized: this.boundaryPathsOptimized,
      },
      {headers: headers})
      .subscribe((response: any) => {
          console.log(response);
          this.returnedHousings = response.amountHousings;
          this.performanceDatabaseQuery = parseFloat(response.performance[0] + '.' + (response.performance[1] / 1000000));
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }
}
