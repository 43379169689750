<app-content-header i18n-title title="Destination Report" i18n-subTitle
                    subTitle="Ranking of requested destinations"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">
  <div class="row">
    <div class="col-md-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Settings</h3>
        </div>
        <div class="block-content">
          <form [formGroup]="formGeneral">
            <div class="form-group row">
              <label class="col-lg-3 col-form-label" for="dateStart">Start</label>
              <div class="col-lg-9">
                <input type="text" class="form-control" id="dateStart" name="dateStart"
                       placeholder="Enter a Start Date.." bsDatepicker formControlName="dateStart"
                       [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label" for="dateEnd">End</label>
              <div class="col-lg-9">
                <input type="text" class="form-control" id="dateEnd" name="dateEnd" placeholder="Enter a End Date.."
                       bsDatepicker formControlName="dateEnd"
                       [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
              </div>
            </div>


            <div class="col-12">
              <div class="custom-control custom-radio custom-control-inline mb-5">
                <input class="custom-control-input" type="radio" name="mode" id="example-inline-radio1" value="REQUEST" formControlName="mode">
                <label class="custom-control-label" for="example-inline-radio1">Requests</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline mb-5">
                <input class="custom-control-input" type="radio" name="mode" id="example-inline-radio2" value="BOOKED" formControlName="mode">
                <label class="custom-control-label" for="example-inline-radio2">Bookings</label>
              </div>
            </div>



            <div class="form-group row">
              <div class="col-lg-12">
                <button class="btn btn-primary col-lg-12" type="button" (click)="requestData()">Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Result</h3>
        </div>
        <div class="block-content">
          <table class="table">
            <thead>
            <tr>
              <th>#</th>
              <th>GooglePlaceId</th>
              <th>Destination</th>
              <th class="text-right">Count</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of result; let i = index" [ngClass]="{'table-success': DIRECT_SEARCH_DESTINATIONS.indexOf(data._id) !== -1}">
              <td>#{{i+1}}</td>
              <td>{{data._id}}</td>
              <td>{{data.name}}</td>
              <td class="text-right">{{data.count}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
