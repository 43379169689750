import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HousingInfoService {
  private selectedHousing;

  constructor() { }

  public getSelectedHousing() {
    return this.selectedHousing;
  }

  public setSelectedHousing(h: any) {
    this.selectedHousing = h;
  }
}
