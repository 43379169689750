import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {EventsService} from "../../../../../services/events/events.service";
import * as moment from "moment";
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'tripmakery-housing-info-overview-availability',
  templateUrl: './housing-availability.component.html',
  styleUrls: ['./housing-availability.component.css']
})
export class HousingAvailabilityComponent implements OnInit {
  public dateStartNum: number;
  public dateEndNum: number;

  @Input() housingId: string;
  @Input() section: string;
  @Input() sectionTitle: string;
  public data;
  public paginationData;
  public page = 1;


  public daysInMonth: Array<number>;
  public daysInMonthFormatted: Array<string>;

  public availabilityMapped = {};

  /* */
  bsValue: Date = new Date(2017, 7);
  minMode: BsDatepickerViewMode = 'month';

  bsConfig: Partial<BsDatepickerConfig>;

  constructor(private http: HttpClient,
              private eventsService: EventsService) {
  }

  ngOnInit() {
    /* Settings and Default Values */
    this.bsValue = moment().startOf('month').toDate();
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode,
      dateInputFormat: 'MMMM YYYY'
    });

    /* */
    this.dateStartNum = parseFloat(moment().startOf('month').format('YYYYMMDD'));
    this.dateEndNum = parseFloat(moment().endOf('month').format('YYYYMMDD'));

    /* */
    this.daysInMonth = this.getDaysArrayByMonth(moment().startOf('month').toDate()).reverse();
    this.daysInMonthFormatted = this.getDaysArrayByMonthFormatted(moment().startOf('month').toDate()).reverse();

    this.requestDataFromBackend();
  }

  onValueChange(value: Date): void {
    /* */
    this.dateStartNum = parseFloat(moment(value).startOf('month').format('YYYYMMDD'));
    this.dateEndNum = parseFloat(moment(value).endOf('month').format('YYYYMMDD'));

    /* */
    this.daysInMonth = this.getDaysArrayByMonth(value).reverse();
    this.daysInMonthFormatted = this.getDaysArrayByMonthFormatted(value).reverse();

    /* */
    this.requestDataFromBackend();
  }

  private mapAvailabilities() {
    this.availabilityMapped = {};
    for (const availability of this.data.availabilities) {
      this.availabilityMapped[availability.dateNum] = availability.rooms.length > 0;
    }
  }

  public createHttpParams() {
    let params = new HttpParams();
    params = params.append('dateStart', this.dateStartNum.toString());
    params = params.append('dateEnd', this.dateEndNum.toString());
    params = params.append('page', this.page.toString());
    params = params.append('limit', '50');
    return params;
  }

  public requestDataFromBackend() {
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/hostelworld/availability', {
      params: this.createHttpParams()
    })
      .subscribe(
        (response: any) => {
          this.data = response;

          this.mapAvailabilities();
        }
      );
  }

  public sendPage(page: number) {
    this.page = page;
    this.requestDataFromBackend();
  }

  public getDaysArrayByMonth(date: Date) {
    let daysInMonth = moment(date).daysInMonth();
    const arrDays = [];

    while (daysInMonth) {
      const current = parseFloat(moment(date).date(daysInMonth).format("YYYYMMDD"));
      arrDays.push(current);
      daysInMonth--;
    }

    return arrDays;
  }
  public getDaysArrayByMonthFormatted(date: Date) {
    let daysInMonth = moment(date).daysInMonth();
    const arrDays = [];

    while (daysInMonth) {
      const current = moment(date).date(daysInMonth).locale("de").format("L");
      arrDays.push(current);
      daysInMonth--;
    }

    return arrDays;
  }
}
