import {Component, ViewChild} from '@angular/core';
import {EventsService} from "../services/events/events.service";
import {BsModalService, ModalDirective} from "ngx-bootstrap";

@Component({
  selector: 'app-error-output',
  templateUrl: './error-output.component.html',
  styleUrls: ['./error-output.component.css']
})
export class ErrorOutputComponent {
  @ViewChild('childModal') childModal: ModalDirective;
  public message: string;

  constructor(private eventsService: EventsService,
              private modalService: BsModalService) {
    this.initializeListener();
  }

  private initializeListener() {
    this.eventsService.on('requestShowErrorMessage', (message: string) => {
      this.message = message;
      this.childModal.show();
    });
  }

  public hideModal() {
    this.childModal.hide();
  }

}
