<app-content-header i18n-title title="Housing Onboarding Import" i18n-subTitle
                    subTitle="Import your csv files to synchronize our production database"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>
<div class="content">

  <div class="row">
    <div class="col-md-12">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Upload Housing Data</h3>
        </div>
        <div class="block-content block-content-full">
          <h3 class="h5">Upload</h3>
          <div class="form-group row">
            <div class="col-12">
              <div class="custom-file mb-5">
                <input type="file"
                       #fileInput
                       class="custom-file-input js-custom-file-input-enabled"
                       id="example-file-multiple-input-custom"
                       name="example-file-multiple-input-custom"
                       data-toggle="custom-file-input"
                       multiple=""
                       accept=".csv"
                >
                <label class="custom-file-label" for="example-file-multiple-input-custom">Choose files</label>
                <label class="custom-file-label">Upload</label>
              </div>
              <button type="button" class="btn btn-alt-primary" (click)="upload()">Upload</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Upload Chain Data</h3>
        </div>
        <div class="block-content block-content-full">
          <h3 class="h5">Upload</h3>
          <div class="form-group row">
            <div class="col-12">
              <div class="custom-file mb-5">
                <input type="file"
                       #fileInputChain
                       class="custom-file-input js-custom-file-input-enabled"
                       id="example-file-multiple-input-chain"
                       name="example-file-multiple-input-chain"
                       data-toggle="custom-file-input"
                       accept=".csv"
                >
                <label class="custom-file-label" for="example-file-multiple-input-custom">Choose files</label>
                <label class="custom-file-label">Upload</label>
              </div>
              <button type="button" class="btn btn-alt-primary" (click)="uploadChain()">Upload</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Upload HubSpot Data</h3>
        </div>
        <div class="block-content block-content-full">
          <h3 class="h5">Upload</h3>
          <div class="form-group row">
            <div class="col-12">
              <div class="custom-file mb-5">
                <input type="file"
                       #fileInputHubspot
                       class="custom-file-input js-custom-file-input-enabled"
                       id="example-file-multiple-input-hubspot"
                       name="example-file-multiple-input-hubspot"
                       data-toggle="custom-file-input"
                       accept=".csv"
                >
                <label class="custom-file-label" for="example-file-multiple-input-custom">Choose files</label>
                <label class="custom-file-label">Upload</label>
              </div>
              <button type="button" class="btn btn-alt-primary" (click)="uploadHubspot()">Upload</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
