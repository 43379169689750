<app-content-header i18n-title [title]="title" i18n-subTitle
                    [subTitle]="subTitle"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">
  <div class="row" *ngIf="(boundaryId != null && googleLocation != null) || boundaryId == null">
    <div class="col-md-12">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title" *ngIf="this.boundaryId == null">Search for your specific destination</h3>
          <h3 class="block-title" *ngIf="this.boundaryId != null">Current destination</h3>
        </div>

        <div class="block-content block-content-full">
          <div class="form-group row">
            <label class="col-4" for="destination">Destination</label>
            <div class="col-8">
              <form [formGroup]="formGoogleLocation">
                <input type="text" class="form-control" id="destination" name="destination"
                       placeholder="Please enter the destination you would like to add"
                       autocomplete="off"
                       ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                       formControlName="googleAddressText"
                       (onAddressChange)="handleGooglePlaceIdChange($event)"
                >
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="googleLocation != null">
    <div class="col-md-12">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Google Destination Details</h3>
        </div>
        <div class="block-content block-content-full">
          <div class="row mb-15">
            <div class="col-md-12">
              <h5>Destination Details</h5>
              <div class="table-responsive">
                <table>
                  <tr>
                    <td>Google Place Id:</td>
                    <td>{{googleLocation.place_id}}</td>
                  </tr>
                  <tr>
                    <td>Formatted Address:</td>
                    <td>{{googleLocation.formatted_address}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="row mb-15">
            <div class="col-md-12">
              <h5>Address Components</h5>
              <div class="table-responsive">
                <table>
                  <ng-container *ngFor="let addressComponent of googleLocation.address_components">
                    <tr>
                      <td>Long Name:</td>
                      <td>{{addressComponent.long_name}}</td>
                    </tr>
                    <tr>
                      <td>Short Name:</td>
                      <td>{{addressComponent.short_name}}</td>
                    </tr>
                    <tr>
                      <td>Types:</td>
                      <td>
                    <span *ngFor="let addressType of addressComponent.types">
                      {{addressType}}
                    </span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <hr>
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<!--  <div class="row" *ngIf="googleLocation != null">-->
  <div class="row" [hidden]="googleLocation == null">
    <div class="col-md-12">

      <div class="block">
        <ul class="nav nav-tabs nav-tabs-block align-items-center js-tabs-enabled" data-toggle="tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': selectionMode === 0}" (click)="selectionMode = 0">Base
              Boundary</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': selectionMode === 1}" (click)="selectionMode = 1">Final
              Boundary</a>
          </li>
          <li class="nav-item ml-auto">
            <div class="btn-group btn-group-sm mr-15">
              <button type="button" class="btn btn-sm btn-secondary" (click)="showModalImportOSM()">
                Import
              </button>
            </div>
          </li>
        </ul>
        <div class="block-content tab-content">
          <div class="tab-pane" [ngClass]="{'show': selectionMode === 0, 'active': selectionMode === 0}"
               role="tabpanel">
            <app-boundaries-v2-boundary-container #boundaryContainerComponentBase [mode]="0"
                                                  [coordinatesPrefilled]="coordinatesGoogleBase"
            [googleLocation]="googleLocation"></app-boundaries-v2-boundary-container>
          </div>
          <div class="tab-pane" [ngClass]="{'show': selectionMode === 1, 'active': selectionMode === 1}"
               role="tabpanel">
            <app-boundaries-v2-boundary-container #boundaryContainerComponentFinal [mode]="1"
                                                  [coordinatesPrefilled]="coordinatesGoogleOptimized"
                                                  [googleLocation]="googleLocation"></app-boundaries-v2-boundary-container>
          </div>
        </div>
      </div>


    </div>
  </div>


  <div class="row" *ngIf="googleLocation != null">
    <div class="col-md-12">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Settings</h3>
        </div>
        <div class="block-content block-content-full">

          <form [formGroup]="formFinalization">
            <div class="form-group row">
              <div class="col-12">
                <div class="custom-control custom-checkbox mb-5">
                  <input class="custom-control-input" type="checkbox" name="boundaries-edit-validated"
                         id="boundaries-edit-validated"
                         value="option1" checked="" formControlName="validated">
                  <label class="custom-control-label" for="boundaries-edit-validated">Validated</label>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-control custom-checkbox mb-5">
                  <input class="custom-control-input" type="checkbox" name="boundaries-edit-accepted"
                         id="boundaries-edit-accepted"
                         value="option1" checked="" formControlName="accepted">
                  <label class="custom-control-label" for="boundaries-edit-accepted">Accepted</label>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-control custom-checkbox mb-5">
                  <input class="custom-control-input" type="checkbox" name="boundaries-edit-accepted"
                         id="boundaries-edit-customEntry"
                         value="option1" checked="" formControlName="customEntry">
                  <label class="custom-control-label" for="boundaries-edit-customEntry">Custom Entry</label>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-control custom-checkbox mb-5">
                  <input class="custom-control-input" type="checkbox" name="boundaries-edit-accepted"
                         id="boundaries-edit-selectableOnMarketplace"
                         value="option1" checked="" formControlName="selectableOnMarketplace">
                  <label class="custom-control-label" for="boundaries-edit-selectableOnMarketplace">Selectable on
                    marketplace</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="googleLocation != null">
    <div class="col-md-12">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Final Step</h3>
        </div>
        <div class="block-content block-content-full">

          <div class="alert alert-info" role="alert"
               *ngIf="boundaryContainerComponentFinal != null && boundaryContainerComponentFinal.hasBoundaryBeenTestedSuccessfully === false && formFinalization.get('accepted').value === true">
            <h6 class="alert-heading font-size-h4 font-w400">Don't forget to test the boundary!</h6>
            <p class="mb-0">You need to successfully test the boundary in case you want to save and activate it!</p>
          </div>

          <button class="btn btn-sm btn-primary" (click)="sendStuffToDb()"
                  [disabled]="boundaryContainerComponentFinal != null && boundaryContainerComponentFinal.hasBoundaryBeenTestedSuccessfully === false && formFinalization.get('accepted').value === true">
            Save
          </button>
<br><br><br>
          <button class="btn btn-sm btn-primary" (click)="testBoundaryInDb()"
                  [disabled]="boundaryId == null">
            Debug Query in our database
          </button>
        </div>
      </div>
    </div>
  </div>


</div>

<app-boundaries-v2-modal-import-osm
  [destinationPrefilled]="googleLocation != null ? googleLocation.formatted_address : null"
  [googleLocation]="googleLocation"
  #modalImportOsmComponent></app-boundaries-v2-modal-import-osm>

