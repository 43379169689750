import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";
import {ConfigService} from "../../../../services/configservice/config.service";
import {EventsService} from "../../../../services/events/events.service";
import {ModalDirective} from "ngx-bootstrap";
import {environment} from "../../../../../environments/environment";
import {OpenStreetMapResponse} from "../../../../shared/interfaces/open-street-map-response.interface";
import {FormControl, FormGroup} from "@angular/forms";
import {OpenStreetMapsService} from "../../../../services/open-street-maps/open-street-maps.service";
import {AgmMap, LatLngBoundsLiteral} from "@agm/core";

@Component({
  selector: 'app-boundaries-v2-modal-import-osm',
  templateUrl: './modal-import-osm.component.html'
})
export class ModalImportOsmComponent implements OnInit {
  @ViewChild('childModal') public childModal: ModalDirective;
  @ViewChild('AgmMap') agmMap: AgmMap;

  public openStreetMapBoundaries: OpenStreetMapResponse[];
  public openStreetMapSelectedElement: OpenStreetMapResponse;
  public openStreetMapSelectedElementGeoJson: any;

  @Input() googleLocation: any;
  public boundingBox: LatLngBoundsLiteral;
  public boundaryPaths: number[];

  @Input() destinationPrefilled: string;

  public formSearch: FormGroup;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private http: HttpClient,
              private osmService: OpenStreetMapsService) {

  }

  ngOnInit() {
    /* */
    this.formSearch = new FormGroup({
      'destination': new FormControl(''),
    });

    this.applyDestinationPrefill();
  }

  private applyDestinationPrefill() {
    /* */
    if (this.destinationPrefilled != null) {
      this.formSearch.setValue({
        destination: this.destinationPrefilled
      })
    }
  }

  showModal() {
    this.applyDestinationPrefill();

    if (this.formSearch.get('destination').value != "") {
      this.getBoundariesFromAPI();
    }

    this.childModal.show();
  }

  public getBoundariesFromAPI() {
    this.loadingIndicatorService.showLoadingBar();
    this.openStreetMapBoundaries = [];

    const params = {
      query: this.formSearch.get('destination').value
    };

    /* Server Anfrage */
    this.http.get(environment.urls.service + '/api/v2/authenticated/admin/boundary/open-street-map', {
      params: params
    }).subscribe(
      (response: any) => {
        this.openStreetMapBoundaries = response.elements.filter((element) => element.osm_type === "relation");
        console.log(response);
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.loadingIndicatorService.hideLoadingBar();
      }
    );
  }

  // https://stackoverflow.com/questions/22603220/uncaught-invalidvalueerror-not-a-feature-or-featurecollection
  public selectBoundaryByIndex(index: number) {
    const selectedBoundary = this.openStreetMapBoundaries[index];
    selectedBoundary.lat = parseFloat(selectedBoundary.lat) as any;
    selectedBoundary.lon = parseFloat(selectedBoundary.lon) as any;
    this.openStreetMapSelectedElement = selectedBoundary;

    console.log(this.openStreetMapSelectedElement);

    this.mapSelectedObjectToGmaps();
  }

  private mapSelectedObjectToGmaps() {
    if (this.openStreetMapSelectedElement != null && this.openStreetMapSelectedElement.boundingbox.length > 0) {
      this.boundingBox = {
        east: parseFloat(this.openStreetMapSelectedElement.boundingbox[0]),
        south: parseFloat(this.openStreetMapSelectedElement.boundingbox[1]),
        west: parseFloat(this.openStreetMapSelectedElement.boundingbox[2]),
        north: parseFloat(this.openStreetMapSelectedElement.boundingbox[3])
      };
    }

    // /* Assign it */


    // console.log(this.osmService.getPolygonTypeByNumberArray(this.openStreetMapSelectedElement.geojson.coordinates));
    this.boundaryPaths = this.osmService.transformOSMResponseToGoogleMapsCoordinates(this.openStreetMapSelectedElement);
    // console.log(this.osmService.getPolygonTypeByGoogleMapsCoordsArray(this.boundaryPaths as any));


    console.log("========== Traverse OSM Response to GMaps Coordinates ==========");
    console.log(this.openStreetMapSelectedElement.geojson.coordinates);
    console.log(this.boundaryPaths);
    console.log("================================================================");

    // console.log(this.boundaryPaths);
  }

  public propagateImport() {
    this.childModal.hide();
    this.eventsService.broadcast('BOUNDARIES_IMPORT_OSM_ELEMENT', this.openStreetMapSelectedElement)
  }
}
