<div class="col-xl-12" *ngIf="paginationData != null">
  <h2 class="content-heading">Latest PreOffers (Submitted Offers by Housings)</h2>

  <!-- -->
  <div class="block block-rounded" *ngIf="preOffers != null && preOffers.length > 0">
    <div class="block-content">
      <div class="table-responsive">
        <table class="table table-borderless table-striped">
          <thead>
          <tr>
            <th>Id</th>
            <th>Request</th>
            <th>Creator</th>
            <th>State</th>
            <th>Meta</th>
            <th>Created</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let preOffer of preOffers | paginate: {
              id: 'ctrls_dp_' + section,
              itemsPerPage: paginationData.limit,
              currentPage: paginationData.page,
              totalItems: paginationData.total}">
            <td>
              {{preOffer._id}}
            </td>
            <td>
              {{preOffer.request}}
            </td>
            <td class="text-left">
              <span class="badge badge-primary" *ngIf="preOffer.status.createdByAdmin === true">Admin</span>
              <span class="badge badge-info" *ngIf="preOffer.status.createdBySytem === true">System</span>
              <span class="badge badge-success" *ngIf="(preOffer.status.createdBySytem === false || preOffer.status.createdBySytem == null) && (preOffer.status.createdByAdmin === false || preOffer.status.createdByAdmin == null)">Hotel</span>
            </td>
            <td class="text-left">
              <span class="badge badge-warning" *ngIf="preOffer.state === 'WAITING_FOR_APPROVAL'">Approval required</span>
              <span class="badge badge-success" *ngIf="preOffer.state === 'BOOKED'">Booked</span>
              <span class="badge badge-warning" *ngIf="preOffer.state === 'EXPIRED'">Expired</span>
              <span class="badge badge-danger" *ngIf="preOffer.state === 'USER_DECLINED'">Declined by user</span>
              <span class="badge badge-info" *ngIf="preOffer.state === 'USER_INFORMED'">Forwarded</span>
            </td>
            <td>
              <i class="fa fa-mail-forward" [ngClass]="{'text-success': preOffer.status.forwarded === true, 'text-danger': preOffer.status.forwarded === false}"></i>&nbsp;
              <i class="fa fa-shopping-basket" [ngClass]="{'text-success': preOffer.status.marketplacePreOffer === true, 'text-danger': preOffer.status.marketplacePreOffer === false || preOffer.status.marketplacePreOffer == null}"></i>
            </td>
            <td class="d-none d-sm-table-cell">
              {{preOffer.created}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
      <pagination-controls
        id="ctrls_dp_{{section}}"
        [maxSize]="paginationData.limit"
        (pageChange)="sendPage($event)"
      ></pagination-controls>
    </div>
  </div>

  <!-- -->
  <div class="block block-rounded" *ngIf="preOffers == null || preOffers.length === 0">
    <div class="block-content block-content-full text-center">
      No results to display
    </div>
  </div>

</div>
