<app-content-header i18n-title title="Destination Override" i18n-subTitle
                    subTitle="Edit a destination override rule"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>
<div class="content">
  <div class="row">
    <div class="col-md-12">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Edit a destination override rule</h3>
        </div>

        <!-- -->
        <div class="block-content block-content-full">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4">
                  <label for="originalDestination" class="mt-5">Original Destination</label></div>
                <div class="col-md-8">
                  <input type="text" class="form-control" id="originalDestination" name="originalDestination"
                         placeholder="Enter the original destination"
                         autocomplete="off"
                         ngx-google-places-autocomplete
                         [options]='options'
                         #placesRef="ngx-places"
                         (onAddressChange)="handleGooglePlaceIdChangeOriginal($event)"
#originalDestinationInput
                  >
                </div>
              </div>
              <div class="row" *ngIf="googleLocationOriginal != null">
                <div class="col-12">
                  <hr>
                  <pre>{{googleLocationOriginalReadable}}</pre>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4">
                  <label for="newDestination" class="mt-5">New Destination</label></div>
                <div class="col-md-8">
                  <input type="text" class="form-control" id="newDestination" name="newDestination"
                         placeholder="Enter the new destination"
                         autocomplete="off"
                         ngx-google-places-autocomplete
                         [options]='options'
                         #placesRef="ngx-places"
                         (onAddressChange)="handleGooglePlaceIdChangeNew($event)"
                         #newDestinationInput
                  >
                </div>
              </div>
              <div class="row" *ngIf="googleLocationNewReadable != null">
                <div class="col-12">
                  <hr>
                  <pre>{{googleLocationNewReadable}}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="block-content block-content-full bg-body-light">
          <button class="btn btn-success btn-sm" (click)="postNewDestinationOverride()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
