<form [formGroup]="form" (submit)="onSubmitForm()">
  <div class="block block-rounded block-themed">
    <div class="block-header bg-gd-primary">
      <h3 class="block-title">Distances</h3>
      <div class="block-options">
        <button type="submit" class="btn btn-sm btn-alt-primary" (click)="onSubmitForm()" [disabled]="housing == null || isLoading === true">
          <i class="fa fa-save mr-5" *ngIf="isLoading === false"></i>
          <i class="fa fa-cog fa-spin mr-5" *ngIf="isLoading === true"></i>Save
        </button>
      </div>
    </div>
    <div class="block-content block-content-full">
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-dist-city-center">City Center</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('cityCenter').touched || form.get('cityCenter').dirty) && form.get('cityCenter').invalid}">
          <input type="text" class="form-control" id="housing-dist-city-center" name="housing-dist-city-center"
                 placeholder="Distance CityCenter" formControlName="cityCenter">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('cityCenter').errors)">
            {{error}}
          </div>
        </div>
        <label class="col-2 col-form-label" for="housing-dist-public-transport">Public Trsp.</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('publicTransport').touched || form.get('publicTransport').dirty) && form.get('publicTransport').invalid}">
          <input type="text" class="form-control" id="housing-dist-public-transport"
                 name="housing-dist-public-transport" placeholder="Public Transport" formControlName="publicTransport">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('publicTransport').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-dist-airport">Airport</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('airport').touched || form.get('airport').dirty) && form.get('airport').invalid}">
          <input type="text" class="form-control" id="housing-dist-airport" name="housing-dist-airport"
                 placeholder="Airport" formControlName="airport">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('airport').errors)">
            {{error}}
          </div>
        </div>
        <label class="col-2 col-form-label" for="housing-dist-beach">Beach</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('beach').touched || form.get('beach').dirty) && form.get('beach').invalid}">
          <input type="text" class="form-control" id="housing-dist-beach" name="housing-dist-beach"
                 placeholder="Beach" formControlName="beach">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('beach').errors)">
            {{error}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label" for="housing-dist-highway">Highway</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('highway').touched || form.get('highway').dirty) && form.get('highway').invalid}">
          <input type="text" class="form-control" id="housing-dist-highway" name="housing-dist-highway"
                 placeholder="Highway" formControlName="highway">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('highway').errors)">
            {{error}}
          </div>
        </div>
        <label class="col-2 col-form-label" for="housing-dist-highway">Slope</label>
        <div class="col-4" [ngClass]="{'is-invalid': (form.get('slope').touched || form.get('slope').dirty) && form.get('slope').invalid}">
          <input type="text" class="form-control" id="housing-dist-slope" name="housing-dist-slope"
                 placeholder="Slope" formControlName="slope">
          <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(form.get('slope').errors)">
            {{error}}
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
