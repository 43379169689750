import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Amenity} from '@tripbakers/enums';
import {FormControl, FormGroup} from "@angular/forms";
import {LoadingIndicatorService} from "../../../../../services/loading-indicator/loading-indicator.service";
import {HousingInfoService} from "../../../../../services/housing-info/housing-info.service";
import {HttpClient} from "@angular/common/http";
import {EventsService} from "../../../../../services/events/events.service";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-housing-info-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.css']
})
export class HousingInfoDetailsAmenitiesComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  form: FormGroup;
  public amenityData = [];
  public displayForm = false;
  isLoading: boolean = false;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    const amenitiesContainer: { [p: string]: Array<string> } = {};
    const amenities = Object.values(Amenity);

    /* First Mapping, excluding internal and "NO" stuff */
    for (const amenity of amenities) {
      const amenityGroup = amenity.split("_")[0];
      if (amenityGroup === "NO" || amenityGroup === "internal") {
        continue;
      }
      if (amenitiesContainer[amenityGroup] == null) {
        amenitiesContainer[amenityGroup] = [];
      }
      amenitiesContainer[amenityGroup].push(amenity);
    }

    /* Final Mapping for easier Frontend implementation (Object Keys not available in FE) */
    for (const amenityIdentifier in amenitiesContainer) {
      if (amenitiesContainer.hasOwnProperty(amenityIdentifier)) {
        this.amenityData.push(
          {identifier: amenityIdentifier, amenities: amenitiesContainer[amenityIdentifier]}
        )
      }
    }

    /* */
    this.form = new FormGroup({});
    for (const amenity of amenities) {
      this.form.addControl(amenity, new FormControl({value: false, disabled: true}));
    }
  }

  onSubmitForm() {
    if (this.form.status !== 'VALID') {
      return;
    }
    this.showLoading();

    this.postData().subscribe(
      (response: any) => {
        this.hideLoading();
        this.housingInfoService.setSelectedHousing(response);
      },
      err => {

        if (err.error.message === "E_VALIDATION_FAILED") {
          try {
            for (const error of err.error.errors) {
              this.form.get(error.property).setErrors(error.constraints);
              this.form.get(error.property).markAsDirty();
            }
          } catch (error) {
            console.log("Catched E", error)
          }
          this.hideLoading();
          return;
        }

        this.hideLoading();
        this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));
      }
    )
  }

  private postData() {
    const amenitiesSelected = [];
    const amenities = Object.values(Amenity);

    for (const amenity of amenities) {
      if (this.form.get(amenity).value === true) {
        amenitiesSelected.push(amenity);
      }
    }

    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/amenities', {
      amenities: amenitiesSelected
    });
  }

  ngOnInit() {
    if (this.housing != null) {
      this.mapHousingToForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  private mapHousingToForm() {
    const vals = {};
    const amenities = Object.values(Amenity);

    for (const amenity of amenities) {
      vals[amenity] = (this.housing.amenities == null) ? false : this.housing.amenities[amenity] === true
    }


    this.form.setValue(
      vals
    );

    this.enableDisableFormCtrl();

    // @TYPE
    // @PARTNER
    // @AMENITIES
  }

  public toggleVisibility() {
    this.displayForm = !this.displayForm;
  }

  private enableDisableFormCtrl() {
    const enable = (this.housing != null);
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (enable) {
          this.form.controls[key].enable();
        } else {
          this.form.controls[key].disable();
        }
      }
    }
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

}
