<app-content-header i18n-title title="Booking Details" i18n-subTitle
                    subTitle="All details of the specific booking"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content" *ngIf="bookingDetails != null">
  <div class="row gutters-tiny">
    <div class="col-md-5">
      <div class="row">
        <div class="col-md-12">
          <app-instant-booking-details-user [user]="bookingDetails.user"></app-instant-booking-details-user>
        </div>
        <div class="col-md-12">
          <app-instant-booking-details-housing [housing]="bookingDetails.housing"></app-instant-booking-details-housing>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <app-instant-booking-details-details [details]="bookingDetails"></app-instant-booking-details-details>
    </div>
  </div>
</div>
