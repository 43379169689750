import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ConfigService} from "../../../services/configservice/config.service";
import {environment} from "../../../../environments/environment";
import {ActivatedRoute, Params} from "@angular/router";
import {LoadingIndicatorService} from "../../../services/loading-indicator/loading-indicator.service";
import {EventsService} from "../../../services/events/events.service";
import * as FileSaver from 'file-saver';
import {BreadCrumbElement} from "../../../shared/entities/breadcrumb.element.entity";

@Component({
  selector: 'app-housing-filter-ib',
  templateUrl: './housing-filter-ib.component.html',
  styleUrls: ['./housing-filter-ib.component.css']
})
export class HousingFilterIbComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};


  public list_cities: any = [
    {
      googlePlaceId: 'ChIJAVkDPzdOqEcRcDteW0YgIQQ',
      text: 'Berlin'
    },
    {
      googlePlaceId: 'ChIJuRMYfoNhsUcRoDrWe_I9JgQ',
      text: 'Hamburg'
    },
    {
      googlePlaceId: 'ChIJB1lG8XvJuEcRsHMqSvxgJwQ',
      text: 'Düsseldorf'
    },
    {
      googlePlaceId: 'ChIJqdYaECnPCUcRsP6IQsuxIQQ',
      text: 'Dresden'
    },
    {
      googlePlaceId: 'ChIJ2V-Mo_l1nkcRfZixfUq4DAE',
      text: 'München'
    },
    {
      googlePlaceId: 'ChIJ04-twTTbmUcR5M-RdxzB1Xk',
      text: 'Stuttgart'
    },
    {
      googlePlaceId: 'ChIJn8o2UZ4HbUcRRluiUYrlwv0',
      text: 'Wien'
    },
    {
      googlePlaceId: 'ChIJ5S-raZElv0cR8HcqSvxgJwQ',
      text: 'Köln'
    },
    {
      googlePlaceId: 'ChIJWxIr4ajVGA0RwCyQ5L3rAAM',
      text: 'Lissabon'
    },
    {
      googlePlaceId: 'ChIJ5TCOcRaYpBIRCmZHTz37sEQ',
      text: 'Barcelona'
    },
    {
      googlePlaceId: 'ChIJi3lwCZyTC0cRkEAWZg-vAAQ',
      text: 'Prag'
    },
    {
      googlePlaceId: 'ChIJ0RhONcBEFkcRv4pHdrW2a7Q',
      text: 'Krakau'
    }

  ];
  public selected_city: string;
  public page = 1;
  public pageSize = 25;
  public list_housings: any = [];

  public amountToCheck: number;
  public amountDeclined: number;
  public amountAccepted: number;

  public pagination: any;
  public paginationNums: Array<number>;
  public section = 1;

  constructor(private http: HttpClient,
              private configService: ConfigService,
              private activatedRoute: ActivatedRoute,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params["googlePlaceId"] && params["page"]) {
        this.selected_city = params["googlePlaceId"];
        this.page = parseFloat(params["page"]);
        this.loadHousingsToCheck();
      }
    });

    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Housings Instant Booking Filter', 'javascript:void(0)')
    ]

  }

  ngOnInit() {

  }

  public changedItem(e: any) {
    this.selected_city = e.googlePlaceId;
    this.loadHousingsToCheck();
  }

  public loadHousingsToCheck() {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});
    this.loadingIndicatorService.showLoadingBar();

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/ib-check/' + this.selected_city + '?page=' + this.page + '&pageSize=' + this.pageSize + '&section=' + this.section, {headers: headers})
      .subscribe((response: any) => {
          this.list_housings = response.housings;
          this.pagination = response.pagination;

          this.amountToCheck = response.counts.totalNotChecked;
          this.amountDeclined = response.counts.totalDeclined;
          this.amountAccepted = response.counts.totalAccepted;

          this.calculatePagination();
          this.loadingIndicatorService.hideLoadingBar();

        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }

  public calculatePagination() {
    const maxPre = 4;
    const maxAfter = 4;
    const paginationNums = [];

    for (let i = maxPre; i > 0; i--) {
      const num = this.page - i;
      if (num > 0) {
        paginationNums.push(num);
      }
    }
    for (let i = 0; i < maxAfter; i++) {
      const num = this.page + i;
      if (num <= this.pagination.pageMax) {
        paginationNums.push(num);
      }
    }

    this.paginationNums = paginationNums;
  }

  public switchPage(e: number) {
    this.page = e;
    this.loadHousingsToCheck();
  }

  public switchSection(section: number) {
    this.page = 1;
    this.pageSize = 25;
    this.list_housings = [];
    this.section = section;

    this.loadHousingsToCheck();
  }

  public downloadCheckedHousings() {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});
    headers = headers.append('Content-Type',  'text');

    console.log(headers);

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/ib-check/' + this.selected_city + '/export-accepted', {headers: headers, responseType: 'text', observe: 'response'})
      .subscribe(
        data => {
          const filename = data.headers.get('content-disposition');
          this.createPseudoDownload(data.body, filename.replace('attachment; filename=', ''));
        },
        error => console.log(error),
        () => console.info("OK")
      );
  }

  public downloadDeclinedHousings() {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});
    headers = headers.append('Content-Type',  'text');

    console.log(headers);

    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/ib-check/' + this.selected_city + '/export-declined', {headers: headers, responseType: 'text', observe: 'response'})
      .subscribe(
        data => {
          const filename = data.headers.get('content-disposition');
          this.createPseudoDownload(data.body, filename.replace('attachment; filename=', ''));
        },
        error => console.log(error),
        () => console.info("OK")
      );
  }

  public createPseudoDownload(d: any, filename: string) {
    const blob = new Blob([d], {type: 'text/csv;charset=utf-8'});
    FileSaver.saveAs(blob, filename);
  }
}
