<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-popin"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" bsModal>
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">Booking Change Request Details</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" data-dismiss="modal" aria-label="Close" (click)="hideChildModal()">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content" *ngIf="bookingChangeRequestData != null">
          <div class="row">
            <label class="col-4">RequestId</label>
            <div class="col-6">
              {{bookingChangeRequestData.requestId}}
            </div>
            <div class="col-2">
              <a class="btn btn-sm" href="{{environment_.urls.kitchen}}/pipeline/untouched?source=search&requestId={{bookingChangeRequestData.requestId}}" target="_blank"><i class="fa fa-forward text-success"></i></a>
            </div>
          </div>
          <div class="row">
            <label class="col-4">OfferId</label>
            <div class="col-6">
              {{bookingChangeRequestData.offerId}}
            </div>
            <div class="col-2">
              <a class="btn btn-sm" href="{{environment_.urls.kitchen}}/request/{{bookingChangeRequestData.requestId}}/offers" target="_blank"><i class="fa fa-forward text-success"></i></a>
            </div>
          </div>
          <div class="row">
            <label class="col-4">BookingId</label>
            <div class="col-6">
              {{bookingChangeRequestData.bookingId}}
            </div>
            <div class="col-2">
              <a class="btn btn-sm" href="{{environment_.urls.kitchen}}/request/{{bookingChangeRequestData.requestId}}/offers" target="_blank"><i class="fa fa-forward text-success"></i></a>
            </div>
          </div>
          <div class="row">
            <label class="col-4">CreatedAt</label>
            <div class="col-6">
              {{bookingChangeRequestData.createdAt}}
            </div>
            <div class="col-2">
            </div>
          </div>
          <div class="row">
            <label class="col-4">DealtWithAt</label>
            <div class="col-6">
              {{bookingChangeRequestData.dealtWithAt}}
            </div>
            <div class="col-2">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              {{bookingChangeRequestData.message}}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <form [formGroup]="form">
                <div class="form-group row">
                  <label class="col-4">New State</label>
                  <div class="col-4">
                    <select class="form-control" formControlName="state">
                      <option value="NEW">New</option>
                      <option value="PROCESSED">Processed</option>
                      <option value="DENIED">Denied</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-alt-secondary" data-dismiss="modal" (click)="hideChildModal()">Abort
        </button>
        <button type="button" class="btn btn-alt-success" (click)="handleClick()">
          <i class="fa fa-check"></i> Save
        </button>
      </div>
    </div>
  </div>
</div>

