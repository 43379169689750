import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ModalDirective} from "ngx-bootstrap";
import {FormControl, FormGroup} from "@angular/forms";
import {EventsService} from "../../../../services/events/events.service";
import {environment} from "../../../../../environments/environment";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";

@Component({
  selector: 'tripmakery-booking-change-requests-edit-modal',
  templateUrl: './modal-booking-change-requests-edit.component.html',
  styleUrls: ['./modal-booking-change-requests-edit.component.css']
})
export class ModalBookingChangeRequestsEditComponent implements OnInit {
  public bookingChangeRequestId: string;
  public bookingChangeRequestData: any;
  public form: FormGroup;
  public environment_;

  @ViewChild('childModal') public childModal: ModalDirective;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'state': new FormControl({value: '', disabled: false}),
    });
    this.environment_ = environment;
  }

  ngOnInit() {
    this.eventsService.on('requestShowModalBookingChangeRequestEdit', (bookingChangeRequestId) => {
      this.bookingChangeRequestId = bookingChangeRequestId;
      this.loadChangeRequestDetails();
      this.showChildModal()
    });
  }

  private loadChangeRequestDetails() {
    this.loadingIndicatorService.showLoadingBar();
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/booking/change-requests/' + this.bookingChangeRequestId)
      .subscribe(
        (response: any) => {
          this.bookingChangeRequestData = response;
          this.form.get('state').setValue(response.state);
        },
        (error) => {
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public showChildModal(): void {
    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/booking/change-requests/' + this.bookingChangeRequestId + '/state', {
      state: this.createNullIfEmptyString(this.form.get('state').value),
    })
      .subscribe(
        (response: any) => {
          this.eventsService.broadcast('refreshBookingChangeRequestsList');
        },
        (error) => {
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        },
        () => {
          this.hideChildModal();
        }
      );
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return null;
    }
    return s;
  }

  private createFalseIfEmptyString(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return false;
    }
    return s;
  }

}
