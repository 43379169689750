import {google} from "@agm/core/services/google-maps-types";

export class AddressObj {
  street: string;
  city: string;
  country: string;
  countryCode: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  adminLevel1: string;
  adminLevel1Code: string;
  adminLevel2: string;
  adminLevel2Code: string;
  adminLevel3: string;
  adminLevel3Code: string;
  googlePlaceId: string;
  name: string;
  raw: any;

  get displayString(): string {

    if (this.city) {
      return `${this.city}` + (this.countryCode ? `, ${this.countryCode}` : '');
    } else if (this.country) {
      return `${this.country}`;
    } else {
      return this.name;
    }

  }

  isCountry(): boolean {
    return !this.city && !this.adminLevel1 && !this.adminLevel2 && !this.adminLevel3 && !!this.countryCode;
  }

}
