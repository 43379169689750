<div class="content">
  <div class="row">
    <div class="col-md-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Navigation</h3>
        </div>
        <div class="block-content">
          <div class="list-group push">
            <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
               (click)="changePage(0)" [ngClass]="{'active': pageId === 0}">
              General
            </a>
            <!--<a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"-->
               <!--(click)="changePage(1)" [ngClass]="{'active': pageId === 1}">-->
              <!--Amenities-->
            <!--</a>-->
            <!--<a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"-->
               <!--(click)="changePage(2)" [ngClass]="{'active': pageId === 2}">-->
              <!--Contact-->
            <!--</a>-->
            <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
               (click)="changePage(3)" [ngClass]="{'active': pageId === 3}">
              Images
            </a>
            <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
               (click)="changePage(4)" [ngClass]="{'active': pageId === 4}">
              Finalize
            </a>
            <a
              class="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-success"
              [routerLink]="['/developer/housing-filter-ib']" [queryParams]="{googlePlaceId: revert_googlePlaceId, page: revert_page}">
              <span><i class="fa fa-angle-double-left"></i> Back to List</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="block block-bordered" *ngIf="pageId === 0">
        <div class="block-header block-header-default">
          <h3 class="block-title">General</h3>
          <div class="block-options pull-right">
            <a *ngIf="housing != null && housing.sourcePages.hrs != null" class="badge badge-primary" href="{{housing.sourcePages.hrs}}" target="_blank">HRS</a>&nbsp;
            <a *ngIf="housing != null && housing.sourcePages.bk != null" class="badge badge-info" href="{{housing.sourcePages.bk}}" target="_blank">Booking</a>&nbsp;
            <a *ngIf="housing != null && housing.sourcePages.ta != null" class="badge badge-success" href="{{housing.sourcePages.ta}}" target="_blank">Tripadvisor</a>&nbsp;
            <a *ngIf="housing != null && housing.homepage != null" class="badge badge-danger" href="{{housing.homepage}}" target="_blank">Website</a>
          </div>
        </div>

        <div class="block-content block-content-full">
          <form class="px-30" [formGroup]="formGeneral" (ngSubmit)="onSubmitFormGeneral()" autocomplete="off">

            <div class="form-group row" [ngClass]="{'is-invalid': formGeneral.get('name').untouched === false && formGeneral.get('name').status === 'INVALID'}">
              <label class="col-lg-3 col-form-label" for="general-stars">Name</label>
              <div class="col-lg-7">
                <input type="text" formControlName="name" class="form-control" id="general-name" name="general-name" placeholder="Enter Name..">
                <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(formGeneral.get('name').errors)">{{error}}</div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{'is-invalid': formGeneral.get('email').untouched === false && formGeneral.get('email').status === 'INVALID'}">
              <label class="col-lg-3 col-form-label" for="general-stars">Email</label>
              <div class="col-lg-7">
                <input type="text" formControlName="email" class="form-control" id="general-email" name="general-email" placeholder="Enter Email..">
                <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(formGeneral.get('email').errors)">{{error}}</div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{'is-invalid': formGeneral.get('stars').untouched === false && formGeneral.get('stars').status === 'INVALID'}">
              <label class="col-lg-3 col-form-label" for="general-stars">Stars</label>
              <div class="col-lg-7">
                <input type="text" formControlName="stars" class="form-control" id="general-stars" name="general-stars" placeholder="Enter Stars..">
                <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(formGeneral.get('stars').errors)">{{error}}</div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{'is-invalid': formGeneral.get('homepage').untouched === false && formGeneral.get('homepage').status === 'INVALID'}">
              <label class="col-lg-3 col-form-label" for="general-homepage">Homepage</label>
              <div class="col-lg-7">
                <input type="text" formControlName="homepage" class="form-control" id="general-homepage" name="general-homepage" placeholder="Enter Website..">
                <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(formGeneral.get('homepage').errors)">{{error}}</div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{'is-invalid': formGeneral.get('phone').untouched === false && formGeneral.get('phone').status === 'INVALID'}">
              <label class="col-lg-3 col-form-label" for="general-phone">Phone</label>
              <div class="col-lg-7">
                <input type="text" formControlName="phone" class="form-control" id="general-phone" name="general-phone" placeholder="Enter Phone..">
                <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(formGeneral.get('phone').errors)">{{error}}</div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{'is-invalid': formGeneral.get('numberOfRooms').untouched === false && formGeneral.get('numberOfRooms').status === 'INVALID'}">
              <label class="col-lg-3 col-form-label" for="general-numberOfRooms">Number Of Rooms</label>
              <div class="col-lg-7">
                <input type="text" formControlName="numberOfRooms" class="form-control" id="general-numberOfRooms" name="general-numberOfRooms" placeholder="Enter Number of Rooms..">
                <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(formGeneral.get('numberOfRooms').errors)">{{error}}</div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{'is-invalid': formGeneral.get('rating').untouched === false && formGeneral.get('rating').status === 'INVALID'}">
              <label class="col-lg-3 col-form-label" for="general-numberOfRooms">Rating</label>
              <div class="col-lg-7">
                <input type="text" formControlName="rating" class="form-control" id="general-rating" name="general-rating" placeholder="Enter Ratings..">
                <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(formGeneral.get('rating').errors)">{{error}}</div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{'is-invalid': formGeneral.get('amount_singleBed').untouched === false && formGeneral.get('amount_singleBed').status === 'INVALID'}">
              <label class="col-lg-3 col-form-label" for="general-amount-singleBed">Amount singlebed rooms</label>
              <div class="col-lg-7">
                <input type="text" formControlName="amount_singleBed" class="form-control" id="general-amount-singleBed" name="general-amount-singleBed" placeholder="Enter Amount Single Bed Rooms..">
                <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(formGeneral.get('amount_singleBed').errors)">{{error}}</div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{'is-invalid': formGeneral.get('amount_doubleBed').untouched === false && formGeneral.get('amount_doubleBed').status === 'INVALID'}">
              <label class="col-lg-3 col-form-label" for="general-amount-doubleBed">Amount doublebed rooms</label>
              <div class="col-lg-7">
                <input type="text" formControlName="amount_doubleBed" class="form-control" id="general-amount-doubleBed" name="general-amount-doubleBed" placeholder="Enter Amount Double Bed Room..">
                <div class="invalid-feedback" *ngFor="let error of extractValidationErrorsAsArray(formGeneral.get('amount_doubleBed').errors)">{{error}}</div>
              </div>
            </div>

            <div class="form-group row">
              <button type="submit" class="btn btn-success">Submit</button>
            </div>
          </form>


        </div>
      </div>

      <div class="block block-bordered" *ngIf="pageId === 1">
        <div class="block-header block-header-default">
          <h3 class="block-title">Amenities</h3>
        </div>
        <div class="block-content block-content-full">
        </div>
      </div>

      <div class="block block-bordered" *ngIf="pageId === 2">
        <div class="block-header block-header-default">
          <h3 class="block-title">Distances</h3>
        </div>
        <div class="block-content block-content-full">
        </div>
      </div>

      <div class="block block-bordered" *ngIf="pageId === 3">
        <div class="block-header block-header-default">
          <h3 class="block-title">Images</h3>
          <div class="block-options pull-right">
            <a *ngIf="housing != null && housing.sourcePages.hrs != null" class="badge badge-primary" href="{{housing.sourcePages.hrs}}" target="_blank">HRS</a>&nbsp;
            <a *ngIf="housing != null && housing.sourcePages.bk != null" class="badge badge-info" href="{{housing.sourcePages.bk}}" target="_blank">Booking</a>&nbsp;
            <a *ngIf="housing != null && housing.sourcePages.ta != null" class="badge badge-success" href="{{housing.sourcePages.ta}}" target="_blank">Tripadvisor</a>&nbsp;
            <a *ngIf="housing != null && housing.homepage != null" class="badge badge-danger" href="{{housing.homepage}}" target="_blank">Website</a>
          </div>
        </div>
        <div class="block-content block-content-full">
          <div>
            <tabset>
              <tab heading="Overview">
                <div class="block">
                  <div class="block-content">
                    <h3>Preview Image</h3>
                    <img width="400px" src="{{housingImagePreview}}">
                    <h3>Offer Image</h3>
                    <img width="400px" src="{{housingImageOffer}}">
                    <h3>Image List</h3>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                        <tr>
                        <th>Img</th>
                        <th class="text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let image of housingImageList; let i = index">
                          <td>
                            <!--<img width="400px" src="{{image}}">-->
                            <a href="{{environment.urls.blob}}/{{housingId}}/raw/{{image}}" target="_blank"><img width="400px" src="{{environment.urls.blob}}/{{housingId}}/raw/{{image}}"></a>
                          </td>
                          <td class="text-center">
                            <button type="button" class="btn btn-primary" (click)="setPreviewImage(image)">Set as Preview Image</button>&nbsp;
                            <a href="http://makery-user-stage.azurewebsites.net/offer/B1j4iU9sM?bI={{environment.urls.blob}}/{{housingId}}/raw/{{image}}" target="_blank" class="btn btn-secondary">Preview Offer Image</a>&nbsp;
                            <button type="button" class="btn btn-warning" (click)="setOfferImage(image)">Set as Offer Image</button><br><br><br>
                            <button type="button" class="btn btn-danger" (dblclick)="deleteImage(image)">Delete</button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </tab>
              <tab heading="Upload Images">
                <input type="file" #fileInput placeholder="Upload file..." multiple/>
                <div class="block-content">
                  <button type="button" (click)="upload()">Upload</button>
                </div>
              </tab>
            </tabset>
          </div>
        </div>
      </div>

      <div class="block block-bordered" *ngIf="pageId === 4">
        <div class="block-header block-header-default">
          <h3 class="block-title">Finalize</h3>
        </div>
        <div class="block-content block-content-full">
          <div class="row">
            <div class="col-12 text-center">
              <ng-template #popTemplate><div [innerHtml]="popoverQualifiesForIBHtml"></div></ng-template>
              <h4>Did the housing qualify for Instant Booking? <a href="javascript:void(0)" [popover]="popTemplate"
                                                                     triggers="mouseenter:mouseleave">*</a></h4>
              <div class="row">
                <div class="col-6 text-center">
                  <button (click)="onSubmitSetHousingToQualifiedForIB(true)" class="btn btn-success">Yes</button>
                </div>
                <div class="col-6 text-center">
                  <button (click)="onSubmitSetHousingToQualifiedForIB(false)" class="btn btn-danger">No</button>
                </div>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              &nbsp;
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <h4>Do you want to mark the check as finished?</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">

              <div class="row">
                <div class="col-6 text-center">
                  <button (click)="onSubmitSetHousingToFinished(true)" class="btn btn-success">Yes</button>
                </div>
                <div class="col-6 text-center">
                  <button (click)="onSubmitSetHousingToFinished(false)" class="btn btn-danger">No</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
