import {Injectable} from '@angular/core';
import {OpenStreetMapResponse} from "../../shared/interfaces/open-street-map-response.interface";
import * as Offset from "polygon-offset";

@Injectable()
export class OpenStreetMapsService {

  constructor() {
  }

  public transformOSMResponseToGoogleMapsCoordinates(o: OpenStreetMapResponse) {
    const coords = this.transformNumberArrayToGoogleMapsCoordinates(o.geojson.coordinates);
    return coords;
  }

  public transformNumberArrayToGoogleMapsCoordinates(coordinates: Array<Array<number>> | Array<Array<Array<number>>>) {
    const boundaryContainer = [];

    if (!Array.isArray(coordinates)) {
      return [];
    }

    /* Recursive mapping */
    for (const entry of coordinates) {
      if (Array.isArray(entry) && Array.isArray(entry[0])) {
        boundaryContainer.push(this.transformNumberArrayToGoogleMapsCoordinates(entry as any));
      } else {
        boundaryContainer.push({lng: entry[0], lat: entry[1]})
      }
    }

    /* Cleanup */
    boundaryContainer.forEach((boundary, index) => {
      if (Array.isArray(boundary) && Array.isArray(boundary[0])) {
        boundaryContainer[index] = boundary[0];
      }
    });

    /* */
    return boundaryContainer;
  }

  public minimizeBoundary(coordinates: Array<number> | Array<Array<number>> | Array<Array<Array<number>>>, rounds: number) {
    const minimizedBoundary = [];

    for (const entry of coordinates) {
      if (Array.isArray(entry) && Array.isArray(entry[0]) && !Array.isArray(entry[0][0])) {
        const minimized = this.minimizeNumberArray(entry as any, rounds);
        if (minimized.length > 3) {
          minimizedBoundary.push(minimized);
        }
      } else {
        minimizedBoundary.push(this.minimizeBoundary(entry as any, rounds));
      }
    }

    return minimizedBoundary;
  }

  public minimizeNumberArray(data: Array<number>, rounds: number) {
    let dataArray = data;

    for (let i = 0; i < rounds; i++) {
      dataArray = dataArray.filter(function (_, i) {
        return (i + 1) % 2;
      })
    }

    return dataArray;
  }

  public marginBoundary(coordinates: any, rounds: number) {
    const minimizedBoundary = [];

    for (const entry of coordinates) {
      if (Array.isArray(entry) && Array.isArray(entry[0]) && !Array.isArray(entry[0][0])) {
        if (entry.length > 3) {
          const margined = this.marginNumberArray(entry as any, rounds).filter((element) => element.length > 3);
          minimizedBoundary.push(margined);
        }
      } else {
        minimizedBoundary.push(this.marginBoundary(entry as any, rounds));
      }
    }

    return minimizedBoundary;
  }

  public marginNumberArray(data: Array<number>, margin: number) {
    const offset = new Offset();
    return offset.data(data).margin(margin);
  }

  public getPolygonTypeByNumberArray(data: Array<number> | Array<Array<number>> | Array<Array<Array<number>>>) {
    const depth = this.getDepth(data);

    if (depth <= 3) {
      return "Polygon"
    } else {
      return "MultiPolygon"
    }
  }

  public getPolygonTypeByGoogleMapsCoordsArray(data: Array<{ lng: number, lat: number }> | Array<Array<{ lng: number, lat: number }>> | Array<Array<Array<{ lng: number, lat: number }>>>) {
    const depth = this.getDepth(data);

    if (depth <= 3) {
      return "Polygon"
    } else {
      return "MultiPolygon"
    }
  }

  private getDepth(object: any) {
    let level = 1;
    for (const key in object) {
      if (!object.hasOwnProperty(key)) continue;

      if (typeof object[key] == 'object') {
        const depth = this.getDepth(object[key]) + 1;
        level = Math.max(depth, level);
      }
    }
    return level;
  }

  public transformGoogleMapsCoordinatesToNumberArray(coordinates: Array<{ lng: number, lat: number }> | Array<Array<{ lng: number, lat: number }>> | Array<Array<Array<{ lng: number, lat: number }>>> | Array<Array<Array<Array<{ lng: number, lat: number }>>>>): Array<number> | Array<Array<number>> | Array<Array<Array<number>>> {
    let boundaryContainer = [];

    for (const entry of coordinates) {
      const localAround = [];

      if (Array.isArray(entry) && Array.isArray(entry[0])) {
        boundaryContainer.push(this.transformGoogleMapsCoordinatesToNumberArray(entry));
      } else {
        for (const entry_ of entry as any) {
          localAround.push([entry_.lng, entry_.lat])
        }
      }

      boundaryContainer.push(localAround);
    }

    boundaryContainer = boundaryContainer.filter((element) => element.length > 0);

    return boundaryContainer;
  }

  public transformGoogleMapsCoordinatesToMongoDBNumberArray(coordinates: Array<{ lng: number, lat: number }> | Array<Array<{ lng: number, lat: number }>> | Array<Array<Array<{ lng: number, lat: number }>>> | Array<Array<Array<Array<{ lng: number, lat: number }>>>>): Array<number> | Array<Array<number>> | Array<Array<Array<number>>> {
    const transformedBoundary = this.transformGoogleMapsCoordinatesToNumberArray(coordinates);
    return this.checkAndCreateBoundaryLoop(transformedBoundary);
  }

  public checkAndCreateBoundaryLoop(boundary: Array<any>) {
    boundary.forEach((entry, index) => {
      if (Array.isArray(entry) && Array.isArray(entry[0]) && !Array.isArray(entry[0][0])) {
        const maxIndexInEntry = (entry as Array<any>).length - 1;
        if (entry[0][0] != entry[maxIndexInEntry][0] || entry[0][1] != entry[maxIndexInEntry][1]) {
          (entry as Array<any>).push(entry[0]);
          boundary[index] = entry;
        }

        /* */
        if (entry.length <= 2) {
          delete boundary[index];
        }

      } else {
        this.checkAndCreateBoundaryLoop(boundary);
      }
    });


    return boundary;
  }
}
