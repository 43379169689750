<app-content-header i18n-title title="Phone Callback Requests" i18n-subTitle
                    subTitle="An overview of all callback requests"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>
<div class="content">
  <div class="row">
    <div class="col-md-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>

        <!-- -->
        <div class="block-content block-content-full">
          <form [formGroup]="form" (ngSubmit)="loadChangeRequestsToCheck()">
            <div class="row">
              <label class="col-12" for="state">State</label>
              <div class="col-12">
                <select class="form-control" id="state" formControlName="state">
                  <option value="">Please select..</option>
                  <option value="REQUESTED">Requested</option>
                  <option value="MISSED">Missed</option>
                  <option value="DONE">Done</option>
                </select>
              </div>
            </div>

            <div class="row mt-15">
              <div class="col-12">
                <hr>
              </div>
            </div>

            <div class="row">
              <label class="col-12" for="phoneCallbackId">CallbackId</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="CallbackId"
                       autocomplete="off"
                       id="phoneCallbackId"
                       formControlName="phoneCallbackId">
              </div>
            </div>

            <div class="row mt-15">
              <label class="col-12" for="requestId">RequestId</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="RequestId"
                       autocomplete="off"
                       id="requestId"
                       formControlName="requestId">
              </div>
            </div>

            <div class="row mt-15">
              <label class="col-12" for="offerId">OfferId</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="OfferId"
                       autocomplete="off"
                       id="offerId"
                       formControlName="offerId">
              </div>
            </div>

            <div class="row mt-15">
              <div class="col-12"><hr></div>
            </div>

            <div class="row mt-15">
              <label class="col-12" for="beginTime">Starting time</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="Starting time (example: 9 for 9 oclock)"
                       autocomplete="off"
                       id="beginTime"
                       formControlName="beginTime">
              </div>
            </div>

            <div class="row mt-15">
              <label class="col-12" for="endTime">Ending time</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       placeholder="Ending time (example: 12 for 12 oclock)"
                       autocomplete="off"
                       id="endTime"
                       formControlName="endTime">
              </div>
            </div>

            <div class="row mt-15">
              <div class="col-12">
                <hr>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <button class="btn btn-info btn-sm" type="submit">Apply Filter</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Phone Callback Requests</h3>
        </div>
        <div class="block-content block-content-full">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>Id</th>
                <th>RequestId</th>
                <th>OfferId</th>
                <th>State</th>
                <th>Time</th>
                <th>CreatedAt</th>
                <th>UpdatedAt</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody *ngIf="phoneCallbacks != null && pagination != null">
              <tr *ngFor="let changeRequests of phoneCallbacks | paginate: {
              itemsPerPage: pagination.limit,
              currentPage: pagination.page,
              totalItems: pagination.total}"
              >
                <td>{{changeRequests._id}}</td>
                <td>{{changeRequests.requestId}}</td>
                <td>{{changeRequests.offerId}}</td>
                <td>
                  <span class="badge badge-warning" *ngIf="changeRequests.state === 'REQUESTED'">Requested</span>
                  <span class="badge badge-success" *ngIf="changeRequests.state === 'DONE'">Done</span>
                  <span class="badge badge-danger" *ngIf="changeRequests.state === 'MISSED'">Missed</span>
                </td>
                <td>Between {{changeRequests.beginTime}} and {{changeRequests.endTime}} ({{changeRequests.timezone}})</td>
                <td>{{changeRequests.createdAt}}</td>
                <td>{{changeRequests.updatedAt}}</td>
                <td>
                  <button class="btn btn-sm btn-primary" (click)="requestChangeRequestDetails(changeRequests._id)">
                    Edit
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
          <pagination-controls [maxSize]="10"
                               (pageChange)="sendPage($event)"
                               *ngIf="pagination != null">

          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
<tripmakery-phone-callback-request-edit-modal></tripmakery-phone-callback-request-edit-modal>
