import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ModalDirective} from 'ngx-bootstrap';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {LoadingIndicatorService} from '../../../../services/loading-indicator/loading-indicator.service';
import {EventsService} from '../../../../services/events/events.service';
import {environment} from '../../../../../environments/environment';
import {CountryCodeService} from '../../../../services/country-code/country-code.service';
import {FieldsBasicPlaceDetails} from '../../../../shared/consts/fields-basic-place-details';

@Component({
  selector: 'tripmakery-destination-add-destination-modal',
  templateUrl: './destination-add-destination-modal.component.html',
})
export class DestinationAddDestinationModalComponent {

  public destinationGooglePlaceDetails;
  public options = {
    fields: FieldsBasicPlaceDetails,
  };

  public bookingChangeRequestId: string;
  public bookingChangeRequestData: any;
  public form: FormGroup;
  public environment_;
  public isLoading: boolean = false;

  public googleDataDe: any;
  public googleDataEn: any;
  public googleDataEnGb: any;
  public googleDataEs: any;
  public googleDataIt: any;


  public bsConfig = {
    ignoreBackdropClick: true,
    backdrop: true,
  };

  public isGooglePlaceIdInUse: boolean;
  public isNameSimilar: boolean;
  public doesBoundaryExist: boolean;
  public isBoundaryApproved: boolean;
  public urlBoundaryApproval: string;
  public urlBoundaryAdd: string;

  public isEmailInUse: boolean;
  public emailInUseUserId: string;
  public emailInUseUrl: string;
  public googlePlaceIdInUseUrl: string;

  public countryListEnglish: Array<{ countryCode: string, name: string }>;

  @ViewChild('childModal') public childModal: ModalDirective;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
              private router: Router,
              public countryCodeService: CountryCodeService,
              private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.countryListEnglish = this.countryCodeService.getAllEnglishCountryDataAsArray();

    this.form = new FormGroup({
      'googleAddressText': new FormControl({value: '', disabled: false}),
      'destinationNameDe': new FormControl({value: '', disabled: false}),
      'destinationNameEnGb': new FormControl({value: '', disabled: false}),
      'destinationNameEn': new FormControl({value: '', disabled: false}),
      'destinationNameEs': new FormControl({value: '', disabled: false}),
      'destinationNameIt': new FormControl({value: '', disabled: false}),
      'slugsDestinationDe': new FormControl({value: '', disabled: false}),
      'slugsDestinationEnGb': new FormControl({value: '', disabled: false}),
      'slugsDestinationEn': new FormControl({value: '', disabled: false}),
      'slugsDestinationEs': new FormControl({value: '', disabled: false}),
      'slugsDestinationIt': new FormControl({value: '', disabled: false}),
      'countryCode': new FormControl({value: '', disabled: false}),
      'priceFrom': new FormControl({value: '', disabled: false}),
      'prio': new FormControl({value: '', disabled: false}),
      'isCountry': new FormControl({value: false, disabled: false}),
      'isCapital': new FormControl({value: false, disabled: false}),
      'isFederalState': new FormControl({value: false, disabled: false}),
      'isEnabled': new FormControl({value: false, disabled: false}),
      'hasHeaderImage': new FormControl({value: false, disabled: false}),
      'isListDestination': new FormControl({value: false, disabled: false}),
    });
    this.environment_ = environment;
  }

  public showChildModal(): void {
    this.isEmailInUse = false;
    this.form.reset();

    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    /* */
    const body = this.createCreationBody();

    console.log(body);

    /* */
    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/pages/destinations', body)
      .subscribe(
        (response: any) => {
          this.router.navigate(['pages', 'destinations', response.pageDestination._id]);

        },
        (err) => {
          if (err.error.message === 'E_VALIDATION_FAILED') {
            console.log('Just a validation Error');
            for (const error of err.error.errors) {
              try {
                console.log(error.property, error.constraints);

                this.form.get(error.property).setErrors(error.constraints);
                this.form.get(error.property).markAsDirty();

              } catch (error) {
                console.log('Catched E', error);
              }
            }

            this.hideLoading();
            return;
          } else if (err.error.message === 'E_PAGE_DESTINATION_GOOGLE_PLACE_ID_ALREADY_IN_USE') { // TODO
            this.isGooglePlaceIdInUse = true;
            this.googlePlaceIdInUseUrl = environment.urls.tool + '/pages/destinations/' + err.error.pageDestinationId;
            this.hideLoading();
            return;
          }

          console.log(err);

          this.hideLoading();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
          this.hideChildModal();
        }
      );
  }

  private createCreationBody() {
    /* */
    const countryCode = this.createNullIfEmptyString(this.form.get('countryCode').value);
    const countryNameDe = countryCode != null ? this.countryCodeService.countryListAlpha2German[countryCode] : null;
    const countryNameEn = countryCode != null ? this.countryCodeService.countryListAlpha2English[countryCode] : null;
    const countryNameEnGb = countryCode != null ? this.countryCodeService.countryListAlpha2English[countryCode] : null;
    const countryNameEs = countryCode != null ? this.countryCodeService.countryListAlpha2Spanish[countryCode] : null;
    const countryNameIt = countryCode != null ? this.countryCodeService.countryListAlpha2Italian[countryCode] : null;

    /* */
    const slugsCountryNameDe = countryCode != null ? this.createSlugFromString(countryNameDe) : null;
    const slugsCountryNameEn = countryCode != null ? this.createSlugFromString(countryNameEn) : null;
    const slugsCountryNameEnGb = countryCode != null ? this.createSlugFromString(countryNameEnGb) : null;
    const slugsCountryNameEs = countryCode != null ? this.createSlugFromString(countryNameEs) : null;
    const slugsCountryNameIt = countryCode != null ? this.createSlugFromString(countryNameIt) : null;

    return {
      /* */
      googlePlaceIdAutoComplete: this.googleDataDe != null ? this.googleDataDe.place_id : null,

      destinationNameDe: this.createNullIfEmptyString(this.form.get('destinationNameDe').value),
      destinationNameEn: this.createNullIfEmptyString(this.form.get('destinationNameEn').value),
      destinationNameEnGb: this.createNullIfEmptyString(this.form.get('destinationNameEnGb').value),
      destinationNameEs: this.createNullIfEmptyString(this.form.get('destinationNameEs').value),
      destinationNameIt: this.createNullIfEmptyString(this.form.get('destinationNameIt').value),

      slugsDestinationDe: this.createNullIfEmptyString(this.form.get('slugsDestinationDe').value),
      slugsDestinationEn: this.createNullIfEmptyString(this.form.get('slugsDestinationEn').value),
      slugsDestinationEnGb: this.createNullIfEmptyString(this.form.get('slugsDestinationEnGb').value),
      slugsDestinationEs: this.createNullIfEmptyString(this.form.get('slugsDestinationEs').value),
      slugsDestinationIt: this.createNullIfEmptyString(this.form.get('slugsDestinationIt').value),

      priceFrom: this.tryToCreateNumberOrReturnNullIfFailed(this.form.get('priceFrom').value),
      prio: this.tryToCreateNumberOrReturnNullIfFailed(this.form.get('prio').value),

      /* */
      isCountry: this.createFalseIfNull(this.form.get('isCountry').value),
      isCapital: this.createFalseIfNull(this.form.get('isCapital').value),
      isFederalState: this.createFalseIfNull(this.form.get('isFederalState').value),
      isListDestination: this.createFalseIfNull(this.form.get('isListDestination').value),
      isEnabled: this.createFalseIfNull(this.form.get('isEnabled').value),
      hasHeaderImage: this.createFalseIfNull(this.form.get('hasHeaderImage').value),

      /* */
      countryCode,
      countryNameDe,
      countryNameEn,
      countryNameEnGb,
      countryNameEs,
      countryNameIt,
      slugsCountryNameDe,
      slugsCountryNameEn,
      slugsCountryNameEnGb,
      slugsCountryNameEs,
      slugsCountryNameIt,

      /* */
      googleDataDe: this.googleDataDe,
      googleDataEn: this.googleDataEn,
      googleDataEnGb: this.googleDataEnGb,
      googleDataEs: this.googleDataEs,
      googleDataIt: this.googleDataIt,

      /* */
      languageVersionEnabledDe: false,
      languageVersionEnabledEn: false,
      languageVersionEnabledEnGb: false,
      languageVersionEnabledEs: false,
      languageVersionEnabledIt: false,

      /* */
      recommendedGroups: [],
    };
  }

  public async getGooglePlaceDetailsFromService() {
    const response = await this.http.get(environment.urls.service + '/api/v1/authenticated/admin/helper/google/google-place-id/' + this.destinationGooglePlaceDetails.place_id + '?languages[]=en&languages[]=de&languages[]=es&languages[]=it', {}).toPromise();

    const elements = (response as any).googleElements;
    for (const element of elements) {
      if (element.language === 'en') {
        this.googleDataEn = element.googleResponse.result;
        this.googleDataEnGb = element.googleResponse.result;
      }
      if (element.language === 'de') {
        this.googleDataDe = element.googleResponse.result;
      }
      if (element.language === 'es') {
        this.googleDataEs = element.googleResponse.result;
      }
      if (element.language === 'it') {
        this.googleDataIt = element.googleResponse.result;
      }
    }

    this.mapGoogleDetailsToForm();
  }

  public async getBoundaryDetailsByGooglePlaceIdAndHandleFormActivation() {
    try {
      /* */
      this.isBoundaryApproved = true;
      this.doesBoundaryExist = true;

      /* */
      const response: any = await this.http.get(environment.urls.service + '/api/v2/authenticated/admin/boundary/find-by-google-place-id?googlePlaceId=' + this.destinationGooglePlaceDetails.place_id, {}).toPromise();

      if (response.validated === false || response.accepted === false) {
        this.isBoundaryApproved = false;
        this.urlBoundaryApproval = 'developer/boundaries-v2/' + response._id + '/edit';
        return;
      }

    } catch (exception) {
      this.doesBoundaryExist = false;
      this.urlBoundaryAdd = 'developer/boundaries-v2/add';
    } finally {
      /* */
      this.changeDetectorRef.detectChanges();
    }
  }

  private tryToDetermineCountry() {
    for (const addressComponent of this.googleDataDe.address_components) {
      if (addressComponent.types.indexOf('country') !== -1) {
        return addressComponent.short_name;
      }
    }

    return null;
  }

  private mapGoogleDetailsToForm() {
    /* */
    const countryCode = this.tryToDetermineCountry();
    if (countryCode != null) {
      this.form.patchValue({'countryCode': countryCode});
    }

    /* */
    this.form.patchValue({'destinationNameDe': this.googleDataDe.name});
    this.form.patchValue({'slugsDestinationDe': this.createSlugFromString(this.googleDataDe.name)});
    this.form.patchValue({'destinationNameEn': this.googleDataEn.name});
    this.form.patchValue({'slugsDestinationEn': this.createSlugFromString(this.googleDataEn.name)});
    this.form.patchValue({'destinationNameEnGb': this.googleDataEnGb.name});
    this.form.patchValue({'slugsDestinationEnGb': this.createSlugFromString(this.googleDataEnGb.name)});
    this.form.patchValue({'destinationNameEs': this.googleDataEs.name});
    this.form.patchValue({'slugsDestinationEs': this.createSlugFromString(this.googleDataEs.name)});
    this.form.patchValue({'destinationNameIt': this.googleDataIt.name});
    this.form.patchValue({'slugsDestinationIt': this.createSlugFromString(this.googleDataIt.name)});
  }

  private createSlugFromString(data: string) {
    /* make it lowercase */
    let elem = data.toLowerCase();

    /* replace umlauts */
    elem = elem.replace(/\d/g, '').trim();
    elem = elem.replace(/&/g, 'und');
    elem = elem.replace(/ä/g, 'ae');
    elem = elem.replace(/ü/g, 'ue');
    elem = elem.replace(/ö/g, 'oe');

    /* replace other kinds of umlauts */
    elem = elem.replace(/[áàâãå]/g, 'a');
    elem = elem.replace(/[úùû]/g, 'u');
    elem = elem.replace(/[ç]/g, 'c');
    elem = elem.replace(/[ô]/g, 'o');
    elem = elem.replace(/[é]/g, 'e');

    /* replace all non english letters with - */
    elem = elem.replace(/[^A-Za-z0-9\-_]/g, '-');

    /* */
    return elem;
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    return s;
  }

  private tryToCreateNumberOrReturnNullIfFailed(s: string) {
    const num = parseFloat(s);
    if (isNaN(num)) {
      return null;
    }
    return num;
  }

  private createFalseIfNull(element) {
    if (element == null) {
      return false;
    }
    return element;
  }

  public async handleGooglePlaceIdChangeOriginal(address: Address) {
    console.log(address);
    this.destinationGooglePlaceDetails = address;

    await this.getGooglePlaceDetailsFromService();
    await this.getBoundaryDetailsByGooglePlaceIdAndHandleFormActivation();

    /* */
    this.changeDetectorRef.detectChanges();
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        console.log(field);
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
