<app-content-header i18n-title title="Validation Errors" i18n-subTitle
                    subTitle="A list of all logged validation errors"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">
  <div class="row">

    <div class="col-2">
      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Settings</h3>
        </div>
        <div class="block-content block-content-full">
          <form (change)="requestData()" [formGroup]="form">
          <select class="form-control" name="filter-state" id="filter-state" formControlName="state">
            <option>NEW</option>
            <option>IN_PROGRESS</option>
            <option>FINISHED</option>
            <option>CANCELLED</option>
          </select>
          </form>
        </div>
      </div>

    </div>
    <div class="col-10">
      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Overview</h3>
        </div>
        <div class="block-content block-content-full">
          <table class="table table-borderless table-striped">
            <thead>
            <tr>
              <th>ID</th>
              <th class="text-center">Type</th>
              <th>Path</th>
              <th>State</th>
              <th>Created At</th>
              <th class="text-center">Link</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let entry of validationErrors">
              <td>
                {{entry._id}}
              </td>
              <td class="text-center">
                {{entry.requestType}}
              </td>
              <td>
                {{entry.path}}
              </td>
              <td>
                {{entry.state}}
              </td>
              <td>
                {{entry.createdAt}}
              </td>
              <td class="text-center">
                <a [routerLink]="['/developer', 'validation-error', entry._id]">Details</a>
              </td>
            </tr>
            </tbody>
          </table>
          <nav aria-label="Orders navigation">
            <ul class="pagination justify-content-end" *ngIf="paginationData != null">
              <li class="page-item" *ngFor="let page of paginationData.pages">
                <a class="page-link" [routerLink]="['/developer', 'validation-error']" [queryParams]="{page: page}">{{page}}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
