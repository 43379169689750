import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HousingInfoService} from '../../../../services/housing-info/housing-info.service';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {EventsService} from "../../../../services/events/events.service";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  public housingId;
  public amountOfHousingRequests = 0;
  public amountOfNoOffers = 0;
  public amountOfPreOffers = 0;
  public amountOfOffers = 0;
  public amountOfBookings = 0;
  public amountOfDeclinedOffersByUser = 0;
  public amountOfPriceHistoryEntriesBooking = 0;
  public amountOfPriceHistoryEntriesTrivago = 0;
  public amountOfPictures = 0;

  constructor(private housingInfoService: HousingInfoService,
              private eventsService: EventsService,
              private http: HttpClient,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.parent.params.subscribe((params) => {
      this.housingId = params['housingId'];
    });
    this.requestDetailDataFromBackend();
  }

  public requestDetailDataFromBackend() {
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housingId + '/overview')
      .subscribe(
        (response: any) => {
          this.amountOfHousingRequests = response.amountOfHousingRequests;
          this.amountOfNoOffers = response.amountOfNoOffers;
          this.amountOfPreOffers = response.amountOfPreOffers;
          this.amountOfOffers = response.amountOfOffers;
          this.amountOfBookings = response.amountOfBookings;
          this.amountOfDeclinedOffersByUser = response.amountOfDeclinedOffersByUser;
          this.amountOfPriceHistoryEntriesBooking = response.amountOfPriceHistoryEntriesBooking;
          this.amountOfPriceHistoryEntriesTrivago = response.amountOfPriceHistoryEntriesTrivago;
          this.amountOfPictures = response.amountOfPictures;
        }
      );
  }

}
