<nav id="sidebar">
  <!-- Sidebar Scroll Container -->
  <div id="sidebar-scroll">
    <!-- Sidebar Content -->
    <div class="sidebar-content">
      <!-- Side Header -->
      <div class="content-header content-header-fullrow px-15">
        <!-- Mini Mode -->
        <div class="content-header-section sidebar-mini-visible-b">
          <!-- Logo -->
          <span class="content-header-item font-w700 font-size-xl float-left animated fadeIn">
                                    <span class="text-dual-primary-dark">trp</span><span
            class="text-primary">mkry</span>
                                </span>
          <!-- END Logo -->
        </div>
        <!-- END Mini Mode -->

        <!-- Normal Mode -->
        <div class="content-header-section text-center align-parent sidebar-mini-hidden">
          <!-- Close Sidebar, Visible only on mobile screens -->
          <!-- Layout API, functionality initialized in Codebase() -> uiApiLayout() -->
          <button type="button" class="btn btn-circle btn-dual-secondary d-lg-none align-v-r"
                  data-toggle="layout" data-action="sidebar_close">
            <i class="fa fa-times text-danger"></i>
          </button>
          <!-- END Close Sidebar -->

          <!-- Logo -->
          <div class="content-header-item">
            <a class="link-effect font-w700" [routerLink]="['/']">
              <span class="font-size-xl text-dual-primary-dark">trip</span><span
              class="font-size-xl text-primary">makery</span>
            </a>
          </div>
          <!-- END Logo -->
        </div>
        <!-- END Normal Mode -->
      </div>
      <!-- END Side Header -->

      <!-- Side User -->
      <div class="content-side content-side-full content-side-user px-10 align-parent">
        <!-- Visible only in mini mode -->
        <div class="sidebar-mini-visible-b align-v animated fadeIn">
          <img class="img-avatar img-avatar32" src="assets/media/avatars/avatar15.jpg" alt="">
        </div>
        <!-- END Visible only in mini mode -->

        <!-- Visible only in normal mode -->
        <div class="sidebar-mini-hidden-b text-center">
          <a class="img-link" href="javascript:void(0)">
            <img class="img-avatar"
                 src="assets/media/avatars/avatar15.jpg" alt="">
          </a>
          <ul class="list-inline mt-10">
            <li class="list-inline-item">
              <a class="link-effect text-dual-primary-dark font-size-xs font-w600 text-uppercase"
                 href="javascript:void(0)">ADMIN</a>
            </li>
            <li class="list-inline-item" *ngIf="accountService.isLoggedIn() === true">
              <a class="link-effect text-dual-primary-dark" (click)="logout()" style="cursor:pointer">
                <i class="si si-logout"></i>
              </a>
            </li>
          </ul>
        </div>
        <!-- END Visible only in normal mode -->
      </div>
      <!-- END Side User -->

      <!-- Side Navigation -->
      <div class="content-side content-side-full">
        <ul class="nav-main">
          <li class="nav-main-heading">
            <span class="sidebar-mini-visible">DASH</span>
            <span class="sidebar-mini-hidden">Dashboard</span>
          </li>

          <li routerLinkActive="open">
            <a class="nav-submenu" data-toggle="nav-submenu" href="#"><i
              class="fa fa-hotel"></i><span class="sidebar-mini-hide">Housings</span></a>
            <ul>
              <li>
                <a routerLinkActive="active" [routerLink]="['/housings/search']"><span
                  class="sidebar-mini-hide">Search</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/housings/add']"><span
                  class="sidebar-mini-hide">Add</span></a>
              </li>
            </ul>
          </li>

          <li routerLinkActive="open">
            <a class="nav-submenu" data-toggle="nav-submenu" href="#"><i
              class="fa fa-users"></i><span class="sidebar-mini-hide">Users</span></a>
            <ul>
              <li>
                <a routerLinkActive="active" [routerLink]="['/users/search']"><span
                  class="sidebar-mini-hide">Search</span></a>
              </li>
            </ul>
          </li>

          <li routerLinkActive="open">
            <a class="nav-submenu" data-toggle="nav-submenu" href="#"><i
              class="fa fa-map"></i><span class="sidebar-mini-hide">Requests</span></a>
            <ul>
              <li>
                <a routerLinkActive="active" [routerLink]="['/requests']"><span
                  class="sidebar-mini-hide">Search</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/requests/follow-up']"><span
                  class="sidebar-mini-hide">Follow Up</span></a>
              </li>
            </ul>
          </li>

          <li routerLinkActive="open">
            <a class="nav-submenu" data-toggle="nav-submenu" href="#"><i
              class="fa fa-money"></i><span class="sidebar-mini-hide">Bookings</span></a>
            <ul>
              <li>
                <a routerLinkActive="active" [routerLink]="['/admin/booking/bookings/change-requests']"><span
                  class="sidebar-mini-hide">Change Requests</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/admin/booking/direct-search']"><span
                  class="sidebar-mini-hide">Direct Search</span></a>
              </li>
            </ul>
          </li>

          <li routerLinkActive="open">
            <a class="nav-submenu" data-toggle="nav-submenu" href="#"><i
              class="fa fa-money"></i><span class="sidebar-mini-hide">Offers</span></a>
            <ul>
              <li>
                <a routerLinkActive="active" [routerLink]="['/admin/offer/change-requests']"><span
                  class="sidebar-mini-hide">Change Requests</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/offer/messages']"><span
                  class="sidebar-mini-hide">Messages</span></a>
              </li>
            </ul>
          </li>

          <li routerLinkActive="open">
            <a class="nav-submenu" data-toggle="nav-submenu" href="#"><i
              class="fa fa-globe"></i><span class="sidebar-mini-hide">Dest Override</span></a>
            <ul>
              <li>
                <a routerLinkActive="active" [routerLink]="['/destination-override/add']"><span
                  class="sidebar-mini-hide">Add</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/destination-override']"><span
                  class="sidebar-mini-hide">List</span></a>
              </li>
            </ul>
          </li>

          <li routerLinkActive="open">
            <a class="nav-submenu" data-toggle="nav-submenu" href="#"><i
              class="fa fa-address-card"></i><span class="sidebar-mini-hide">Housing Requests</span></a>
            <ul>
              <li>
                <a routerLinkActive="active" [routerLink]="['/housing-requests/request-filter-check']"><span
                  class="sidebar-mini-hide">Request Filter Check</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/housing-requests/add']"><span
                  class="sidebar-mini-hide">Add</span></a>
              </li>
            </ul>
          </li>

          <li routerLinkActive="open">
            <a class="nav-submenu" data-toggle="nav-submenu" href="#"><i
              class="fa fa-phone"></i><span class="sidebar-mini-hide">Phone Callback</span></a>
            <ul>
              <li>
                <a routerLinkActive="active" [routerLink]="['/admin/phone-callback/requests']"><span
                  class="sidebar-mini-hide">List</span></a>
              </li>
            </ul>
          </li>

          <li routerLinkActive="open">
            <a class="nav-submenu" data-toggle="nav-submenu" href="#"><i
              class="fa fa-newspaper-o"></i><span class="sidebar-mini-hide">Pages</span></a>
            <ul>
              <li>
                <a routerLinkActive="active" [routerLink]="['/pages/site-creation']"><span
                  class="sidebar-mini-hide">Site Creation</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/pages/destinations/search']"><span
                  class="sidebar-mini-hide">Destinations</span></a>
              </li>
              <li routerLinkActive="open">
                <a class="nav-submenu" data-toggle="nav-submenu" href="#">
                  <span class="sidebar-mini-hide">BL Pages</span></a>
                <ul>
                  <li>
                    <a routerLinkActive="active" [routerLink]="['/pages/bl/search']"><span
                      class="sidebar-mini-hide">BL Pages</span></a>
                  </li>
                  <li>
                    <a routerLinkActive="active" [routerLink]="['/pages/bl-keyword/search']"><span
                      class="sidebar-mini-hide">BL Keywords</span></a>
                  </li>
                </ul>
              </li>

              <li routerLinkActive="open">
                <a class="nav-submenu" data-toggle="nav-submenu" href="#">
                  <span class="sidebar-mini-hide">Generated Texts</span></a>
                <ul>
                  <li>
                    <a routerLinkActive="active" [routerLink]="['/pages/generated-text']"><span
                      class="sidebar-mini-hide">Generated Text</span></a>
                  </li>
                  <li>
                    <a routerLinkActive="active" [routerLink]="['/pages/generated-text/get-by-keys']"><span
                      class="sidebar-mini-hide">Get By Keys</span></a>
                  </li>
                  <li>
                    <a routerLinkActive="active" [routerLink]="['/pages/generated-text/destination']"><span
                      class="sidebar-mini-hide">Destination</span></a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li routerLinkActive="open">
            <a class="nav-submenu" data-toggle="nav-submenu" href="#"><i
              class="fa fa-line-chart"></i><span class="sidebar-mini-hide">Reports</span></a>
            <ul>
              <li>
                <a routerLinkActive="active" [routerLink]="['/admin/report/request/destinations']"><span
                  class="sidebar-mini-hide">Top Destinations</span></a>
              </li>
            </ul>
          </li>

          <li class="nav-main-heading">
            <span class="sidebar-mini-visible">DEV</span>
            <span class="sidebar-mini-hidden">Developer Area</span></li>
          <li routerLinkActive="open">
            <a class="nav-submenu" data-toggle="nav-submenu" href="#"><i
              class="fa fa-search"></i><span class="sidebar-mini-hide">Developer</span></a>
            <ul>
              <li>
                <a routerLinkActive="active" [routerLink]="['/developer/boundaries-v2/add']"><span
                  class="sidebar-mini-hide">Add Boundary</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/developer/boundaries']"><span
                  class="sidebar-mini-hide">Boundaries</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/developer/housing-filter-ib']"><span
                  class="sidebar-mini-hide">Instant Booking</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/developer/validation-error']"><span
                  class="sidebar-mini-hide">Validation Errors</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/developer/crawler-jobs']"><span
                  class="sidebar-mini-hide">Crawler Jobs</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/developer/emails/email-existence']"><span
                  class="sidebar-mini-hide">Email Existence Check</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/developer/emails/email-server-blacklist']"><span
                  class="sidebar-mini-hide">Email Server Blacklist Check</span></a>
              </li>
              <li>
                <a routerLinkActive="active" [routerLink]="['/developer/emails/bounces']"><span
                  class="sidebar-mini-hide">Email Bounces</span></a>
              </li>
              <li routerLinkActive="open">
                <a class="nav-submenu" data-toggle="nav-submenu" href="#">
                  <span class="sidebar-mini-hide">Imports</span></a>
                <ul>
                  <li>
                    <a routerLinkActive="active" [routerLink]="['/developer/import/onboardings']"><span
                      class="sidebar-mini-hide">Housing Onboardings</span></a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
