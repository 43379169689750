<app-content-header i18n-title title="Request Follow Ups" i18n-subTitle
                    subTitle="Manage your follow-up calls or checks"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">


  <div class="row">
    <div class="col-md-3">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Filter</h3>
        </div>

        <div class="block-content">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="form">
                <div class="form-group row">
                  <label class="col-12">Request Id</label>
                  <div class="col-12">
                    <input type="text" class="form-control"
                           placeholder="Please enter your Request Id"
                           autocomplete="off"
                           formControlName="requestId">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12">Group Manager</label>
                  <div class="col-12">

                    <ng-select
                      placeholder="Choose one.."
                      id="example-select22"
                      [items]="groupManagerList"
                      bindLabel="displayName"
                      bindValue="_id"
                      (change)="changeGroupManager($event)"
                      [clearable]="true"
                    >

                      <ng-template ng-label-tmp let-item="item">
                        <span style="margin-left:10px">{{item.displayName}}</span>
                      </ng-template>

                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <span style="margin-left:10px">{{item.displayName}}</span>
                      </ng-template>

                    </ng-select>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-12 text-center">
                    <button type="button" class="btn btn-success" (click)="applyFilter()">Apply Filter</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="col-md-9">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Result</h3>
          <div class="block-options">
            <button type="button" (click)="showAddModal(null, null)" class="btn-block-option" data-dismiss="modal"
                    aria-label="Open">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>

        <ng-container *ngIf="paginationData != null && requests != null && requests.length > 0">
          <div class="block-content">
            <div class="table-responsive">
              <table class="table table-sm">
                <thead>
                <tr>
                  <th>RequestId</th>
                  <th>GroupManager</th>
                  <th>Destination</th>
                  <th class="text-right">Persons</th>
                  <th class="text-center">State</th>
                  <th>Follow-Up Date</th>
                  <th class="text-center">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let pipeline of requests | paginate: {
                id: 'ctrls_autodisqualification',
              itemsPerPage: paginationData.limit,
              currentPage: paginationData.page,
              totalItems: paginationData.total}"
                >
                  <td>{{pipeline.request._id}}</td>
                  <td>{{pipeline.groupManager.displayName}}</td>
                  <td>{{pipeline.request.destination.name}}</td>
                  <td class="text-right">{{pipeline.request.persons.total}}</td>
                  <td class="text-center">
                    <span class="badge badge-info" *ngIf="pipeline.state === 'Untouched'">Untouched</span>
                    <span class="badge badge-info" *ngIf="pipeline.state === 'Call'">Call</span>
                    <span class="badge badge-info" *ngIf="pipeline.state === 'RequestPartner'">Request Partner</span>
                    <span class="badge badge-warning" *ngIf="pipeline.state === 'WaitForOffers'">Waiting for Offers</span>
                    <span class="badge badge-warning" *ngIf="pipeline.state === 'WaitForUser'">Waiting for User</span>
                    <span class="badge badge-success" *ngIf="pipeline.state === 'Booked'">Booked</span>
                    <span class="badge badge-success" *ngIf="pipeline.state === 'Disqualified'">Disqualified</span>
                  </td>
                  <td>{{pipeline.adminInternals.followUp.followUpAtString}}</td>
                  <td class="text-center">
                    <a href="{{createKitchenUrl(pipeline.request._id)}}"><span class="btn btn-sm btn-success"><i class="fa fa-share"></i>
</span></a>
                    &nbsp;
                    <button type="button"
                            (click)="showAddModal(pipeline.request._id, pipeline.adminInternals.followUp.followUpAt)"
                            class="btn btn-sm btn-info" data-dismiss="modal" aria-label="Open">
                      <i class="fa fa-edit"></i>
                    </button>
                    &nbsp;
                    <button type="button" class="btn btn-sm btn-danger"
                            (dblclick)="requestAutoDisqualifyDelete(pipeline.request._id)">
                      <i class="fa fa-close"></i>
                    </button>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
            <pagination-controls
              id="ctrls_autodisqualification"
              [maxSize]="paginationData.limit"
              (pageChange)="sendPage($event)"
            ></pagination-controls>
          </div>
        </ng-container>

        <ng-container *ngIf="requests == null || requests.length === 0">
          <div class="block-content block-content-full text-center">
            No results to display
          </div>
        </ng-container>

      </div>
    </div>

  </div>
</div>

<!-- Required Modals -->
<tripmakery-request-follow-up-add-modal></tripmakery-request-follow-up-add-modal>
