import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-housing-search-housing-element',
  templateUrl: './housing-element.component.html',
  styleUrls: ['./housing-element.component.css']
})
export class HousingElementComponent implements OnInit {
  @Input('housing') housing: any;
  @Input() index: any;
  public id: number;
  public environment = environment;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

  }

}
