import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { EventsService } from '../../../../services/events/events.service';
import { environment } from '../../../../../environments/environment';
import { CountryCodeService } from '../../../../services/country-code/country-code.service';
import { FormUtils } from '../../../../shared/utils/form-utils';

@Component({
  selector: 'app-generated-text-destination-add-modal',
  templateUrl: './generated-text-destination-add-modal.component.html',
})
export class GeneratedTextDestinationAddModalComponent implements OnInit {
  public bookingChangeRequestId: string;
  public form: FormGroup;
  public environment_;
  public isLoading: boolean = false;

  public bsConfig = {
    ignoreBackdropClick: true,
    backdrop: true,
  };
  public patterns: Array<{ _id: string, patternName: string, description: string }> = [];

  @ViewChild('childModal') public childModal: ModalDirective;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
              private router: Router,
              public countryCodeService: CountryCodeService,
              private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.form = new FormGroup({
      'identifier': new FormControl({value: '', disabled: false}),
      'description': new FormControl({value: '', disabled: false}),
      'patternId': new FormControl({value: '', disabled: false}),
    });
    this.environment_ = environment;
  }

  public ngOnInit() {
    this.handleReceiveAvailablePatterns();
  }

  public showChildModal(): void {
    this.form.reset();

    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    /* */
    const body = this.createCreationBody();

    console.log(body);

    /* */
    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/generated-text/destination', body)
      .subscribe(
        (response: any) => {
          this.router.navigate(['pages', 'generated-text', 'destination', response._id]);

        },
        (err) => {
          if (err.error.message === 'E_VALIDATION_FAILED') {
            for (const error of err.error.errors) {
              try {
                console.log(error.property, error.constraints);

                this.form.get(error.property).setErrors(error.constraints);
                this.form.get(error.property).markAsDirty();

              } catch (error) {
                console.log('Catched E', error);
              }
            }

            this.hideLoading();
            return;
          }

          console.log(err);

          this.hideLoading();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
          this.hideChildModal();
        }
      );
  }

  public handleReceiveAvailablePatterns() {
    /* */
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/ai-pattern/all')
      .subscribe(
        (response: any) => {
          this.patterns = response;
        },
        (err) => {
          console.log(err);

          this.hideLoading();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
        }
      );
  }

  private createCreationBody() {

    return {
      /* */
      identifier: FormUtils.createNullIfEmptyString(this.form.get('identifier').value),
      description: FormUtils.createNullIfEmptyString(this.form.get('description').value),
      patternId: FormUtils.createNullIfEmptyString(this.form.get('patternId').value),
    };
  }


  public extractValidationErrorsAsArray(errors) {
    return FormUtils.extractValidationErrorsAsArray(errors);
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

}
