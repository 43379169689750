import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {environment} from "../../../../../../environments/environment";
import {LoadingIndicatorService} from "../../../../../services/loading-indicator/loading-indicator.service";
import {HousingInfoService} from "../../../../../services/housing-info/housing-info.service";
import {HttpClient} from "@angular/common/http";
import {EventsService} from "../../../../../services/events/events.service";

@Component({
  selector: 'app-housing-info-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class HousingInfoDetailsRatingComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  form: FormGroup;
  isLoading: boolean = false;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'rating': new FormControl({value: '', disabled: true}),
      'numberOfRatings': new FormControl({value: '', disabled: true}),
      'breakfast': new FormControl({value: '', disabled: true}),
      'food': new FormControl({value: '', disabled: true}),
      'rooms': new FormControl({value: '', disabled: true}),
      'wifi': new FormControl({value: '', disabled: true}),
      'price': new FormControl({value: '', disabled: true}),
      'service': new FormControl({value: '', disabled: true}),
      'equipment': new FormControl({value: '', disabled: true}),
      'location': new FormControl({value: '', disabled: true}),
      'comfort': new FormControl({value: '', disabled: true}),
      'tidiness': new FormControl({value: '', disabled: true}),
      'building': new FormControl({value: '', disabled: true}),
    });
  }

  onSubmitForm() {
    if (this.form.status !== 'VALID') {
      return;
    }
    this.showLoading();

    this.postData().subscribe(
      (response: any) => {
        this.hideLoading();
        this.housingInfoService.setSelectedHousing(response);
      },
      err => {

        if (err.error.message === "E_VALIDATION_FAILED") {
          try {
            for (const error of err.error.errors) {
              this.form.get(error.property).setErrors(error.constraints);
              this.form.get(error.property).markAsDirty();
            }
          } catch (error) {
            console.log("Catched E", error)
          }
          this.hideLoading();
          return;
        }

        this.hideLoading();
        this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));
      }
    )
  }

  private postData() {
    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/rating', {
      rating: this.createNullIfEmptyStringOrReturnFloat(this.form.get('rating').value),
      numberOfRatings: this.createNullIfEmptyStringOrReturnFloat(this.form.get('numberOfRatings').value),
      breakfast: this.createNullIfEmptyStringOrReturnFloat(this.form.get('breakfast').value),
      food: this.createNullIfEmptyStringOrReturnFloat(this.form.get('food').value),
      rooms: this.createNullIfEmptyStringOrReturnFloat(this.form.get('rooms').value),
      wifi: this.createNullIfEmptyStringOrReturnFloat(this.form.get('wifi').value),
      price: this.createNullIfEmptyStringOrReturnFloat(this.form.get('price').value),
      service: this.createNullIfEmptyStringOrReturnFloat(this.form.get('service').value),
      equipment: this.createNullIfEmptyStringOrReturnFloat(this.form.get('equipment').value),
      location: this.createNullIfEmptyStringOrReturnFloat(this.form.get('location').value),
      comfort: this.createNullIfEmptyStringOrReturnFloat(this.form.get('comfort').value),
      tidiness: this.createNullIfEmptyStringOrReturnFloat(this.form.get('tidiness').value),
      building: this.createNullIfEmptyStringOrReturnFloat(this.form.get('building').value)
    });
  }

  private createNullIfEmptyStringOrReturnFloat(s: string) {
    if (s != null && typeof s === "string" && s.trim() === '') {
      return null;
    }
    const d = parseFloat(s);

    if (isNaN(d)) {
      return s;
    }

    return d;
  }

  ngOnInit() {
    if (this.housing != null) {
      this.mapHousingToForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  private mapHousingToForm() {
    const vals = {
      rating: this.returnEmptyStringWhenNull(this.housing.rating),
      numberOfRatings: this.returnEmptyStringWhenNull(this.housing.numberOfRatings),
      breakfast: (this.housing.ratingDetails) ? this.returnEmptyStringWhenNull(this.housing.ratingDetails.breakfast) : '',
      food: (this.housing.ratingDetails) ? this.returnEmptyStringWhenNull(this.housing.ratingDetails.food) : '',
      rooms: (this.housing.ratingDetails) ? this.returnEmptyStringWhenNull(this.housing.ratingDetails.rooms) : '',
      wifi: (this.housing.ratingDetails) ? this.returnEmptyStringWhenNull(this.housing.ratingDetails.wifi) : '',
      price: (this.housing.ratingDetails) ? this.returnEmptyStringWhenNull(this.housing.ratingDetails.price) : '',
      service: (this.housing.ratingDetails) ? this.returnEmptyStringWhenNull(this.housing.ratingDetails.service) : '',
      equipment: (this.housing.ratingDetails) ? this.returnEmptyStringWhenNull(this.housing.ratingDetails.equipment) : '',
      location: (this.housing.ratingDetails) ? this.returnEmptyStringWhenNull(this.housing.ratingDetails.location) : '',
      comfort: (this.housing.ratingDetails) ? this.returnEmptyStringWhenNull(this.housing.ratingDetails.comfort) : '',
      tidiness: (this.housing.ratingDetails) ? this.returnEmptyStringWhenNull(this.housing.ratingDetails.tidiness) : '',
      building: (this.housing.ratingDetails) ? this.returnEmptyStringWhenNull(this.housing.ratingDetails.building) : ''
    };
    this.form.setValue(
      vals
    );

    this.enableDisableFormCtrl();
  }

  public returnEmptyStringWhenNull(element) {
    return (element == null) ? '' : element;
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  private enableDisableFormCtrl() {
    const enable = (this.housing != null);
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (enable) {
          this.form.controls[key].enable();
        } else {
          this.form.controls[key].disable();
        }
      }
    }
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }
}
