<div class="col-xl-12" *ngIf="paginationData != null">
  <h2 class="content-heading">Latest No-Offers (Declined Housing Requests)</h2>

  <!-- -->
  <div class="block block-rounded" *ngIf="noOffers != null && noOffers.length > 0">
    <div class="block-content">
      <div class="table-responsive">
        <table class="table table-borderless table-striped">
          <thead>
          <tr>
            <th>Id</th>
            <th>Request</th>
            <th>Creator</th>
            <th>Decline Reason</th>
            <th>Created</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let noOffer of noOffers | paginate: {
              id: 'ctrls_dp_' + section,
              itemsPerPage: paginationData.limit,
              currentPage: paginationData.page,
              totalItems: paginationData.total}">
            <td>
              {{noOffer._id}}
            </td>
            <td>
              {{noOffer.request}}
            </td>
            <td class="text-left">
                <span class="badge badge-primary" *ngIf="noOffer.createdByAdmin === true">Admin</span>
                <span class="badge badge-info" *ngIf="noOffer.createdBySytem === true || noOffer.housingNoReaction === true">System</span>
                <span class="badge badge-success"
                      *ngIf="(noOffer.createdBySytem === false || noOffer.createdBySytem == null) && (noOffer.createdByAdmin === false || noOffer.createdByAdmin == null) && (noOffer.housingNoReaction === false || noOffer.housingNoReaction == null)">Hotel</span>
            </td>
            <td class="text-left">
                <span class="badge badge-warning" *ngIf="noOffer.date.noAvailability === true">No Availability</span>
                <span class="badge badge-warning" *ngIf="noOffer.budget.tooSmall === true">Budget too small</span>
                <span class="badge badge-warning" *ngIf="noOffer.housingSize.tooSmall === true">Housing too small</span>
            </td>
            <td class="d-none d-sm-table-cell">
              {{noOffer.created}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="block-content block-content-sm bg-body-light font-size-sm text-center">
      <pagination-controls
        id="ctrls_dp_{{section}}"
        [maxSize]="paginationData.limit"
        (pageChange)="sendPage($event)"
      ></pagination-controls>
    </div>
  </div>

  <!-- -->
  <div class="block block-rounded" *ngIf="noOffers == null || noOffers.length === 0">
    <div class="block-content block-content-full text-center">
      No results to display
    </div>
  </div>

</div>
