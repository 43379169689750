import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {BreadCrumbElement} from "../../../../shared/entities/breadcrumb.element.entity";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";
import {ConfigService} from "../../../../services/configservice/config.service";
import {EventsService} from "../../../../services/events/events.service";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {ModalImportOsmComponent} from "../modal-import-osm/modal-import-osm.component";
import {OpenStreetMapResponse} from "../../../../shared/interfaces/open-street-map-response.interface";
import {OpenStreetMapsService} from "../../../../services/open-street-maps/open-street-maps.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {FormControl, FormGroup} from "@angular/forms";
import {BoundaryContainerComponent} from "../boundary-container/boundary-container.component";
import { FieldsBasicPlaceDetails } from '../../../../shared/consts/fields-basic-place-details';

@Component({
  selector: 'app-boundaries-v2-add',
  templateUrl: './add.component.html'
})
export class AddComponent implements OnInit, AfterViewInit {
  @ViewChild('modalImportOsmComponent') modalImportOsmComponent: ModalImportOsmComponent;
  @ViewChild('boundaryContainerComponentBase') boundaryContainerComponentBase: BoundaryContainerComponent;
  @ViewChild('boundaryContainerComponentFinal') boundaryContainerComponentFinal: BoundaryContainerComponent;

  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public page = 1;
  public limit = 25;
  public section = 1;

  /* */
  public pagination: any;
  public elements;

  public googleLocation: Address;
  public options = {
    fields: FieldsBasicPlaceDetails,
  };
  public formSendToDb: FormGroup;
  public formFinalization: FormGroup;
  public formGoogleLocation: FormGroup;

  public selectionMode = 0;

  public title = "Add Boundaries";
  public subTitle = "Easily add new destination boundaries to our database";

  public boundaryId: string;

  public bViewAfterInitInitialized = true;

  /* */
  public osmObject: OpenStreetMapResponse;
  public coordinatesGoogleBase = [];
  public coordinatesGoogleOptimized = [];

  constructor(private activatedRoute: ActivatedRoute,
              private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private osmService: OpenStreetMapsService,
              private http: HttpClient,
              private router: Router) {

    /* */
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Developer', 'javascript:void(0)'),
      new BreadCrumbElement('Boundaries v2', 'javascript:void(0)'),
      new BreadCrumbElement('Add', 'javascript:void(0)')
    ];

  }

  async ngOnInit() {
    this.registerEventListener();

    this.formFinalization = new FormGroup({
      'validated': new FormControl(false),
      'accepted': new FormControl(false),
      'customEntry': new FormControl(false),
      'selectableOnMarketplace': new FormControl(false),
    });

    this.formGoogleLocation = new FormGroup({
      'googleAddressText': new FormControl(''),
    });

    /* */
    const placeId = this.activatedRoute.snapshot.queryParamMap.get('placeId');
    if (placeId != null) {
      const googleData: any = await this.http.get(environment.urls.service + '/api/v1/authenticated/admin/google/places?placeId=' + placeId).toPromise();
      this.googleLocation = googleData.googlePlaceResponse;
      this.formGoogleLocation.setValue({
        googleAddressText: this.googleLocation.formatted_address
      });
    }

    /* */
    this.boundaryId = this.activatedRoute.snapshot.params['boundaryId'];
    if (this.boundaryId != null) {
      /* */
      this.title = "Edit Boundaries";
      this.subTitle = "Easily edit destination boundaries in our database";

      /* */
      this.breadCrumbElements = [
        new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
        new BreadCrumbElement('Developer', 'javascript:void(0)'),
        new BreadCrumbElement('Boundaries v2', 'javascript:void(0)'),
        new BreadCrumbElement(this.boundaryId, 'javascript:void(0)'),
        new BreadCrumbElement('Edit', 'javascript:void(0)')
      ];

      /* */
      await this.loadBoundaryDetails();

      /* */
      this.formGoogleLocation.controls['googleAddressText'].disable();
    }
  }

  public ngAfterViewInit(): void {
  }

  public async handleGooglePlaceIdChange(address: Address) {
    const elementsWithGooglePlaceId = await this.getBoundariesWithGooglePlaceIdFromAPI(address.place_id);

    if (elementsWithGooglePlaceId.length > 0) {
      return await this.router.navigate(['developer', 'boundaries-v2', elementsWithGooglePlaceId[0]["_id"], 'edit']);
    }

    this.googleLocation = address;
  }

  public showModalImportOSM() {
    this.modalImportOsmComponent.showModal();
  }

  public async loadBoundaryDetails() {
    this.loadingIndicatorService.showLoadingBar();

    try {
      const response = await this.http.get(environment.urls.service + '/api/v2/authenticated/admin/boundary/' + this.boundaryId).toPromise();
      this.mapBoundaryResponseToAppData(response);
    } catch (error) {
      console.log(error);
      this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
    }
    this.loadingIndicatorService.hideLoadingBar();

  }

  private mapBoundaryResponseToAppData(response: any) {
    /* */
    this.googleLocation = response.googleResponse;
    this.osmObject = response.osmResponse;
    this.coordinatesGoogleBase = this.osmService.transformNumberArrayToGoogleMapsCoordinates(response.boundaries);

    /* */
    // console.log("========= Response Boundaries vs Traversed Boundaries ============");
    // console.log(response.boundaries);
    // console.log(this.coordinatesGoogleBase);
    // console.log("==================================================================");

    /* */
    this.formGoogleLocation.setValue({
      googleAddressText: this.googleLocation.formatted_address
    });

    /* */
    this.formFinalization.setValue({
      validated: response.validated,
      accepted: response.accepted,
      customEntry: response.customEntry,
      selectableOnMarketplace: response.selectableOnMarketplace,
    });

    /* In case we don't have any optimized boundaries yet: import the non-optimized :) */
    if (response.boundariesOptimized != null && response.boundariesOptimized.length > 0) {
      this.coordinatesGoogleOptimized = this.osmService.transformNumberArrayToGoogleMapsCoordinates(response.boundariesOptimized);
    } else {
      this.coordinatesGoogleOptimized = this.osmService.transformNumberArrayToGoogleMapsCoordinates(response.boundaries);
    }
  }

  private registerEventListener() {
    this.eventsService.on('BOUNDARIES_IMPORT_OSM_ELEMENT', (o: OpenStreetMapResponse) => {
      this.osmObject = o;
      this.coordinatesGoogleBase = this.osmService.transformOSMResponseToGoogleMapsCoordinates(o);
      this.coordinatesGoogleOptimized = this.osmService.transformOSMResponseToGoogleMapsCoordinates(o);
    });

    this.eventsService.on('BOUNDARIES_SECONDARY_REQUESTS_BASE_BOUNDARY', () => {
      this.coordinatesGoogleOptimized = this.boundaryContainerComponentBase.extractCoordinatesAsLatLng();
    });
  }

  /**
   *
   * @param googlePlaceId
   */
  public async getBoundariesWithGooglePlaceIdFromAPI(googlePlaceId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('googlePlaceId', googlePlaceId);

    try {
      const response = await this.http.get(environment.urls.service + '/api/v2/authenticated/admin/boundary', {params: httpParams}).toPromise();
      this.loadingIndicatorService.hideLoadingBar();
      return response["elements"];
    } catch (error) {
      this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
      this.loadingIndicatorService.hideLoadingBar();
    }
  }

  /**
   * Requests all relevant data from the Child Components
   */
  public sendStuffToDb() {
    if (this.formFinalization.get('accepted').value === true &&
      !this.boundaryContainerComponentFinal.hasBoundaryBeenTestedSuccessfully) {
      return;
    }

    if (this.boundaryId == null) {
      this.sendPostCall()
    } else {
      this.sendPatchCall()
    }
  }

  private sendPatchCall() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.patch(environment.urls.service + '/api/v2/authenticated/admin/boundary/' + this.boundaryId, this.createAjaxBody())
      .subscribe((response: any) => {
          this.mapBoundaryResponseToAppData(response);
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }

  private sendPostCall() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.post(environment.urls.service + '/api/v2/authenticated/admin/boundary', this.createAjaxBody())
      .subscribe((response: any) => {
          this.router.navigate(['developer', 'boundaries-v2', response["_id"], 'edit']);
          this.loadingIndicatorService.hideLoadingBar();
          return;
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }

  private createAjaxBody() {
    return {
      boundaries: this.osmService.transformGoogleMapsCoordinatesToMongoDBNumberArray(this.boundaryContainerComponentBase.extractCoordinatesAsLatLng()),
      boundariesOptimized: this.osmService.transformGoogleMapsCoordinatesToMongoDBNumberArray(this.boundaryContainerComponentFinal.extractCoordinatesAsLatLng()),
      googleResponse: this.googleLocation,
      osmResponse: this.osmObject,
      validated: this.formFinalization.get('validated').value,
      accepted: this.formFinalization.get('accepted').value,
      customEntry: this.formFinalization.get('customEntry').value,
      selectableOnMarketplace: this.formFinalization.get('selectableOnMarketplace').value
    };
  }

  public async testBoundaryInDb() {
    this.loadingIndicatorService.showLoadingBar();

    try {
      const response = await this.http.get(environment.urls.service + '/api/v2/authenticated/admin/boundary/' + this.boundaryId + '/test-query').toPromise();
      console.log(response);
    } catch (error) {
      this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
    }

    this.loadingIndicatorService.hideLoadingBar();
  }
}
