import {Component, OnInit} from '@angular/core';
import {BreadCrumbElement} from "../../shared/entities/breadcrumb.element.entity";
import {FormControl, FormGroup} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {LoadingIndicatorService} from "../../services/loading-indicator/loading-indicator.service";
import {HttpClient} from "@angular/common/http";
import {EventsService} from "../../services/events/events.service";

@Component({
  selector: 'app-housing-request',
  templateUrl: './housing-request.component.html',
  styleUrls: ['./housing-request.component.css']
})
export class HousingRequestComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [
    new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
    new BreadCrumbElement('Housing Requests', 'javascript:void(0)'),
    new BreadCrumbElement('Add', 'javascript:void(0)')
  ];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public form: FormGroup;
  public isLoading: boolean = false;
  public isSuccessful: boolean = false;

  /* */
  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
              private http: HttpClient) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      'housingId': new FormControl({value: '', disabled: false}),
      'requestId': new FormControl({value: '', disabled: false})
    });
  }

  public onSubmitForm() {
    this.loadingIndicatorService.showLoadingBar();

    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing-request', {
      housingId: this.form.get('housingId').value,
      requestId: this.form.get('requestId').value
    })
      .subscribe((response: any) => {
          this.loadingIndicatorService.hideLoadingBar();
          this.isSuccessful = true;

          setTimeout(() => {
            this.isSuccessful = false;
          }, 2000);
        },
        err => {
          this.loadingIndicatorService.hideLoadingBar();

          if (err.error.message === "E_VALIDATION_FAILED") {
            try {
              for (const error of err.error.errors) {
                this.form.get(error.property).setErrors(error.constraints);
                this.form.get(error.property).markAsDirty();
              }
            } catch (error) {
              console.log("Catched E", error)
            }
            return;
          } else {
            this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));
          }

        });
  }

  /**
   * @TODO Static Method in Error.Utils
   * @param errors
   */
  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

}

