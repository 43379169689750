<app-content-header i18n-title title="Email Server Blacklist Check" i18n-subTitle
                    subTitle="Check if a mail server is blacklisted somewhere"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>
<div class="content">
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Settings</h3>
        </div>

        <!-- -->
        <div class="block-content block-content-full">
          <form [formGroup]="form" (ngSubmit)="checkIfServerIsBlacklisted()">
            <div class="row">
              <label class="col-12" for="server">Server</label>
              <div class="col-12">
                <input type="text" class="form-control"
                       id="server"
                       formControlName="server">
              </div>
            </div>

            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>

            <div class="row">
              <div class="col-12">
                <button class="btn btn-info btn-sm" type="submit">Check if blacklisted</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="data != null">
    <div class="col-md-12 ">
      <div class="block block-bordered">
        <div class="block-header block-header-default">
          <h3 class="block-title">Result</h3>
        </div>

        <!-- -->
        <div class="block-content block-content-full">
          <div class="row">
            <div class="col-12">
              <table>
                <thead>
                <tr>
                  <th>Blacklist</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let entry of data.list">
                  <td>{{entry.blacklist}}</td>
                  <td>
                    <span *ngIf="entry.listed === true" class="alert-danger">Listed</span>
                    <span *ngIf="entry.listed === false" class="alert-success">OK</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
