<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-popin"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" bsModal>
    <div class="modal-content">
      <div class="block block-themed block-transparent mb-0">
        <div class="block-header bg-primary-dark">
          <h3 class="block-title">Phone Callback Request Details</h3>
          <div class="block-options">
            <button type="button" class="btn-block-option" data-dismiss="modal" aria-label="Close" (click)="hideChildModal()">
              <i class="si si-close"></i>
            </button>
          </div>
        </div>
        <div class="block-content" *ngIf="phoneCallbackData != null">
          <div class="row">
            <label class="col-4">RequestId</label>
            <div class="col-6">
              {{phoneCallbackData.requestId}}
            </div>
            <div class="col-2">
              <a class="btn btn-sm" href="{{environment_.urls.kitchen}}/pipeline/untouched?source=search&requestId={{phoneCallbackData.requestId}}" target="_blank"><i class="fa fa-forward text-success"></i></a>
            </div>
          </div>
          <div class="row">
            <label class="col-4">OfferId</label>
            <div class="col-6">
              {{phoneCallbackData.offerId}}
            </div>
            <div class="col-2">
              <a class="btn btn-sm" href="{{environment_.urls.kitchen}}/request/{{phoneCallbackData.requestId}}/offers" target="_blank"><i class="fa fa-forward text-success"></i></a>
            </div>
          </div>
          <div class="row">
            <label class="col-4">Phone</label>
            <div class="col-6">
              {{phoneCallbackData.phonenumber}}
            </div>
            <div class="col-2">
<!--              <a class="btn btn-sm" href="{{environment_.urls.kitchen}}/request/{{phoneCallbackData.requestId}}/offers" target="_blank"><i class="fa fa-forward text-success"></i></a>-->
            </div>
          </div>
          <div class="row">
            <label class="col-4">Time</label>
            <div class="col-6">
              Between {{phoneCallbackData.beginTime}} and {{phoneCallbackData.endTime}} ({{phoneCallbackData.timezone}})
            </div>
            <div class="col-2">
<!--              <a class="btn btn-sm" href="{{environment_.urls.kitchen}}/request/{{phoneCallbackData.requestId}}/offers" target="_blank"><i class="fa fa-forward text-success"></i></a>-->
            </div>
          </div>
          <div class="row">
            <label class="col-4">CreatedAt</label>
            <div class="col-6">
              {{phoneCallbackData.createdAt}}
            </div>
            <div class="col-2">
            </div>
          </div>
          <div class="row">
            <label class="col-4">UpdatedAt</label>
            <div class="col-6">
              {{phoneCallbackData.updatedAt}}
            </div>
            <div class="col-2">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <h6>Latest Requests of this User</h6>
              <div class="table-responsive" *ngIf="phoneCallbacksUserId != null && phoneCallbacksUserId.length != 0">
                <table class="table table-sm">
                  <tr>
                    <th>RequestId</th>
                    <th>OfferId</th>
                    <th>State</th>
                  </tr>
                  <tr *ngFor="let callback of phoneCallbacksUserId">
                    <td>{{callback.requestId}}</td>
                    <td>{{callback.offerId}}</td>
                    <td>
                      <span class="badge badge-warning" *ngIf="callback.state === 'REQUESTED'">Requested</span>
                      <span class="badge badge-success" *ngIf="callback.state === 'DONE'">Done</span>
                      <span class="badge badge-danger" *ngIf="callback.state === 'MISSED'">Missed</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-6">
              <h6>Latest Requests of this Request</h6>
              <div class="table-responsive" *ngIf="phoneCallbacksRequest != null && phoneCallbacksRequest.length != 0">
                <table class="table table-sm">
                  <tr>
                    <th>RequestId</th>
                    <th>OfferId</th>
                    <th>State</th>
                  </tr>
                  <tr *ngFor="let callback of phoneCallbacksRequest">
                    <td>{{callback.requestId}}</td>
                    <td>{{callback.offerId}}</td>
                    <td>
                      <span class="badge badge-warning" *ngIf="callback.state === 'REQUESTED'">Requested</span>
                      <span class="badge badge-success" *ngIf="callback.state === 'DONE'">Done</span>
                      <span class="badge badge-danger" *ngIf="callback.state === 'MISSED'">Missed</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <form [formGroup]="form">
                <div class="form-group row">
                  <label class="col-4">New State</label>
                  <div class="col-4">
                    <select class="form-control" formControlName="state">
                      <option value="REQUESTED">Requested</option>
                      <option value="DONE">Done</option>
                      <option value="MISSED">Missed</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-alt-secondary" data-dismiss="modal" (click)="hideChildModal()">Abort
        </button>
        <button type="button" class="btn btn-alt-success" (click)="handleClick()" [disabled]="isLoading">
          <ng-container *ngIf="isLoading === false"><i class="fa fa-check"></i> Save</ng-container>
          <ng-container *ngIf="isLoading === true"><i class="fa fa-cog fa-spin"></i></ng-container>
        </button>
      </div>
    </div>
  </div>
</div>

