import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { EventsService } from '../../../../services/events/events.service';
import { environment } from '../../../../../environments/environment';
import { UserInfoHousingAccessAddModalComponent } from '../user-housing-access-add-modal/user-info-housing-access-add-modal.component';

@Component({
  selector: 'tripmakery-user-info-housing-access',
  templateUrl: './user-info-housing-access.component.html',
})
export class UserInfoHousingAccessComponent implements OnInit {
  public bookingChangeRequestId: string;
  public form: FormGroup;
  public environment_;
  public isLoading: boolean = false;

  public page = 1;
  public limit = 25;
  public paginationData;
  public rights;

  @ViewChild('userInfoHousingAccessAddModalComponent') userInfoHousingAccessAddModalComponent: UserInfoHousingAccessAddModalComponent;
  @Input() user;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
              private router: Router
  ) {
    this.eventsService.on('userEditReloadHousingAccessRights', () => {
      this.sendRequest();
    });
    this.environment_ = environment;
  }

  public ngOnInit() {
    this.sendRequest();
  }

  public sendRequest() {
    this.showLoading();
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/user/' + this.user._id + '/housing-access-rights?page=' + this.page + '&limit=' + this.limit)
      .subscribe(
        (response: any) => {
          this.rights = response.elements;
          this.paginationData = response.pagination;
        },
        (err) => {
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
        }
      );
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

  public sendPage(page: number) {
    this.page = page;
    this.sendRequest();
  }

  public showAddHousingRightModal() {
    this.userInfoHousingAccessAddModalComponent.showChildModal();
  }

  public requestDelete(rightId: string) {
    this.showLoading();
    this.http.delete(environment.urls.service + '/api/v1/authenticated/admin/user/' + this.user._id + '/housing-access-rights/' + rightId)
      .subscribe(
        (response: any) => {
          // this.rights = response.elements;
          // this.paginationData = response.pagination;
          this.sendRequest();
        },
        (err) => {
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
        }
      );
  }

}
