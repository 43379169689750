import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {LoadingIndicatorService} from "../../../../../services/loading-indicator/loading-indicator.service";
import {EventsService} from "../../../../../services/events/events.service";
import {HousingInfoService} from "../../../../../services/housing-info/housing-info.service";
import {ImageModalComponent} from "./image-modal/image-modal.component";

@Component({
  selector: 'app-housing-info-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class HousingInfoDetailsImagesComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  @ViewChild('fileInput') fileInput;
  @ViewChild('imageModalComponent') imageModalComponent: ImageModalComponent;
  public images: Array<string> = [];
  public imageSelected: string;
  public isLoading: boolean = false;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private eventsService: EventsService) {
  }

  ngOnInit() {
    this.eventsService.on('housingInfoShowImageModal', (imageId) => {
      this.showImageInModal(imageId);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  private mapHousingToForm() {
    this.images = [];

    if (this.housing.imagesOptimized != null && this.housing.imagesOptimized.list != null) {
      for (const imageCategory in this.housing.imagesOptimized.list) {
        if (this.housing.imagesOptimized.list.hasOwnProperty(imageCategory)) {
          for (const image of this.housing.imagesOptimized.list[imageCategory]) {
            this.images.push(image);
          }
        }
      }
    }
  }

  upload() {
    let fileBrowser = this.fileInput.nativeElement;
    const formData = new FormData();
    formData.append("category", "general");

    if (fileBrowser.files && fileBrowser.files[0]) {
      for (const file of fileBrowser.files) {
        formData.append("files", file);
      }
    }

    this._upload(formData);
  }

  _upload(formData) {
    this.loadingIndicatorService.showLoadingBar();

    let headers = new HttpHeaders();
    headers.delete('Content-Type');

    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/images', formData, {headers: headers})
      .subscribe((response: any) => {
          this.housingInfoService.setSelectedHousing(response);
          this.loadingIndicatorService.hideLoadingBar();

        },
        error => {
          if (error.error === "Unauthorized") {
          }
          console.log("Error", error);
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));

        }
      );
  }

  onSubmitImport() {
    this.loadingIndicatorService.showLoadingBar();
    this.isLoading = true;

    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/images/booking', {})
      .subscribe((response: any) => {
          this.housingInfoService.setSelectedHousing(response);
          this.loadingIndicatorService.hideLoadingBar();
          this.isLoading = false;
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          console.log("Error", error);
          this.loadingIndicatorService.hideLoadingBar();
          this.isLoading = false;
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));

        }
      );
  }

  public showImageInModal(image: string) {
    this.imageSelected = image;
    this.imageModalComponent.showModal();
  }
}
