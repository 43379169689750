<div class="block block-rounded block-themed" *ngIf="housing != null">
  <div class="block-header bg-gd-primary">
    <h3 class="block-title">Registration Details</h3>
  </div>
  <div class="block-content block-content-full">
    <h5>General</h5>
    <div class="row">
      <div class="col-3">Registered:</div>
      <div class="col-9">
        {{housing.topPartnerProgram.registered}}
      </div>
    </div>

    <ng-container *ngIf="housing.topPartnerProgram.registered === true">
      <div class="row">
        <div class="col-3">Registered At:</div>
        <div class="col-9">
          {{housing.topPartnerProgram.registeredAt}}
        </div>
      </div>
      <div class="row">
        <div class="col-12"><hr></div>
      </div>
      <h5>Contact</h5>

      <div class="row">
        <div class="col-3">First Name:</div>
        <div class="col-9">
          {{housing.topPartnerProgram.contact.firstName}}
        </div>
      </div>
      <div class="row">
        <div class="col-3">Last Name:</div>
        <div class="col-9">
          {{housing.topPartnerProgram.contact.lastName}}
        </div>
      </div>
      <div class="row">
        <div class="col-3">Email:</div>
        <div class="col-9">
          {{housing.topPartnerProgram.contact.email}}
        </div>
      </div>
      <ng-container *ngIf="housing.topPartnerProgram.messageForTripmakery != null">
        <div class="row">
          <div class="col-12"><hr></div>
        </div>
        <h5>Message</h5>
        <div class="row">
          <div class="col-12">
            {{housing.topPartnerProgram.messageForTripmakery}}
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
