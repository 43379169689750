import {Injectable} from '@angular/core';
import {AccountService} from "../accountservice/account.service";
import {ConfigService} from "../configservice/config.service";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
              private accountService: AccountService,
              private configService: ConfigService) {
  }

  public getJWT() {
    let token = this.accountService.getToken();

    if (token == null) {
      token = this.configService.getJWT();

      if (token) {
        this.accountService.setToken(token);
      }
    }

    return token;
  }

  public load() {
    return new Promise((resolve: any) => {
      if (this.getJWT() == null) {
        resolve(true);
        return;
      }

      let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.getJWT()});

      this.http.get(environment.urls.service + '/api/v1/user/check-token', {headers: headers})
        .subscribe((response: any) => {
            this.accountService.setUserId(response.user._id);
            resolve(true);
          },
          error => {
            console.log(error);
            // if (error.error === "Unauthorized") {
              this.accountService.setToken(null);
              this.configService.setJWT(null);
              resolve(true);
            // }
          }
        );
    });
  }
}

export function initConfiguration(authService: AuthService): Function {
  return () => authService.load();
}
