export const environment = {
  testing: false,
  production: false,
  staging: true,
  urls: {
    service: "https://service.stage.tripmakery.com",
    blob: "https://tripmakerystaging.blob.core.windows.net/housings/img",
    kitchen: "https://admin.stage.tripmakery.com",
    partner: "https://partner.stage.tripmakery.com",
    pro: "https://pro.stage.tripmakery.com",
    tool: "https://admin-tool.stage.tripmakery.com",
    user: "https://user.stage.tripmakery.com",
    landingpage: "https://stage.tripmakery.com"
  },
  xauth: 'RmtIp7aeVbHxNjxaQgpN1MDAjkbIHiSKUlkV23SlLHAtKcpbPv24YuXwEgftFsBY',
};
