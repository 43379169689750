import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadCrumbElement } from '../../../../shared/entities/breadcrumb.element.entity';
import { PagingService } from '../../../../services/pagingservice/paging.service';
import { Amenity, HousingType } from '@tripbakers/enums';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularParams } from './interfaces/angular.params.interface';
import { IUserListElement } from './interfaces/user-list-element.interface';
import { UserAddUserModalComponent } from '../../user-add-modal/user-add-user-modal.component';

declare const Codebase;

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnInit {
  @ViewChild('userAddUserModalComponent') userAddUserModalComponent: UserAddUserModalComponent;

  /* */
  public page = 1;

  /* */
  public sort = 'stars';

  /* */
  public userTotal = 0;

  /* */
  public sorting = -1;

  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};
  public users: Array<IUserListElement> = [];
  public paginationData;
  public form: FormGroup;
  public form2: FormGroup;

  constructor(private pagingService: PagingService,
              private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private route: ActivatedRoute,
              private router: Router,
              private activeRoute: ActivatedRoute
  ) {
    const formGroupData = {
      'email': new FormControl({value: '', disabled: false}),
      'firstName': new FormControl({value: '', disabled: false}),
      'lastName': new FormControl({value: '', disabled: false}),
      'isB2BUser': new FormControl({value: false, disabled: false}),
      'isHousingUserAccount': new FormControl({value: false, disabled: false}),
    };

    this.form = new FormGroup(formGroupData);

    this.form2 = new FormGroup({
      'limit': new FormControl({value: '25', disabled: false}),
      'sortingMode': new FormControl({value: '1', disabled: false}),
      'sortingField': new FormControl({value: 'name', disabled: false})
    });

  }

  ngOnInit() {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Users', 'javascript:void(0)')
    ];

    this.activeRoute.queryParams.subscribe((params: AngularParams) => {
      console.log('Angular URL Params: ' + JSON.stringify(params));
      this.mapAngularQueryParamsToForm(params);

      const httpParams: HttpParams = this.setHttpParamsFromFilledForm();
      console.log('Created HTTP Params: ' + httpParams);
      this.sendRequest(httpParams);
    });

    Codebase.helper('core-tab');
  }

  public submitForm() {
    const queryParams = {
      page: this.page,
      limit: this.form2.get('limit').value,
      email: this.form.get('email').value,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
      isB2BUser: this.form.get('isB2BUser').value,
      isHousingUserAccount: this.form.get('isHousingUserAccount').value,
    };

    this.router.navigate(['./'], {
      relativeTo: this.activeRoute,
      queryParams: queryParams,
      replaceUrl: true
    });
  }

  public mapAngularQueryParamsToForm(params: AngularParams) {
    for (const field in params) {
      if (params.hasOwnProperty(field)) {

        /* */
        if (field === 'email') {
          this.form.patchValue({'email': params[field]});
        }

        /* */
        if (field === 'firstName') {
          this.form.patchValue({'firstName': params[field]});
        }

        /* */
        if (field === 'lastName') {
          this.form.patchValue({'lastName': params[field]});
        }

        /* */
        if (field === 'isB2BUser') {
          this.form.patchValue({'isB2BUser': params[field] === 'true'});
        }

        /* */
        if (field === 'isHousingUserAccount') {
          this.form.patchValue({'isHousingUserAccount': params[field] === 'true'});
        }

        /* */
        if (field === 'page') {
          const page = params[field];
          if (page !== '' && !isNaN(parseInt(page, 10))) {
            this.page = parseInt(page, 10);
          }
        }

        /* */
        if (field === 'limit') {
          this.form2.patchValue({'limit': params[field]});
        }

      }
    }
  }

  public setHttpParamsFromFilledForm() {
    let params: HttpParams = new HttpParams();

    /* */
    const email = this.form.get('email').value.trim();
    if (email !== '') {
      params = params.append('email', email);
    }

    /* */
    const firstName = this.form.get('firstName').value.trim();
    if (firstName !== '') {
      params = params.append('firstName', firstName);
    }

    /* */
    const lastName = this.form.get('lastName').value.trim();
    if (lastName !== '') {
      params = params.append('lastName', lastName);
    }

    /* */
    const isB2BUser = this.form.get('isB2BUser').value;
    if (isB2BUser !== null) {
      params = params.append('isB2BUser', isB2BUser.toString());
    }

    /* */
    const isHousingUserAccount = this.form.get('isHousingUserAccount').value;
    if (isHousingUserAccount !== null) {
      params = params.append('isHousingUserAccount', isHousingUserAccount.toString());
    }

    /* */
    if (this.page != null) {
      params = params.append('page', this.page.toString(10));
    }

    if (this.form2.get('limit').value !== '') {
      params = params.append('limit', this.form2.get('limit').value);
    }

    return params;
  }

  public sendRequest(params: HttpParams) {
    this.loadingIndicatorService.showLoadingBar();

    /* Server Anfrage */
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/user', {
      params: params
    })
      .subscribe(
        (response: any) => {

          this.users = response.elements;
          this.paginationData = response.pagination;
          this.userTotal = response.pagination.total;
        },
        (error) => {

        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public getTokenAndForwardToProTool(userId: string) {
    this.loadingIndicatorService.showLoadingBar();

    /* Server Anfrage */
    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/user/' + userId + '/login-token', {})
      .subscribe(
        (response: any) => {
          const token = response.loginToken.token;
          const url = environment.urls.pro + '?token=' + token;
          window.open(url, '_blank');
        },
        (error) => {

        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  /* */
  public sendPage(page: number) {
    this.page = page;
    this.submitForm();
  }

  public showAddUserModal() {
    this.userAddUserModalComponent.showChildModal();
  }
}



