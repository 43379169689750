<app-content-header i18n-title title="Offer Details" i18n-subTitle
                    subTitle="View Stuff"
                    [backgroundImage]="backgroundImage"
                    [containerClass]="containerClass"
                    [subContainerClass]="subContainerClass"
                    [pyClass]="pyClass"
                    [contentClass]="contentClass"
                    [h1class]="h1class"
                    [h2class]="h2class"
                    [subContainerBackgroundImage]="subContainerBackgroundImage"
                    [containerBackgroundImage]="containerBackgroundImage"
></app-content-header>

<app-content-breadcrumb [elements]="breadCrumbElements"></app-content-breadcrumb>

<div class="content">


  <div class="row">

    <div class="col-md-12">

      <div class="block">
        <div class="block-header block-header-default">
          <h3 class="block-title">Offer Details</h3>
        </div>

        <ng-container *ngIf="offer != null">
          <div class="block-content">
            <div class="table-responsive">
TODO
            </div>
          </div>
        </ng-container>

      </div>
    </div>

  </div>
</div>

