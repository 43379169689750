import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EventsService } from '../../../../../services/events/events.service';
import { FormControl, FormGroup } from '@angular/forms';
import { HousingType } from '@tripbakers/enums';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GoogleLocationMapper } from '../../../../../services/googlemapper/google-location-mapper.service';
import { LoadingIndicatorService } from '../../../../../services/loading-indicator/loading-indicator.service';
import { HousingInfoService } from '../../../../../services/housing-info/housing-info.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { FieldsBasicPlaceDetails } from '../../../../../shared/consts/fields-basic-place-details';

@Component({
  selector: 'app-housing-info-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css']
})
export class HousingInfoDetailsBasicInfoComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  form: FormGroup;
  housingTypes: Array<string>;
  public options = {
    fields: FieldsBasicPlaceDetails,
  };
  public housingLocation = {};
  public cityLocation = {};
  public googleLocationMapper;
  isLoading: boolean = false;
  isLocationInUseAlready = false;
  locationInUseHousingId: string;
  locationInUseHousingName: string;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.isLocationInUseAlready = false;
    this.locationInUseHousingId = null;
    this.locationInUseHousingName = null;

    this.googleLocationMapper = new GoogleLocationMapper();
    this.housingTypes = Object.values(HousingType);

    this.form = new FormGroup({
      'name': new FormControl({value: '', disabled: true}),
      'email': new FormControl({value: '', disabled: true}),
      'phone': new FormControl({value: '', disabled: true}),
      'homepage': new FormControl({value: '', disabled: true}),
      'stars': new FormControl({value: '', disabled: true}),
      'displayStarsAsPoints': new FormControl({value: false, disabled: false}),
      'price': new FormControl({value: '', disabled: true}),
      'priceAdjusted': new FormControl({value: '', disabled: true}),
      'checkIn': new FormControl({value: '', disabled: true}),
      'checkOut': new FormControl({value: '', disabled: true}),
      'isPartner': new FormControl({value: false, disabled: true}),
      'isTopPartner': new FormControl({value: false, disabled: true}),
      'isExtranetPartner': new FormControl({value: false, disabled: true}),
      'priceCalculationLockedByAdmin': new FormControl({value: false, disabled: true}),
      // 'commission': new FormControl({value: '10', disabled: false}),
      // 'language': new FormControl({value: null, disabled: false}),
      'marketplace': new FormControl({value: false, disabled: true}),
      'city': new FormControl({value: false, disabled: true}),
      'address': new FormControl({value: false, disabled: true}),
    });

    for (const housingType of this.housingTypes) {
      this.form.addControl('housingType_' + housingType, new FormControl({value: false, disabled: true}));
    }
  }

  onSubmitForm() {
    if (this.form.status !== 'VALID') {
      return;
    }
    this.showLoading();


    this.isLocationInUseAlready = false;
    this.locationInUseHousingId = null;
    this.locationInUseHousingName = null;

    this.postData().subscribe(
      (response: any) => {
        this.hideLoading();
        this.housingInfoService.setSelectedHousing(response);
      },
      err => {
        console.log('Catched an error', err);

        if (err.error.message === 'E_VALIDATION_FAILED') {
          console.log('Just a validation Error');
          try {
            for (const error of err.error.errors) {
              this.form.get(error.property).setErrors(error.constraints);
              this.form.get(error.property).markAsDirty();

              console.log(error.property, error.constraints);
            }
          } catch (error) {
            console.log('Catched E', error);
          }
          this.hideLoading();
          return;
        } else if (err.error.message === 'E_NON_UNIQUE_GOOGLE_PLACE_ID') {
          this.isLocationInUseAlready = true;
          this.locationInUseHousingId = err.error.housing._id;
          this.locationInUseHousingName = err.error.housing.name;
        } else {
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        }
        this.hideLoading();
      }
    );
  }

  private postData() {
    const body = {
      name: this.form.get('name').value,
      email: this.form.get('email').value,
      phone: this.form.get('phone').value,
      homepage: this.form.get('homepage').value,
      stars: this.createNullIfEmptyStringOrReturnFloat(this.form.get('stars').value),
      displayStarsAsPoints: this.form.get('displayStarsAsPoints').value === true,
      price: this.createNullIfEmptyStringOrReturnFloat(this.form.get('price').value),
      checkIn: this.form.get('checkIn').value,
      checkOut: this.form.get('checkOut').value,
      isPartner: this.form.get('isPartner').value,
      isTopPartner: this.form.get('isTopPartner').value,
      isExtranetPartner: this.form.get('isExtranetPartner').value,
      // commission: this.createNullIfEmptyStringOrReturnFloat(this.form.get('commission').value),
      // language: this.form.get('language').value,
      marketplace: this.form.get('marketplace').value,
      city: this.form.get('city').value,
      address: this.form.get('address').value,
      priceCalculationLockedByAdmin: this.form.get('priceCalculationLockedByAdmin').value,
    };
    body['housingLocation'] = this.housingLocation;

    const selectedHousingTypes = [];
    for (const housingType of this.housingTypes) {
      if (this.form.get('housingType_' + housingType).value === true) {
        selectedHousingTypes.push(housingType);
      }
    }
    body['types'] = selectedHousingTypes;

    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/basic-info', body);
  }

  public handleAddressChangeOfCity(address: Address) {
    console.log(address);
    console.log('Before: ' + this.housing.location.cityGooglePlaceId);

    /* */
    if (this.housing.location.locationGooglePlaceIds == null) {
      this.housing.location.locationGooglePlaceIds = [];
    }
    let position = this.housing.location.locationGooglePlaceIds.indexOf(this.housing.location.cityGooglePlaceId);
    if (position !== -1) {
      this.housing.location.locationGooglePlaceIds.slice(position, 1);
    }

    /* */
    this.housing.location.cityGooglePlaceId = address.place_id;
    position = this.housing.location.locationGooglePlaceIds.indexOf(this.housing.location.cityGooglePlaceId);
    if (position === -1) {
      this.housing.location.locationGooglePlaceIds.push(this.housing.location.cityGooglePlaceId);
    }

    /* */
    const mappedLocation = this.googleLocationMapper.map(address);
    this.housing.location.city = mappedLocation.city;

    console.log('After: ' + this.housing.location.cityGooglePlaceId);
  }

  public handleAddressChangeOfAddress(address: Address) {
    const oldHousingLocation = this.housingLocation;

    this.housingLocation = this.googleLocationMapper.map(address);
    this.housingLocation['cityGooglePlaceId'] = this.housing.location.cityGooglePlaceId;
    this.housingLocation['locationGooglePlaceIds'] = this.housing.location.locationGooglePlaceIds;


    if (this.housingLocation['city'] == null && oldHousingLocation['city'] != null) {
      this.housingLocation['city'] = oldHousingLocation['city'];
    }

    console.log(this.housingLocation);
  }


  ngOnInit() {
    if (this.housing != null) {
      this.mapHousingToForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  private mapHousingToForm() {
    this.housingLocation = this.housing.location;

    const vals = {
      name: this.housing ? this.createEmptyStringIfNull(this.housing.name) : '',
      email: this.housing ? this.createEmptyStringIfNull(this.housing.email) : '',
      phone: this.housing ? this.createEmptyStringIfNull(this.housing.phone) : '',
      homepage: this.housing ? this.createEmptyStringIfNull(this.housing.homepage) : '',
      stars: this.housing ? this.createEmptyStringIfNull(this.housing.stars) : '',
      price: this.housing ? this.createEmptyStringIfNull(this.housing.price) : '',
      displayStarsAsPoints: this.housing && this.housing.preferences && this.housing.preferences.displayStarsAsPoints != null ? this.housing.preferences.displayStarsAsPoints : false,
      priceAdjusted: this.housing ? this.createEmptyStringIfNull(this.housing.priceAdjusted) : '',
      checkIn: this.housing && this.housing.checkIn ? this.housing.checkIn : '',
      checkOut: this.housing && this.housing.checkOut ? this.housing.checkOut : '',
      isPartner: this.housing && this.housing.internalInfo.isPartner ? this.housing.internalInfo.isPartner : false,
      isTopPartner: this.housing && this.housing.internalInfo.isTopPartner ? this.housing.internalInfo.isTopPartner : false,
      isExtranetPartner: this.housing && this.housing.internalInfo.isExtranetPartner ? this.housing.internalInfo.isExtranetPartner : false,
      marketplace: this.housing && this.housing.internalInfo.marketplace != null ? this.housing.internalInfo.marketplace : false,
      priceCalculationLockedByAdmin: this.housing && this.housing.internalInfo.priceCalculationLockedByAdmin != null ? this.housing.internalInfo.priceCalculationLockedByAdmin : false,
      // commission: this.housing && this.housing.internalInfo && this.housing.internalInfo.commission ? this.housing.internalInfo.commission : '10',
      // language: this.housing && this.housing.preferences && this.housing.preferences.language ? this.housing.preferences.language : null,
      city: this.housing.location.city + ', ' + this.housing.location.country,
      address: this.housing.location.street + ', ' + this.housing.location.city + ', ' + this.housing.location.country
    };

    for (const housingType of this.housingTypes) {
      vals['housingType_' + housingType] = (this.housing.type != null) ? this.housing.type.indexOf(housingType) !== -1 : false;
    }


    this.form.setValue(
      vals
    );

    this.enableDisableFormCtrl();

    // @TYPE
    // @PARTNER
    // @AMENITIES
  }

  private enableDisableFormCtrl() {
    const enable = (this.housing != null);
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (enable) {
          this.form.controls[key].enable();
        } else {
          this.form.controls[key].disable();
        }
      }
    }
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  private createEmptyStringIfNull(s: string) {
    if (s == null) {
      return '';
    }

    return s;
  }

  private createNullIfEmptyStringOrReturnFloat(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    const d = parseFloat(s);

    if (isNaN(d)) {
      return s;
    }

    return d;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

}
