<div class="block block-rounded block-themed" *ngIf="validationError != null">
  <div class="block-header bg-gd-primary">
    <h3 class="block-title">Body</h3>
    <div class="block-options">
    </div>
  </div>
  <div class="block-content block-content-full">
    <code>
      {{stringify(validationError.body)}}
    </code>
  </div>
</div>

<div class="block block-rounded block-themed" *ngIf="validationError != null">
  <div class="block-header bg-gd-primary">
    <h3 class="block-title">Header</h3>
    <div class="block-options">
    </div>
  </div>
  <div class="block-content block-content-full">
    <code>
      {{stringify(validationError.headers)}}
    </code>
  </div>
</div>

