import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BreadCrumbElement} from "../../../../shared/entities/breadcrumb.element.entity";
import {LoadingIndicatorService} from "../../../../services/loading-indicator/loading-indicator.service";
import {ConfigService} from "../../../../services/configservice/config.service";
import {EventsService} from "../../../../services/events/events.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {
  AgmMap,
  LatLngLiteral,
  PolyMouseEvent
} from "@agm/core";
import {MouseEvent} from "@agm/core";
import * as mapTypes from "@agm/core/services/google-maps-types";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit, AfterViewInit {
  @ViewChild(AgmMap) map: any;
  polygon: mapTypes.Polygon;

  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  public amountHousings = 0;
  public amountRequests = 0;

  public boundary: any;
  public performanceDatabaseQuery: number;

  public paths: Array<Array<LatLngLiteral>> = [];
  public pathsOutput: string;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private configService: ConfigService,
              private eventsService: EventsService,
              private http: HttpClient,
              private router: ActivatedRoute) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }

  public addPolygon(event: MouseEvent) {
    console.log(event);

    const coordsTotal = this.extractCoordinatesAsLatLng();

    const mod = 1.05;
    const coords = [];
    coords.push({lat: event.coords.lat + mod, lng: event.coords.lng + mod});
    coords.push({lat: event.coords.lat + mod, lng: event.coords.lng - mod});
    coords.push({lat: event.coords.lat - mod, lng: event.coords.lng - mod});
    coords.push({lat: event.coords.lat - mod, lng: event.coords.lng + mod});

    coordsTotal.push(coords);


    this.createPolygonByCoords(coordsTotal);

  }

  public createPolygonByCoords(coords: Array<any>) {
    if (this.polygon != null) {
      this.polygon.setMap(null);
      this.polygon = null;
    }

    this.map._mapsWrapper.createPolygon({
      paths: coords,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.3,
      editable: true,
      draggable: false,
      clickable: true,
    }).then((polygon: mapTypes.Polygon) => {
      this.polygon = polygon;

      this.polygon.addListener('click', (event) => {
        this.handleMouseClick(event);
      });
    });
  }

  public extractCoordinatesAsLatLng() {
    if (this.polygon == null) {
      return [];
    }

    const verticesArray: any = this.polygon.getPaths();
    let arr = [];

    // Iterate over the vertices.
    for (let j = 0; j < verticesArray.getLength(); j++) {
      const vertices = verticesArray.getAt(j);
      const localArray = [];
      for (let i = 0; i < vertices.getLength(); i++) {
        const xy = vertices.getAt(i);
        localArray.push({lat: xy.lat(), lng: xy.lng()});
      }

      arr.push(localArray);
    }

    return arr;
  }

  public handleButtonExportClick() {
    const verticesArray: any = this.polygon.getPaths();
    let arr = [];

    // Iterate over the vertices.
    for (let j = 0; j < verticesArray.getLength(); j++) {
      const vertices = verticesArray.getAt(j);
      const localArray = [];
      for (let i = 0; i < vertices.getLength(); i++) {
        const xy = vertices.getAt(i);
        localArray.push([xy.lng(), xy.lat()]);
      }

      arr.push(localArray);
    }

    /* */
    for (const coordinateArray of arr) {
      if (coordinateArray[0] !== coordinateArray[coordinateArray.length - 1]) {
        coordinateArray.push(coordinateArray[0]);
      }
    }

    this.pathsOutput = JSON.stringify(arr);
  }

  public handleMouseClick(event: PolyMouseEvent) {
    console.log(event);

    console.log("Path: " + event.path);
    console.log("Vertex: " + event.vertex);

    const coords = this.extractCoordinatesAsLatLng();

    if (event.path != null && event.vertex != null) {
      console.log(coords[event.path][event.vertex]);
      console.log("Length: " + coords[event.path].length);
      if (coords[event.path].length <= 2) {
        console.log("Removing whole poly entry");
        coords.splice(event.path, 1)
      } else {
        console.log("Removing vertex");
        coords[event.path].splice(event.vertex, 1);
      }
    }

    this.createPolygonByCoords(coords);
  }

  public testBoundaryDetailsNew() {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + this.configService.getJWT()});
    this.loadingIndicatorService.showLoadingBar();


    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/boundary/test-query',
      {
        boundariesOptimized: JSON.parse(this.pathsOutput),
      },
      {headers: headers})
      .subscribe((response: any) => {
          console.log(response);
          this.amountRequests = response.amountRequests;
          this.amountHousings = response.amountHousings;
          this.performanceDatabaseQuery = parseFloat(response.performance[0] + '.' + (response.performance[1] / 1000000));
          this.loadingIndicatorService.hideLoadingBar();
        },
        error => {
          if (error.error === "Unauthorized") {
          }
          this.loadingIndicatorService.hideLoadingBar();
          this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(error));
        }
      )
  }

}
