import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {environment} from "../../../../../../environments/environment";
import {LoadingIndicatorService} from "../../../../../services/loading-indicator/loading-indicator.service";
import {HousingInfoService} from "../../../../../services/housing-info/housing-info.service";
import {HttpClient} from "@angular/common/http";
import {EventsService} from "../../../../../services/events/events.service";
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-housing-info-details-deleted',
  templateUrl: './deleted.component.html',
  styleUrls: ['./deleted.component.css']
})
export class HousingInfoDetailsDeletedComponent implements OnInit, OnChanges {
  @Input('housing') housing;
  form: FormGroup;
  isLoading: boolean = false;

  /* */
  minMode: BsDatepickerViewMode = 'day';
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(private http: HttpClient,
              private housingInfoService: HousingInfoService,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'deleted': new FormControl({value: false, disabled: true}),
      'closedUntil': new FormControl({value: '', disabled: true})
    });
  }

  onSubmitForm() {
    if (this.form.status !== 'VALID') {
      return;
    }
    this.showLoading();

    this.postData().subscribe(
      (response: any) => {
        this.hideLoading();
        this.housingInfoService.setSelectedHousing(response);
      },
      err => {

        if (err.error.message === "E_VALIDATION_FAILED") {
          try {
            for (const error of err.error.errors) {
              this.form.get(error.property).setErrors(error.constraints);
              this.form.get(error.property).markAsDirty();
            }
          } catch (error) {
            console.log("Catched E", error)
          }
          this.hideLoading();
          return;
        }

        this.hideLoading();
        this.eventsService.broadcast("requestShowErrorMessage", JSON.stringify(err));
      }
    )
  }

  private postData() {
    return this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/housing/' + this.housing._id + '/delete', {
      deleted: this.form.get('deleted').value,
      closedUntil: this.form.get('closedUntil').value == "" ? null : this.form.get('closedUntil').value,
      deletedReasons: []
    });
  }

  ngOnInit() {
    if (this.housing != null) {
      this.mapHousingToForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.housing.currentValue != null) {
      this.housing = changes.housing.currentValue;
      this.mapHousingToForm();
    }
  }

  private mapHousingToForm() {
    const vals = {
      deleted: this.housing.internalInfo == null ? false : this.housing.internalInfo.deleted,
      closedUntil: this.housing.internalInfo == null || this.housing.internalInfo.closedUntil == null ? null : moment.utc(this.housing.internalInfo.closedUntil).toDate(),
    };

    this.form.setValue(
      vals
    );

    this.enableDisableFormCtrl();
  }

  private enableDisableFormCtrl() {
    const enable = (this.housing != null);
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (enable) {
          this.form.controls[key].enable();
        } else {
          this.form.controls[key].disable();
        }
      }
    }
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

}
