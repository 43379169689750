import {Component, OnInit} from '@angular/core';
import {BreadCrumbElement} from '../../../../shared/entities/breadcrumb.element.entity';
import {ActivatedRoute, Router} from '@angular/router';
import {EventsService} from '../../../../services/events/events.service';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LoadingIndicatorService} from '../../../../services/loading-indicator/loading-indicator.service';
import {HousingInfoService} from '../../../../services/housing-info/housing-info.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-request-search',
  templateUrl: './request-search.component.html',
  styleUrls: ['./request-search.component.css']
})
export class RequestSearchComponent implements OnInit {
  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [
    new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
    new BreadCrumbElement('Requests', 'javascript:void(0)'),
  ];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};

  /* */
  public request;
  public pipeline;
  public requestId: string;

  /* */
  public form: FormGroup;

  /* */
  constructor(private activatedRoute: ActivatedRoute,
              public housingInfoService: HousingInfoService,
              private eventsService: EventsService,
              private loadingIndicatorService: LoadingIndicatorService,
              private http: HttpClient,
              private router: Router) {
    this.form = new FormGroup({
      'requestId': new FormControl({value: '', disabled: false}),
    });
  }

  public ngOnInit() {

  }

  public async performSearch() {
    const requestId = this.form.get('requestId').value;
    await this.router.navigate(['requests', requestId]);
  }

}
