import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadCrumbElement } from '../../../../shared/entities/breadcrumb.element.entity';
import { PagingService } from '../../../../services/pagingservice/paging.service';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularParams } from '../../../pages/destination/destination-search/interfaces/angular.params.interface';
import { GeneratedTextDestinationAddModalComponent } from '../destination-add-modal/generated-text-destination-add-modal.component';

declare const Codebase;

@Component({
  selector: 'app-generated-text-destination-search',
  templateUrl: './generated-text-destination-search.component.html',
  styleUrls: ['./generated-text-destination-search.component.css']
})
export class GeneratedTextDestinationSearchComponent implements OnInit {
  @ViewChild('generatedTextDestinationAddModalComponent') public generatedTextDestinationAddModalComponent: GeneratedTextDestinationAddModalComponent;

  /* */
  public page = 1;
  public limit = 25;

  /* */
  public sort = 'stars';

  /* */
  public sorting = -1;

  /* */
  public breadCrumbElements: Array<BreadCrumbElement> = [];

  /* */
  public backgroundImage = '/assets/media/photos/photo21@2x.jpg';
  public containerBackgroundImage = {'background-image': 'url(' + this.backgroundImage + ')'};
  public subContainerBackgroundImage = {};
  public containerClass = {'bg-image': true};
  public subContainerClass = {'bg-white-op-90': true};
  public contentClass: any = {'content': true, 'text-center': true, 'content-top': true};
  public pyClass = {'py-50': true};
  public h1class: any = {'h1': true};
  public h2class: any = {'h4': true};
  public elements: Array<any> = [];
  public paginationData;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private activeRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.breadCrumbElements = [
      new BreadCrumbElement('tripmakery', 'javascript:void(0)'),
      new BreadCrumbElement('Pages', 'javascript:void(0)'),
      new BreadCrumbElement('Generated Text', 'javascript:void(0)'),
      new BreadCrumbElement('Destinations', 'javascript:void(0)'),
    ];

    this.activeRoute.queryParams.subscribe((params: AngularParams) => {
      console.log('Angular URL Params: ' + JSON.stringify(params));
      this.mapAngularQueryParamsToForm(params);

      const httpParams: HttpParams = this.setHttpParamsFromFilledForm();
      console.log('Created HTTP Params: ' + httpParams);
      this.sendRequest(httpParams);
    });

    Codebase.helper('core-tab');
  }

  public submitForm() {

  }

  public mapAngularQueryParamsToForm(params: AngularParams) {
    for (const field in params) {
      if (params.hasOwnProperty(field)) {

        /* */
        if (field === 'page') {
          const page = params[field];
          if (page !== '' && !isNaN(parseInt(page, 10))) {
            this.page = parseInt(page, 10);
          }
        }

        /* */
        if (field === 'limit') {
          const limit = params[field];
          if (limit !== '' && !isNaN(parseInt(limit, 10))) {
            this.limit = parseInt(limit, 10);
          }
        }
      }
    }
  }

  public setHttpParamsFromFilledForm() {
    let params: HttpParams = new HttpParams();

    /* */
    if (this.page != null) {
      params = params.append('page', this.page.toString(10));
    }

    /* */
    if (this.limit != null) {
      params = params.append('limit', this.limit.toString(10));
    }

    return params;
  }

  public sendRequest(params: HttpParams) {
    this.loadingIndicatorService.showLoadingBar();

    /* Server Anfrage */
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/generated-text/destination', {
      params: params
    })
      .subscribe(
        (response: any) => {
          this.elements = response.elements;
          this.paginationData = response.pagination;
        },
        (error) => {

        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  /* */
  public sendPage(page: number) {
    this.page = page;
    this.submitForm();
  }

}



