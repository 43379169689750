import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-validation-error-details-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.css']
})
export class ValidationErrorDetailsCodeComponent implements OnInit {
  @Input('validationError') public validationError;

  constructor() {
  }

  ngOnInit() {
  }

  public stringify(o: any) {
    return JSON.stringify(o, null, "\t")
  }

}
