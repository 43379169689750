import { Moment } from 'moment';

export class DateRangeUtils {

  /**
   * Creates a DateList from DateRange and returns them as numbers and dates
   *
   * @param mDateStart
   * @param mDateEnd
   */
  public static createDateListFromDateRangeComplete(mDateStart: Moment, mDateEnd: Moment) {
    const now = mDateStart.clone();
    const datesNum = [];
    const datesDate = [];

    while (now.isSameOrBefore(mDateEnd)) {
      datesNum.push(parseFloat(now.format('YYYYMMDD')));
      datesDate.push(now.toDate());
      now.add(1, 'days');
    }
    return {
      datesNum,
      datesDate,
    };
  }

}
