<div class="block block-rounded block-themed" *ngIf="user != null">
  <div class="block-header bg-gd-primary">
    <h3 class="block-title">User Details</h3>
  </div>
  <div class="block-content">
    <div class="form-group row">
      <label class="col-12" for="user-name">Name</label>
      <div class="col-12">
        <span id="user-name" name="user-name">{{user.firstName}} {{user.lastName}}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12" for="user-email">Email</label>
      <div class="col-12">
        <span id="user-email" name="user-email">{{user.email}}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12" for="user-phone">Phone</label>
      <div class="col-12">
        <span id="user-phone" name="user-phone">{{user.phone}}</span>
      </div>
    </div>
  </div>
</div>
